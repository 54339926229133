// Firebase
import { collection, writeBatch, doc, getDoc, getDocs, onSnapshot, orderBy, query, setDoc, Timestamp, updateDoc, where } from 'firebase/firestore';
import { collections, db } from '../../firebaseConfig';

// Managers
import { activity } from './ActivityManager';

class ModelManager {

    /**
     * Method to add a new model.
     * 
     * @param {string} key - App key.
     * @param {string} data - Model data.
     * @param {string} title - Title for the model.
     * @returns {model} - New model.
    */
    async add(appKey, key, data) {

        await setDoc(doc(db, collections.models, key), data);

        activity.log(appKey, 'writes', 1);

        return data;
    }

    /**
     * Deletes a model from the Firestore database.
     * 
     * @param {string} appKey - App key.
     * @param {string} modelKey - The key of the model to delete.
     */
    async delete(appKey, modelKey) {
        try {
            // Start a batch
            const batch = writeBatch(db);

            // Fetch the model to get the modelKey
            const modelRef = doc(db, collections.models, modelKey);
            const modelSnap = await getDoc(modelRef);

            activity.log(appKey, 'reads', 1);

            if (!modelSnap.exists()) {
                console.error("Model does not exist");
                return;
            }

            // Delete the selected model
            batch.delete(modelRef);

            activity.log(appKey, 'deletes', 1);

            // Function to delete documents from a given collection
            const deleteFromCollection = async (collectionPath) => {
                const querySnapshot = await getDocs(query(collection(db, collectionPath), where("modelKey", "==", modelKey)));
                querySnapshot.forEach((doc) => {
                    batch.delete(doc.ref);
                    activity.log(appKey, 'deletes', 1);
                });
            };

            // Delete fields associated with the model
            await deleteFromCollection(collections.fields);

            // Delete objects from their specific collection
            const objectsQuerySnapshot = await getDocs(collection(db, modelKey));

            activity.log(appKey, 'reads', objectsQuerySnapshot.docs.length);

            objectsQuerySnapshot.forEach((doc) => {
                batch.delete(doc.ref);
                activity.log(appKey, 'deletes', 1);
            });

            // Delete related index entries
            const indexQuery = query(collection(db, collections.index), where("modelKey", "==", modelKey));
            try {
                const querySnapshot = await getDocs(indexQuery);

                activity.log(appKey, 'reads', querySnapshot.docs.length);

                if (querySnapshot.empty) {
                    console.log(`No indexes found for model key ${modelKey}.`);
                } else {
                    querySnapshot.forEach((doc) => {
                        batch.delete(doc.ref);  // Avoid async in batch delete
                    });

                    activity.log(appKey, 'deletes', querySnapshot.docs.length);
                }
            } catch (error) {
                console.error(`Error deleting index entries for model key ${modelKey}:`, error);
                throw error;  // Propagate the error if deletion fails
            }

            // Commit the batch
            await batch.commit();

        } catch (error) {
            console.error('Error deleting model:', error);
            // Return an error message
            return { success: false, message: "Error deleting model." };
        }
    }


    /**
      * Fetches models for an app.
      * 
      * @param {string} appKey - App key to get models for.
      */
    async list(appKey) {
        try {
            const modelsCollection = collection(db, collections.models);
            const q = query(modelsCollection, where("appKey", "==", appKey), orderBy("sort"));

            try {
                const snapshot = await getDocs(q);
                activity.log(appKey, 'reads', snapshot.docs.length);
                return snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
            } catch (error) {
                console.error("Error fetching models:", error);
                throw error;
            }
        } catch (error) {
            console.error("Error setting up model fetch:", error);
            throw error;
        }
    }

    /**
      * Fetches models and subscribes to real-time updates.
      * 
      * @param {string} appKey - App key.
      * @param {function} onUpdate - Callback function that handles the update.
      */
    listAndSubscribe(appKey, onUpdate) {
        try {
            // Create a reference to the models collection
            const modelsCollection = collection(db, collections.models);

            // Create a query to find models by appKey and sort them by title
            const q = query(modelsCollection, where("appKey", "==", appKey), orderBy("sort"));

            // Subscribe to real-time updates
            const unsubscribe = onSnapshot(q, snapshot => {
                const modelList = snapshot.docs.map(doc => ({
                    id: doc.id, 
                    ...doc.data()
                }));

                // Call the onUpdate callback with the updated list
                if (onUpdate) {
                    onUpdate(modelList);
                    activity.log(appKey, 'reads', modelList.length);
                }
            }, error => {
                console.error("Error fetching models:", error);
            });

            // Return the unsubscribe function to allow the caller to unsubscribe later
            return unsubscribe;
        } catch (error) {
            console.error("Error setting up real-time updates:", error);
            throw error;
        }
    }

    /**
      * Fetches a model by its model key.
      * 
      * @param {string} appKey - App key.
      * @param {string} modelKey - Key of the model to retrieve.
      */
    async get(appKey, modelKey) {
        try {
            // Create a reference to a specific model document using modelKey
            const modelRef = doc(db, collections.models, modelKey);

            // Get the document
            const docSnap = await getDoc(modelRef);

            activity.log(appKey, 'reads', 1);

            if (docSnap.exists()) {
                // If the document exists, construct and return the model object
                return {
                    id: docSnap.id,
                    ...docSnap.data() 
                };
            } else {
                // If no document exists for the provided key, handle accordingly
                console.log("No model found with the given modelKey.");
                return null;
            }
        } catch (error) {
            console.error("Error fetching model:", error);
            throw error;
        }
    }

    /**
     * Updates a model's data in the Firestore database.
     *
     * @param {string} appKey - App key.
     * @param {string} modelKey - The key (document ID) of the model to update.
     * @param {object} data - An object containing the model properties and their new values.
     */
    async update(appKey, modelKey, data) {
        try {
            const now = Timestamp.now();

            // Ensure dateModified is always updated to the current timestamp
            data.dateModified = now;

            // Update the document in Firestore
            await updateDoc(doc(db, collections.models, modelKey), data);

            // Log the activity - assuming an activity logging function exists
            activity.log(appKey, 'writes', 1);

            // Return a success message
            return { success: true, message: "Model updated successfully." };
        } catch (error) {
            console.error('Error updating model:', error);
            // Return an error message
            return { success: false, message: "Error updating model." };
        }
    }
}

export default ModelManager;
