import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './CalendarMonth.css';

// Components
import CalendarMonthDay from './CalendarMonthDay';
import CalendarMonthDayEvent from './CalendarMonthDayEvent';
import CalendarMonthHeader from './CalendarMonthHeader';

const CalendarMonth = () => {
    const {
        activeDate,
        events,
        selectedApp,
        selectedCalendar,
        selectedCalendarTags,
    } = useContext(Global);

    // Define days of the week labels
    const daysOfWeek = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

    const [days, setDays] = useState([]);
    const [displayEvents, setDisplayEvents] = useState([]);
    const [dayHeight, setDayHeight] = useState(0);
    const [dayWidth, setDayWidth] = useState(100); // Initialize dayWidth as a state variable
    const daysRef = useRef(null);

    // Utilities for event height and margin
    const DAY_HEADER_HEIGHT = 30; // Adjust as needed for day header height
    const EVENT_HEIGHT = 18; // Adjust as needed for event height
    const EVENT_MARGIN = 3; // Adjust as needed for margin below each event

    useEffect(() => {
        if (events && selectedCalendar) {
            const filteredEvents = events.filter(event => {
                if (event.calendarKey !== selectedCalendar.key) return false;
                if (selectedCalendarTags.length === 0) return true;
                return event.tags.some(tag => selectedCalendarTags.includes(tag));
            });
            setDisplayEvents(filteredEvents);
        }
    }, [events, selectedCalendar, selectedCalendarTags]);

    const getMonthDays = (year, month) => new Date(year, month + 1, 0).getDate();
    const getFirstDayOfMonth = (year, month) => new Date(year, month, 1).getDay();

    const generateDays = (year, month) => {
        const daysInMonth = getMonthDays(year, month);
        const firstDayOfMonth = getFirstDayOfMonth(year, month);
        const daysInPreviousMonth = getMonthDays(year, month - 1);
        const days = [];

        for (let i = firstDayOfMonth - 1; i >= 0; i--) {
            days.push({ day: daysInPreviousMonth - i, month: 'prev' });
        }
        for (let i = 1; i <= daysInMonth; i++) {
            days.push({ day: i, month: 'current' });
        }
        let nextMonthDays = 1;
        while (days.length % 7 !== 0) {
            days.push({ day: nextMonthDays++, month: 'next' });
        }
        return days;
    };

    useEffect(() => {
        if (activeDate && selectedCalendar) {
            const year = activeDate.getFullYear();
            const month = activeDate.getMonth();
            const generatedDays = generateDays(year, month);
            setDays(generatedDays);
            if (daysRef.current) {
                const rows = Math.ceil(generatedDays.length / 7);
                const containerHeight = daysRef.current.offsetHeight;
                setDayHeight(containerHeight / rows);
            }
        }
    }, [activeDate, selectedApp, displayEvents, selectedCalendar, daysRef]);

    const recalculatePositions = useCallback(() => {
        if (daysRef.current) {
            const containerWidth = daysRef.current.offsetWidth;
            const containerHeight = daysRef.current.offsetHeight;

            // Calculate dayWidth based on container width and 7 columns
            const newDayWidth = containerWidth / 7;
            setDayWidth(newDayWidth);

            // Calculate dayHeight based on container height and number of rows
            const rows = Math.ceil(days.length / 7);
            setDayHeight(containerHeight / rows);
        }
    }, [days.length]);

    useEffect(() => {
        if (daysRef.current) {
            const resizeObserver = new ResizeObserver(() => {
                recalculatePositions();
            });

            resizeObserver.observe(daysRef.current);

            // Cleanup observer on unmount
            return () => resizeObserver.disconnect();
        }
    }, [recalculatePositions, daysRef]);

    // Helper function to initialize slot tracking for each day of the month
    const formatDayKey = (day) => `${day.day}-${day.month}`;

    const initializeSlotTracker = () => {
        const slots = {};
        days.forEach((day) => {
            if (day.month === 'current') {
                const dateKey = formatDayKey(day);
                slots[dateKey] = []; // Initialize as an empty array for each day
            }
        });
        return slots;
    };

    // Function to find the next available slot for a day in the slot tracker
    const getAvailableSlot = (start, end, slotTracker) => {
        let slotIndex = 0;

        // Loop until we find a slot that is free on every day in the event's span
        while (true) {
            let isSlotAvailable = true;

            // Iterate through each day in the event's span
            let currentDay = new Date(start);
            while (currentDay <= end) {
                const dayKey = formatDayKey({ day: currentDay.getDate(), month: 'current' });

                // Initialize slots for this day if they don't exist
                if (!slotTracker[dayKey]) {
                    slotTracker[dayKey] = [];
                }

                const daySlots = slotTracker[dayKey];

                // If the slotIndex is already occupied on any day, mark as unavailable
                if (daySlots[slotIndex]) {
                    isSlotAvailable = false;
                    break;
                }

                // Move to the next day
                currentDay.setDate(currentDay.getDate() + 1);
            }

            // If the slot is available across all days, reserve it
            if (isSlotAvailable) {
                currentDay = new Date(start);
                while (currentDay <= end) {
                    const dayKey = formatDayKey({ day: currentDay.getDate(), month: 'current' });
                    slotTracker[dayKey][slotIndex] = true; // Reserve the slot for this day
                    currentDay.setDate(currentDay.getDate() + 1);
                }
                return slotIndex; // Return the consistent slot index
            }

            // If not available, try the next slot index
            slotIndex++;
        }
    };


    // Update calculateEventPosition to use the dynamic dayWidth and dayHeight values
    const calculateEventPosition = (start, end, slotTracker) => {
        const totalDays = Math.round((end - start) / (1000 * 60 * 60 * 24)) + 1;
        const startDayIndex = days.findIndex(day =>
            day.day === start.getDate() && day.month === 'current'
        );

        const row = Math.floor(startDayIndex / 7);
        const column = startDayIndex % 7;

        const width = totalDays * dayWidth;
        const left = column * dayWidth;

        // Use getAvailableSlot to find a consistent slot across all days in the span
        const slotIndex = getAvailableSlot(start, end, slotTracker);
        const top = row * dayHeight + slotIndex * (EVENT_HEIGHT + EVENT_MARGIN) + DAY_HEADER_HEIGHT;

        return { width, top, left };
    };

    const splitEventByWeeks = (event) => {
        const startDate = event.startDate.toDate();
        const endDate = event.endDate ? event.endDate.toDate() : startDate;

        let currentStart = new Date(startDate);
        const segments = [];

        while (currentStart <= endDate) {
            // Find the start and end of the current week
            const weekEnd = new Date(currentStart);
            weekEnd.setDate(weekEnd.getDate() + (6 - currentStart.getDay()));

            // Determine the segment end within the event duration
            const segmentEnd = endDate < weekEnd ? endDate : weekEnd;

            segments.push({
                start: new Date(currentStart),
                end: new Date(segmentEnd),
            });

            // Move to the next week
            currentStart = new Date(segmentEnd);
            currentStart.setDate(currentStart.getDate() + 1);
        }

        return segments;
    };

    return (
        <div className="calendar-month-container">
            <div className="calendar-month-header">
                <CalendarMonthHeader daysToShow={daysOfWeek} />
            </div>
            <div ref={daysRef} className="calendar-month-days-wrapper">
                <div className="calendar-month-days">
                    {days.map((day, index) => {
                        return (
                            <CalendarMonthDay
                                key={index}
                                day={day}
                                dayWidth={dayWidth}
                                dayHeight={dayHeight}
                                dayHeaderHeight={DAY_HEADER_HEIGHT}
                            />
                        );
                    })}
                </div>

                {/* Initialize slotTracker once for the entire render */}
                <div className="calendar-month-events-overlay">
                    {(() => {
                        const slotTracker = initializeSlotTracker(); // Initialize only once per render

                        return displayEvents.map((event, index) => {
                            const segments = splitEventByWeeks(event);

                            return segments.map((segment, segmentIndex) => {
                                const { width, top, left } = calculateEventPosition(segment.start, segment.end, slotTracker);

                                return (
                                    <CalendarMonthDayEvent
                                        key={`${index}-${segmentIndex}`}
                                        event={event}
                                        style={{
                                            width: `${width}px`,
                                            top: `${top}px`,
                                            left: `${left}px`,
                                            height: `${EVENT_HEIGHT}px`,
                                            position: 'absolute',
                                        }}
                                    />
                                );
                            });
                        });
                    })()}
                </div>
            </div>
        </div>
    );
};

export default CalendarMonth;
