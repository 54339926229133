import { createGlobalStyle } from 'styled-components';

export const RootStyles = createGlobalStyle`

  body {
    margin: 0;
    font-family: ${(props) => props.fontFamily};
    display: flex;
    flex-direction: column;
  }

  *::-webkit-scrollbar {
    width: 8px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.scrollbarColor} !important;
    border-radius: 4px;
  }

  *::-webkit-scrollbar-thumb:hover {
    background-color: ${(props) => props.scrollbarColor} !important;
    /* Darker shade when hovering over the scrollbar thumb */
  }

  * {
    scrollbar-color: ${(props) => props.scrollbarColor} transparent !important;
    scrollbar-width: thin;
  }

  *::placeholder {
    color: ${(props) => props.placeholderColor};
    opacity: 1; /* to ensure the color is applied fully */
  }

  textarea {
    font-family: ${(props) => props.fontFamily};
  }
    
`;

