import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../Global';

// Device Detections
import { isMobile } from 'react-device-detect';

// Styles
import './MenuItems.css';

// Theme
import { useStyle, useTheme } from '../../ThemeContext';

// Components
import Calendars from './calendars/Calendars';
import Channels from './channels/Channels';
import Collections from './collections/Collections';
import MenuHeader from './MenuHeader';
import MenuSettings from './MenuSettings';

const InlineStyles = useStyle`
    .menu-item:hover {
        background-color: ${(props) => props.hoverColor} !important;
        border-color: ${(props) => props.hoverColor} !important;
    }
`;

const MenuItems = () => {
    const { theme } = useTheme();
    const {
        selectedModel,
    } = useContext(Global);

    const [isMenuSettingsOpen, setMenuSettingsOpen] = useState(false);

    return (
        <>
            <InlineStyles
                hoverColor={theme.backgroundColorFaded}
            />
            <div className="menuitems-container"
                style={{
                    backgroundColor: theme.backgroundColor,
                }}>

                {isMobile &&
                    <MenuHeader />
                }

                <div className="menuitems-list">

                    {/* CALENDARS */}
                    <Calendars />

                    {/* CHANNELS */}
                    <Channels />

                    {/* COLLECTIONS */}
                    <Collections />

                    {selectedModel &&
                        <MenuSettings
                            modelKey={selectedModel.key}
                            isMenuSettingsOpen={isMenuSettingsOpen}
                            setMenuSettingsOpen={setMenuSettingsOpen}
                        />
                    }

                </div>

                {/* BUTTONS */}
                {isMobile &&
                    <div className="menu-items-buttons">

                        {/* NAV BAR 
                        {selectedApp && currentUser &&
                            <NavBar />
                        }
                        */}

                    </div>
                }
            </div>
        </>
    );
};

export default MenuItems;
