import React, { useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Drag and drop
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// Formatter
import { formatDayAndDate } from '../../../utilities/Formatters';

// Components
import CalendarDayAllDayEvent from './CalendarDayAllDayEvent';
import CalendarDayHourBlock from './CalendarDayHourBlock';
import CalendarDayEvent from './CalendarDayEvent';

// Styles
import './CalendarDay.css';

// Managers
import EventManager from '../../../managers/EventManager';

const eventManager = new EventManager();

const CalendarDay = () => {

    // Global
    const {
        activeDate,
        events,
        selectedApp,
        selectedCalendar,
        selectedCalendarTags,
        setActiveDate,
        setCalendarTitle,
    } = useContext(Global);

    const [todaysEvents, setTodaysEvents] = useState([]);
    const [displayEvents, setDisplayEvents] = useState([]);
    const [allDayEvents, setAllDayEvents] = useState([]); // For events spanning the whole day

    const hourBlocksRef = useRef(null);
    const hourLabelWidth = 60;
    const hours = Array.from({ length: 24 }, (_, i) => `${i % 12 === 0 ? 12 : i % 12} ${i < 12 ? 'AM' : 'PM'}`);

    // Function to filter events based on startDate and endDate relative to activeDate
    const filterAllDayEvents = (eventsForDay) => {
        const allDay = [];
        const withinDay = [];

        eventsForDay.forEach(event => {
            const eventStart = event.startDate.toDate();
            const eventEnd = event.endDate.toDate();

            // Check if event spans the entire active day (starts before and ends after)
            if (eventStart < activeDate && eventEnd > activeDate) {
                allDay.push(event);
            } else {
                withinDay.push(event);
            }
        });

        return { allDay, withinDay };
    };

    useEffect(() => {
        if (!selectedCalendar) return;
        
        if (todaysEvents && selectedCalendar) {
            const filteredEvents = todaysEvents.filter(event => {
                if (event.calendarKey !== selectedCalendar.key) return false;
                if (selectedCalendarTags.length === 0) return true;
                return event.tags.some(tag => selectedCalendarTags.includes(tag));
            });

            const { allDay, withinDay } = filterAllDayEvents(filteredEvents);

            // Set all-day spanning events separately for display in "calendar-day-all-day"
            setAllDayEvents(allDay);

            // Exclude all-day spanning events from the main displayEvents list
            setDisplayEvents(withinDay);
        }
    }, [todaysEvents, selectedCalendar, selectedCalendarTags]);

    useEffect(() => {
        const currentHour = new Date().getHours();
        const scrollTo = currentHour * 70;
        hourBlocksRef.current?.scrollTo({ top: scrollTo, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        if (!activeDate) {
            setActiveDate(new Date());
        } else {
            setCalendarTitle(formatDayAndDate(new Date(activeDate)));
        }
    }, [activeDate, setCalendarTitle, setActiveDate]);

    useEffect(() => {
        if (activeDate) {
            setTodaysEvents(eventManager.getEventsOnDate(events, activeDate));
        }
    }, [selectedApp, activeDate, events]);

    return (
        <DndProvider backend={HTML5Backend}>

            {/* ALL DAY */}
            <div className="calendar-day-all-day"
                style={{
                    marginLeft: hourLabelWidth,
                }}>
                {allDayEvents.map((event, index) => (
                    <CalendarDayAllDayEvent
                        key={index}
                        event={event}
                    />
                ))}
            </div>

            {/* CONTAINER - SCROLLABLE */}
            <div className="calendar-day-container" ref={hourBlocksRef}>
                <div className="calendar-day-wrapper">
                    {hours.map((hour, index) => (
                        <CalendarDayHourBlock
                            key={index}
                            hour={hour}
                            index={index}
                            labelWidth={hourLabelWidth}
                            useDrop={useDrop}
                        />
                    ))}

                    <div className="calendar-day-events-overlay">
                        {displayEvents.map((event, index) => (
                            <CalendarDayEvent
                                key={index}
                                event={event}
                                index={index}
                                leftOffset={hourLabelWidth}
                                useDrag={useDrag}
                                setTodaysEvents={setTodaysEvents}
                            />
                        ))}
                    </div>
                </div>
            </div>

        </DndProvider>
    );
};

export default CalendarDay;
