import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { collections } from '../../firebaseConfig';

// Activity
import { activity } from '../../common/managers/ActivityManager';

// Managers
import DataManager from '../../common/managers/DataManager';
const dataManager = new DataManager();

/**
 * Custom hook to load app users for a specific app and merge current user information.
 */
const usePreloadAppUsers = () => {
  // Global state
  const { 
    currentUser, 
    selectedApp, 
    setAppUsers, 
    setCurrentUser 
  } = useContext(Global);

  useEffect(() => {
    // Exit early if no selected app or app key
    if (!selectedApp || !selectedApp.key) {
      setAppUsers([]);
      return;
    }

    console.log('Loading app users...');

    // Function to load app users
    const loadAppUsers = (appKey) => {
      const handleUpdate = (users) => {
        // Set app users in the global state
        setAppUsers(users);

        // Log activity
        activity.log(appKey, 'reads', users.length);

        // If currentUser exists, merge the current app user data
        if (currentUser) {
          const appUser = users.find(user => user.userKey === currentUser.userKey);
          if (appUser) {
            // Only update currentUser if the merged data is different from the existing currentUser
            const mergedUser = { ...currentUser, ...appUser };

            // Prevent unnecessary update if currentUser already has the merged data
            if (JSON.stringify(currentUser) !== JSON.stringify(mergedUser)) {
              setCurrentUser(mergedUser);
            }
          }
        }
      };

      const params = [
        { field: "appKey", operator: "==", value: appKey },
      ];

      // Subscribe to app users
      const unsubscribe = dataManager.listAndSubscribe(
        appKey,
        collections.appusers,
        handleUpdate,
        params
      );

      return unsubscribe;
    };

    // Subscribe to app users
    const unsubscribeAppUsers = loadAppUsers(selectedApp.key);

    // Cleanup when component unmounts or when dependencies change
    return () => {
      if (unsubscribeAppUsers) {
        unsubscribeAppUsers();
      }
    };
  }, [selectedApp, currentUser, setAppUsers, setCurrentUser]);  // Ensure dependencies are correct

};

export default usePreloadAppUsers;
