import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { collections } from '../../firebaseConfig';

// Activity
import { activity } from '../../common/managers/ActivityManager';

// Managers
import DataManager from '../../common/managers/DataManager';
import { set } from 'firebase/database';
const dataManager = new DataManager();

/**
 * Custom hook to load bookmarks for a specific app.
 */
const usePreloadBookmarks = () => {

  // Global
  const { 
    currentUser,
    selectedApp, 
    setBookmarks 
  } = useContext(Global);

  useEffect(() => {

    // Exit early if no selected app or app key
    if (!selectedApp || !selectedApp.key || !currentUser || !currentUser.userKey) {
      setBookmarks([]);
      return;
    }


    // Function to load bookmarks
    const loadBookmarks = (appKey) => {
      const handleUpdate = (items) => {
        setBookmarks(items);
        activity.log(appKey, 'reads', items.length);
      };

      const params = [
        { field: "userKey", operator: "==", value: currentUser.userKey },
      ];

      const unsubscribe = dataManager.listAndSubscribe(
        appKey,
        collections.bookmarks,
        handleUpdate,
        params
      );

      return unsubscribe;
    };

    // Subscribe to bookmarks
    const unsubscribeBookmarks = loadBookmarks(selectedApp.key);

    // Cleanup when component unmounts or when dependencies change
    return () => {
      if (unsubscribeBookmarks) {
        unsubscribeBookmarks();
      }
    };
  }, [selectedApp, setBookmarks, currentUser]);

};

export default usePreloadBookmarks;
