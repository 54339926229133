import React, { useContext, useEffect } from 'react';

// Global
import { Global } from '../Global';

// Utilities
import { defaultFont } from '../common/utilities/Defaults.js';
import { FormMode } from '../common/utilities/Enums';
import { loadFont } from '../common/utilities/Fonts';

// Device Detection
import { isMobile, isTablet } from 'react-device-detect';

// Styles
import './Mobile.css';

// Theme
import { useStyle, useTheme } from '../ThemeContext';

// Components
import AccountAbout from '../account/about/AccountAbout';
import AccountBilling from '../account/billing/AccountBilling';
import AccountMenu from '../account/menu/AccountMenu';
import AppUserProfile from '../common/appusers/AppUserProfile';
import AppUsers from '../common/appusers/AppUsers';
import Bookmarks from '../common/bookmarks/Bookmarks';
import Calendar from '../common/calendar/Calendar';
import Chat from '../common/channels/chat/Chat';
import Favorites from '../common/favorites/Favorites';
import FieldSelector from '../common/form/fieldselector/FieldSelector';
import Form from '../common/form/Form';
import Forum from '../common/channels/forum/Forum';
import Menu from '../common/menu/Menu';
import Messages from '../common/messages/Messages';
import NavBar from '../common/components/navbar/NavBar';
import Objects from '../common/objects/Objects';
import RunHome from '../run/forum/RunHome';
import RunHeader from './MobileHeader';
import Settings from '../run/settings/Settings';
import TitleBar from '../common/titlebar/TitleBar';
import VideoChat from '../common/channels/videochat/VideoChat';

// Dynamic Styles
import { MobileStyles } from './MobileStyles';

const Mobile = () => {
  const { theme } = useTheme();
  const {
    accountAboutVisible,
    accountBillingVisible,
    accountVisible,
    appUsersVisible,
    bookmarksVisible,
    calendarVisible,
    chatVisible,
    currentUser,
    favoritesVisible,
    fieldSelectorVisible,
    formMode,
    forumVisible,
    menuVisible,
    messagesVisible,
    objectsView,
    runHomeVisible,
    selectedApp,
    selectedAppUser,
    selectedModel,
    selectedObject,
    setMenuVisible,
    settingsVisible,
    videoChatVisible,
  } = useContext(Global);

  const closeMenu = () => {
    setMenuVisible(false);
  };

  const renderContent = () => {
    if (fieldSelectorVisible) return <FieldSelector size="LARGE" />;

    // Show the add form if:
    // - there is a selected model, and
    // - there isn't a selected object, and 
    // - the objects view is not TABLE, and
    // - the form mode is ADD
    if (selectedModel && !selectedObject && objectsView !== "TABLE" && formMode === FormMode.ADD) return <Form />;

    // Show the edit form if:
    // - there is a selected model, and
    // - there is a selected object, and 
    // - the objects view is not TABLE, and
    // - the form mode is EDIT
    if (selectedModel && selectedObject && objectsView !== "TABLE" && formMode !== FormMode.ADD) return <Form />;

    if (selectedAppUser) return <AppUserProfile />;
    if (chatVisible) return <Chat />;
    if (forumVisible) return <Forum />;
    if (videoChatVisible) return <VideoChat />;
    //if (selectedForumPost) return <Forum />;
    if (calendarVisible) return <Calendar />;
    if (bookmarksVisible) return <Bookmarks />;
    if (favoritesVisible) return <Favorites />;
    if (settingsVisible) return <Settings />;
    if (runHomeVisible) return <RunHome />;
    if (appUsersVisible) return <AppUsers />;
    if (messagesVisible) return <Messages />;
    if (accountVisible) {
      if (isMobile) {
        if (accountAboutVisible) {
          return <AccountAbout />;
        } else if (accountBillingVisible) {
          return <AccountBilling />;
        } else {
          return <AccountMenu />;
        }
      } else {
        return <AccountMenu />;
      }
    }
    return <Objects />;
  };

  useEffect(() => {
    if (!selectedApp) return;
    loadFont(selectedApp.fontFamily || defaultFont);
  }, [selectedApp]);

  return (
    <>
      <MobileStyles
        fontFamily={selectedApp.fontFamily || defaultFont}
        selectionBackground={theme.highlightBackgroundColor}
        selectionForeground={theme.highlightForegroundColor}
      />
      <div
        className="mobile-container"
        style={{
          backgroundColor: theme.backgroundColor
        }}>
        {selectedApp &&
          selectedApp.userKey === currentUser.userKey &&
          !isMobile &&
          !isTablet && (
            <div className="mobile-title-bar">
              <TitleBar />
            </div>
          )}
        {menuVisible && (
          <div className="mobile-menu-mask" onClick={closeMenu}></div>
        )}
        <div className={isMobile ? "mobile-center" : "mobile-center-mobile"} style={{ backgroundColor: theme.backgroundColor }}>
          <div className={`mobile-menu ${menuVisible ? 'visible' : ''}`}>
            <Menu />
          </div>
          <div className="mobile-content">
            <RunHeader />
            <div className="mobile-summaries">
              {renderContent()}
            </div>
          </div>

          {/* BUTTONS MASK */}
          <div className="mobile-buttons-mask"
            style={{
              backgroundColor: theme.backgroundColor
            }}>
          </div>

          {/* BUTTONS */}
          {isMobile &&
            <div className="mobile-buttons">

              {/* NAV BAR */}
              {selectedApp && currentUser &&
                <NavBar />
              }

            </div>
          }

        </div>
      </div>
    </>
  );
};

export default Mobile;
