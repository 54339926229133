import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { collections } from '../../firebaseConfig';

// Activity
import { activity } from '../../common/managers/ActivityManager';

// Managers
import DataManager from '../../common/managers/DataManager';
const dataManager = new DataManager();

/**
 * Custom hook to load calendars for a specific app.
 */
const usePreloadCalendars = () => {

  // Global
  const {
    selectedApp,
    setCalendars
  } = useContext(Global);

  useEffect(() => {
    // Exit early if no selected app or app key
    if (!selectedApp || !selectedApp.key) {
      setCalendars([]);
      return;
    }

    // Function to load calendars
    const loadCalendars = (appKey) => {
      const handleUpdate = (items) => {
        setCalendars(items);
        activity.log(appKey, 'reads', items.length);
      };

      const sortFields = [
        "sort", // Ascending by default
      ];

      const unsubscribe = dataManager.listAndSubscribe(
        appKey,
        collections.calendars,
        handleUpdate,
        [],
        sortFields
      );

      return unsubscribe;
    };

    // Subscribe to calendars
    const unsubscribeCalendars = loadCalendars(selectedApp.key);

    // Cleanup when component unmounts or when dependencies change
    return () => {
      if (unsubscribeCalendars) {
        unsubscribeCalendars();
      }
    };
  }, [selectedApp, setCalendars]);

};

export default usePreloadCalendars;
