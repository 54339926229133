import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Device Detections
import { isMobile } from 'react-device-detect';

// Utilities
import { ChannelType, FormMode, MenuType, Section, UserPreference } from '../utilities/Enums';

// Styles
import './MenuBar.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Images
import CubeIcon from '../../common/svg/CubeIcon';
import DockDownIcon from '../../common/svg/DockDownIcon';

// Components
import MenuBarButton from './MenuBarButton';

// Managers
import UserManager from '../../common/managers/UserManager';

const userManager = new UserManager();

const MenuBar = () => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        calendars,
        channels,
        currentUser,
        models,
        resetVisibility,
        selectedApp,
        setCalendarVisible,
        setCurrentUser,
        setMenuType,
        setSelectedCalendar,
        setSelectedCalendarType,
        setSelectedChannel,
        setSelectedModel,
        setChatVisible,
        setForumVisible,
        setVideoChatVisible,
        setFormMode,
    } = useContext(Global);

    const handleDockDownClick = async () => {
        setMenuType(MenuType.LEFT);

        // Call the setPreference method to update LAST_THEME settings
        await userManager.setPreference(
            currentUser,
            setCurrentUser,
            selectedApp.key,
            UserPreference.LAST_MENU_TYPE,
            MenuType.LEFT
        );
    };

    const handleCalendarSelect = async (key) => {
        const calendar = calendars.find((c) => c.key === key);

        resetVisibility();
        setCalendarVisible(true);
        setSelectedCalendar(calendar);

        // Set the last page preference
        const page = {
            section: 'CALENDARS',
            calendarKey: calendar.key,
        }

        await userManager.setPreference(
            currentUser,
            setCurrentUser,
            selectedApp.key,
            UserPreference.LAST_PAGE,
            page
        );

        // Retrieve the saved LAST_CALENDAR_TYPE preference before displaying it.
        const lastCalendarType = userManager.getPreference(
            currentUser,
            selectedApp.key,
            UserPreference.LAST_CALENDAR_TYPE
        );

        if (lastCalendarType) {
            setSelectedCalendarType(lastCalendarType);
        }

    }

    const handleChannelSelect = async (key) => {
        const channel = channels.find((c) => c.key === key);

        resetVisibility();

        setSelectedCalendar(null);
        setSelectedModel(null);
        setSelectedChannel(channel);

        if (channel.type === ChannelType.TEXT) { setChatVisible(true) }
        if (channel.type === ChannelType.FORUM) { setForumVisible(true) }
        if (channel.type === ChannelType.VIDEO) { setVideoChatVisible(true) }

        // Set the last page preference
        const page = {
            section: 'CHANNELS',
            channelKey: channel.key,
        }

        await userManager.setPreference(
            currentUser,
            setCurrentUser,
            selectedApp.key,
            UserPreference.LAST_PAGE,
            page
        );
    }

    const handleCollectionSelect = async (key) => {
        const model = models.find((c) => c.key === key);
        resetVisibility();

        // Show the add form on the desktop
        if (!isMobile) {
            setFormMode(FormMode.ADD);
        }

        setSelectedModel(model);

        // Set the last page preference
        const page = {
            section: 'COLLECTIONS',
            modelKey: model.key,
        }

        await userManager.setPreference(
            currentUser,
            setCurrentUser,
            selectedApp.key,
            UserPreference.LAST_PAGE,
            page
        );
    }

    return (

        <div className="menu-bar-container">

            {/* DOCK DOWN BUTTON */}
            <div className="menu-bar-dock-down-button"
                onClick={handleDockDownClick}>
                <DockDownIcon
                    color={theme.foregroundColorFaded}
                    width="20"
                    height="20"
                />
            </div>

            {/* SECTION BUTTONS */}
            <div className="menu-bar-buttons">

                {/* CALENDAR */}
                <MenuBarButton
                    section={Section.CALENDARS}
                    title="Calendars"
                    icon={CubeIcon}
                    options={calendars}
                    onSelect={handleCalendarSelect}
                />

                {/* CHANNELS */}
                <MenuBarButton
                    section={Section.CHANNELS}
                    title="Channels"
                    icon={CubeIcon}
                    options={channels}
                    onSelect={handleChannelSelect}
                />

                {/* COLLECTIONS */}
                <MenuBarButton
                    section={Section.COLLECTIONS}
                    title="Collections"
                    icon={CubeIcon}
                    options={models}
                    onSelect={handleCollectionSelect}
                />

            </div>

        </div>
    );
};

export default MenuBar;
