import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global';

// Utilities
import { FormMode } from '../utilities/Enums';

// Styles
import './Object.css';

// Theme
import { useStyle, useTheme } from '../../ThemeContext';

// Components
import ObjectRow from './ObjectRow';

// Styled Components
const InlineStyles = useStyle`
    .object-container:hover {
        border-color: ${(props) => props.hoverColor} !important;
    }
`;

/**
 * Object Component
 * 
 * This component renders an object in list view.
 * 
 * @param {object} object - The object containing field values.
 * @param {function} onClick - The function to call onClick.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Object = ({ object, onClick }) => {
  const { theme } = useTheme();
  const {
    formMode,
    models,
    selectedObject,
    setFormMode,
    setChangesMade,
    setSelectedObject,
  } = useContext(Global);

  // State Variables
  const [rows, setRows] = useState([]);

  /**
   * Initializes the model.
   */
  useEffect(() => {
    const model = models.find((model) => model.key === object.modelKey);

    // Only update state if values differ
    if (model) {
      if (model.rows !== rows) setRows(model.rows);
    }
  }, [object, models, rows]);

  /**
   * Handles click on the object.
   */
  const handleClick = async () => {
    // Set the selected object
    setSelectedObject(object);

    // If the mode is ADD, set the mode to EDIT or VIEW
    if (formMode === FormMode.ADD) {
      setFormMode(FormMode.EDIT);
    }

    // Initialize changes made to false
    setChangesMade(false);
  };

  return (
    <>
      <InlineStyles
        hoverColor={theme.backgroundColor}
      />

      {/* CONTAINER */}
      <div className="object-container"
        key={object.key}
        onClick={onClick ? onClick : handleClick}
        style={{
          borderRightWidth: selectedObject && selectedObject.key === object.key ? "5px" : "0px",
          borderRightColor: selectedObject && selectedObject.key === object.key ? theme.highlightBackgroundColor : "transparent",
        }}>

        {/* ROWS */}
        {rows && rows.map((row, index) => (
          <ObjectRow
            key={row.id}
            object={object}
            row={row}
          />
        ))}

      </div>
    </>
  );
};

export default Object;
