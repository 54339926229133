import React from 'react';

// Styles
import './TextSummaryPreview.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const TextSummaryPreview = ({ element }) => {
    const { getProcessedStyles } = useTheme();

    const { styles = {} } = element;

    // Filter out top, left, width, height, and zIndex
    const { top, left, width, height, zIndex, ...filteredStyles } = styles;

    const combinedStyles = {
        ...filteredStyles,
        width: 'auto',
        height: 'auto',
    };

    return (
        <div className="text-summary-preview-container" style={getProcessedStyles(combinedStyles)}>
            {element.field.title}
        </div>
    );
};

export default TextSummaryPreview;
