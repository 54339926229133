import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './CalendarHeader.css';

// Theme
import { useTheme } from '../../../ThemeContext';

const CalendarHeader = () => {
  const { theme } = useTheme();
  const {
    selectedApp,
    selectedCalendar
  } = useContext(Global);

  return (
    <div className="calendar-header-container">
      <div className="calendar-header-left"
        style={{ 
          color: theme.foregroundColorFaded,
          fontFamily: selectedApp.fontFamily,
        }}>
        {selectedCalendar &&
          <>
            {selectedCalendar.title}
          </>
        }
      </div>
      <div className="calendar-header-right" >
      </div>
    </div>
  );
};

export default CalendarHeader;
