import React, { useEffect, useState } from 'react';
import { startOfWeek, endOfWeek } from 'date-fns'; // For handling week calculations

// Styles
import './WeekPicker.css';

// Theme
import { useStyle, useTheme } from '../../../ThemeContext';

// DayPicker
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

const DayPickerStyles = useStyle`
    .rdp {
        --rdp-accent-color: ${(props) => props.highlightColor} !important;
        --rdp-background-color: transparent !important;
    }
    .rdp-caption {
        padding: 12px !important;
    }
    .rdp-caption-button {
        background-color: transparent !important;
        cursor: pointer;
    }
    .rdp-caption-select {
        background-color: transparent !important;
        border: none;
        cursor: pointer;
        font-size: 12pt;
        font-weight: bold;
    }
    .rdp-caption-title {
        font-size: 12pt;
        font-weight: bold;
    }
    .rdp-week-selected {
        background-color: ${(props) => props.highlightColor} !important;
        border-radius: 0 !important; /* To highlight the full week range */
    }
    .rdp-day_selected {
        background-color: ${(props) => props.highlightColor} !important;
        border-radius: 0 !important; /* To highlight the full week range */
    }
`;

// Custom caption component with year selector
const CustomCaption = ({ currentMonth, onMonthChange, onYearChange }) => {
    const { theme } = useTheme();
    if (!currentMonth) {
        currentMonth = new Date(); // Default to the current date if undefined
    }

    const currentYear = currentMonth.getFullYear();
    const years = [];
    for (let i = currentYear - 120; i <= currentYear + 10; i++) {
        years.push(i);
    }

    const handleYearChange = (e) => {
        const newYear = parseInt(e.target.value, 10);
        onYearChange(newYear);
    };

    const handleMonthChange = (delta) => {
        onMonthChange(delta);
    };

    return (
        <div className="rdp-caption">
            <span className="rdp-caption-title">{currentMonth.toLocaleString('default', { month: 'long' })}</span>
            <div
                className="rdp-caption-button"
                style={{ color: theme.foregroundColor }}
                onClick={() => handleMonthChange(-1)}>{'<'}</div>
            <div
                className="rdp-caption-button"
                style={{ color: theme.foregroundColor }}
                onClick={() => handleMonthChange(1)}>{'>'}</div>
            <select
                className="rdp-caption-select"
                style={{ color: theme.foregroundColor }}
                value={currentYear}
                onChange={handleYearChange}>
                {years.map((year) => (
                    <option key={year} value={year}>
                        {year}
                    </option>
                ))}
            </select>
        </div>
    );
};

const WeekPicker = ({
    isOpen,
    selectedDate, // This is the activeDate from global state
    onClose,
    onDateSelect // This will update activeDate to the first day of the selected week
}) => {
    const { theme } = useTheme();

    // State Variables
    const [isAnimating, setIsAnimating] = useState(false);
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [selectedRange, setSelectedRange] = useState(null); // For week selection

    // Find the start and end of the week based on the selectedDate (activeDate)
    useEffect(() => {
        if (selectedDate) {
            const start = startOfWeek(selectedDate, { weekStartsOn: 0 });
            const end = endOfWeek(selectedDate, { weekStartsOn: 0 });
            setSelectedRange({ from: start, to: end });
        }
    }, [selectedDate]);

    // Fade
    useEffect(() => {
        if (isOpen) {
            setIsAnimating(true);
        } else {
            setIsAnimating(false);
        }
    }, [isOpen]);

    const handleMonthChange = (delta) => {
        const newDate = new Date(currentMonth);
        newDate.setMonth(newDate.getMonth() + delta);
        setCurrentMonth(newDate);
    };

    const handleYearChange = (newYear) => {
        const newDate = new Date(currentMonth);
        newDate.setFullYear(newYear);
        setCurrentMonth(newDate);
    };

    // Function to handle week selection
    const handleWeekSelect = (date) => {
        const start = startOfWeek(date, { weekStartsOn: 0 }); // Start of the week (Sunday)
        setSelectedRange({ from: start, to: endOfWeek(start, { weekStartsOn: 0 }) });
        onDateSelect(start); // Set activeDate to the first day of the week (Sunday)
    };

    if (!isOpen && !isAnimating) return null;

    return (
        <div
            className="week-picker-overlay"
            onMouseDown={(e) => {
                onClose();
            }}>
            <div
                className="week-picker-content"
                onMouseDown={(e) => {
                    e.stopPropagation();
                }}
                style={{
                    backgroundColor: theme.backgroundColor,
                    color: theme.foregroundColor,
                    opacity: isAnimating ? 1 : 0, transition: 'opacity 300ms'
                }}>

                <DayPickerStyles highlightColor={theme.highlightBackgroundColor} />

                <DayPicker
                    mode="single"
                    selected={selectedRange} // Highlight the selected week range
                    onSelect={handleWeekSelect} // Use custom week selection function
                    month={currentMonth}
                    captionLayout="dropdown"
                    components={{
                        Caption: (props) => (
                            <CustomCaption
                                currentMonth={currentMonth}
                                onMonthChange={handleMonthChange}
                                onYearChange={handleYearChange}
                            />
                        )
                    }}
                    modifiers={{
                        selectedRange: selectedRange ? [selectedRange] : [],
                    }}
                    modifiersStyles={{
                        selectedRange: {
                            backgroundColor: theme.highlightBackgroundColor,
                            color: theme.foregroundColor,
                        },
                    }}
                />
            </div>
        </div>
    );
};

export default WeekPicker;
