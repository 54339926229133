import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../Global';

// Utilities
import { UserPreference } from '../../common/utilities/Enums';

// Managers
import UserManager from '../../common/managers/UserManager';
const userManager = new UserManager();

/**
 * Custom hook to load a user's theme for the selected app.
 */
const useLoadMenuTypePreference = () => {

  // Global
  const { 
    currentUser,
    selectedApp, 
    setMenuType,
  } = useContext(Global);

  useEffect(() => {

    if (!selectedApp) return;

    const lastMenuType = userManager.getPreference(
      currentUser,
      selectedApp.key,
      UserPreference.LAST_MENU_TYPE
    );

    if (lastMenuType) {
      setMenuType(lastMenuType);
    }

  }, [selectedApp]);
  
};

export default useLoadMenuTypePreference;
