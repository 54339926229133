import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './CollectionEdit.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import CollectionTags from '../collectiontags/CollectionTags';
import Modal from '../../../components/modal/Modal';
import ModalButton from '../../../components/modal/ModalButton';
import ModalButtons from '../../../components/modal/ModalButtons';
import ModalInput from '../../../components/modal/ModalInput';
import ModalLabel from '../../../components/modal/ModalLabel';
import ModalSection from '../../../components/modal/ModalSection';
import ModalTextarea from '../../../components/modal/ModalTextarea';

// Managers
import ModelManager from '../../../managers/ModelManager';

const modelManager = new ModelManager();

/**
 * CollectionEdit Component
 * 
 * This component allows the user to edit a collection.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const CollectionEdit = ({ model, isOpen, setOpen }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        selectedApp,
    } = useContext(Global);

    // State Variables
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [tags, setTags] = useState([]);

    // Load the model attributes
    useEffect(() => {
        if (!model) return;
        setTitle(model.title);
        setDescription(model.description || '');
        setTags(model.tags || []);
    }, [model]);

    const handleSave = async () => {

        const data = {
            title: title,
            description: description,
            tags: tags
        };

        await modelManager.update(selectedApp.key, model.key, data);

        setOpen(false);
    };

    if (!model) return null;

    return (
        <>
            {/* MODAL */}
            <Modal
                title="Edit Collection"
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                width="330px"
                height="auto">

                {/* NAME */}
                <ModalSection>

                    <ModalLabel text="Collection Name" />

                    <ModalInput
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        maxLength="100"
                    />

                </ModalSection>

                {/* DESCRIPTION */}
                <ModalSection>

                    <ModalLabel text="Description" />

                    <ModalTextarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        maxLength="255"
                        lines="2"
                    />

                </ModalSection>

                {/* TAGS */}
                <ModalSection>

                    <ModalLabel text="Tags" />

                    <CollectionTags
                        initialTags={tags}
                        onTagsChange={(updatedTags) => setTags(updatedTags)}
                    />

                </ModalSection>

                {/* BUTTONS */}
                <ModalButtons>

                    {/* SAVE */}
                    <ModalButton
                        onClick={handleSave}
                        label="Save"
                    />

                </ModalButtons>

            </Modal>
        </>
    );
};

export default CollectionEdit;
