import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './TagSummary.css';

// Theme
import { useTheme } from '../../../ThemeContext';

const TagSummary = ({ element, object }) => {
    
    // Theme
    const { getProcessedStyles } = useTheme();

    // Global
    const { models } = useContext(Global);

    // State Variables
    const [model, setModel] = useState(null); // Fix for useState declaration

    useEffect(() => {
        // Find the model related to the object
        const model = models.find((model) => model.id === object.modelKey);
        setModel(model);
    }, [object, models]);

    const { styles = {} } = element;

    // Filter out position and size styles handled by parent
    const { top, left, width, height, zIndex, ...filteredStyles } = styles;

    const processed = getProcessedStyles(filteredStyles);

    return (
        <div className="tag-summary-container" style={processed}>
            {object.viewCount !== undefined && model && model.tags &&
                <>
                    {object.tags && object.tags
                        .filter(tag => model.tags.includes(tag))  // Only show tags present in model.tags
                        .map((tag, index) => (
                            <div key={index}>
                                {tag}
                            </div>
                        ))
                    }
                </>
            }
        </div>
    );
};

export default TagSummary;
