import React, { useEffect, useState } from 'react';

// Styles
import './GallerySummary.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import BackIcon from '../../../common/svg/BackIcon';
import ForwardIcon from '../../../common/svg/ForwardIcon';

/**
 * GallerySummary Component
 * 
 * This component renders a gallery in a summary view.
 * 
 * @param {object} field - The field metadata.
 * @param {object} object - The object containing field values.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const GallerySummary = ({ element, object }) => {
    const { theme, hexToRgba } = useTheme();
    const [currentIndex, setCurrentIndex] = useState(0);

    // Destructure values from element and object
    const { styles = {} } = element || {};
    const { key: fieldKey } = element.field || {};
    const images = object?.[fieldKey] || [];

    // Filter out position and size styles handled by parent
    const { top, left, width, height, zIndex, ...filteredStyles } = styles;

    /**
     * Sets the currentIndex to the last image index if the images array changes.
     */
    useEffect(() => {
        if (!images.length) return;

        // Ensure currentIndex is within the bounds of the images array
        if (currentIndex >= images.length) {
            setCurrentIndex(images.length - 1);
        }
    }, [images, currentIndex]);

    /**
     * Handles navigating to the previous item.
     * 
     * @param {event} event - Event object.
     */
    const handlePrevious = (event) => {
        event.stopPropagation();
        setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    };

    /**
     * Handles navigating to the next item.
     * 
     * @param {event} event - Event object.
     */
    const handleNext = (event) => {
        event.stopPropagation();
        setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, images.length - 1));
    };

    /**
     * There's no need to render if there are no images.
     */
    if (!images.length) {
        return null;
    }

    return (
        <>
            {/*<div className="gallery-summary-container" style={filteredStyles}>*/}
            <div className="gallery-summary-container" style={filteredStyles}>

                {/* IMAGE */}
                <img
                    src={images[currentIndex]?.url}
                    className="gallery-summary-image"
                    alt={`summary ${currentIndex + 1}`}
                />

                {/* PREVIOUS BUTTON */}
                {currentIndex > 0 && (
                    <div
                        className="gallery-summary-nav-button previous-button"
                        style={{ backgroundColor: hexToRgba(theme.backgroundColor, 0.5) }}
                        onClick={handlePrevious}>
                        <BackIcon
                            color={theme.foregroundColor}
                            width="15"
                            height="15"
                        />
                    </div>
                )}

                {/* NEXT BUTTON */}
                {currentIndex < images.length - 1 && (
                    <div
                        className="gallery-summary-nav-button next-button"
                        style={{ backgroundColor: hexToRgba(theme.backgroundColor, 0.5) }}
                        onClick={handleNext}>
                        <ForwardIcon
                            color={theme.foregroundColor}
                            width="15"
                            height="15"
                        />
                    </div>
                )}

                {/* COUNT */}
                {images.length > 1 && (
                    <div className="gallery-summary-image-count"
                        style={{
                            color: theme.foregroundColor,
                            backgroundColor: hexToRgba(theme.backgroundColor, 0.5)
                        }}>
                        {`${currentIndex + 1} of ${images.length}`}
                    </div>
                )}
            </div>
        </>
    );
};

export default GallerySummary;
