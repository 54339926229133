import React, { useContext, useEffect, useState, useRef } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './CalendarStoryline.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import AddButton from '../../../../common/components/addbutton/AddButton';
import CalendarStorylineEvents from './CalendarStorylineEvents';
import CalendarStorylineGrid from './CalendarStorylineGrid';
import CalendarStorylineTags from './CalendarStorylineTags';
import CalendarStorylineYears from './CalendarStorylineYears';

const CalendarStoryline = () => {

    // Theme
    const { theme, generateColorShades } = useTheme();

    // Global
    const {
        activeDate,
        events,
        selectedCalendar,
        selectedCalendarTags,
        selectedCalendarType,
        setDefaultEventTag,
        setEventVisible,
        setSelectedEvent,
        setSelectedTime,
    } = useContext(Global);

    // References
    const containerRef = useRef(null);

    // State Variables
    const [years, setYears] = useState([]);
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [tags, setTags] = useState([]);
    const [shades, setShades] = useState([]);

    // Settings
    const tagHeight = 80;
    const tagWidth = 150;
    const yearHeight = 40;
    const yearWidth = 365; // One pixel per day

    /**
     * Filters events by the selected calendar.
     */
    useEffect(() => {
        if (selectedCalendar) {
            const filteredEvents = events.filter(event => event.calendarKey === selectedCalendar.key);
            setFilteredEvents(filteredEvents);
        }
    }, [events, selectedCalendar]);

    /** 
     * Generates a list of unique years to be displayed along the top of the timeline.
     */
    useEffect(() => {
        const allYears = filteredEvents.flatMap(event => {
            const startYear = event.startDate.toDate().getFullYear();
            const endYear = event.endDate.toDate().getFullYear();
            return Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i);
        });

        const uniqueSortedYears = Array.from(new Set(allYears)).sort((a, b) => a - b);
        setYears(uniqueSortedYears);
    }, [filteredEvents]);

    /**
     * Gathers calendar tags to be displayed on the left side of the timeline.
     */
    useEffect(() => {
        if (!selectedCalendar) return;
        
        // If no tags are selected, use all tags; otherwise, filter to selected tags only
        const filteredTags = selectedCalendarTags.length === 0
            ? selectedCalendar.tags
            : selectedCalendar.tags.filter(tag => selectedCalendarTags.includes(tag));

        // Set the filtered (or all) tags
        setTags(filteredTags);

        // Generate color shades based on the filtered tags count
        const shades = generateColorShades(theme.highlightBackgroundColor, filteredTags.length);
        setShades(shades);
    }, [selectedCalendar, selectedCalendarTags]);

    /**
     * Handles click on the timeline to capture x/y coordinates and identify date and tag.
     */
    const handleTimelineClick = (event) => {
        const timelineElement = event.currentTarget;
        const x = event.clientX - timelineElement.getBoundingClientRect().left;
        const y = event.clientY - timelineElement.getBoundingClientRect().top - yearHeight;

        // Determine year and day within the year
        const yearIndex = Math.floor(x / yearWidth); // Index of the year in the 'years' array
        const year = years[yearIndex];
        const dayOfYear = Math.floor(x % yearWidth); // Day within the year, from 0 to 364

        // Determine tag based on Y position
        const tagIndex = Math.floor(y / tagHeight); // Index of the tag in the 'tags' array
        const tag = tags[tagIndex];

        setDefaultEventTag(tag);

        // Calculate the date from year and day of year
        const date = new Date(year, 0); // January 1st of the specified year
        date.setDate(dayOfYear + 1); // Add days to reach the day within the year

        // Set the default event date and show the event form
        setSelectedEvent(null); // Clear selected event
        const selected = new Date(date.getTime());
        selected.setMinutes(0);
        selected.setSeconds(0);
        setSelectedTime(selected);
        setEventVisible(true);
    };

    const handleAddClick = () => {
        // Clear the currently selected event
        setSelectedEvent(null);

        // Create a new date object from activeDate
        const selected = new Date(activeDate.getTime());
        selected.setHours(0);
        selected.setMinutes(0);
        selected.setSeconds(0);
        setSelectedTime(selected);

        // Display the event form
        setEventVisible(true);
    }

    return (
        <div ref={containerRef} className="calendar-storyline-container">

            {/* LEFT */}
            <div className="calendar-storyline-left" style={{ width: tagWidth }}>
                <div className="calendar-storyline-spacer"
                    style={{
                        borderBottomColor: theme.backgroundColorFaded,
                        borderRightColor: theme.backgroundColorFaded,
                        borderTopColor: theme.backgroundColorFaded,
                        height: `${yearHeight}px`,
                        width: tagWidth,
                    }}
                />
                <div className="calendar-storyline-tags-position" style={{ width: tagWidth }}>
                    <CalendarStorylineTags
                        tags={tags}
                        tagHeight={tagHeight}
                        shades={shades} />
                </div>
            </div>

            {/* TIMELINE */}
            <div
                className="calendar-storyline-timeline"
                style={{ maxWidth: `calc(100% - ${tagWidth}px)` }}
                onClick={handleTimelineClick}>

                {/* YEARS */}
                <div className="calendar-storyline-years-position">
                    <CalendarStorylineYears years={years} yearWidth={yearWidth} />
                </div>

                {/* DATA */}
                <div className="calendar-storyline-data-wrapper">

                    {/* GRIDLINES */}
                    <div className="calendar-storyline-gridlines-position">
                        <CalendarStorylineGrid tags={tags} tagHeight={tagHeight} />
                    </div>

                    {/* EVENTS */}
                    <div className="calendar-storyline-events-position">
                        <CalendarStorylineEvents
                            filteredEvents={filteredEvents}
                            tags={tags}
                            years={years}
                            tagHeight={tagHeight}
                            shades={shades}
                        />
                    </div>

                </div>

            </div>

            {/* ADD BUTTON */}
            {(selectedCalendarType === 'SCHEDULE' || selectedCalendarType === 'STORYLINE') &&
                <div className="calendar-storyline-add-button">
                    <AddButton onClick={handleAddClick} />
                </div>
            }

        </div>
    );
};

export default CalendarStoryline;
