import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './ThemeToggle.css';

// Utilities
import { UserPreference } from '../../../common/utilities/Enums';

// Themes
import { useTheme } from '../../../ThemeContext';

// Managers
import UserManager from '../../../common/managers/UserManager';

const userManager = new UserManager();

const ThemeToggle = () => {

    // Theme
    const { 
        currentTheme, 
        setCurrentTheme, 
        theme 
    } = useTheme();

    // Global
    const {
      currentUser,
      selectedApp,
      setCurrentUser,
    } = useContext(Global);

    const toggleTheme = async () => {
        const newTheme = currentTheme === 'DARK' ? 'LIGHT' : 'DARK';
        setCurrentTheme(newTheme);

        // Call the setPreference method to update LAST_THEME settings
        await userManager.setPreference(
          currentUser,
          setCurrentUser,
          selectedApp.key,
          UserPreference.LAST_THEME,
          newTheme
        );
    };

    return (
        <div className="theme-toggle-container" onClick={toggleTheme}>

            <div
                className="theme-toggle-left"
                style={{ borderColor: currentTheme === "LIGHT" ? theme.highlightBackgroundColor : "white" }}
            >

            </div>
            <div
                className="theme-toggle-right"
                style={{ borderColor: currentTheme === "DARK" ? theme.highlightBackgroundColor : "black" }}
            >
            </div>
        </div>
    );
};

export default ThemeToggle;
