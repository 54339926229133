import React from 'react';

const DayIcon = ({ color = "white", width = "19", height = "16" }) => (
    <svg
        version="1.1"
        width={width}
        height={height}
        viewBox="0 0 19 16" fill="none">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-204.000000, -554.000000)">
                <g transform="translate(100.000000, 100.000000)">
                    <g transform="translate(102.000000, 450.000000)">
                        <g>
                            <polygon points="0 0 24 0 24 24 0 24"></polygon>
                            <path d="M21,18 L2,18 L2,20 L21,20 L21,18 Z M19,10 L19,14 L4,14 L4,10 L19,10 Z M20,8 L3,8 C2.45,8 2,8.45 2,9 L2,15 C2,15.55 2.45,16 3,16 L20,16 C20.55,16 21,15.55 21,15 L21,9 C21,8.45 20.55,8 20,8 Z M21,4 L2,4 L2,6 L21,6 L21,4 Z"
                                fill={color}></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default DayIcon;
