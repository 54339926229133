import React, { useContext } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './ForumPostItem.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import Document from '../../components/document/Document';
import ForumPostThumb from '../forumpostthumb/ForumPostThumb';
import UserThumb from '../../../components/userthumb/UserThumb';

const ForumPostItem = ({ post, timestamp }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        getAppUser,
        selectedApp,
        setSelectedForumPost
    } = useContext(Global);

    const handlePostClick = async (post) => {
        setSelectedForumPost(post);
    };

    return (
        <>
            {/* WRAPPER */}
            <div key={post.key}
                className="forum-post-item-wrapper"
                onClick={() => handlePostClick(post)}>

                {/* LEFT */}
                <div className="forum-post-item-left">
                    <UserThumb
                        user={post} // username will be in the post object
                        size="23"
                    />
                </div>

                {/* CENTER */}
                <div className="forum-post-item-center">

                    {/* HEADER */}
                    <div className="forum-post-item-header">

                        {/* USERNAME */}
                        <div className="forum-post-item-user"
                            style={{
                                color: theme.foregroundColor,
                                fontFamily: selectedApp.fontFamily
                            }}>
                            {getAppUser(post.userKey).username}
                        </div>

                        {/* TIMESTAMP */}
                        <div className="forum-post-item-timestamp"
                            style={{
                                color: theme.foregroundColorFaded
                            }}>
                            •&nbsp;&nbsp;{timestamp}
                        </div>
                    </div>

                    {/* TITLE */}
                    <div className="forum-post-item-title"
                        style={{
                            color: theme.foregroundColor,
                            fontFamily: selectedApp.fontFamily
                        }}>
                        {post.title}
                    </div>

                    {/* DOCUMENTS */}
                    {post.documents && post.documents.length > 0 &&
                        <div className="forum-post-item-documents-wrapper">
                            {post.documents.map((document) => (
                                <Document
                                    key={document.id}
                                    document={document}
                                />
                            ))}
                        </div>
                    }
                </div>

                {/* RIGHT */}
                <div className="forum-post-item-right">

                    {/* THUMBNAIL */}
                    {post.photoUrls && post.photoUrls.length > 0 &&
                        <ForumPostThumb key={post.key} url={post.photoUrls[0]} />
                    }

                </div>
            </div>
        </>
    );
};

export default ForumPostItem;
