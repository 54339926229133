import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './UserThumb.css';

// Components
import UserDot from '../userdot/UserDot';

/**
 * UserThumb Component
 * 
 * This component renders a user thumbnail.
 * 
 * @param {object} user - The user object.
 * @param {integer} size - The size of the thumbnail.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const UserThumb = ({ user, size }) => {
    const {
        getAppUser,
        selectedApp,
    } = useContext(Global);

    const [displayUser, setDisplayUser] = useState(null);

    // If there is a selected app, we'll use the app user. 
    // Otherwise, we'll use the current user.
    useEffect(() => {
        if (selectedApp) {
            setDisplayUser(getAppUser(user.userKey));
        } else {
            setDisplayUser(user);
        }
    }, [selectedApp, user, getAppUser]);

    return (
        <>
            {displayUser &&
                <>
                    {/* CONTAINER */}
                    <div className="user-thumb-container"
                        style={{
                            height: `${size}px`,
                            borderRadius: '50%',
                            overflow: 'hidden',
                            width: `${size}px`,
                        }}>

                        {displayUser.photo ? (
                            <>
                                {/* CONTAINER */}
                                <img
                                    src={displayUser.photo}
                                    className="user-thumb-photo"
                                    style={{
                                        height: `${size}px`,
                                        width: `${size}px`,
                                    }}
                                    alt="User"
                                />
                            </>
                        ) : (
                            <>
                                {/* DOT */}
                                <UserDot
                                    user={displayUser}
                                    size={size}
                                />
                            </>
                        )}
                    </div>
                </>
            }
        </>
    );
};

export default UserThumb;

