import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './TitleBar.css';

// Components
import AppTitle from '../apptitle/AppTitle';
import NavBar from '../../common/components/navbar/NavBar';
import ThemeToggle from '../../desktop/components/themetoggle/ThemeToggle';
import UserButton from '../components/userbutton/UserButton';

/**
 * TitleBar Component
 * 
 * This component renders the title bar at the top of the screen.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const TitleBar = () => {
    const {
        changePage,
        currentUser,
        resetVisibility,
        selectedApp,
        setCurrentDevice,
        setCurrentCanvas,
        isMobile,
    } = useContext(Global);

    /**
     * Method to handle the APPDECK logo click event.
     */
    const handleLogoClick = () => {

        resetVisibility();

        setCurrentDevice("MOBILE");
        setCurrentCanvas("FORM");

        if (currentUser) {
            changePage('HOME');
        } else {
            changePage('WELCOME');
        }
    }

    return (
        <>
            {/* CONTAINER */}
            <div className="title-bar-container">

                {/* LEFT */}
                <div className="title-bar-left">

                    {!isMobile && selectedApp && currentUser &&
                        <>
                            {/* APP INFO */}
                            <div className="title-bar-app"
                                onClick={handleLogoClick}>

                                {/* APP TITLE */}
                                <AppTitle />

                            </div>

                            {/* BUTTONS */}
                            <div className="title-bar-buttons">

                                {/* NAV BAR */}
                                {!isMobile && selectedApp && currentUser &&
                                    <NavBar />
                                }

                            </div>
                        </>
                    }
                </div>

                {/* RIGHT */}
                <div className="title-bar-right">

                    {/* THEME */}
                    {selectedApp &&
                        <ThemeToggle />
                    }

                    {/* USER BUTTON */}
                    {currentUser &&
                        <div className="title-bar-current-user" >
                            <UserButton />
                        </div>
                    }

                </div>

            </div>
        </>
    );
};

export default TitleBar;
