import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global';

// Utilities
import { LoginMode, Page } from '../../common/utilities/Enums';

// Firebase
import { signOut } from 'firebase/auth';
import { auth } from '../../firebaseConfig';

// Styles
import './AccountMenu.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import AccountMenuToolBar from './AccountMenuToolBar';
import AccountMenuSummary from './AccountMenuSummary';

const AccountMenu = () => {
    const { theme } = useTheme();
    const {
        setAccountAboutVisible,
        setAccountBillingVisible,
        setLoginMode,
        setPage,
        setSelectedApp,
        setSelectedModel,
        setSelectedObject,
        setSelectedField,
        setUserAccountOpen,
    } = useContext(Global);

    const [accountPage, setAccountPage] = useState("");

    useEffect(() => {

        async function setSelectedAccountPage() {

            if (accountPage === "ABOUT") {
                setAccountAboutVisible(true);
                setAccountBillingVisible(false);
            }

            if (accountPage === "BILLING") {
                setAccountAboutVisible(false);
                setAccountBillingVisible(true);
            }

            if (accountPage === "LOGOUT") {

                try {
                    await signOut(auth);

                    setSelectedApp(null);
                    setSelectedModel(null);
                    setSelectedObject(null);
                    setSelectedField(null);

                    setUserAccountOpen(false);

                    setLoginMode(LoginMode.LOGIN);

                    setPage(Page.WELCOME);
                    
                } catch (error) {
                    console.error('Error signing out:', error);
                }
            }
        }
        setSelectedAccountPage();

    }, [
        accountPage, 
        setAccountAboutVisible, 
        setAccountBillingVisible,
        setLoginMode,
        setPage,
        setSelectedApp,
        setSelectedModel,
        setSelectedObject,
        setSelectedField
    ]);

    const handleItemClick = async (option) => {
        setAccountPage(option);
    };

    return (
        <>
            <div className="account-menu-container"
                style={{
                    borderLeftColor: theme.backgroundColorFaded,
                    borderRightColor: theme.backgroundColorFaded,
                    borderTopColor: theme.backgroundColorFaded,
                }}>
                <AccountMenuToolBar />
                <div className="account-menu-list">
                    <div className="account-menu-option-wrapper"
                        onClick={() => handleItemClick("ABOUT")}>
                        <AccountMenuSummary
                            title="About Me"
                            description="Manage your personal information."
                        />
                    </div>
                    <div className="account-menu-option-wrapper"
                        onClick={() => handleItemClick("BILLING")}>
                        <AccountMenuSummary
                            title="Billing"
                            description="View your current billing status for the apps you've created."
                        />
                    </div>
                    <div className="account-menu-option-wrapper"
                        onClick={() => handleItemClick("LOGOUT")}>
                        <AccountMenuSummary
                            title="Sign Out"
                            description="Sign out from all apps."
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default AccountMenu;
