import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Firebase
import { doc, updateDoc } from 'firebase/firestore';
import { collections, db } from '../../../../firebaseConfig';

// Activity
import { activity } from '../../../../common/managers/ActivityManager';

// Styles
import './CollectionItemMenu.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import DotsVerticalIcon from '../../../../common/svg/DotsVerticalIcon';

// Images
import DeleteIcon from '../../../../common/svg/DeleteIcon';
import DownIcon from '../../../../common/svg/DownIcon';
import RenameIcon from '../../../../common/svg/RenameIcon';
import UpIcon from '../../../../common/svg/UpIcon';

// Components
import CollectionDelete from '../collectiondelete/CollectionDelete';
import CollectionEdit from '../collectionedit/CollectionEdit';
import ContextMenuButton from '../../../../common/components/contextmenu/ContextMenuButton';
import ContextMenuGroup from '../../../../common/components/contextmenu/ContextMenuGroup';
import ContextMenuOption from '../../../../common/components/contextmenu/ContextMenuOption';
import ContextMenuPopup from '../../../../common/components/contextmenu/ContextMenuPopup';

const CollectionItemMenu = ({ model }) => {
    const { theme } = useTheme();
    const {
        models,
        hideProgress,
        selectedApp,
        selectedModel,
        showProgress
    } = useContext(Global);

    // State Variables
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [menuVisible, setMenuVisible] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

    const toggleMenu = (event) => {
        if (!menuVisible) {
            event.preventDefault();
            setMenuPosition({ x: event.clientX, y: event.clientY });
            setMenuVisible(true);
        } else {
            setMenuVisible(false);
        }
    };

    const handleDeleteClick = (event) => {
        event.stopPropagation();
        setMenuVisible(false);

        setDeleteOpen(true);
    };

    const handleMoveUpClick = async (event) => {
        event.stopPropagation();
        setMenuVisible(false);

        sort('up');
    };

    const handleMoveDownClick = (event) => {
        event.stopPropagation();
        setMenuVisible(false);

        sort('down');
    };

    const handleEditClick = (event) => {
        event.stopPropagation();
        setMenuVisible(false);
        setEditOpen(true);
    };

    const sort = async (direction) => {
        showProgress("Moving...");
        const currentIndex = models.findIndex(item => item.key === model.key);
        const newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;

        if (newIndex >= 0 && newIndex < models.length) {
            const targetItem = models[newIndex];
            const currentSort = model.sort;
            model.sort = targetItem.sort;
            targetItem.sort = currentSort;

            try {
                await updateDoc(doc(db, collections.models, model.key), { sort: model.sort });
                await updateDoc(doc(db, collections.models, targetItem.key), { sort: targetItem.sort });

                activity.log(selectedApp.key, 'writes', 2);
            } catch (error) {
                console.error('Error updating sort order:', error);
            }
        }

        hideProgress();
    };

    return (
        <div className="collection-item-menu-outer-container">

            {/* MENU BUTTON */}
            <div className="collection-item-menu-container">
                <ContextMenuButton 
                    title="Collection Options" 
                    icon={DotsVerticalIcon} 
                    iconSize={11}
                    onToggle={toggleMenu} 
                    dotsColor={selectedModel && selectedModel.key === model.key ? theme.highlightForegroundColor : theme.foregroundColorFaded}
                />
            </div>

            {/* MENU */}
            <ContextMenuPopup
                visible={menuVisible}
                setVisible={setMenuVisible}
                backgroundColor={theme.backgroundColorFaded}
                width={170}
                position={menuPosition}>

                {/* BASE OPTIONS */}
                <ContextMenuGroup title={model ? model.title : ""}>

                    <ContextMenuOption
                        icon={RenameIcon}
                        text="Edit"
                        onClick={handleEditClick}
                    />

                    <ContextMenuOption
                        icon={UpIcon}
                        text="Move Up"
                        onClick={handleMoveUpClick}
                    />

                    <ContextMenuOption
                        icon={DownIcon}
                        text="Move Down"
                        onClick={handleMoveDownClick}
                    />

                    <ContextMenuOption
                        icon={DeleteIcon}
                        text="Delete"
                        onClick={handleDeleteClick}
                    />

                </ContextMenuGroup>

            </ContextMenuPopup>

            {/* COLLECTION EDIT */}
            <CollectionDelete
                model={model}
                isOpen={deleteOpen}
                setOpen={setDeleteOpen}
            />

            {/* COLLECTION EDIT */}
            <CollectionEdit 
                model={model}
                isOpen={editOpen}
                setOpen={setEditOpen}
            />

        </div>
    );
};

export default CollectionItemMenu;
