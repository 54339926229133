import React, { useState, useEffect } from 'react';

// Styles
import './CalendarTags.css';

// Images
import PlusIcon from '../../../svg/PlusIcon';
import RemoveIcon from '../../../svg/RemoveIcon';

// Theme
import { useTheme } from '../../../../ThemeContext';

/**
 * CalendarTags Component
 * 
 * Handles the display, addition, and removal of tags for a calendar.
 * 
 * @param {Array} initialTags - Initial list of tags.
 * @param {Function} onTagsChange - Callback to notify parent of tag changes.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const CalendarTags = ({ 
    initialTags = [], 
    onTagsChange 
}) => {

    // Theme
    const { theme } = useTheme();

    // State Variables
    const [tags, setTags] = useState([]);
    const [newTag, setNewTag] = useState('');

    useEffect(() => {
        setTags(initialTags);
    }, [initialTags]);

    // Handle adding a new tag
    const handleAddTag = () => {
        if (newTag && !tags.includes(newTag)) {
            const updatedTags = [...tags, newTag];
            setTags(updatedTags);
            onTagsChange(updatedTags); // Notify parent of the new tag list
            setNewTag(''); // Clear the input after adding
        }
    };

    // Handle removing a tag
    const handleRemoveTag = (tagToRemove) => {
        const updatedTags = tags.filter(tag => tag !== tagToRemove);
        setTags(updatedTags);
        onTagsChange(updatedTags); // Notify parent of the updated tag list
    };

    return (
        <div>

            {/* TAGS CONTAINER */}
            <div className="calendar-tags-container">
                {/* Display existing tags */}
                {tags.map(tag => (
                    <div
                        key={tag}
                        className="calendar-tag-item"
                        style={{
                            backgroundColor: theme.backgroundColorFaded,
                            color: theme.foregroundColor
                        }}>
                        <span className="calendar-tag-item-text">{tag}</span>
                        <div
                            onClick={() => handleRemoveTag(tag)}
                            className="calendar-remove-tag-button">
                            <RemoveIcon color={theme.foregroundColorFaded} width="14" height="14" />
                        </div>
                    </div>
                ))}
            </div>

            {/* ADD TAG */}
            <div className="calendar-add-tag-section">
                <input
                    type="text"
                    className="calendar-new-tag-input"
                    value={newTag}
                    onChange={(e) => setNewTag(e.target.value)}
                    placeholder="Add a tag"
                    style={{
                        backgroundColor: theme.backgroundColorFaded,
                        borderColor: theme.backgroundColorFaded,
                        color: theme.foregroundColor
                    }}
                />
                <div className="calendar-add-tag-button" onClick={handleAddTag}>
                    <PlusIcon color={theme.highlightForegroundColor} width="14" height="14" />
                </div>
            </div>
        </div>
    );
};

export default CalendarTags;
