import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './LoginPassword.css';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * LoginPassword Component
 * 
 * This component renders an input for a user's password.
 * 
 * @param {function} onChange - The function to call with updated values.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const LoginPassword = ({ onChange }) => {
  const { theme } = useTheme();
  const {
    selectedApp,
    studio
  } = useContext(Global);

  /**
   * Method to handle password input change.
   * 
   * @param {Event} e - Change event
   */
  const handlePasswordChange = (e) => {
    onChange(e.target.value.trim());
  };

  return (
    <>

      {/* CONTAINER */}
      <div className="login-password-container">

        {/* PASSWORD LABEL */}
        <div
          className="login-password-label"
          style={{
            color: selectedApp ? theme.foregroundColorFaded : studio.foregroundColorFaded
          }}>
          Password
        </div>

        {/* PASSWORD INPUT */}
        <input type="password"
          className="login-password-input"
          style={{
            color: studio.foregroundColor,
            backgroundColor: selectedApp ? theme.backgroundColorFaded : studio.backgroundColorFaded
          }}
          onChange={handlePasswordChange}
          placeholder="Password"
        />

      </div>

    </>
  );
};

export default LoginPassword;
