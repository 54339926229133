import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Firestore
import { Timestamp } from 'firebase/firestore';

// Utilities
import { getEventTimespan } from '../../../utilities/Events';

// Styles
import './CalendarWeekEvent.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Managers
import EventManager from '../../../../common/managers/EventManager';

const eventManager = new EventManager();

const CalendarWeekEvent = ({ event, index, leftOffset, daysToShow, overlayWidth, useDrag }) => {
  // Theme
  const { theme } = useTheme();

  // Global
  const {
    selectedApp,
    setEventVisible,
    setSelectedEvent
  } = useContext(Global);

  // State Variables
  const [resizeHeight, setResizeHeight] = useState(null);
  const [isResizing, setIsResizing] = useState(false);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'weekevent',
    item: { event },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setEventVisible(true);
  }

  const handleResizeStart = (e) => {
    e.preventDefault();
    setIsResizing(true);
  };

  useEffect(() => {
    const handleResize = (e) => {
      if (isResizing) {
        const eventContainer = document.querySelector('.calendar-week-event-container');
        if (!eventContainer) return;

        const eventRect = eventContainer.getBoundingClientRect();
        const newHeight = e.clientY - eventRect.top;
        setResizeHeight(newHeight);
      }
    };

    const handleResizeEnd = async () => {
      if (isResizing) {
        setIsResizing(false);

        // Calculate the nearest 15-minute interval for the end time
        const startTime = event.startDate.toDate();
        const hoursAdded = resizeHeight / 70;
        const endTime = new Date(startTime.getTime() + hoursAdded * 60 * 60 * 1000);

        // Snap to the nearest 15-minute interval
        const roundedMinutes = Math.round(endTime.getMinutes() / 15) * 15;
        endTime.setMinutes(roundedMinutes);
        endTime.setSeconds(0);

        let endTimestamp = Timestamp.fromDate(endTime);

        const data = {
          endDate: endTimestamp,
        };

        await eventManager.update(selectedApp.key, event.key, data);

      }
    };

    if (isResizing) {
      document.addEventListener('mousemove', handleResize);
      document.addEventListener('mouseup', handleResizeEnd);
    } else {
      document.removeEventListener('mousemove', handleResize);
      document.removeEventListener('mouseup', handleResizeEnd);
    }

    // Clean up the listeners on unmount
    return () => {
      document.removeEventListener('mousemove', handleResize);
      document.removeEventListener('mouseup', handleResizeEnd);
    };
  }, [isResizing, resizeHeight, event]);

  const getEventStyle = (event, index) => {
    const startTime = event.startDate.toDate();
    const endTime = event.endDate.toDate();

    // Determine the specific date for the start of the event
    const eventDate = new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate());

    // Find the index of the day the event starts on
    const dayIndex = daysToShow.findIndex(d =>
      d.getFullYear() === eventDate.getFullYear() &&
      d.getMonth() === eventDate.getMonth() &&
      d.getDate() === eventDate.getDate()
    );

    // Calculate left position based on day index and overlay width
    const calculateLeftPosition = (dayIndex) => {
      const totalWidth = overlayWidth - leftOffset; // Use overlay width minus the offset
      const columnWidth = totalWidth / 7; // Assuming 7 days in a week

      // If the day index is -1 (event starts outside displayed days), default it to 0
      if (dayIndex === -1) {
        dayIndex = 0;
      }

      // Ensure left position is not negative
      return dayIndex === 0 ? leftOffset : Math.max(0, leftOffset + (columnWidth * dayIndex));
    };

    const top = (startTime.getHours() + startTime.getMinutes() / 60) * 70; // Position by hour
    const midnight = new Date(startTime);
    midnight.setHours(23, 59, 59, 999); // Set to end of the starting day

    // Set display end time as the earlier of the event's actual end or midnight
    const displayEndTime = endTime > midnight ? midnight : endTime;
    const durationHours = (displayEndTime - startTime) / (1000 * 60 * 60);

    // Calculate height based on the duration up to the display end time
    const height = resizeHeight !== null ? resizeHeight : durationHours * 70 - 2;

    // Check if the event spans multiple days
    const isMultiDay = endTime > midnight;

    const left = calculateLeftPosition(dayIndex);

    return {
      backgroundColor: theme.highlightBackgroundColor,
      borderColor: theme.highlightBackgroundColor,
      top: `${top}px`,
      height: `${height}px`, // Set height based on display end time
      left: `${left}px`,
      position: 'absolute',
      width: `calc((100% - 80px) / 7)`, // Dynamic width calculation for each day
      isMultiDay,  // New flag to indicate multi-day status
    };
  };

  const eventStyle = getEventStyle(event, index);

  return (
    <div
      ref={drag}
      key={event.key}
      className="calendar-week-event-container"
      onClick={() => handleEventClick(event)}
      style={{ ...eventStyle, opacity: isDragging ? 0.5 : 1 }}>
      <div className="calendar-week-event-title"
        style={{
          color: theme.highlightForegroundColor
        }}>
        {event.title}
      </div>
      <div className="calendar-week-event-time"
        style={{
          color: theme.highlightForegroundColor
        }}>
        {getEventTimespan(event)}
      </div>

      {/* RESIZE HANDLE */}
      {!eventStyle.isMultiDay && (
        <div
          className="calendar-week-event-resize-handle"
          onMouseDown={handleResizeStart}
          style={{
            backgroundColor: theme.backgroundColor,
            opacity: isDragging ? 0.4 : 0.2,
          }}></div>
      )}
    </div>
  );
};

export default CalendarWeekEvent;
