import React, { useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Firebase
import { Timestamp } from 'firebase/firestore';

// Utilities
import { generateKey } from '../../../utilities/Keys';
import { defaultSummaryTitleStyles } from '../../../utilities/Defaults';

// Styles
import './CollectionAdd.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import CollectionTags from '../collectiontags/CollectionTags';
import Modal from '../../../components/modal/Modal';
import ModalButton from '../../../components/modal/ModalButton';
import ModalButtons from '../../../components/modal/ModalButtons';
import ModalInput from '../../../components/modal/ModalInput';
import ModalLabel from '../../../components/modal/ModalLabel';
import ModalSection from '../../../components/modal/ModalSection';
import ModalSwitch from '../../../components/modal/ModalSwitch';
import ModalTextarea from '../../../components/modal/ModalTextarea';

// Managers
import FieldManager from '../../../../common/managers/FieldManager';
import ModelManager from '../../../../common/managers/ModelManager';
import PromptManager from '../../../../common/managers/PromptManager';

const fieldManager = new FieldManager();
const modelManager = new ModelManager();
const promptManager = new PromptManager();

/**
 * CollectionAdd Component
 * 
 * This component allows the user to add a collection.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const CollectionAdd = () => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        collectionAddOpen,
        currentUser,
        hideProgress,
        models,
        resetVisibility,
        selectedApp,
        setCollectionAddOpen,
        setFormMode,
        setSelectedModel,
        showProgress
    } = useContext(Global);

    // State Variables
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [tags, setTags] = useState([]);
    const [autogen, setAutogen] = useState(true);
    const [autopop, setAutopop] = useState(true);

    const handleAdd = async () => {

        if (!title) {
            alert('Please enter a collection title.');
            return;
        }

        showProgress("Adding collection...");

        setCollectionAddOpen(false);

        const userKey = currentUser.userKey;
        const username = currentUser.username;
        const appKey = selectedApp.key;

        const newModelKey = generateKey();  // Generate key for the new model

        // Current timestamp
        const now = Timestamp.now();

        // Initialize the highest sort number to 0
        let highestSortNumber = 0;

        // Iterate through each model to find the highest sort number
        models.forEach(model => {
            const sortNumber = model.sort;
            if (sortNumber > highestSortNumber) {
                highestSortNumber = sortNumber;
            }
        });

        const titleFieldKey = generateKey();

        // We need to create a default title field if autogen is disabled
        const titleFieldData = {
            appKey: appKey,
            key: titleFieldKey,
            title: "Title",
            description: "Title of the item.",
            type: "text",
            sort: 0,
            modelKey: newModelKey,
            modelTitle: title,
            modelDescription: "",
            options: [],
        };

        const titleFieldContentKey = generateKey();

        const titleFieldBlock = {
            id: generateKey(),
            content: [{
                id: titleFieldContentKey,
                key: titleFieldContentKey,
                title: "Title",
                field: titleFieldData,
                type: "field",
            }],
            align: 'left',
        }

        // Create the model data
        const modelData = {
            key: newModelKey,
            appKey: appKey,
            description: "",
            userKey: userKey,
            username: username,
            title: title,
            description: description,
            noview: [],
            noadd: [],
            noedit: [],
            nodelete: [],
            sort: highestSortNumber + 1,
            tags: tags,
            titleFieldKey: titleFieldKey, // Overridden below if autogen is enabled
            rows: [{ id: generateKey(), blocks: [titleFieldBlock] }],
            styles: defaultSummaryTitleStyles,
            dateCreated: now,
            dateModified: now,
        };

        // Add the model to the database first
        await modelManager.add(appKey, newModelKey, modelData);

        // Generate default fields if autogen is enabled
        if (autogen) {
            // Submit the model prompt
            await submitModelPrompt(selectedApp, modelData, []);
        } else {

            // Add the title field to the database
            await fieldManager.add(appKey, titleFieldKey, newModelKey, titleFieldData);

            // Set the selected model
            setSelectedModel(modelData);
        }

        // Reset the title field
        setTitle('');

        // Reset the description field
        setTitle('');

        // Reset the autogen field if necessary
        setAutogen(true);

        // Hide the progress panel
        hideProgress();
    };

    const submitModelPrompt = async (app, model, models) => {

        try {

            const prompt = await promptManager.prepareModelPrompt(app.title, app.description, model.title, model.description, models, autopop);

            console.log('Model prompt:', prompt);

            const response = await promptManager.send(prompt);

            console.log('Model prompt response:', response);

            await promptManager.processModelResponse(
                response,
                app,
                model,
                showProgress,
                setSelectedModel,
                setFormMode,
                resetVisibility,
                currentUser
            );

        } catch (error) {
            console.error('Failed to fetch API response:', error);
        }
    };

    return (
        <>
            {/* MODAL */}
            <Modal title="New Collection"
                isOpen={collectionAddOpen}
                onClose={() => setCollectionAddOpen(false)}
                width="330px"
                height="auto">

                {/* NAME */}
                <ModalSection>

                    <ModalLabel text="Collection Name" />

                    <ModalInput
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        maxLength="100"
                        autoFocus={true}
                    />

                </ModalSection>

                {/* DESCRIPTION */}
                <ModalSection>

                    <ModalLabel text="Description" />

                    <ModalTextarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        maxLength="255"
                        lines="2"
                    />

                </ModalSection>

                {/* TAGS */}
                <ModalSection>

                    <ModalLabel text="Tags" />

                    <CollectionTags
                        initialTags={tags}
                        onTagsChange={(updatedTags) => setTags(updatedTags)}
                    />

                </ModalSection>

                {/* AI FIELD GENERATION */}
                <ModalSection>

                    <ModalSwitch
                        label="Auto-Generate Fields"
                        value={autogen}
                        onChange={() => setAutogen(!autogen)}
                    />

                </ModalSection>

                {/* AI DATA POPULATION */}
                <ModalSection>

                    <ModalSwitch
                        label="Auto-Populate Data"
                        value={autopop}
                        onChange={() => setAutopop(!autopop)}
                    />

                </ModalSection>

                {/* BUTTONS */}
                <ModalButtons>

                    {/* ADD BUTTON */}
                    <ModalButton
                        onClick={handleAdd}
                        label="Continue..."
                    />

                </ModalButtons>

            </Modal >
        </>
    );
};

export default CollectionAdd;
