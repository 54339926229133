import React, { useState } from 'react';

// Styles
import './UserGroupMenu.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import DeleteIcon from '../../../../common/svg/DeleteIcon';
import PencilIcon from '../../../../common/svg/PencilIcon';

// Components
import ContextMenuButton from '../../../../common/components/contextmenu/ContextMenuButton';
import ContextMenuOption from '../../../../common/components/contextmenu/ContextMenuOption';
import ContextMenuPopup from '../../../../common/components/contextmenu/ContextMenuPopup';

const UserGroupMenu = ({ onEditClick, onDeleteClick }) => {
    const { theme } = useTheme();

    // State Variables
    const [menuVisible, setMenuVisible] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

    /**
     * Toggles visibility of the context menu.
     */
    const toggleMenu = (e) => {
        e.stopPropagation();
        if (!menuVisible) {
            e.preventDefault();
            setMenuPosition({ x: e.clientX, y: e.clientY });
            setMenuVisible(true);
        } else {
            setMenuVisible(false);
        }
    };

    /**
     * Handles click on the edit option.
     */
    const handleEditClick = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        onEditClick();
        setMenuVisible(false);
    };

    /**
     * Handles click on the delete option.
     */
    const handleDeleteClick = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        onDeleteClick();
        setMenuVisible(false);
    };

    return (
        <>
            {/* CONTAINER */}
            <div className="user-group-outer-container">

                {/* MENU BUTTON */}
                <div className="user-group-container">
                    <ContextMenuButton
                        title="Options"
                        onToggle={toggleMenu}
                    />
                </div>

                {/* MENU */}
                <ContextMenuPopup
                    visible={menuVisible}
                    setVisible={setMenuVisible}
                    backgroundColor={theme.backgroundColorFaded}
                    width={140}
                    position={menuPosition}>

                    {/* EDIT */}
                    <ContextMenuOption
                        icon={PencilIcon}
                        text="Edit"
                        onClick={handleEditClick}
                    />

                    {/* DELETE */}
                    <ContextMenuOption
                        icon={DeleteIcon}
                        text="Delete"
                        onClick={handleDeleteClick}
                    />

                </ContextMenuPopup>

            </div>
        </>
    );
};

export default UserGroupMenu;
