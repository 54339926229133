import React from 'react';

const AddRowIcon = ({ color = "white", width = "32", height = "32" }) => (
    <svg 
    version="1.1"
    width={width}
    height={height}
    viewBox="0 0 32 32">
  <polygon fill={color} points="4 18 15 18 15 24.172 12.414 21.586 11 23 16 28 21 23 19.586 21.586 17 24.172 17 18 28 18 28 16 4 16 4 18"/>
  <path fill={color} d="M26,4H6A2,2,0,0,0,4,6v4a2,2,0,0,0,2,2H26a2,2,0,0,0,2-2V6A2,2,0,0,0,26,4Zm0,6H6V6H26Z"/>
</svg>
);

export default AddRowIcon;
