import React from 'react';

// Styles
import './AppUserSummary.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import UserThumb from '../../common/components/userthumb/UserThumb';

const AppUserSummary = ({ appUser }) => {
    const { theme } = useTheme();

    return (
        <>
            {/* CONTAINER */}
            <div className="app-user-summary-container">

                {/* USER THUMB */}
                <div className="app-user-summary-dot">
                    <UserThumb
                        user={appUser}
                        size="30"
                    />
                </div>

                {/* NAME/ROLE */}
                <div className="app-user-summary-user">

                    {/* NAME */}
                    <div className="app-user-summary-name"
                        style={{
                            color: theme.foregroundColor
                        }}>
                        {appUser.username}
                    </div>
                    
                </div>
            </div>
        </>
    );
};

export default AppUserSummary;
