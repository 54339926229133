import React from 'react';

const TextIcon = ({ color = "white", width = "24", height = "24" }) => (
    <svg
        version="1.1"
        width={width}
        height={height}
        viewBox="0 0 24 24">
        <g>
            <g>
                <rect width="24" height="24" opacity="0" />
                <path fill={color} d="M20 4H4a1 1 0 0 0-1 1v3a1 1 0 0 0 2 0V6h6v13H9a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2h-2V6h6v2a1 1 0 0 0 2 0V5a1 1 0 0 0-1-1z" />
            </g>
        </g>
    </svg>

);

export default TextIcon;
