import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { signOut } from 'firebase/auth';
import { auth } from '../../firebaseConfig';

// Router
import { useNavigate, useLocation } from 'react-router-dom';

// Managers
import AppManager from '../../common/managers/AppManager';
import InviteManager from '../../common/managers/InviteManager';
import UserManager from '../../common/managers/UserManager';

const appManager = new AppManager();
const inviteManager = new InviteManager();
const userManager = new UserManager();

/**
 * Custom hook to detect the presence of an invite in the querystring and react accordingly.
 */
const useDetectInvite = () => {

  // Navigate
  let navigate = useNavigate();

  // Location
  const location = useLocation();

  // Global
  const { 
    setInvite,
    setSelectedApp,
  } = useContext(Global);

  useEffect(() => {

    const getQueryStringValue = (key) => {
      const queryParams = new URLSearchParams(location.search);
      return queryParams.get(key);
    };

    const inviteCode = getQueryStringValue('i');

    if (inviteCode) {

      async function fetchInvite() {
        try {
          // Log out
          await signOut(auth);

          const fetchedInvite = await inviteManager.fetchInvite(inviteCode);

          // If an invite exists, set the invite in Global and
          // redirect to the root again
          if (fetchedInvite) {

            setInvite(fetchedInvite);

            // Fetch the app
            const app = await appManager.fetchApp(fetchedInvite.appKey);
            if (app) {
              setSelectedApp(app);
            }

            // TEMP - NAVIGATE TO INVITE LANDING
            navigate('/');

          }
        } catch (error) {
          console.error('Failed to fetch invite:', error);
        }
      }
      fetchInvite();
    }
  }, [location.search, navigate, setInvite]);
  
};

export default useDetectInvite;
