import React, { useContext, useEffect, useRef, useState } from 'react';
import Peer from 'simple-peer';

// Global
import { Global } from '../../../Global';

// Device Detections
import { isMobile } from 'react-device-detect';

// Styles
import './VideoChat.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import VideoChatUsers from './VideoChatUsers';

/**
 * VideoChat Component
 * 
 * This component renders a video chat channel.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const VideoChat = () => {
  const { theme } = useTheme();
  const {
  } = useContext(Global);

  // Keeps track of whether this component was the initiator
  const [initiator, setInitiator] = useState(false);

  // Keeps track of the local stream
  const [stream, setStream] = useState(null);

  // Get the user's video and audio stream and generate a key for 
  // the component when it mounts
  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      .then((mediaStream) => {
        setStream(mediaStream);
        if (localVideoRef.current) {
          localVideoRef.current.srcObject = mediaStream;
        }
      })
      .catch((err) => console.error('Failed to get local stream', err));
  }, []);









  const [peerSignal, setPeerSignal] = useState('');
  const [peerConnected, setPeerConnected] = useState(false);

  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);

  const peerRef = useRef(null);

  const createPeer = (isInitiator) => {
    setInitiator(isInitiator);

    const peer = new Peer({
      initiator: isInitiator,  // True for the initiator (offerer), false for the receiver (answerer)
      trickle: false,
      stream: stream,
    });

    peer.on('signal', (data) => {
      setPeerSignal(JSON.stringify(data));  // This signal should be sent to the remote peer
    });

    peer.on('stream', (remoteStream) => {
      if (remoteVideoRef.current) {
        remoteVideoRef.current.srcObject = remoteStream;
      }
    });

    peer.on('connect', () => {
      console.log('Peer connected!');
      setPeerConnected(true);
    });

    peerRef.current = peer;
  };

  const acceptSignal = (signalData) => {
    if (peerRef.current) {
      peerRef.current.signal(JSON.parse(signalData));
    }
  };

  return (
    <>
      {/* CONTAINER */}
      <div className="video-chat-container">

        {/* LEFT */}
        <div className="video-chat-left">

          {/* STREAMS */}
          <div className="video-chat-videos">

            {/* LOCAL */}
            <div className="video-chat-video-wrapper">
              <video ref={localVideoRef} autoPlay muted />
            </div>

            {/* REMOTE */}
            <div className="video-chat-video-wrapper">
              <video ref={remoteVideoRef} autoPlay />
            </div>

          </div>

          {/* TEMP */}
          <div style={{
            margin: '20px',
            display: 'flex',
            flexDirection: 'row'
          }}>

            <div style={{
              display: 'flex',
              flexDirection: 'column'
            }}>
              {!peerConnected && (
                <>
                  <button
                    style={{
                      color: theme.highlightForegroundColor,
                      backgroundColor: theme.highlightBackgroundColor,
                      borderRadius: '7px',
                      padding: '12px',
                      marginBottom: '8px',
                      borderWidth: '0px'
                    }}
                    onClick={() => createPeer(true)}>
                    Call Peer
                  </button>

                  <textarea
                    style={{
                      borderWidth: '0px',
                      borderRadius: '7px',
                      color: theme.foregroundColor,
                      backgroundColor: theme.backgroundColorFaded
                    }}
                    rows="10"
                    cols="40"
                    value={peerSignal || ''}
                    readOnly
                    placeholder="Signal Data"
                  />
                </>
              )}
            </div>

            <div>
              <textarea
                    style={{
                      borderWidth: '0px',
                      borderRadius: '7px',
                      color: theme.foregroundColor,
                      backgroundColor: theme.backgroundColorFaded,
                      marginLeft: '10px',
                      marginTop: '48px'
                    }}
                rows="10"
                cols="40"
                onChange={(e) => acceptSignal(e.target.value)}
                placeholder="Paste remote signal data here"
              />
            </div>

          </div>

        </div>

        {/* RIGHT */}
        <div className="video-chat-right"
          style={{
            borderLeftColor: theme.backgroundColorFaded
          }}>

          {/* USERS */}
          {!isMobile &&
            <VideoChatUsers />
          }

        </div>

      </div>

    </>



  );
};

export default VideoChat;

