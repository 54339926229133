import React from 'react';

// Styles
import './CalendarStorylineEvents.css';

// Components
import CalendarStorylineEvent from './CalendarStorylineEvent';

const CalendarStorylineEvents = ({ filteredEvents, tags, years, tagHeight, shades }) => {

    return (
        <div className="calendar-storyline-events-container">
            {filteredEvents.map((event, index) => (
                <CalendarStorylineEvent 
                    key={event.id} 
                    event={event} 
                    tags={tags} 
                    years={years} 
                    tagHeight={tagHeight}
                    shades={shades}
                />
            ))}
        </div>
    );
};

export default CalendarStorylineEvents;
