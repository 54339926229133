import React, { useContext, useState } from 'react';

// Styles
import './ObjectsToolBar.css';

// Global
import { Global } from '../../Global';

// Utilities
import { FormMode } from '../utilities/Enums';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import ObjectsSearchButton from './searchbutton/ObjectsSearchButton';
import Search from '../../common/search/Search';
import ViewMenu from './ViewMenu';

// Managers
import ObjectManager from '../../common/managers/ObjectManager';

const objectManager = new ObjectManager();

const ObjectsToolBar = () => {
    const { theme } = useTheme();
    const {
        models,
        resetVisibility,
        selectedApp,
        selectedModel,
        setFormMode,
        setObjectsView,
        setSelectedModel,
        setSelectedObject,
    } = useContext(Global);

    // State Variables
    const [searchVisible, setSearchVisible] = useState(false);

    const handleSearchClick = () => {
        setSearchVisible(true);
    };

    const handleSearch = async (entry) => {

        // Reset the visibility
        resetVisibility();

        // Make sure the form is selected instead of table
        setObjectsView("LIST");

        // Find the model in the models array and set it to active
        const model = models.find(model => model.key === entry.modelKey);
        setSelectedModel(model);

        // Fetch the selected object and set it to active
        const object = await objectManager.fetch(selectedApp.key, entry.modelKey, entry.objectKey);
        setSelectedObject(object);

        // Set the form mode to edit
        setFormMode(FormMode.EDIT);

        // Close the search dialog
        setSearchVisible(false);
    };

    return (
        <>
            <div className="objects-toolbar-container">
                <div className="objects-toolbar-buttons">
                    <div className="objects-toolbar-left"
                        style={{ color: theme.foregroundColorFaded }}>
                        {selectedModel &&
                            <>
                                {selectedModel.title}
                                <ObjectsSearchButton
                                    onClick={handleSearchClick}
                                />
                            </>
                        }
                    </div>
                    <div className="objects-toolbar-right" >
                        {selectedModel &&
                            <ViewMenu />
                        }
                    </div>
                </div>
            </div>

            {/* SEARCH FORM */}
            <Search
                onSearch={handleSearch}
                isVisible={searchVisible}
                setVisible={setSearchVisible}
                modelKey={selectedModel && selectedModel.key}
            />
        </>
    );
};

export default ObjectsToolBar;
