import React from 'react';

// Styles
import './Gallery.css';

const Gallery = ({ 
    content, 
    onClick,
    mode = "VIEW"
}) => {

    // Function to handle opening an image in a new tab in VIEW mode
    const handleImageClick = (url) => {
        if (mode === "VIEW") {
            window.open(url, '_blank');
        }
    };

    /**
     * Handles a gallery click.
     */
    const handleClick = async () => {
        if (mode === "EDIT") {
            onClick();
        }

        // Do nothing in view mode
    };

    return (
        <div className="gallery-container"
            style={{
                height: content && content.length === 1 ? "auto" : "300px",
                width: content && content.length === 1 ? "auto" : "100%"
            }}
            onClick={handleClick}>

            {content && content.length === 1 &&
                <img
                    src={content[0].url}
                    alt="Photo"
                    className="gallery-photo"
                    style={{
                        height: content && content.length === 1 ? "auto" : "300px",
                    }}
                    onClick={() => handleImageClick(content[0].url)}
                />
            }

            {content && content.length === 2 &&
                <>
                    <div className="gallery-left">
                        <img
                            src={content[0].url}
                            alt="Photo"
                            className="gallery-photo"
                            onClick={() => handleImageClick(content[0].url)}
                        />
                    </div>
                    <div className="gallery-right">
                        <img
                            src={content[1].url}
                            alt="Photo"
                            className="gallery-photo"
                            onClick={() => handleImageClick(content[1].url)}
                        />
                    </div>
                </>
            }

            {content && content.length >= 3 &&
                <>
                    <div className="gallery-left">
                        <img
                            src={content[0].url}
                            alt="Photo"
                            className="gallery-photo"
                            onClick={() => handleImageClick(content[0].url)}
                        />
                    </div>
                    <div className="gallery-right">
                        <div className="gallery-right-top">
                            <img
                                src={content[1].url}
                                alt="Photo"
                                className="gallery-photo"
                                onClick={() => handleImageClick(content[1].url)}
                            />
                        </div>
                        <div className="gallery-right-bottom">
                            <img
                                src={content[2].url}
                                alt="Photo"
                                className="gallery-photo"
                                onClick={() => handleImageClick(content[2].url)}
                            />
                        </div>
                    </div>
                </>
            }
        </div>
    );
};

export default Gallery;
