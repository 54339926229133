import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { UserProvider } from './UserContext';
import { ThemeProvider } from './ThemeContext';
import { GlobalProvider, Global } from './Global';

// Components
import Root from './root/Root';

// Styles
import './App.css';

function App() {
  return (
    <GlobalProvider>
      <UserProvider>
        <Router>
          <div className="App-root">
            <GlobalConsumer>
              {({ selectedApp }) => (
                <ThemeProvider selectedApp={selectedApp}>
                  <Routes>
                    <Route path="/" element={<Root />} />
                  </Routes>
                </ThemeProvider>
              )}
            </GlobalConsumer>
          </div>
        </Router>
      </UserProvider>
    </GlobalProvider>
  );
}

const GlobalConsumer = ({ children }) => {
  const context = useContext(Global);
  if (!context) {
    return null; // or a loading spinner
  }
  return children(context);
};

export default App;
