import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './UserGroups.css';

// Components
import Divider from '../../../common/components/divider/Divider';
import UserGroup from './usergroup/UserGroup';
import UserGroupAdd from './usergroupadd/UserGroupAdd';
import UserGroupAddButton from './usergroupaddbutton/UserGroupAddButton';

const UserGroups = () => {

    // Global
    const {
        userGroups
    } = useContext(Global);

    // State Variables
    const [addVisible, setAddVisible] = useState(false);

    const handleClick = () => {
        setAddVisible(true);
    };

    return (
        <>
            <div className="user-groups-container">
                <UserGroupAddButton
                    onClick={handleClick}
                />
                <div className="user-groups-list">
                    {userGroups.map((userGroup) => (
                        <>
                            <UserGroup
                                key={userGroup.key}
                                userGroup={userGroup}
                            />
                            <Divider />
                        </>
                    ))}
                </div>
            </div>

            <UserGroupAdd
                isVisible={addVisible}
                setVisible={setAddVisible}
            />
        </>
    );
};

export default UserGroups;
