import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './CalendarToolBar.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import CalendarIcon from '../../../common/svg/CalendarIcon';

// Components
import CalendarMenu from '../CalendarMenu';
import CalendarNav from '../nav/CalendarNav';
import CalendarTitle from '../title/CalendarTitle';
import DatePicker from '../../components/datepicker/DatePicker';
import MonthPicker from '../../components/monthpicker/MonthPicker';
import WeekPicker from '../../components/weekpicker/WeekPicker';

const CalendarToolBar = () => {
    const { theme } = useTheme();
    const {
        activeDate,
        selectedCalendarType,
        setActiveDate,
    } = useContext(Global);

    // Local State Variables
    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const [monthPickerOpen, setMonthPickerOpen] = useState(false);
    const [weekPickerOpen, setWeekPickerOpen] = useState(false);

    const handleSelect = (date) => {
        if (!date) return;

        setActiveDate(date);
        setDatePickerOpen(false);
        setWeekPickerOpen(false);
    };

    const handlePickerButtonClick = () => {
        switch (selectedCalendarType) {
            case 'DAY':
                setDatePickerOpen(true);
                break;
            case 'WEEK':
                setWeekPickerOpen(true);
                break;
            case 'MONTH':
                setMonthPickerOpen(true);
                break;
            default:
                break;
        }
    };

    return (
        <div className="calendar-toolbar-container">

            {/* TOP */}
            <div className="calendar-toolbar-top">

                {selectedCalendarType === 'SCHEDULE' ? (
                    <>
                        {/* LEFT */}
                        <div className="calendar-toolbar-left">
                            <div className="calendar-toolbar-schedule"
                                style={{ color: theme.foregroundColor }}>
                                All Events
                            </div>
                        </div>
                    </>
                ) : selectedCalendarType === 'STORYLINE' ? (
                    <>
                        {/* LEFT */}
                        <div className="calendar-toolbar-left">
                            <div className="calendar-toolbar-storyline"
                                style={{ color: theme.foregroundColor }}>
                                Storyline
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        {/* LEFT */}
                        <div className="calendar-toolbar-left">

                            {/* NAV */}
                            <div className="calendar-toolbar-nav">
                                <CalendarNav />
                            </div>

                            {/* PICKERS */}
                            <div className="calendar-toolbar-pickers">

                                <div
                                    className="date-picker-button-container"
                                    onClick={handlePickerButtonClick}>
                                    <CalendarIcon
                                        color={theme.foregroundColor}
                                        width="16"
                                        height="16"
                                    />
                                </div>

                                {/* DATE PICKER POPUP */}
                                {selectedCalendarType === 'DAY' &&
                                    <DatePicker
                                        isOpen={datePickerOpen}
                                        onClose={() => setDatePickerOpen(false)}
                                        selectedDate={activeDate}
                                        onDateSelect={handleSelect}
                                    />
                                }

                                {/* WEEK PICKER POPUP */}
                                {selectedCalendarType === 'WEEK' &&
                                    <WeekPicker
                                        isOpen={weekPickerOpen}
                                        onClose={() => setWeekPickerOpen(false)}
                                        selectedDate={activeDate}
                                        onDateSelect={handleSelect}
                                    />
                                }

                                {/* MONTH PICKER POPUP */}
                                {selectedCalendarType === 'MONTH' &&
                                    <MonthPicker
                                        isOpen={monthPickerOpen}
                                        onClose={() => setMonthPickerOpen(false)}
                                        onMonthSelect={handleSelect}
                                        initialYear={activeDate.getFullYear()}
                                    />
                                }

                            </div>

                            {/* TITLE */}
                            <div className="calendar-toolbar-title"
                                style={{ color: theme.foregroundColor }}>
                                <CalendarTitle />
                            </div>

                        </div>
                    </>
                )}

                {/* RIGHT */}
                <div className="calendar-toolbar-right" >
                    <CalendarMenu />
                </div>

            </div>
        </div >
    );
};

export default CalendarToolBar;
