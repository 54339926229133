import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './AccountMenuSummary.css';

// Theme
import { useStyle, useTheme } from '../../ThemeContext';

// Styled Components
const InlineStyles = useStyle`
    .account-menu-summary-container:hover {
        background-color: ${(props) => props.hoverColor} !important;
        border-color: ${(props) => props.hoverColor} !important;
    }
`;

const AccountMenuSummary = ({ title, description }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        selectedApp,
    } = useContext(Global);

    return (
        <>
            <InlineStyles
                hoverColor={theme.backgroundColorFaded}
            />

            <div className="account-menu-summary-container">
                <div className="account-menu-summary-title"
                    style={{
                        color: theme.foregroundColor,
                        fontFamily: selectedApp && selectedApp.fontFamily,
                    }}>
                    {title}
                </div>
                <div className="account-menu-summary-description"
                    style={{
                        color: theme.foregroundColorFaded,
                        fontFamily: selectedApp && selectedApp.fontFamily,
                    }}>
                    {description}
                </div>
            </div>
            <div className="account-menu-summary-divider"
                style={{
                    borderBottomColor: theme.backgroundColorFaded
                }}
            ></div>
        </>
    );
};

export default AccountMenuSummary;
