// Firebase
import { doc, deleteDoc, collection, query, orderBy, onSnapshot, setDoc, updateDoc, where } from 'firebase/firestore';
import { collections, db } from '../../firebaseConfig';

// Activity
import { activity } from '../../common/managers/ActivityManager';

class EventManager {

    /**
     * Adds an event
     * 
     * @param {string} appKey - App key.
     * @param {string} key - The document ID for the new event.
     * @param {object} data - Event data.
     */
    async add(appKey, key, data) {

        // Set the new event document in Firestore
        await setDoc(doc(db, collections.events, key), data);

        activity.log(appKey, 'writes', 1);

        // Return success message
        return data;
    }

    /**
     * Deletes an event from the database.
     * 
     * @param {string} appKey - Key of the app.
     * @param {string} key - Key of the event being deleted.
    */
    async delete(appKey, key) {
        await deleteDoc(doc(db, collections.events, key));

        activity.log(appKey, 'deletes', 1);
    }

    /**
      * Fetches events and subscribes to real-time updates.
      * 
      * @param {string} appKey - App key.
      * @param {function} onUpdate - Callback function that handles the update.
      */
    listAndSubscribe(appKey, onUpdate) {

        try {
            // Create a reference to the events collection
            const eventsCollection = collection(db, collections.events);

            // Create a query to find events by appKey and sort them by title
            const q = query(eventsCollection, where("appKey", "==", appKey), orderBy("startDate"));

            // Subscribe to real-time updates
            const unsubscribe = onSnapshot(q, snapshot => {
                // Create an array of events from the snapshot
                const eventList = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                // Activity logging needs to occur in onUpdate

                // Call the onUpdate callback with the updated list
                if (onUpdate) {
                    activity.log(appKey, 'reads', eventList.length);
                    onUpdate(eventList);
                }

            }, error => {
                console.error("Error fetching events:", error);
            });

            // Return the unsubscribe function to allow the caller to unsubscribe later
            return unsubscribe;
        } catch (error) {
            console.error("Error setting up real-time updates:", error);
            throw error;
        }
    }

    /**
     * Filters events to find those that start, end, or span across a specific date.
     * 
     * @param {Array} events - Array of event objects with startDate and endDate as Firestore Timestamps.
     * @param {Date} date - The date to check the events against.
     * @returns {Array} Array of events that match the criteria.
     */
    getEventsOnDate(events, date) {
        // Create start and end times for the date at midnight and just before midnight
        const dayStart = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        const dayEnd = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);

        const eventsOnDate = events.filter(event => {
            // Convert Firestore Timestamps to JavaScript Date objects
            const startDate = event.startDate.toDate();
            const endDate = event.endDate.toDate();

            return (
                // Check if the event starts or ends on the given date
                (startDate >= dayStart && startDate <= dayEnd) ||
                (endDate >= dayStart && endDate <= dayEnd) ||
                // Check if the event spans across the given date
                (startDate <= dayStart && endDate >= dayEnd)
            );
        });

        return eventsOnDate;
    }

    /**
     * Filters events to find those that start, end, or span across the specified date and the two following days.
     * 
     * @param {Array} events - Array of event objects with startDate and endDate as Firestore Timestamps.
     * @param {Date} activeDate - The starting date for the three-day period to check the events against.
     * @returns {Array} Array of events that match the criteria for the three-day span.
     */
    getThreeDayEvents(events, activeDate) {
        // Create start time for the active date at midnight
        const periodStart = new Date(activeDate.getFullYear(), activeDate.getMonth(), activeDate.getDate());

        // Create end time two days after the active date, just before midnight
        const periodEnd = new Date(activeDate.getFullYear(), activeDate.getMonth(), activeDate.getDate() + 2, 23, 59, 59, 999);

        const eventsInThreeDays = events.filter(event => {
            // Convert Firestore Timestamps to JavaScript Date objects
            const startDate = event.startDate.toDate();
            const endDate = event.endDate.toDate();

            return (
                // Check if the event starts or ends within the three-day period
                (startDate >= periodStart && startDate <= periodEnd) ||
                (endDate >= periodStart && endDate <= periodEnd) ||
                // Check if the event spans across the entire period
                (startDate <= periodStart && endDate >= periodEnd)
            );
        });

        return eventsInThreeDays;
    }

    /**
     * Filters events to find those that start, end, or span across the specified date and the two following days.
     * 
     * @param {Array} events - Array of event objects with startDate and endDate as Firestore Timestamps.
     * @param {Date} startDate - The starting date for the week to check the events against.
     * @param {Date} endDate - The ending date for the week to check the events against.
     * @returns {Array} Array of events that match the criteria for the three-day span.
     */
    getEventsBetweenDates(events, startDate, endDate) {
        if (!events || events.length === 0) return [];
        
        // Create start time for the active date at midnight
        const periodStart = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());

        // Create end time two days after the active date, just before midnight
        const periodEnd = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59, 999);

        const eventsInSpan = events.filter(event => {
            // Convert Firestore Timestamps to JavaScript Date objects
            const startDate = event.startDate.toDate();
            const endDate = event.endDate.toDate();

            return (
                // Check if the event starts or ends within the three-day period
                (startDate >= periodStart && startDate <= periodEnd) ||
                (endDate >= periodStart && endDate <= periodEnd) ||
                // Check if the event spans across the entire period
                (startDate <= periodStart && endDate >= periodEnd)
            );
        });

        return eventsInSpan;
    }

    /**
     * Updates an event
     * 
     * @param {string} appKey - App key.
     * @param {string} key - The document ID for the existing event.
     * @param {object} data - Event data.
     */
    async update(appKey, key, data) {

        // Update the event document in Firestore
        await updateDoc(doc(db, collections.events, key), data);

        activity.log(appKey, 'writes', 1);

        // Return success message
        return data;
    }

}

export default EventManager;
