import React from 'react';

// Styles
import './ModalSection.css';

/**
 * ModalSection Component
 * 
 * This component renders a section container for modal items.
 * 
 * @param {JSX.Element} children - The children to render.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ModalSection = ({ children }) => {

  return (
      <div className="modal-section-container">
        {children}
      </div>
  );
};

export default ModalSection;
