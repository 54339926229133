import React from 'react';

// Device Detections
import { isMobile, isTablet } from 'react-device-detect';

// Styles
import './RunHome.css';

const RunHome = () => {

  return (
    <>
      <div className={(isMobile || isTablet) ? "run-home-container-mobile" : "run-home-container"}>
        
      </div>
    </>
  );
};

export default RunHome;
