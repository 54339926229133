import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global';

// Utilities
import { generateKey } from '../../common/utilities/Keys';

// Drag/Drop
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// Styles
import './Designer.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import CanvasButtons from './canvas/canvasbuttons/CanvasButtons';
import CanvasRow from './canvas/canvasrow/CanvasRow';
import ListElements from './list/listelements/ListElements';
import Modal from '../../common/components/modal/Modal';
import Properties from './properties/Properties';
import SaveDesignButton from './savedesignbutton/SaveDesignButton';

// Managers
import ModelManager from '../../common/managers/ModelManager';

const modelManager = new ModelManager();

// Utility to create a new block
const createBlock = (content = []) => ({
    id: generateKey(),
    content: content,  // Accept content to allow splitting
    align: 'left',
});

const Designer = ({ visible, setVisible }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        selectedApp,
        selectedElement,
        selectedModel,
        setSelectedElement,
        setSelectedModel,
    } = useContext(Global);

    // State Variables
    const [rows, setRows] = useState([{ id: generateKey(), blocks: [createBlock()] }]);
    const [selectedBlock, setSelectedBlock] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);

    useEffect(() => {
        if (selectedModel.rows) {
            setRows(selectedModel.rows);
        } else {
            setRows([{ id: generateKey(), blocks: [createBlock()] }]);
        }
    }, [selectedModel]);

    // Clears selections
    const clearSelections = async () => {
        setSelectedRow(null);
        setSelectedBlock(null);
        setSelectedElement(null);
    };

    // Handles selecting a block
    const handleBlockSelect = (rowId, blockId) => {
        clearSelections();
        setSelectedBlock({ rowId, blockId });
    };

    // Handle selecting a row
    const handleRowSelect = async (rowId) => {
        await clearSelections();
        setSelectedRow(rowId);
    };

    // Handle selecting an element inside a block
    const handleElementSelect = async (element) => {
        await clearSelections();
        setSelectedElement(element);
    };

    // Split the selected block into two blocks
    const handleSplitBlock = (rowId, blockId) => {
        setRows(rows.map(row => {
            if (row.id === rowId) {
                const newBlocks = row.blocks.map(block => {
                    if (block.id === blockId) {
                        // Ensure each new block gets a unique key
                        return [createBlock(block.content), createBlock([])]; // Split into two blocks
                    }
                    return block;
                }).flat();
                return { ...row, blocks: newBlocks };
            }
            return row;
        }));
    };

    // Delete the selected row
    const handleDeleteRow = () => {
        setRows(rows.filter(row => row.id !== selectedRow))
        clearSelections();
    };

    // Delete the selected block or the entire row if it's the last block
    const handleDeleteBlock = () => {
        const { rowId, blockId } = selectedBlock;  

        setRows(prevRows =>
            prevRows.map(row => {
                if (row.id === rowId) {
                    // Check if the row only has one block
                    if (row.blocks.length === 1) {
                        return null; // Return null to indicate this row should be removed
                    }
                    // Remove the block with the matching blockId from the blocks array
                    return {
                        ...row,
                        blocks: row.blocks.filter(block => block.id !== blockId)
                    };
                }
                return row; // Return the row unchanged if it's not the target row
            }).filter(row => row !== null) // Remove rows where we returned null (i.e., rows with only one block)
        );

        clearSelections();
    };

    // Delete the selected element by its key
    const handleDeleteElement = () => {
        const { key } = selectedElement;  // Extract the key from the passed element

        // Iterate over the rows to find the row, block, and element by key
        setRows(prevRows => prevRows.map(row => {
            return {
                ...row,
                blocks: row.blocks.map(block => {
                    const newContent = block.content.filter(item => item.key !== key); // Remove the element by key
                    return { ...block, content: newContent };
                })
            };
        }));

        clearSelections();
    };

    // Delete the selected block or entire row if it's the last block
    const handleCanvasClick = () => {
        clearSelections();
    }

    // Align block content
    const handleAlignBlock = (rowId, blockId, alignType) => {
        setRows(rows.map(row => {
            if (row.id === rowId) {
                return {
                    ...row,
                    blocks: row.blocks.map(block =>
                        block.id === blockId ? { ...block, align: alignType } : block
                    ),
                };
            }
            return row;
        }));
    };

    // Move a row from one position to another
    const handleMoveRow = (dragIndex, hoverIndex) => {
        const newRows = [...rows];
        const [draggedRow] = newRows.splice(dragIndex, 1); // Remove the dragged row
        newRows.splice(hoverIndex, 0, draggedRow); // Insert it in the new position
        setRows(newRows); // Update the state with reordered rows
    };

    // Add a new row
    const addRow = () => {
        setRows([...rows, { id: generateKey(), blocks: [createBlock()] }]);
    };

    // Update block content when an element is dropped, appending instead of replacing
    const handleDrop = (rowId, blockId, element) => {

        // Clear any existing selections
        clearSelections();

        // Destructure the element object and omit the 'icon' property
        const { icon, ...elementWithoutIcon } = element;

        // When an element is dropped, generate a unique key for the instance
        const newBlockElement = { ...elementWithoutIcon, key: generateKey() };

        // Update the rows state with the new element
        setRows(rows.map(row => {
            if (row.id === rowId) {
                return {
                    ...row,
                    blocks: row.blocks.map(block =>
                        block.id === blockId
                            ? { ...block, content: [...block.content, newBlockElement] }
                            : block
                    ),
                };
            }
            return row;
        }));

        setSelectedElement(newBlockElement);
    };

    /**
     * Method to save the design to the model record
     */
    const handleSave = async () => {

        // Save the data to the database
        await modelManager.update(selectedApp.key, selectedModel.key, { rows: rows });

        // Update the selected model in state with the new elements
        setSelectedModel(prev => ({ ...prev, rows }));

        // Close the designer
        setVisible(false);

    };

    // Keyboard shortcuts
    useEffect(() => {
        // Function to handle key press events
        const handleKeyPress = (event) => {

            // Check if the active element is an input, textarea, or other focusable element
            const activeElement = document.activeElement;
            const isInputFocused = activeElement.tagName === 'INPUT' ||
                activeElement.tagName === 'TEXTAREA' ||
                activeElement.isContentEditable;

            // If an input is focused, do not remove or move the selected element
            if (isInputFocused) return;

            if (event.key === 'Backspace' || event.key === 'Delete') {
                // Check if an element is selected
                if (selectedElement) {
                    handleDeleteElement(selectedElement);
                }
                // Check if a block is selected
                else if (selectedBlock) {
                    handleDeleteBlock(selectedBlock.rowId, selectedBlock.blockId);
                }
                // Check if a row is selected
                else if (selectedRow) {
                    handleDeleteRow(selectedRow);
                }
            }
        };

        // Attach the event listener for keydown
        window.addEventListener('keydown', handleKeyPress);

        // Cleanup the event listener when the component unmounts or dependencies change
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [selectedRow, selectedBlock, selectedElement, handleDeleteRow, handleDeleteBlock, handleDeleteElement]);

    return (
        <Modal
            title={`${selectedModel.title} -  Card Layout`}
            isOpen={visible}
            onClose={() => setVisible(false)}
            height="80%"
            width="920px">
            <DndProvider backend={HTML5Backend}>
                <div className="designer-container">

                    <div className="designer-left">

                        {/* ELEMENTS PANEL */}
                        <div className="designer-elements"
                            style={{
                                backgroundColor: theme.backgroundColor,
                            }}>
                            <ListElements useDrag={useDrag} />
                        </div>

                        <div className="designer-canvas-wrapper">

                            {/* BUTTONS */}
                            <CanvasButtons
                                selectedRow={selectedRow}
                                selectedBlock={selectedBlock}
                                selectedElement={selectedElement}
                                onAddRow={addRow}
                                onDeleteRow={handleDeleteRow}
                                onSplitBlock={() => handleSplitBlock(selectedBlock.rowId, selectedBlock.blockId)}
                                onDeleteBlock={() => handleDeleteBlock(selectedBlock.rowId, selectedBlock.blockId)}
                                onAlignLeft={() => handleAlignBlock(selectedBlock.rowId, selectedBlock.blockId, 'left')}
                                onAlignCenter={() => handleAlignBlock(selectedBlock.rowId, selectedBlock.blockId, 'center')}
                                onAlignRight={() => handleAlignBlock(selectedBlock.rowId, selectedBlock.blockId, 'right')}
                                onDeleteElement={() => handleDeleteElement(selectedElement)}
                            />

                            {/* Main Canvas */}
                            <div className="designer-canvas"
                                onClick={handleCanvasClick}>
                                {rows.map((row, index) => (
                                    <CanvasRow
                                        key={row.id}
                                        index={index}
                                        row={row}
                                        selectedRow={selectedRow}
                                        selectedBlock={selectedBlock}
                                        selectedElement={selectedElement}
                                        onMoveRow={handleMoveRow}
                                        onRowSelect={handleRowSelect}
                                        onBlockSelect={handleBlockSelect}
                                        onDrop={handleDrop}
                                        onElementSelect={handleElementSelect}
                                        useDrag={useDrag}
                                        useDrop={useDrop}
                                    />
                                ))}
                            </div>

                            <div className="designer-save-button">

                                {/* SAVE BUTTON */}
                                <SaveDesignButton onClick={handleSave} />

                            </div>

                        </div>

                        {/* PROPERTIES */}
                        <div className="designer-properties">
                            <Properties
                                rows={rows}
                                setRows={setRows}
                            />
                        </div>

                    </div>
                </div>
            </DndProvider>

        </Modal>
    );
};

export default Designer;

