import React from 'react';

const TextIcon = ({ color = "white", width = "24", height = "24" }) => (
    <svg
        version="1.1"
        width={width}
        height={height}
        viewBox="0 0 24 24">
        <path fill={color} d="M11,24H1a.945.945,0,0,1-1-1V13a.908.908,0,0,1,.3-.7l12-12a.967.967,0,0,1,1.4,0l10,10a.967.967,0,0,1,0,1.4l-12,12A.908.908,0,0,1,11,24ZM2,22h8.6l11-11L13,2.4,2,13.4Zm5-2a3,3,0,0,1,0-6,3,3,0,0,1,0,6Zm0-4a1,1,0,0,0,0,2,1,1,0,0,0,0-2Z" />
    </svg>


);

export default TextIcon;
