// Firebase
import { collection, onSnapshot, deleteDoc, doc, query, orderBy, setDoc, Timestamp, where } from 'firebase/firestore';
import { collections, db } from '../../firebaseConfig';

// Utilities
import { generateKey } from '../utilities/Keys';

// Managers
import { activity } from './ActivityManager';

class ChatPostManager {

    /**
     * Method to add a new chat post.
     * 
     * @param {string} appKey - App key.
     * @param {string} channelKey - Channel key.
     * @param {string} user - User object.
     * @param {string} text - Text the user is posting.
     * @param {string} photoUrls - Array of uploaded image urls.
     * @param {string} documentUrls - Array of uploaded document urls.
     * @param {array} objects - Array of objects.
     * @param {array} events - Array of events.
     * @returns {post} - New post record.
    */
    async add(appKey, channelKey, user, text, photoUrls = [], videoUrls = [], documents = [], objects = [], events = []) {

        const now = Timestamp.now();

        const key = generateKey();

        const post = {
            key: key,
            appKey: appKey,
            channelKey: channelKey,
            type: "POST",
            userKey: user.key,
            username: user.username,
            text: text,
            photoUrls: photoUrls,
            videoUrls: videoUrls,
            documents: documents,
            objects: objects,
            events: events,
            dateCreated: now
        };

        await setDoc(doc(db, collections.chatposts, key), post);

        activity.log(key, 'writes', 1);

        return post;
    }

    /**
      * Fetches posts in a channel and subscribes to real-time updates.
      * 
      * @param {string} appKey - App key.
      * @param {string} channelKey - Channel key.
      * @param {function} onUpdate - Callback function that handles the update.
      */
    fetchAndSubscribe(appKey, channelKey, onUpdate) {

        try {
            const postsCollection = collection(db, collections.chatposts);

            const q = query(postsCollection,
                where("appKey", "==", appKey),
                where("channelKey", "==", channelKey),
                orderBy("dateCreated", "desc"));

            const unsubscribe = onSnapshot(q, snapshot => {
                const posts = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                if (onUpdate) {
                    onUpdate(posts);
                    activity.log(appKey, 'reads', posts.length);
                }
            }, error => {
                console.error("Error fetching posts:", error);
            });

            return unsubscribe;
        } catch (error) {
            console.error("Error setting up real-time updates:", error);
            throw error;
        }
    }

    /**
     * Deletes a post.
     * 
     * @param {string} key - Post key.
     */
    async delete(key) {
        try {

            await deleteDoc(doc(db, collections.chatposts, key));

        } catch (error) {
            console.error('Error deleting chat post:', error);
            return { success: false, message: "Error deleting post." };
        }
    }

}

export default ChatPostManager;
