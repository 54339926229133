import React, { useState, useContext, useEffect } from 'react';

// Global
import { Global } from '../../../../Global';

// Utilities
import { FormMode } from '../../../utilities/Enums';

// Styles
import './UserList.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Icons
import ForwardIcon from '../../../../common/svg/ForwardIcon';

// Components
import AppUserSummary from '../../../../common/appusers/AppUserSummary';
import FieldContainer from '../../FieldContainer';
import FieldHeader from '../../FieldHeader';
import UserSelector from '../../../components/userselector/UserSelector';

const UserList = ({
    object,
    field,
    onUpdate,
    showFieldMenu = true,
    readOnly = false,
}) => {

    const { theme } = useTheme();
    const { appUsers, formMode, setAppUsersVisible, setSelectedAppUser, setSelectedModel, setSelectedObject } = useContext(Global);

    // State Variables
    const [relatedAppUsers, setRelatedAppUsers] = useState([]);
    const [userSelectorOpen, setUserSelectorOpen] = useState(false);

    /** 
     * Ensures that if we're in add mode, make sure we start out without 
     * any checked users.
     */
    useEffect(() => {
        if (formMode === FormMode.ADD) {
            setRelatedAppUsers([]);
        }
    }, [formMode]);

    /** 
     * Initializes relatedAppUsers based on the object data when component mounts.
     */
    useEffect(() => {
        if (!object || !object[field.key]) return;

        const initializeRelatedUsers = () => {
            const existingUserKeys = object[field.key];
            const existingUsers = appUsers.filter(user => existingUserKeys.includes(user.userKey));
            setRelatedAppUsers(existingUsers);
        };

        initializeRelatedUsers();
    }, [object, appUsers, field.key]);

    /** 
     * Handles user selection updates from UserSelector.
     */
    const handleUserSelect = (selectedUsers) => {
        setRelatedAppUsers(selectedUsers); // Update the selected users state

        // Extract user keys to update the parent component
        const selectedUserKeys = selectedUsers.map(user => user.userKey);
        onUpdate(field, selectedUserKeys); // Notify parent component of the updated selection
    };

    /** 
     * Function to handle the forward action (for navigation or user details).
     */
    const handleForward = (e, appUser) => {
        e.stopPropagation();

        if (formMode !== FormMode.VIEW) {
            handleSelectClick(e);
        } else {
            setAppUsersVisible(true);
            setSelectedAppUser(appUser);
            setSelectedObject(null);
            setSelectedModel(null);
        }
    };

    const handleSelectClick = (e) => {
        e.stopPropagation();
        setUserSelectorOpen(true);
    };

    return (
        <FieldContainer
            readOnly={readOnly}
            field={field}
            onClick={formMode !== FormMode.VIEW && handleSelectClick}>
            <FieldHeader field={field} readOnly={readOnly} showFieldMenu={showFieldMenu} />

            {/* Display selected users in list format */}
            <div className="user-list-items">
                {relatedAppUsers.map((appUser, index) => (
                    <div key={appUser.userKey || index} className="user-list-item">
                        <div className="user-list-item-summary" onClick={(e) => handleForward(e, appUser)}>
                            <AppUserSummary appUser={appUser} />
                        </div>
                        {formMode === FormMode.VIEW && (
                            <div className="user-list-item-forward" onClick={(e) => handleForward(e, appUser)}>
                                <ForwardIcon color={theme.foregroundColor} width="16" height="16" />
                            </div>
                        )}
                    </div>
                ))}
            </div>

            {/* EDIT MODE */}
            {!readOnly && (
                <>
                    {/* NO USERS */}
                    {relatedAppUsers.length === 0 &&
                        <div
                            className="user-list-summary-no-users"
                            style={{
                                color: theme.backgroundColorFaded
                            }}>
                            {relatedAppUsers.length === 0 && 'No members selected'}
                        </div>
                    }

                    {/* USER SELECTOR */}
                    <UserSelector
                        selectedUsers={relatedAppUsers} // Pass selected users directly as objects
                        isOpen={userSelectorOpen}
                        onClose={() => setUserSelectorOpen(false)}
                        onSelect={handleUserSelect}
                    />
                </>
            )}
        </FieldContainer>
    );
};

export default UserList;
