import React from 'react';

const DockUpIcon = ({ color = "white", width = "24", height = "24" }) => (
    <svg
        version="1.1"
        width={width}
        height={height}
        viewBox="0 0 24 24">
        <path d="M4.794 9.997H9.25a.75.75 0 0 1 .102 1.493l-.102.007H4.794c-.672 0-1.203.45-1.267 1.001l-.006.112-.021 4.776c0 .561.49 1.046 1.14 1.104l.133.006h14.454c.672 0 1.203-.45 1.267-1.001l.006-.112.02-4.776c0-.561-.488-1.046-1.14-1.104l-.132-.006H14.75a.75.75 0 0 1-.101-1.493l.101-.007h4.498c1.46 0 2.676 1.066 2.767 2.446l.006.167L22 17.386c0 1.4-1.16 2.52-2.599 2.605l-.174.005H4.773c-1.46 0-2.676-1.067-2.767-2.446L2 17.383l.02-4.776c0-1.4 1.16-2.521 2.6-2.605l.174-.005H9.25 4.794Zm7.819-6.791.094.083 3.723 3.723a1 1 0 0 1-1.32 1.497l-.094-.083-2.017-2.016L13 15a1 1 0 0 1-.883.993L12 16a1 1 0 0 1-.993-.883L11 15V6.41L8.983 8.426a1 1 0 0 1-1.32.083l-.094-.083a1 1 0 0 1-.083-1.32l.083-.094 3.723-3.723a1 1 0 0 1 1.32-.083Z"
            fill={color} />
    </svg>
);

export default DockUpIcon;
