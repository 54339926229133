import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Device Detections
import { isMobile } from 'react-device-detect';

// Utilities
import { FormMode } from '../utilities/Enums';

// Styles
import './Favorites.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import FavoritesToolBar from './FavoritesToolBar';
import Divider from '../components/divider/Divider';
import Object from '../objects/Object';

const Favorites = () => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    favorites,
    models,
    resetVisibility,
    setFormMode,
    setSelectedModel,
    setSelectedObject,
  } = useContext(Global);

  const handleObjectClick = async (object) => {
    resetVisibility();
    const model = models.find(model => model.key === object.modelKey);
    setSelectedModel(model);
    setSelectedObject(object);
  };

  // Split into columns only for non-mobile devices
  const leftColumn = [];
  const rightColumn = [];

  if (!isMobile) {
    favorites.forEach((favorite, index) => {
      if (index % 2 === 0) {
        leftColumn.push(favorite);
      } else {
        rightColumn.push(favorite);
      }
    });
  }

  return (
    <div className="favorites-container">
      <FavoritesToolBar />
      <div className={isMobile ? "favorites-wrapper-mobile" : "favorites-wrapper"}>
        {isMobile ? (
          // Single column for mobile
          <div className="favorites-column-mobile">
            {favorites.map((favorite) => (
              <div key={favorite.id} className="favorites-object" style={{ backgroundColor: theme.backgroundColor }}>
                <Object object={favorite.object} onClick={() => handleObjectClick(favorite.object)} />
                <Divider />
              </div>
            ))}
          </div>
        ) : (
          // Two columns for desktop
          <>
            <div className="favorites-column">
              {leftColumn.map((favorite) => (
                <div key={favorite.id} className="favorites-object" style={{ backgroundColor: theme.backgroundColor }}>
                  <Object object={favorite.object} onClick={() => handleObjectClick(favorite.object)} />
                  <Divider />
                </div>
              ))}
            </div>
            <Divider direction="vertical" />
            <div className="favorites-column">
              {rightColumn.map((favorite) => (
                <div key={favorite.id} className="favorites-object" style={{ backgroundColor: theme.backgroundColor }}>
                  <Object object={favorite.object} onClick={() => handleObjectClick(favorite.object)} />
                  <Divider />
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Favorites;
