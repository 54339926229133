import React, { useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Firebase
import { Timestamp } from 'firebase/firestore';

// Utilities
import { generateKey } from '../../../utilities/Keys';

// Styles
import './CalendarAdd.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import CalendarTags from '../calendartags/CalendarTags';
import Modal from '../../../components/modal/Modal';
import ModalButton from '../../../components/modal/ModalButton';
import ModalButtons from '../../../components/modal/ModalButtons';
import ModalInput from '../../../components/modal/ModalInput';
import ModalLabel from '../../../components/modal/ModalLabel';
import ModalSection from '../../../components/modal/ModalSection';
import ModalSwitch from '../../../components/modal/ModalSwitch';
import ModalTextarea from '../../../components/modal/ModalTextarea';

// Managers
import CalendarManager from '../../../managers/CalendarManager';

const calendarManager = new CalendarManager();

/**
 * CalendarAdd Component
 * 
 * This component allows the user to add a calendar.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const CalendarAdd = () => {
    const { theme } = useTheme();
    const {
        calendarAddOpen,
        currentUser,
        hideProgress,
        calendars,
        selectedApp,
        setCalendarAddOpen,
        setSelectedCalendar,
        showProgress
    } = useContext(Global);

    // State Variables
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [tags, setTags] = useState([]);

    const handleAdd = async () => {

        if (!title) {
            alert('Please enter a calendar title.');
            return;
        }

        showProgress("Adding calendar...");

        setCalendarAddOpen(false);

        const userKey = currentUser.userKey;
        const username = currentUser.username;
        const appKey = selectedApp.key;

        const newCalendarKey = generateKey();  // Generate key for the new calendar

        // Current timestamp
        const now = Timestamp.now();

        // Initialize the highest sort number to 0
        let highestSortNumber = 0;

        // Iterate through each calendar to find the highest sort number
        calendars.forEach(calendar => {
            const sortNumber = calendar.sort;
            if (sortNumber > highestSortNumber) {
                highestSortNumber = sortNumber;
            }
        });

        const calendarData = {
            key: newCalendarKey,
            appKey: appKey,
            description: description,
            userKey: userKey,
            username: username,
            title: title,
            sort: highestSortNumber + 1,
            tags: tags,
            dateCreated: now,
            dateModified: now,
        };

        // Add the calendar to the database first
        await calendarManager.add(appKey, newCalendarKey, calendarData);

        // Set the selected calendar
        setSelectedCalendar(calendarData);

        // Reset the title field
        setTitle('');

        // Reset the description field
        setDescription('');

        // Hide the progress panel
        hideProgress();
    };

    return (
        <>
            {/* MODAL */}
            <Modal title="New Calendar"
                isOpen={calendarAddOpen}
                onClose={() => setCalendarAddOpen(false)}
                width="330px"
                height="auto">

                {/* NAME */}
                <ModalSection>

                    <ModalLabel text="Calendar Name" />

                    <ModalInput
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        maxLength="100"
                        autoFocus={true}
                    />

                </ModalSection>

                {/* DESCRIPTION */}
                <ModalSection>

                    <ModalLabel text="Description" />

                    <ModalTextarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        maxLength="255"
                        lines="2"
                    />

                </ModalSection>

                {/* TAGS */}
                <ModalSection>

                    <ModalLabel text="Tags" />

                    <CalendarTags
                        initialTags={tags}
                        onTagsChange={(updatedTags) => setTags(updatedTags)}
                    />

                </ModalSection>

                {/* BUTTONS */}
                <ModalButtons>

                    {/* ADD BUTTON */}
                    <ModalButton
                        onClick={handleAdd}
                        label="Continue..."
                    />

                </ModalButtons>

            </Modal>
        </>
    );
};

export default CalendarAdd;
