import React from 'react';

// Device Detections
import { isMobile, isTablet } from 'react-device-detect';

// Styles
import './Chat.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import ChatToolbar from './ChatToolbar';
import ChatUsers from './ChatUsers';
import ChatPostForm from './chatpostform/ChatPostForm';
import ChatPosts from './chatposts/ChatPosts';

/**
 * Chat Component
 * 
 * This component renders a chat channel.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Chat = () => {
  const { theme } = useTheme();

  return (
    <div className={(isMobile || isTablet) ? "chat-container-mobile" : "chat-container"}>
      <div className="chat-left">

        {/* TOOLBAR */}
        <ChatToolbar />

        {/* POSTS */}
        <ChatPosts />

        {/* POST FORM */}
        <ChatPostForm />
      </div>
      <div className="chat-right"
        style={{
          borderLeftColor: theme.backgroundColorFaded
        }}>

        {/* USERS */}
        {!isMobile &&
          <ChatUsers />
        }

      </div>

    </div>
  );
};

export default Chat;
