import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Firestore
import { Timestamp } from 'firebase/firestore';

// Utilities
import { generateKey } from '../../../common/utilities/Keys';

// Styles
import './EventForm.css';

// Theme
import { useStyle, useTheme } from '../../../ThemeContext';

// Components
import EventDates from './EventDates';
import EventFieldSelector from './EventFieldSelector';
import EventRemoveButton from './EventRemoveButton';
import EventSaveButton from './EventSaveButton';
import EventTitle from './EventTitle';
import EventDivider from './EventDivider';
import EventObjectSelector from './EventObjectSelector';
import FormField from '../../form/FormField';
import Modal from '../../../common/components/modal/Modal';
import Object from '../../objects/Object';
import TagSelector from '../../../common/components/tagselector/TagSelector';

// Managers
import EventManager from '../../../common/managers/EventManager';
import HeadlineManager from '../../../common/managers/HeadlineManager';
import ObjectManager from '../../../common/managers/ObjectManager';

const eventManager = new EventManager();
const headlineManager = new HeadlineManager();
const objectManager = new ObjectManager();

// Inline Styles
const InlineStyles = useStyle`
  *::placeholder {
    color: ${(props) => props.placeholderColor};
    opacity: .2; 
  }
`;

const EventForm = ({ date, onSave }) => {
  const { theme } = useTheme();
  const {
    currentUser,
    defaultEventTag,
    eventVisible,
    models,
    resetVisibility,
    selectedApp,
    selectedCalendar,
    selectedEvent,
    setEventVisible,
    setSelectedModel,
    setSelectedObject
  } = useContext(Global);

  // State Variables
  const [title, setTitle] = useState('');
  const [defaultStart, setDefaultStart] = useState(null);
  const [defaultEnd, setDefaultEnd] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [selectedEntries, setSelectedEntries] = useState([]);
  const [modalTitle, setModalTitle] = useState('Add Event');
  const [fields, setFields] = useState([]); // Stores event fields
  const [formData, setFormData] = useState({}); // Stores [field.key]: values

  // Tags (for ADD mode)
  const [selectedTags, setSelectedTags] = useState([]);

  // Object
  const [eventObjects, setEventObjects] = useState([]);

  // Set the selected tags when the calendar changes
  useEffect(() => {
    if (!selectedEvent) return;
    setSelectedTags(selectedEvent.tags || []);
  }, [selectedEvent]);

  // Fields
  const handleFieldAdd = (field) => {
    if (field.type === 'checklist') {
      // Copy the field.value to the formData object
      setFormData(prev => ({ ...prev, [field.key]: field.value }));
    }

    setFields(prevFields => [...prevFields, field]);
  };

  // Initialize state based on whether we are adding or editing
  useEffect(() => {
    if (!eventVisible) return;

    if (selectedEvent) {
      // Editing an existing event
      setModalTitle('Edit Event');
      setTitle(selectedEvent.title);
      const start = selectedEvent.startDate.toDate();
      const end = selectedEvent.endDate.toDate();
      setDefaultStart(start);
      setDefaultEnd(end);
      setStartDate(start);
      setStartTime(start);
      setEndDate(end);
      setEndTime(end);
      setSelectedEntries(selectedEvent.attachments || []);
      setFields(selectedEvent.fields || []);
      setEventObjects(selectedEvent.eventObjects || []);
      setFormData(selectedEvent.formData || {});
    } else {
      // Adding a new event
      setModalTitle('Add Event');
      setTitle('');
      const start = new Date(date);
      const end = new Date(start);
      end.setHours(end.getHours() + 1);
      setDefaultStart(start);
      setDefaultEnd(end);
      setStartDate(start);
      setStartTime(start);
      setEndDate(end);
      setEndTime(end);
      setSelectedEntries([]);
      setFields([]);
      setEventObjects([]);
      setFormData({});

      // Set the default tag if one is provided
      if (defaultEventTag) {
        setSelectedTags([defaultEventTag]);
      }
    }
  }, [eventVisible, selectedEvent, date]);

  const handleStartDateChange = (d) => {
    setStartDate(d);
  };

  const handleStartTimeChange = (t) => {
    setStartTime(t);
  };

  const handleEndDateChange = (d) => {
    setEndDate(d);
  };

  const handleEndTimeChange = (t) => {
    setEndTime(t);
  };

  /**
   * Method to handle saving of the event information.
   * 
  */
  const save = async () => {
    if (title.trim() === '') {
      setTitle('(No title)');
    }

    const start = new Date(startDate);
    start.setHours(startTime.getHours(), startTime.getMinutes(), 0, 0);
    const end = new Date(endDate);
    end.setHours(endTime.getHours(), endTime.getMinutes(), 0, 0);

    const key = selectedEvent ? selectedEvent.key : generateKey();

    let startTimestamp = Timestamp.fromDate(start);
    let endTimestamp = Timestamp.fromDate(end);

    const data = {
      appKey: selectedApp.key,
      key: key,
      calendarKey: selectedCalendar.key,
      tags: selectedTags,
      title: title,
      startDate: startTimestamp,
      endDate: endTimestamp,
      attachments: selectedEntries,
      fields: fields,
      eventObjects: eventObjects,
      formData: formData,
    };

    if (selectedEvent) {
      await eventManager.update(selectedApp.key, key, data);
    } else {
      await eventManager.add(selectedApp.key, key, data);

      // Create a headline
      headlineManager.addEventAddedHeadline(
        selectedApp.key,
        currentUser,
        key,
        title,
        startTimestamp,
        endTimestamp
      );
    }

    if (onSave) {
      onSave(data);
    }

    setEventVisible(false);
  };

  /**
   * Method to handle the removal of an event.
   * 
  */
  const handleRemoveEvent = async () => {
    await eventManager.delete(selectedApp.key, selectedEvent.key);
    setEventVisible(false);
  };

  /**
   * Method to handle the selection of an object.
   * 
   * @param {string} entry - The selected search index entry.
  */
  const handleObjectSelect = async (entry) => {
    const modelKey = entry.modelKey;

    // Fetch the object
    const object = await objectManager.fetch(selectedApp.key, modelKey, entry.objectKey);

    // Update the eventObjects state by adding the new object to the existing array
    setEventObjects(prevObjects => [...prevObjects, object]);

  };

  const handleFieldUpdate = (field, value) => {
    // Add the field value pair to the fieldObject
    setFormData(prev => ({ ...prev, [field.key]: value }));
  };

  const handleObjectClick = async (object) => {
    resetVisibility();
    const model = models.find(model => model.key === object.modelKey);
    setSelectedModel(model);
    setSelectedObject(object);
  };

  return (
    <>
      <InlineStyles
        placeholderColor={theme.foregroundColorFaded}
      />

      <Modal title={modalTitle} isOpen={eventVisible} onClose={() => setEventVisible(false)} width="100%" height="100%">

        <div className="event-form-container">

          <div className="event-form-sections">
            {/* EVENT TITLE */}
            <EventTitle
              title={title}
              setTitle={setTitle}
            />

            {/* DIVIDER */}
            <EventDivider />

            {/* DATES AND TIMES */}
            <EventDates
              defaultStart={defaultStart}
              defaultEnd={defaultEnd}
              onStartDateChange={handleStartDateChange}
              onStartTimeChange={handleStartTimeChange}
              onEndDateChange={handleEndDateChange}
              onEndTimeChange={handleEndTimeChange}
            />

            {/* DIVIDER */}
            <EventDivider />

            {/* TAGS */}
            <TagSelector
              tags={(selectedCalendar && selectedCalendar.tags) || []}
              selectedTags={selectedTags}
              onTagClick={setSelectedTags}
            />

            {/* DIVIDER */}
            <EventDivider />

            {/* OBJECTS */}
            {eventObjects.map((object) => (
              <div key={object.id} className="event-form-object"
                style={{
                  backgroundColor: theme.backgroundColorFaded
                }}
                onClick={() => handleObjectClick(object)}>
                <Object object={object} />
              </div>
            ))}

            <EventDivider />

            {/* FORM FIELDS */}
            {formData && fields.map((field) => (
              <FormField
                object={formData}
                key={field.key}
                field={field}
                onUpdate={handleFieldUpdate}
                showFieldMenu={false}
              />
            ))}

            {fields.length > 0 &&
              <>
                < EventDivider />
              </>
            }

            {/* EVENT OBJECT BUTTON */}
            <EventObjectSelector
              onObjectSelect={handleObjectSelect}
            />

            {/* FIELD SELECTOR */}
            <EventFieldSelector
              fields={fields}
              onFieldAdd={handleFieldAdd}
            />

          </div>

          {/* MASK */}
          <div className="event-form-buttons-mask"
            style={{
              backgroundColor: theme.backgroundColor
            }}>
          </div>

          {/* BUTTONS */}
          <div className="event-form-buttons">

            {/* SAVE BUTTON */}
            <EventSaveButton
              onSave={save}
            />

            {selectedEvent &&
              <>
                {/* DELETE BUTTON */}
                <EventRemoveButton
                  onRemove={handleRemoveEvent}
                />
              </>
            }

          </div>

        </div>

      </Modal >
    </>
  );
};

export default EventForm;
