import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './AppUsers.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import AppUsersToolBar from './AppUsersToolBar';
import AppUserInviteButton from './AppUserInviteButton';
import AppUserSummary from './AppUserSummary';

const AppUsers = () => {
    const { theme } = useTheme();
    const {
        appUsers,
        setSelectedAppUser,
    } = useContext(Global);

    const handleUserClick = (appUser) => {
        setSelectedAppUser(appUser);
    };

    return (
        <>
            <div className="app-users-container"
                style={{
                    borderRightColor: theme.backgroundColorFaded,
                    borderTopColor: theme.backgroundColorFaded,
                }}>
                <AppUsersToolBar />
                <div className="app-users-list">
                    {appUsers.map((appUser) => (
                        <div className="app-users-user-wrapper"
                            key={appUser.key}
                            onClick={() => handleUserClick(appUser)}>
                            <AppUserSummary
                                appUser={appUser}
                            />
                        </div>
                    ))}
                </div>
                <div className="app-users-add-button">
                    <AppUserInviteButton />
                </div>
            </div>
            {/**
                {currentUser.userKey == selectedApp.userKey && currentUser.userKey !== appUser.userKey &&
                    <AppUserMenu
                        appUserKey={appUser.key}
                        handleDelete={() => handleDelete(appUser.key)}
                    />
                }
            */}
        </>
    );
};

export default AppUsers;
