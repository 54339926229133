import React, { useContext } from 'react';

// Global
import { Global } from '../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Utilities
import { defaultFont } from '../common/utilities/Defaults.js';
import { Page } from '../common/utilities/Enums.js';

// Custom Hooks
import useDetectInvite from './hooks/DetectInvite.js';
import useLoadPagePreference from './hooks/LoadPagePreference.js';
import useLoadThemePreference from './hooks/LoadThemePreference.js';
import useLoadMenuTypePreference from './hooks/LoadMenuTypePreference.js';
import usePreloadAppFields from './hooks/PreloadAppFields.js';
import usePreloadAppUsers from './hooks/PreloadAppUsers.js';
import usePreloadBookmarks from './hooks/PreloadBookmarks.js';
import usePreloadCalendars from './hooks/PreloadCalendars.js';
import usePreloadChannels from './hooks/PreloadChannels.js';
import usePreloadConversations from './hooks/PreloadConversations.js';
import usePreloadEvents from './hooks/PreloadEvents.js';
import usePreloadFavorites from './hooks/PreloadFavorites.js';
import usePreloadModels from './hooks/PreloadModels.js';
import usePreloadUserGroups from './hooks/PreloadUserGroups.js';
import usePreprocessInvite from './hooks/PreprocessInvite.js';
import useProcessLoginStatus from './hooks/ProcessLoginStatus.js';

// Styles
import './Root.css';

// Theme
import { useTheme } from '../ThemeContext';

// Components
import Account from '../account/Account';
import AppAdd from '../common/appadd/AppAdd';
import Home from '../home/Home';
import LogoLarge from '../common/logolarge/LogoLarge';
import Desktop from '../desktop/Desktop';
import Mobile from '../mobile/Mobile';
import Welcome from '../welcome/Welcome';
import Progress from '../common/components/progress/Progress';

// Dynamic Styles
import { RootStyles } from './RootStyles';

const Root = () => {

  // Theme
  const {
    theme
  } = useTheme();

  // Global
  const {
    appAddVisible,
    page,
    selectedApp,
    studio
  } = useContext(Global);

  // HOOKS

  // Login Status
  useProcessLoginStatus();

  // App Data Preload
  usePreloadAppFields();
  usePreloadAppUsers();
  usePreloadBookmarks();
  usePreloadCalendars();
  usePreloadChannels();
  usePreloadConversations();
  usePreloadEvents();
  usePreloadFavorites();
  usePreloadModels();
  usePreloadUserGroups();

  // Theme Preference
  useLoadThemePreference();

  // Page Preference
  useLoadPagePreference();

  // Menu Type Preference
  useLoadMenuTypePreference();

  // Invite Detection and Processing
  useDetectInvite();
  usePreprocessInvite();

  return (
    <>
      <RootStyles
        fontFamily={(selectedApp && selectedApp.fontFamily) || defaultFont}
        placeholderColor={theme.foregroundColorFaded}
        scrollbarColor={theme.backgroundColorFaded}
      />

      {/* SPLASH SCREEN */}
      {page === Page.ROOT && (
        <div className="root-container"
          style={{
            backgroundColor: (studio && studio.backgroundColor) ? studio.backgroundColor : 'black'
          }}>
          <LogoLarge />
        </div>
      )}

      {/* LOGGED IN - HOME */}
      {page === Page.HOME && (
        <Home />
      )}

      {/* LOGGED OUT - WELCOME */}
      {page === Page.WELCOME && (
        <Welcome />
      )}

      {/* RUN */}
      {page === Page.RUN && (
        <>
          {isMobile ? (
            <>
              <Mobile />
            </>
          ) : (
            <>
              <Desktop />
            </>
          )}
        </>
      )}

      {/* PROGRESS PANEL */}
      <Progress />

      {/* ACCOUNT */}
      <Account />

      {/* APP ADD */}
      {appAddVisible &&
        <AppAdd />
      }

    </>
  );
};

export default Root;

