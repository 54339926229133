// Firebase
import { collection, onSnapshot, query, where, doc, setDoc } from 'firebase/firestore';
import { collections, db } from '../../firebaseConfig';
import { activity } from './ActivityManager';

// Utilities
import { generateKey } from '../utilities/Keys';

class ElementManager {

    /**
     * Method to add an element value record.
     * 
     * @param {string} appKey - App key.
     * @param {string} modelKey - The key of the element model type.
     * @param {string} userKey - The key of the user storing the value.
     * @param {string} objectKey - The key of the object.
     * @param {string} elementKey - Key of the element that will display the value. Will be the document ID.
     * @param {object} value - The value relevant to the element and user.
     * 
     * @returns {object} - New user element record.
    */
    async add(appKey, modelKey, userKey, objectKey, elementKey, value) {

        const key = generateKey();

        const data = {
            key: key,
            appKey: appKey,
            modelKey: modelKey,
            userKey: userKey,
            objectKey: objectKey,
            elementKey: elementKey,
            value: value
        };

        await setDoc(doc(db, collections.userelements, elementKey), data);

        activity.log(appKey, 'writes', 1);

        return data;
    }

    /**
      * Fetches user element records.
      * 
      * @param {string} appKey - App key.
      * @param {string} userKey - User key.
      * @param {function} onUpdate - Callback function that handles the update.
      */
    list(appKey, userKey, onUpdate) {
        try {
            // Create a reference to the user elements collection
            const userElementsCollection = collection(db, collections.userelements);

            // Create a query to find models by appKey and sort them by title
            const q = query(userElementsCollection, where("appKey", "==", appKey), where("userKey", "==", userKey));

            // Subscribe to real-time updates
            const unsubscribe = onSnapshot(q, snapshot => {
                const userElementList = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                // Call the onUpdate callback with the updated list
                if (onUpdate) {
                    onUpdate(userElementList);
                    activity.log(appKey, 'reads', userElementList.length);
                }
            }, error => {
                console.error("Error fetching user elements:", error);
            });

            // Return the unsubscribe function to allow the caller to unsubscribe later
            return unsubscribe;
        } catch (error) {
            console.error("Error setting up real-time updates:", error);
            throw error;
        }
    }

    /**
     * Updates a user element value.
     * 
     * @param {string} appKey - Key of the app.
     * @param {string} elementKey - Key of the element.
     * @param {string} value - Value to save.
    */
    async update(appKey, elementKey, data) {
        const ref = doc(db, collections.userelements, elementKey);
    
        // Use setDoc instead of updateDoc to create the document if it doesn't exist
        await setDoc(ref, data, { merge: true });
    
        activity.log(appKey, 'writes', 1);
        
    }

}

export default ElementManager;
