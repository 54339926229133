import React from 'react';

// Styles
import './CanvasButton.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const CanvasButton = ({ title, icon: IconComponent, onClick }) => {

    // Theme
    const { theme } = useTheme();

    return (
        <div className="canvas-button-container"
            onClick={onClick}
            title={title}
            style={{
                backgroundColor: theme.backgroundColorFaded
            }}>
            <IconComponent
                color={theme.foregroundColorFaded}
                width="16"
                height="16"
            />
        </div>
    );
};

export default CanvasButton;

