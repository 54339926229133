import React, { useEffect, useState } from 'react';

// Styles
import './Checkbox.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import CheckOffIcon from '../../../../common/svg/CheckOffIcon';
import CheckOnIcon from '../../../../common/svg/CheckOnIcon';

// Components
import FieldContainer from '../../FieldContainer';
import FieldMenu from '../../fieldmenu/FieldMenu';

/**
 * Checkbox Component
 * 
 * This component renders a checkbox field.
 * 
 * @param {object} object - The object containing field values.
 * @param {object} field - The field metadata.
 * @param {function} onUpdate - The function to call with updated values.
 * @param {boolean} showFieldMenu - Indicates whether the menu should be shown.
 * @param {boolean} readOnly - Indicates whether the input should be read-only.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Checkbox = ({
    object,
    field,
    onUpdate,
    showFieldMenu = true,
    readOnly = false
}) => {
    const { theme } = useTheme();

    // State Variables
    const [value, setValue] = useState(false); // Default ADD mode value

    /**
     * Initializes the field value.
     */
    useEffect(() => {
        setValue(object?.[field.key] || false);
    }, [object, field.key]);

    /**
     * Handles click on the checkbox.
     */
    const handleToggle = () => {
        if (readOnly) return;
        const newCheckedState = !value;
        setValue(newCheckedState);
        onUpdate(field, newCheckedState ? true : false);
    };

    return (
        <>
            {/* CONTAINER */}
            <FieldContainer
                field={field}
                readOnly={readOnly} >

                <div className="checkbox-container">
                    <div className="checkbox-wrapper" onClick={handleToggle}>
                        {value ? (
                            <CheckOnIcon
                                color={theme.highlightBackgroundColor}
                                checkColor={theme.highlightForegroundColor}
                                width="30"
                                height="30"
                            />
                        ) : (
                            <CheckOffIcon
                                color={theme.foregroundColor}
                                width="30"
                                height="30"
                            />
                        )}

                        {/* LABEL */}
                        <label className="checkbox-label"
                            style={{ color: theme.foregroundColor }}
                        >
                            {field.title}
                        </label>
                    </div>

                    {/* FIELD MENU */}
                    <div className="checkbox-field-menu-wrapper">
                        {showFieldMenu &&
                            <FieldMenu field={field} />
                        }
                    </div>
                </div>

            </FieldContainer>
        </>
    );
};

export default Checkbox;
