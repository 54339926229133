import React, { useContext } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './CollectionItem.css';

// Theme
import { useStyle, useTheme } from '../../../../ThemeContext';

// Images
import CubeIcon from '../../../../common/svg/CubeIcon';

// Components
import CollectionItemMenu from '../collectionitemmenu/CollectionItemMenu';

// Inline Styles
const InlineStyles = useStyle`
    .collection-item-container:hover {
        background-color: ${(props) => props.hoverColor} !important;
        border-color: ${(props) => props.hoverColor} !important;
    }
`;

/**
 * CollectionItem Component
 * 
 * This component renders a collection item in the menu.
 * 
 * @param {object} collection - The collection to represent in the menu.
 * @param {boolean} isActive - Indicates if the item has been selected.
 * @param {function} onClick - The function to call on a click.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const CollectionItem = ({ model, title, isActive, onClick }) => {
    const { theme } = useTheme();
    const {
        selectedApp,
    } = useContext(Global);

    return (
        <>
            {/* STYLES */}
            <InlineStyles hoverColor={theme.backgroundColorFaded} />

            {/* CONTAINER */}
            <div
                className="collection-item-container"
                style={{
                    backgroundColor: isActive ? theme.highlightBackgroundColor : 'transparent',
                }}>
                <div className="collection-item-left"
                    onClick={onClick}>
                    <CubeIcon
                        color={isActive ? theme.highlightForegroundColor : theme.foregroundColorFaded}
                        width="18"
                        height="14"
                    />
                    <div className="collection-item-text"
                        style={{
                            color: isActive ? theme.highlightForegroundColor : theme.foregroundColor,
                            fontFamily: selectedApp.fontFamily,
                        }}>
                        {title}
                    </div>
                </div>

                <div
                    className="model-item-edit-image">
                    <CollectionItemMenu model={model} isActive={isActive} />
                </div>

            </div>
        </>
    );
};

export default CollectionItem;
