import React, { useEffect, useContext, useRef, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Firebase
import { Timestamp } from 'firebase/firestore';
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import { storage } from '../../../../firebaseConfig';

// Utilities
import { generateKey } from '../../../../common/utilities/Keys';
import { truncateFileName } from '../../../../common/utilities/Strings';

// Activity
import { activity } from '../../../../common/managers/ActivityManager';

// Styles
import './Documents.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import RemoveIcon from '../../../../common/svg/RemoveIcon';

// Components
import FieldContainer from '../../FieldContainer';
import FieldHeader from '../../FieldHeader';
import UserThumb from '../../../../common/components/userthumb/UserThumb';

// Managers
import DocumentManager from '../../../../common/managers/DocumentManager';
import HeadlineManager from '../../../../common/managers/HeadlineManager';

const documentManager = new DocumentManager();
const headlineManager = new HeadlineManager();

const Documents = ({
    object,
    field,
    onUpdate,
    showFieldMenu = true,
    readOnly = false
}) => {
    const { theme } = useTheme();
    const {
        currentUser,
        hideProgress,
        selectedApp,
        selectedModel,
        showProgress
    } = useContext(Global);

    const [documents, setDocuments] = useState([]);
    const fileInputRef = useRef(null);

    useEffect(() => {
        if (!object || !object.key || !field || !field.key) return;

        async function fetchDocuments() {
            try {
                const results = await documentManager.fetchFieldDocuments(object.key, field.key);
                setDocuments(results);
            } catch (error) {
                console.error('Failed to fetch documents:', error);
            }
        }
        if (object) {
            fetchDocuments();
        }
    }, [object, field]);

    const handleFileUpload = async (event) => {

        showProgress("Uploading files...");

        const files = event.target.files;
        if (!files || files.length === 0) return;

        const allowedExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'png', 'jpg', 'jpeg', 'gif'];

        for (const file of files) {
            const fileName = file.name;
            const fileExtension = fileName.split('.').pop().toLowerCase();

            if (!allowedExtensions.includes(fileExtension)) {
                alert(`Invalid file type for ${fileName}. Only documents and images are allowed.`);
                continue;
            }

            const key = generateKey();
            const storageRef = ref(storage, `documents/${selectedApp.key}/${selectedModel.key}/${field.key}/${object.key}/${key}.${fileExtension}`);
            try {
                await uploadBytes(storageRef, file);
                const fileUrl = await getDownloadURL(storageRef);

                // Save a document record
                const key = generateKey();

                // Current timestamp
                const now = Timestamp.now();

                // Create a document record with additional metadata
                const document = {
                    key: key,
                    appKey: selectedApp.key,
                    objectKey: object.key,
                    fieldKey: field.key,
                    userKey: currentUser.userKey,
                    username: currentUser.username,
                    name: fileName,
                    extension: fileExtension,
                    url: fileUrl,
                    size: file.size,
                    type: file.type,
                    lastModified: file.lastModified,
                    dateCreated: now
                };

                // Log the size of the file in bytes
                activity.log(selectedApp.key, 'uploads', file.size);

                // Add the document to the database
                documentManager.addDocument(key, document);

                await addFileHeadline(fileName, fileUrl);

                // Update the documents list
                setDocuments(prevDocuments => [...prevDocuments, document]);

                hideProgress();

            } catch (error) {
                console.error(`Error uploading file ${fileName}:`, error);
            }
        }

        fileInputRef.current.value = "";
    };

    const handleAddClick = () => {
        fileInputRef.current.click();
    };

    const handleDocumentClick = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const handleRemove = async (event, key, url) => {
        event.stopPropagation();

        showProgress("Deleting...");

        try {
            // Delete the document from the database
            await documentManager.deleteDocument(key);

            // Delete the document from storage
            await documentManager.deleteDocumentByUrl(url);

            // Delete any headlines associated with the document
            await headlineManager.deleteDocumentHeadlines(object.key);

            // Update the state to remove the document from the documents array
            setDocuments(prevDocuments => prevDocuments.filter(doc => doc.key !== key));
        } catch (error) {
            console.error("Error deleting document:", error);
        }

        hideProgress();
    };

    const addFileHeadline = async (fileName, fileUrl) => {

        if (selectedModel && selectedModel.titleFieldKey && fileName.trim() !== "" && fileUrl.trim() !== "") {

            // Get the object title
            const objectTitle = object[selectedModel.titleFieldKey];

            if (objectTitle !== undefined) { // Will be undefined when adding
                headlineManager.addDocumentHeadline(
                    selectedApp.key,
                    currentUser,
                    fileName,
                    fileUrl,
                    selectedModel.key,
                    object.key,
                    objectTitle
                );
            }
        }
    };

    /**
    * Formats a file size to a human-readable string.
    * @param {number} size - The file size in bytes.
    * @returns {string} - The formatted file size.
    */
    function formatFileSize(size) {
        const units = ['bytes', 'KB', 'MB', 'GB', 'TB'];
        let unitIndex = 0;

        while (size >= 1024 && unitIndex < units.length - 1) {
            size /= 1024;
            unitIndex++;
        }

        return `${Math.round(size)} ${units[unitIndex]}`;
    }

    return (
        <>
            {/* CONTAINER */}
            <FieldContainer
                readOnly={readOnly}
                field={field}>

                {/* HEADER */}
                <FieldHeader
                    field={field}
                    readOnly={readOnly}
                    showFieldMenu={showFieldMenu}
                />
                <div className="documents-list">
                    {documents.map(doc => (
                        <div key={doc.key}
                            className="documents-document"
                            onClick={() => handleDocumentClick(doc.url)}
                            style={{
                                borderColor: theme.backgroundColor
                            }}>
                            <div className="documents-document-left">
                                <UserThumb
                                    user={doc} // username is in the document object
                                    size="22"
                                />
                            </div>
                            <div className="documents-document-center">
                                <div
                                    className="documents-document-name"
                                    style={{
                                        color: theme.foregroundColor
                                    }}>
                                    {truncateFileName(doc.name, 30)}
                                </div>
                                <div className="documents-document-properties">
                                    <div
                                        className="documents-document-property"
                                        style={{
                                            color: theme.foregroundColorFaded
                                        }}>
                                        {`${new Date(doc.lastModified).toLocaleDateString()}`}
                                    </div>
                                    <div className="documents-document-property-dot"
                                        style={{
                                            color: theme.foregroundColorFaded
                                        }}>•</div>
                                    <div
                                        className="documents-document-property"
                                        style={{
                                            color: theme.foregroundColorFaded
                                        }}>
                                        {`${doc.extension}`}
                                    </div>
                                    <div className="documents-document-property-dot"
                                        style={{
                                            color: theme.foregroundColorFaded
                                        }}>•</div>
                                    <div
                                        className="documents-document-property"
                                        style={{
                                            color: theme.foregroundColorFaded
                                        }}>
                                        {formatFileSize(doc.size)}
                                    </div>
                                </div>
                            </div>
                            {!readOnly &&
                                <div className="documents-document-right">
                                    <div
                                        className="documents-document-remove"
                                        onClick={(event) => handleRemove(event, doc.key, doc.url)}>
                                        <RemoveIcon
                                            color={theme.foregroundColorFaded}
                                            width="20"
                                            height="20"
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    ))}
                </div>
                {!readOnly &&
                    <>
                        <input
                            ref={fileInputRef}
                            id="file-upload"
                            type="file"
                            accept=".pdf,.doc,.docx,.xls,.xlsx,.png,.jpg,.jpeg,.gif"
                            onChange={handleFileUpload}
                            style={{ display: 'none' }}
                            aria-label="File Upload"
                            multiple
                        />
                        <div className="documents-footer">
                            <div className="documents-upload-button"
                                style={{ color: theme.foregroundColorFaded }}
                                onClick={handleAddClick}>
                                Attach
                            </div>
                        </div>
                    </>
                }

            </FieldContainer>
        </>
    );
};

export default Documents;
