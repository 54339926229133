import React, { useContext, useState } from 'react';

// Global
import { Global } from '../Global';

// Firebase
import { Timestamp } from 'firebase/firestore';
import { auth, collections } from '../firebaseConfig';

// Utilities
import { Page } from '../common/utilities/Enums';
import { generateKey } from '../common/utilities/Keys';

// Styles
import './Prompt.css';

// Images
import WandIcon from '../common/svg/WandIcon';

// Managers
import AppManager from '../common/managers/AppManager';
import AppUserManager from '../common/managers/AppUserManager';
import ChannelManager from '../common/managers/ChannelManager';
import DataManager from '../common/managers/DataManager';
import ModelManager from '../common/managers/ModelManager';
import PromptManager from '../common/managers/PromptManager';

const appManager = new AppManager();
const appUserManager = new AppUserManager();
const channelManager = new ChannelManager();
const dataManager = new DataManager();
const promptManager = new PromptManager();

let reportFields = [];

const Prompt = () => {
  const {
    currentUser,
    setPage,
    setFormMode,
    setSelectedApp,
    setSelectedModel,
    showProgress,
    studio
  } = useContext(Global);

  const [prompt, setPrompt] = useState('');

  function adjustTextareaHeight(textarea) {
    if (textarea.value.trim().length === 0) {
      return;
    }

    const maxHeight = 400; // Maximum height in pixels
    let newHeight = textarea.scrollHeight - 8;

    if (newHeight > maxHeight) {
      newHeight = maxHeight;
      textarea.style.overflowY = 'scroll'; // Add scrollbar if content exceeds maxHeight
    } else {
      textarea.style.overflowY = 'hidden'; // Hide scrollbar if content is within maxHeight
    }

    textarea.style.height = `${newHeight}px`;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    /* TEMPORARILY DISABLED

    if (prompt.trim().length === 0) return;

    showProgress("Creating app...");

    await submitAppPrompt(prompt);

    hideProgress();
    */
  };

  const submitAppPrompt = async (prompt) => {
    try {
      const combinedPrompt = await promptManager.prepareAppPrompt(prompt);

      console.log(combinedPrompt);

      const response = await promptManager.send(combinedPrompt);

      await processAppResponse(response);

      setPrompt('');
    } catch (error) {
      console.error('Failed to fetch API response:', error);
    }
  };

  const processAppResponse = async (r) => {
    console.log(r);

    reportFields = []; // Clear the report aggregate fields array

    try {

      // Parse the JSON response
      const jsonString = promptManager.cleanJSON(r);
      const jsonData = JSON.parse(jsonString);

      const appKey = generateKey();
      const user = auth.currentUser;
      const userKey = user.uid;

      // Current timestamp
      const now = Timestamp.now();

      const appData = {
        key: appKey,
        themes: {
          dark: {
            backgroundColor: jsonData.backgroundColorDark,
            backgroundColorFaded: jsonData.backgroundColorDarkFaded,
            foregroundColor: jsonData.foregroundColorDark,
            foregroundColorFaded: jsonData.foregroundColorDarkFaded,
            highlightBackgroundColor: jsonData.highlightBackgroundColorDark,
            highlightForegroundColor: jsonData.highlightForegroundColorDark,
          },
          light: {
            backgroundColor: jsonData.backgroundColorLight,
            backgroundColorFaded: jsonData.backgroundColorLightFaded,
            foregroundColor: jsonData.foregroundColorLight,
            foregroundColorFaded: jsonData.foregroundColorLightFaded,
            highlightBackgroundColor: jsonData.highlightBackgroundColorLight,
            highlightForegroundColor: jsonData.highlightForegroundColorLight,
          }
        },
        businessModel: "FREE",
        description: jsonData.appDescription,
        originalKey: appKey,
        prompt: prompt,
        published: false,
        title: jsonData.appTitle,
        version: 1,
        userKey: userKey,
        username: currentUser.username,
        dateCreated: now,
        dateModified: now
      };

      console.log(appData);

      const app = await appManager.add(appKey, appData);

      // Add the app creator as the first app user
      const appUserKey = generateKey();

      const appUserData = {
          key: appUserKey,
          appKey: appKey,
          userKey: userKey,
          username: currentUser.username,
          userEmail: currentUser.email,
          dateJoined: now
      };

      await appUserManager.add(appKey, appUserKey, appUserData);

      // Add the app creator to the user index
      //await userIndexManager.add(appKey, currentUser);

      showProgress("Generating menu...");

      const modelManager = new ModelManager();

      // Initialize a map to store model titles and their keys
      const modelTitleToKey = {};

      // Initialize an array to store modelData objects
      const modelsArray = [];

      let sortIndex = 0;

      // Process channels
      if (jsonData.models && Array.isArray(jsonData.models)) {

        // Initialize the highest sort number to 0
        let channelSortNumber = 0;

        for (const channel of jsonData.channels) {
          const type = channel.type;
          const title = channel.title;
          const description = channel.description;

          const channelKey = generateKey();

          // Current timestamp
          const now = Timestamp.now();

          const channelData = {
              key: channelKey,
              appKey: appKey,
              type: type,
              title: title,
              description: description,
              private: true,
              sort: channelSortNumber,
              userKey: userKey,
              username: currentUser.username,
              dateCreated: now,
              dateModified: now,
          };
  
          await dataManager.add(appKey, channelKey, collections.channels, channelData);

          channelSortNumber++;

        }
      }

      // Check if models exist and are in array format
      if (jsonData.models && Array.isArray(jsonData.models)) {
        for (const model of jsonData.models) {
          const title = model.title;
          const description = model.description;
          const modelKey = generateKey();

          // Store the model key with its title for later lookup
          modelTitleToKey[title] = modelKey;

          // Current timestamp
          const now = Timestamp.now();

          const modelData = {
            key: modelKey,
            appKey: appKey,
            userKey: userKey,
            title: title,
            description: description,
            noview: [],
            noadd: [],
            noedit: [],
            nodelete: [],
            sort: sortIndex,
            dateCreated: now,
            dateModified: now,
          };

          // Add the model to the app
          await modelManager.add(appKey, modelKey, modelData);

          // Push the modelData object to the modelsArray
          modelsArray.push(modelData);

          sortIndex++;
        }
      } else {
        console.log("No models found or invalid model format.");
      }

      // Models
      for (var i = 0; i < modelsArray.length; i++) {
        await submitModelPrompt(app, modelsArray[i], modelsArray);
      }

      console.log(app);
      console.log("Models processed.");

      setSelectedApp(app);

      setPage(Page.RUN);

    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  };

  const submitModelPrompt = async (app, model, models) => {
    try {

      const prompt = await promptManager.prepareModelPrompt(app.title, app.description, model.title, model.description, models);

      console.log(prompt);

      const response = await promptManager.send(prompt);

      await promptManager.processModelResponse(
        response, 
        app, 
        model, 
        showProgress,
        setSelectedModel,
        setFormMode,
        null, // no need to reset visibility
        reportFields
      );

    } catch (error) {
      console.error('Failed to fetch API response:', error);
    }
  };

  return (
    <div className="prompt-container"
      style={{
        backgroundColor: studio.backgroundColorFaded,
        borderColor: studio.backgroundColorFaded
      }}
    >
      <textarea
        className="prompt"
        style={{
          backgroundColor: studio.backgroundColorFaded,
          borderColor: studio.backgroundColorFaded,
          color: studio.foregroundColor
        }}
        value={prompt}
        onChange={(e) => {
          setPrompt(e.target.value);
          adjustTextareaHeight(e.target);
        }}
        placeholder="What kind of app do you need?"
        rows="1"
      />
      <div onClick={handleSubmit} className="prompt-image">
        <WandIcon
          color={studio.foregroundColor}
          width="30"
          height="30"
        />
      </div>
    </div>
  );
};

export default Prompt;

