import React, { useEffect, useState } from 'react';

// Styles
import './ChatPostFormEvent.css';

// Theme
import { useTheme } from '../../../../../../ThemeContext';

// Images
import CalendarIcon from '../../../../../svg/CalendarIcon';

const ChatPostFormEvent = ({ event }) => {
  const { theme } = useTheme();

  // State Variables
  const [eventTitle, setEventTitle] = useState('');

  useEffect(() => {
    if (!event) return;
    const title = event.title;
    setEventTitle(title);
  }, [event]);

  return (
    <div className="chat-post-form-event-container"
      style={{
        backgroundColor: theme.backgroundColorFaded
      }}>
      <CalendarIcon
        color={theme.highlightBackgroundColor}
        width="14"
        height="14"
      />
      <div
        className="chat-post-form-event-eventname"
        style={{
          color: theme.foregroundColor
        }}>
        {eventTitle}
      </div>
    </div>
  );
};

export default ChatPostFormEvent;
