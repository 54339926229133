import React from 'react';

const PlusIcon = ({ color = "white", width = "18", height = "18" }) => (
  <svg 
  version="1.1"
  width={width}
  height={height}
  viewBox="0 0 18 18">
  <path stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16"/>
</svg>
);

export default PlusIcon;
