import React, { useContext } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './ChannelAddButton.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import NewIcon from '../../../svg/NewIcon';

/**
 * ChannelAddButton Component
 * 
 * This component renders a button for adding a channel.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ChannelAddButton = ({ onClick }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        selectedApp,
    } = useContext(Global);

    return (
        <>
            {/* CONTAINER */}
            <div className="channel-add-button-container"
                onClick={onClick}>

                {/* ICON */}
                <div className="channel-add-button-image">
                    <NewIcon
                        color={theme.foregroundColor}
                        width="18"
                        height="14"
                    />
                </div>

                {/* TEXT */}
                <div className="channel-add-button-text"
                    style={{
                        color: theme.foregroundColor,
                        fontFamily: selectedApp.fontFamily,
                    }}>
                    Add Channel
                </div>
                
            </div>
        </>
    );
};

export default ChannelAddButton;
