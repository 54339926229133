import React from 'react';

// Styles
import './AddButton.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import PlusIcon from '../../svg/PlusIcon';

const AddButton = ({ onClick }) => {
  const { theme } = useTheme();

  return (
    <>
      <div className="add-button-container"
        style={{ backgroundColor: theme.highlightBackgroundColor }}
        onClick={onClick}>
        <PlusIcon
          color={theme.highlightForegroundColor}
          width="25"
          height="25"
        />
      </div>
    </>
  );
};

export default AddButton;
