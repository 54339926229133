import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './FormSaveButton.css';

// Theme
import { useTheme } from '../../ThemeContext';

const FormSaveButton = ({onClick}) => {
    const { theme } = useTheme();
    const {
        changesMade
    } = useContext(Global);

    return (

        <div className="form-save-button-container"
            style={{
                backgroundColor: changesMade ? theme.highlightBackgroundColor : theme.backgroundColorFaded,
                color: changesMade ? theme.highlightForegroundColor : theme.foregroundColorFaded
            }}
            onClick={onClick}>
            Save
        </div>
    );
};

export default FormSaveButton;
