import React, { useState } from 'react';

// Styles
import './LinkButton.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

// Images
import LinkIcon from '../../../../../common/svg/LinkIcon';

// Components
import LinkForm from './LinkForm';

const LinkButton = ({ onSelect }) => {
  const { theme } = useTheme();

  // State Variables
  const [linkFormVisible, setLinkFormVisible] = useState(false);

  const handleSave = async (value) => {
    onSelect(value);
  };

  return (
    <>
      {/* CONTAINER */}
      <div className="link-button-container"
        style={{
          backgroundColor: theme.backgroundColorFaded,
        }}
        onClick={() => setLinkFormVisible(true)}>

        {/* ICON */}
        <LinkIcon
          color={theme.foregroundColorFaded}
          width="18"
          height="18"
        />

        {/* LINK MODAL */}
        <LinkForm
          onSave={handleSave}
          isVisible={linkFormVisible}
          setVisible={setLinkFormVisible}
        />

      </div>
    </>
  );
};

export default LinkButton;
