import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './ChatUser.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import UserThumb from '../../../common/components/userthumb/UserThumb';

const ChatUser = ({ appUser }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        selectedApp,
    } = useContext(Global);

    return (
        <>
            {/* CONTAINER */}
            <div className="chat-user-container">

                {/* DOT */}
                <div className="chat-user-dot"
                    style={{
                        color: theme.foregroundColor
                    }}>
                    <UserThumb
                        user={appUser}
                        size="16"
                    />
                </div>

                {/* USERNAME */}
                <div className="chat-user-name"
                    style={{
                        color: theme.foregroundColor,
                        fontFamily: selectedApp.fontFamily
                    }}>
                    {appUser.username}
                </div>

            </div>
        </>
    );
};

export default ChatUser;
