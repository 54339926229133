import React, { useRef } from 'react';

// Styles
import './FileButton.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

const FileButton = ({ type, accept, icon: IconComponent, onSelect, multiple = true }) => {
  const { theme } = useTheme();   

  // References
  const inputRef = useRef(null);

  // Handler for file selection
  const handleSelection = (event) => {
    // Convert selected files from FileList to an array
    const files = Array.from(event.target.files);

    // Map over the selected files and create an object for each file
    const selectedFilesInfo = files.map(file => ({
      url: URL.createObjectURL(file), // Create a local URL for the file
      name: file.name, // Original file name
      size: file.size, // File size in bytes
      type: file.type, // MIME type of the file (e.g., image/png, video/mp4)
      lastModified: file.lastModified, // Timestamp of the last modification
    }));

    // Reset the input to allow re-uploading the same file if needed
    inputRef.current.value = "";

    if (onSelect) {
      onSelect(type, selectedFilesInfo);
    }
  };

  return (
    <>
      {/* CONTAINER */}
      <div className="file-button-container"
        style={{
          backgroundColor: theme.backgroundColorFaded,
        }}
        onClick={() => inputRef.current.click()}>

        {/* ICON */}
        <IconComponent
          color={theme.foregroundColorFaded}
          width="18"
          height="18"
        />

        {/* INPUT */}
        <input
          ref={inputRef}
          id="photo-upload"
          type="file"
          accept={accept}
          onChange={handleSelection}
          style={{ display: 'none' }}
          multiple={multiple}
        />
      </div>
    </>
  );
};

export default FileButton;
