import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../Global';

// Utilities
import { convertDecimalToPercentage } from '../../common/utilities/Numbers';

// Styles
import './AppSettingsIcon.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import AppLogoEdit from '../applogo/AppLogoEdit';
import Slider from '../../common/components/slider/Slider';

/**
 * AppSettingsIcon Component
 * 
 * Renders a component that allows the user to set and adjust the size of the app icon 
 * for dark and light modes.
 * 
 * @param {object} themes - Theme objects.
 * @param {object} defaultSize - Current size.
 * @param {function} onDarkSelect - The function to call when the dark mode icon is selected.
 * @param {function} onLightSelect - The function to call when the light mode icon is selected.
 * @param {function} onSizeChange - The function to call with when the size changes.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const AppSettingsIcon = ({ themes, defaultSize, onDarkSelect, onLightSelect, onSizeChange }) => {
    
    // Theme
    const { theme } = useTheme();

    // Global
    const {
        selectedApp,
    } = useContext(Global);

    // State Variables
    const [size, setSize] = useState(defaultSize);

    /**
     * Method to handle the slider change event.
     * 
     * @param {decimal} value - Slider value, on a scale of 0 to 1.
     */
    const handleSliderChange = (value) => {
        setSize(value);
        onSizeChange(value);
    };

    return (
        <>
            {/* CONTAINER */}
            <div className="app-settings-icon-container">

                {/* TOP */}
                <div className="app-settings-icon-top">
                    <div className="app-settings-icon-top-column">

                        {/* DARK */}
                        <AppLogoEdit
                            title="Dark Mode"
                            backgroundColor={themes.dark.backgroundColor}
                            borderColor={themes.dark.backgroundColorFaded}
                            mode="DARK"
                            size={convertDecimalToPercentage(size)}
                            onSelect={onDarkSelect}
                        />
                    </div>
                    <div className="app-settings-icon-top-column">

                        {/* LIGHT */}
                        <AppLogoEdit
                            title="Light Mode"
                            backgroundColor={themes.light.backgroundColor}
                            borderColor={themes.light.backgroundColorFaded}
                            mode="LIGHT"
                            size={convertDecimalToPercentage(size)}
                            onSelect={onLightSelect}
                        />
                    </div>
                </div>

                {/* BOTTOM */}
                <div className="app-settings-icon-bottom">

                    {/* SLIDER */}
                    <Slider value={size} onChange={handleSliderChange} />

                    {/* SLIDER LABEL */}
                    <div className="app-settings-icon-percentage"
                        style={{
                            color: theme.foregroundColor,
                            fontFamily: selectedApp.fontFamily
                        }}>
                        Size: {convertDecimalToPercentage(size)}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AppSettingsIcon;
