import React, { useEffect, useState } from 'react';

// Utilities
import { getElapsedTime } from '../utilities/DateTime';

// Styles
import './ConversationSummary.css';

// Theme
import { useStyle, useTheme } from '../../ThemeContext';

// Components
import UserThumb from '../components/userthumb/UserThumb';

// Styled Components
const InlineStyles = useStyle`
    .conversation-summary-user:hover {
        background-color: ${(props) => props.hoverColor} !important;
        border-color: ${(props) => props.hoverColor} !important;
    }
`;

const ConversationSummary = ({ conversation }) => {

    // Theme
    const { theme } = useTheme();

    const [participants, setParticipants] = useState([]);
    const [lastMessage, setLastMessage] = useState("");

    useEffect(() => {
        if (conversation.participants) {
            setParticipants(conversation.participants);
        }

        // Trim the last message text to 200 characters
        const trimmedLastMessage = conversation.lastMessage
            ? conversation.lastMessage.text.slice(0, 200)
            : '';

        setLastMessage(trimmedLastMessage);
    }, [conversation]);

    function getUsernamesString() {
        return participants
            .map(user => user.username) // Extract each username
            .filter(Boolean) // Remove any undefined or null usernames
            .join(', '); // Join with a comma and space
    }

    /*
    // Function to extract first and last initials from a name
    const getInitials = (name) => {
        const nameParts = name.split(' ');
        const letter = nameParts[0][0].toUpperCase();
        return { letter };
    };

    // Get the non-current-user participants
    const nonCurrentUserParticipants = conversation.participants
        ? conversation.participants.filter(p => p.userKey !== currentUser.userKey)
        : [];

    // Get the first participant who isn't the current user
    const firstParticipant = nonCurrentUserParticipants.length > 0
        ? nonCurrentUserParticipants[0]
        : null;

    // Get the number of additional participants
    const additionalParticipantsCount = nonCurrentUserParticipants.length - 1;

    // Set the display name for the conversation summary
    const displayName = firstParticipant
        ? `${firstParticipant.username}${additionalParticipantsCount > 0 ? ` + ${additionalParticipantsCount} Others` : ''}`
        : '';

    const initials = firstParticipant ? getInitials(firstParticipant.username) : { letter: '' };

        */

    return (
        <>
            <InlineStyles
                hoverColor={theme.backgroundColorFaded}
            />
            <div className="conversation-summary-container">

                <div className="conversation-summary-top">

                    <div className="conversation-summary-user-thumbs">
                        {participants.map((participant, index) => (

                            <div className="conversation-summary-user-thumb"
                                style={{
                                    borderColor: theme.backgroundColor
                                }}>
                                <UserThumb
                                    user={participant}
                                    size="32"
                                />
                            </div>

                        ))}
                    </div>

                </div>

                <div className="conversation-summary-center"
                    style={{
                        color: theme.foregroundColor
                    }}>
                    {getUsernamesString()}
                </div>

                <div className="conversation-summary-last-message"
                    style={{
                        color: theme.foregroundColor
                    }}>
                    {lastMessage && (
                        <div className={conversation.lastMessageRead ? "conversation-summary-last-read" : "conversation-summary-last-unread"}>
                            {lastMessage}
                        </div>
                    )}
                </div>

                {conversation && conversation.lastMessage && conversation.lastMessage.dateCreated &&
                    <div className="conversation-summary-elapsed"
                        style={{
                            color: theme.backgroundColorFaded
                        }}>
                        {getElapsedTime(conversation.lastMessage.dateCreated)}
                    </div>
                }

                {/* User Thumb 
                <div className="conversation-summary-left">
                    {firstParticipant && (
                        <UserThumb
                            user={firstParticipant}
                            size="32"
                        />
                    )}
                </div>
                <div className="conversation-summary-right">
                    <div className="conversation-summary-top">
                        <div className="conversation-summary-name"
                            style={{
                                color: theme.foregroundColor
                            }}>
                            {displayName}
                        </div>
                    </div>
                    <div className="conversation-summary-last-message"
                        style={{
                            color: theme.foregroundColor
                        }}>
                        {trimmedLastMessage && (
                            <div className={conversation.lastMessageRead ? "conversation-summary-last-read" : "conversation-summary-last-unread"}>
                                {trimmedLastMessage}
                            </div>
                        )}
                    </div>
                </div>
                */}
            </div>
        </>
    );
};

export default ConversationSummary;
