import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Utilities
import { getElapsedTime } from '../../../utilities/DateTime';

// Styles
import './ForumPost.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import Document from '../../components/document/Document';
import Event from '../../components/event/Event';
import ForumPostComments from '../ForumPostComments';
import ForumPostForm from '../forumpostform/ForumPostForm';
import ForumPostMenu from '../forumpostmenu/ForumPostMenu';
import Link from '../../components/link/Link';
import Object from '../../../objects/Object';
import Gallery from '../../components/gallery/Gallery';
import UserThumb from '../../../components/userthumb/UserThumb';
import VideoGallery from '../../components/videogallery/VideoGallery';

// Managers
import ForumPostManager from '../../../managers/ForumPostManager';

const forumPostManager = new ForumPostManager();

const ForumPost = () => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        hideProgress,
        selectedApp,
        selectedForumPost,
        setBackButtonAction, // Navigation
        setBackVisible, // Navigation
        setSelectedForumPost,
        showProgress,
        models,
        resetVisibility,
        setSelectedModel,
        setSelectedObject
    } = useContext(Global);

    // State Variables
    const [post, setPost] = useState(null);
    const [formVisible, setFormVisible] = useState(false);

    /** 
     * Sets the custom function to run when the back button is clicked.
     */
    useEffect(() => {

        setBackButtonAction(() => {
            return () => {
                setSelectedForumPost(null);
                //setForumVisible(true);
                setBackVisible(false);
            };
        });

        if (selectedForumPost) {
            setBackVisible(true);
        }

        return () => { // Reset on unmount
            setBackVisible(false);
            setBackButtonAction(null);
        };

    }, [selectedForumPost, setBackButtonAction, setBackVisible]);

    /**
     * Set the post from the selected post.
     */
    useEffect(() => {
        if (!selectedForumPost) return;
        setPost(selectedForumPost);
    }, [selectedForumPost]);

    const getTimestamp = (post) => {
        return <span
            className="forum-post-timestamp"
            style={{
                color: theme.foregroundColorFaded
            }}>
            {getElapsedTime(post.dateCreated)}
        </span>
    };

    const handleEditClick = () => {
        setFormVisible(true);
    }

    const handleDeleteClick = async () => {
        showProgress("Deleting post...");
        await forumPostManager.delete(post.key);
        setSelectedForumPost(null);
        hideProgress();
    }

    const handleObjectClick = async (object) => {
        resetVisibility();
        const model = models.find(model => model.key === object.modelKey);
        setSelectedModel(model);
        setSelectedObject(object);
    };

    /**
     * Handles tag changes in the form menu.
     */
    const handleTagChange = async (updatedTags) => {
        const data = {
            tags: updatedTags,
        };

        try {
            await forumPostManager.update(selectedApp.key, post.key, data);

            const updatedPost = { ...post, tags: updatedTags };
            setSelectedForumPost(updatedPost);
        } catch (error) {
            console.error('Error updating channel:', error);
        }
    };

    if (!post) return null;

    return (
        <>
            <div className="forum-post-container">

                {/* HEADER */}
                <div className="forum-post-header">

                    {/* LEFT */}
                    <div className="forum-post-header-left">

                        {/* TITLE */}
                        <div className="forum-post-title"
                            style={{
                                color: theme.foregroundColor,
                                fontFamily: selectedApp.fontFamily
                            }}>
                            {post.title}
                        </div>

                    </div>
                    <div className="forum-post-header-right">

                        {/* MENU */}
                        <ForumPostMenu
                            onEditClick={handleEditClick}
                            onDeleteClick={handleDeleteClick}
                            onTagChange={handleTagChange}
                        />

                    </div>

                </div>

                {/* AUTHOR */}
                <div className="forum-post-author">

                    {/* USER DOT */}
                    <UserThumb
                        user={post} // username is in the post object
                        size="23"
                    />

                    {/* USERNAME */}
                    <div className="forum-post-user"
                        style={{
                            color: theme.foregroundColor,
                            fontFamily: selectedApp.fontFamily
                        }}>
                        {post.username}
                    </div>

                    {/* TIMESTAMP */}
                    <div className="forum-post-timestamp"
                        style={{
                            color: theme.foregroundColorFaded
                        }}>
                        • {getTimestamp(post)}
                    </div>
                </div>

                {/* WRAPPER */}
                <div className="forum-post-wrapper">

                    {/* BLOCKS */}
                    {post.blocks.map((block, index) => (
                        <React.Fragment key={block.key}>
                            {/* TEXT */}
                            {block.type === "TEXT" && (
                                <div className="forum-post-content"
                                    style={{
                                        color: theme.foregroundColor,
                                        fontFamily: selectedApp.fontFamily
                                    }}>
                                    {block.content}
                                </div>
                            )}

                            {/* PHOTOS */}
                            {block.type === "PHOTOS" && (
                                <div className="forum-post-gallery-wrapper">
                                    <Gallery content={block.content} />
                                </div>
                            )}

                            {/* VIDEOS */}
                            {block.type === "VIDEOS" && (
                                <div className="forum-post-gallery-wrapper">
                                    <VideoGallery content={block.content} />
                                </div>
                            )}

                            {/* DOCUMENTS */}
                            {block.type === "DOCUMENTS" && (
                                <div className="forum-post-documents-wrapper">
                                    {block.content.map((item, itemIndex) => (
                                        <Document key={itemIndex} content={item} />
                                    ))}
                                </div>
                            )}

                            {/* OBJECTS */}
                            {block.type === "OBJECT" && (
                                <div className="forum-post-object-wrapper"
                                    style={{
                                        backgroundColor: theme.backgroundColorFaded
                                    }}
                                    onClick={() => handleObjectClick(block.content)}>
                                    <Object object={block.content} />
                                </div>
                            )}

                            {/* EVENT */}
                            {block.type === "EVENT" && (
                                <div className="forum-post-event-wrapper">
                                    <Event event={block.content} />
                                </div>
                            )}

                            {/* LINK */}
                            {block.type === "LINK" && (
                                <div className="forum-post-link-wrapper">
                                    <Link url={block.content} />
                                </div>
                            )}
                        </React.Fragment>
                    ))}

                </div>

                {/* COMMENTS */}
                <ForumPostComments
                    post={post}
                />
            </div>

            {/* POST FORM */}
            <ForumPostForm
                isVisible={formVisible}
                setVisible={setFormVisible}
                defaultPost={post}
            />
        </>
    );
};

export default ForumPost;
