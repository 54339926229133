import React from 'react';

// Styles
import './RatingSummaryPreview.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import RateOnIcon from '../../../../common/svg/RateOnIcon';

const RatingSummaryPreview = ({ element }) => {
  const { getProcessedStyles, theme } = useTheme();

  const { styles = {} } = element;

  // Filter out top, left, width, height, and zIndex
  const { top, left, width, height, zIndex, ...filteredStyles } = styles;

  return (
    <div className="rating-summary-preview-container">

      <div className="rating-summary-preview-leading-text" style={getProcessedStyles(filteredStyles)}>
        5.0
      </div>
      <RateOnIcon
        color={theme.highlightBackgroundColor}
        width="14"
        height="14"
      />
      <div className="rating-summary-preview-trailing-text" style={getProcessedStyles(filteredStyles)}>
        (43 ratings)
      </div>
    </div>
  );
};

export default RatingSummaryPreview;
