import React, { useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Drag/Drop
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// Styles
import './CalendarWeek.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import CalendarWeekHeader from './CalendarWeekHeader';
import CalendarWeekHourBlock from './CalendarWeekHourBlock';
import CalendarWeekEvent from './CalendarWeekEvent';
import CalendarWeekAllDayEvent from './CalendarWeekAllDayEvent';

// Managers
import EventManager from '../../../managers/EventManager';

const CalendarWeek = () => {
    const { theme } = useTheme();
    const {
        activeDate,
        events,
        selectedApp,
        selectedCalendar,
        selectedCalendarTags,
        setCalendarTitle,
    } = useContext(Global);

    const [daysToShow, setDaysToShow] = useState([]);
    const [weekDayEvents, setWeekDayEvents] = useState([]);
    const [overlayWidth, setOverlayWidth] = useState(0);
    const [displayEvents, setDisplayEvents] = useState([]);
    const [multiDayEvents, setMultiDayEvents] = useState([]); // For multi-day events above the calendar

    const overlayRef = useRef(null);
    let hourLabelWidth = 60;

    useEffect(() => {
        if (weekDayEvents && selectedCalendar) {
            const filteredEvents = weekDayEvents.filter(event => {
                if (event.calendarKey !== selectedCalendar.key) return false;
                if (selectedCalendarTags.length === 0) return true;
                return event.tags.some(tag => selectedCalendarTags.includes(tag));
            });

            const multiDay = [];
            const singleDay = [];

            filteredEvents.forEach(event => {
                const eventStart = event.startDate.toDate();
                const eventEnd = event.endDate.toDate();

                // Check if the event spans multiple days
                if (
                    eventStart < daysToShow[0] ||             // Starts before the week
                    eventEnd > daysToShow[6] ||               // Ends after the week
                    eventStart.toDateString() !== eventEnd.toDateString() // Spans multiple days
                ) {
                    multiDay.push(event);
                } else {
                    singleDay.push(event);
                }
            });

            setMultiDayEvents(multiDay);
            setDisplayEvents(singleDay);
        }
    }, [weekDayEvents, selectedCalendar, selectedCalendarTags, daysToShow]);

    useEffect(() => {
        const updateOverlayWidth = () => {
            if (overlayRef.current) {
                setOverlayWidth(overlayRef.current.offsetWidth);
            }
        };
        updateOverlayWidth();
        window.addEventListener('resize', updateOverlayWidth);
        return () => window.removeEventListener('resize', updateOverlayWidth);
    }, []);

    useEffect(() => {
        if (activeDate) {
            const baseDate = new Date(activeDate);
            baseDate.setDate(baseDate.getDate() - baseDate.getDay());
            const newDaysToShow = Array.from({ length: 7 }, (_, index) => {
                return new Date(baseDate.getFullYear(), baseDate.getMonth(), baseDate.getDate() + index);
            });
            setDaysToShow(newDaysToShow);
        }
    }, [activeDate]);

    const hourBlocksRef = useRef(null);
    useEffect(() => {
        const currentHour = new Date().getHours();
        const scrollTo = currentHour * 70;
        hourBlocksRef.current?.scrollTo({ top: scrollTo, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        if (daysToShow.length === 7) {
            setCalendarTitle(`${formatDateLong(daysToShow[0])} - ${formatDateLong(daysToShow[6])}`);
        }
    }, [daysToShow, setCalendarTitle]);

    useEffect(() => {
        if (daysToShow.length === 7) {
            const eventManager = new EventManager();
            setWeekDayEvents(eventManager.getEventsBetweenDates(events, daysToShow[0], daysToShow[6]));
        }
    }, [selectedApp, activeDate, events, daysToShow]);

    const hours = Array.from({ length: 24 }, (_, i) => `${i % 12 === 0 ? 12 : i % 12} ${i < 12 ? 'AM' : 'PM'}`);

    const formatDateLong = (date) => {
        return date.toLocaleDateString('en-US', { weekday: 'long' });
    };

    return (
        <DndProvider backend={HTML5Backend}>

            {/* Multi-Day Events */}
            <div className="calendar-week-multi-day-events" ref={overlayRef} style={{ marginLeft: hourLabelWidth }}>
                {multiDayEvents.map((event, index) => (
                    <CalendarWeekAllDayEvent
                        key={index}
                        event={event}
                        daysToShow={daysToShow}
                        overlayWidth={overlayWidth}
                    />
                ))}
            </div>

            {/* CONTAINER - SCROLLABLE */}
            <div className="calendar-week-container" ref={hourBlocksRef}>
                <div className="calendar-week-header" style={{ borderBottomColor: theme.backgroundColorFaded }}>
                    <CalendarWeekHeader daysToShow={daysToShow} />
                </div>
                <div className="calendar-week-wrapper">
                    {hours.map((hour, hourIndex) => (
                        <CalendarWeekHourBlock
                            key={`${hour}_${hourIndex}`}
                            hour={hour}
                            hourIndex={hourIndex}
                            leftOffset={hourLabelWidth}
                            daysToShow={daysToShow}
                            useDrop={useDrop}
                        />
                    ))}

                    <div ref={overlayRef} className="calendar-week-events-overlay">
                        {displayEvents && displayEvents.map((event, index) => (
                            <CalendarWeekEvent
                                key={event.key}
                                event={event}
                                index={index}
                                leftOffset={hourLabelWidth}
                                daysToShow={daysToShow}
                                overlayWidth={overlayWidth}
                                useDrag={useDrag}
                            />
                        ))}
                    </div>
                </div>
            </div>

        </DndProvider>
    );
};

export default CalendarWeek;
