import React from 'react';

// Styles
import './FieldHeader.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import FieldMenu from './fieldmenu/FieldMenu';

const FieldHeader = ({ field, showFieldMenu = false, additionalOptions = [] }) => {
  const { theme } = useTheme();

  return (
    <div className="field-header-container">

          {/* TITLE */}
          <div className="field-header-label"
            style={{ color: theme.foregroundColorFaded }}>
            {field.title}
          </div>

          {/* FIELD MENU */}
          <div>
            {showFieldMenu &&
              <FieldMenu field={field} additionalOptions={additionalOptions} />
            }
          </div>
          
    </div>
  );
};

export default FieldHeader;
