import React from 'react';

// Styles
import './ViewCountSummaryPreview.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const ViewCountSummaryPreview = ({ element }) => {
  const { getProcessedStyles } = useTheme();

  const { styles = {} } = element;

  // Filter out top, left, width, height, and zIndex
  const { top, left, width, height, zIndex, ...filteredStyles } = styles;

  const combinedStyles = {
      ...filteredStyles,
      width: 'auto',
      height: 'auto',
  };

  return (
      <div className="viewcount-summary-preview-container" style={getProcessedStyles(combinedStyles)}>
          12k views
      </div>
  );
};

export default ViewCountSummaryPreview;
