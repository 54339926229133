import React from 'react';

// Styles
import './FollowButton.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import FollowIcon from '../../../../common/svg/FollowIcon';

const FollowButton = ({ element, object }) => {
    const { theme } = useTheme();

    return (
        <div className="follow-button-container">
            <FollowIcon
                color={theme.foregroundColorFaded}
                width="16"
                height="16"
            />
        </div>
    );
};

export default FollowButton;
