import React, { useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../../Global';

// Utilities
import { defaultFont } from '../utilities/Defaults.js';
import { Page } from '../utilities/Enums';

// Device Detection
import { isMobile } from 'react-device-detect';

// Firebase
import { Timestamp } from 'firebase/firestore';
import { collections } from '../../firebaseConfig';

// Utilities
import { generateKey } from '../utilities/Keys';

// Templates
import { appTemplates } from './Templates';

// Styles
import './AppAdd.css';

// Theme
import { defaultThemes, useTheme } from '../../ThemeContext';

// Images
import BackIcon from '../../common/svg/BackIcon';

// Components
import AppAddItem from './AppAddItem';
import Modal from '../components/modal/Modal';

// Managers
import AppManager from '../managers/AppManager';
import AppUserManager from '../managers/AppUserManager';
import ChannelManager from '../managers/ChannelManager';
import DataManager from '../managers/DataManager';
import ModelManager from '../managers/ModelManager';
import PromptManager from '../managers/PromptManager';

const appManager = new AppManager();
const appUserManager = new AppUserManager();
const channelManager = new ChannelManager();
const dataManager = new DataManager();
const modelManager = new ModelManager();
const promptManager = new PromptManager();

/**
 * AppAdd Component
 * 
 * This component allows the user to add an app.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const AppAdd = () => {
    const { theme } = useTheme();
    const {
        appAddVisible,
        currentUser,
        hideProgress,
        resetVisibility,
        selectedApp,
        setAppAddVisible,
        setPage,
        setSelectedApp,
        setSelectedModel,
        setFormMode,
        showProgress
    } = useContext(Global);

    // State Variables
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [activeDiv, setActiveDiv] = useState(1); // Start with the first div
    const [modalTitle, setModalTitle] = useState("Create an App");
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    // References
    const titleInputRef = useRef(null);

    // Utilities
    const vowels = ['A', 'E', 'I', 'O', 'U']; // Array of vowel characters

    /**
     * Handles the selection of a template.
     */
    useEffect(() => {
        if (!selectedTemplate || selectedTemplate === undefined) return;
        setModalTitle(getModalTitle(selectedTemplate.name));
    }, [selectedTemplate]);

    /**
     * Handles the add of an app.
     * 
     * @param {string} e - Click event.
     */
    const handleAdd = async () => {
        if (!title) {
            alert('Please enter an app title.');
            return;
        }

        if (!description) {
            alert('Please enter an app description.');
            return;
        }

        showProgress("Adding app...");

        // Close the modal
        setAppAddVisible(false);

        // If there is a selected template, use it. Otherwise, just
        // add the app with the title and description.
        if (selectedTemplate) {
            await submitAppPrompt(prompt);
        } else {
            await addSimpleApp(title, description);
        }

        resetVisibility();

        setTitle('');
        hideProgress();
    };

    const submitAppPrompt = async (prompt) => {
      try {
        const prompt = await promptManager.prepareAppPrompt(selectedTemplate, title, description);
  
        const response = await promptManager.send(prompt);
  
        await processAppResponse(response);

      } catch (error) {
        console.error('Failed to fetch API response:', error);
      }
    };
    

  const processAppResponse = async (r) => {

    try {

      // Parse the JSON response
      const jsonString = promptManager.cleanJSON(r);
      const jsonData = JSON.parse(jsonString);

      const appKey = generateKey();
      const user = currentUser;
      const userKey = currentUser.userKey;

      // Current timestamp
      const now = Timestamp.now();

      const appData = {
        key: appKey,
        themes: {
          dark: {
            backgroundColor: jsonData.backgroundColorDark,
            backgroundColorFaded: jsonData.backgroundColorDarkFaded,
            foregroundColor: jsonData.foregroundColorDark,
            foregroundColorFaded: jsonData.foregroundColorDarkFaded,
            highlightBackgroundColor: jsonData.highlightBackgroundColorDark,
            highlightForegroundColor: jsonData.highlightForegroundColorDark,
          },
          light: {
            backgroundColor: jsonData.backgroundColorLight,
            backgroundColorFaded: jsonData.backgroundColorLightFaded,
            foregroundColor: jsonData.foregroundColorLight,
            foregroundColorFaded: jsonData.foregroundColorLightFaded,
            highlightBackgroundColor: jsonData.highlightBackgroundColorLight,
            highlightForegroundColor: jsonData.highlightForegroundColorLight,
          }
        },
        description: jsonData.appDescription,
        originalKey: appKey,
        //prompt: prompt,
        title: jsonData.appTitle,
        version: 1,
        userKey: userKey,
        username: currentUser.username,
        dateCreated: now,
        dateModified: now
      };

      const app = await appManager.add(appKey, appData);

      // Set the selected app
      setSelectedApp(appData);

      // Add the app creator as the first app user
      const appUserKey = generateKey();

      const appUserData = {
          key: appUserKey,
          appKey: appKey,
          userKey: userKey,
          username: currentUser.username,
          userEmail: currentUser.email,
          dateJoined: now
      };

      await appUserManager.add(appKey, appUserKey, appUserData);

      // Add the app creator to the user index
      //await userIndexManager.add(appKey, currentUser);

      showProgress("Generating menu...");

      // Initialize a map to store model titles and their keys
      const modelTitleToKey = {};

      // Initialize an array to store modelData objects
      const modelsArray = [];

      let sortIndex = 0;

      // Process channels
      if (jsonData.models && Array.isArray(jsonData.models)) {

        // Initialize the highest sort number to 0
        let channelSortNumber = 0;

        for (const channel of jsonData.channels) {
          const type = channel.type;
          const title = channel.title;
          const description = channel.description;

          const channelKey = generateKey();

          // Current timestamp
          const now = Timestamp.now();

          const channelData = {
              key: channelKey,
              appKey: appKey,
              type: type,
              title: title,
              description: description,
              private: true,
              sort: channelSortNumber,
              userKey: currentUser.userKey,
              username: currentUser.username,
              dateCreated: now,
              dateModified: now,
          };
  
          await dataManager.add(appKey, channelKey, collections.channels, channelData);

          channelSortNumber++;

        }
      }

      // Check if models exist and are in array format
      if (jsonData.models && Array.isArray(jsonData.models)) {
        for (const model of jsonData.models) {
          const title = model.title;
          const description = model.description;
          const modelKey = generateKey();

          // Store the model key with its title for later lookup
          modelTitleToKey[title] = modelKey;

          // Current timestamp
          const now = Timestamp.now();

          const modelData = {
            key: modelKey,
            appKey: appKey,
            userKey: userKey,
            title: title,
            description: description,
            noview: [],
            noadd: [],
            noedit: [],
            nodelete: [],
            sort: sortIndex,
            dateCreated: now,
            dateModified: now,
          };

          // Add the model to the app
          await modelManager.add(appKey, modelKey, modelData);

          // Push the modelData object to the modelsArray
          modelsArray.push(modelData);

          sortIndex++;
        }
      }

      // Models
      for (var i = 0; i < modelsArray.length; i++) {
        await submitModelPrompt(app, modelsArray[i], modelsArray);
      }

      setSelectedApp(app);

      setPage(Page.RUN);

    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  };

  const submitModelPrompt = async (app, model, models) => {
    try {

      const prompt = await promptManager.prepareModelPrompt(app.title, app.description, model.title, model.description, models);

      const response = await promptManager.send(prompt);

      await promptManager.processModelResponse(
        response, 
        app, 
        model, 
        showProgress,
        setSelectedModel,
        setFormMode,
        null
      );

    } catch (error) {
      console.error('Failed to fetch API response:', error);
    }
  };

    /**
     * Adds a simple app containing only the title, default themes and description.
     * 
     * @param {string} title - The title of the app.
     * @param {string} description - The description of the app.
     */
    const addSimpleApp = async (title, description) => {

        const userKey = currentUser.userKey;
        const username = currentUser.username;
        const useremail = currentUser.email;

        // Add the app
        const newKey = generateKey();

        // Current timestamp
        const now = Timestamp.now();

        const appData = {
            key: newKey,
            themes: defaultThemes,
            description: description,
            title: title,
            userKey: userKey,
            username: username,
            dateCreated: now,
            dateModified: now
        };

        await appManager.add(newKey, appData);

        // Add the app creator as the first app user
        const appUserKey = generateKey();

        const appUserData = {
            key: appUserKey,
            appKey: newKey,
            userKey: userKey,
            username: username,
            userEmail: useremail,
            dateJoined: now
        };

        await appUserManager.add(newKey, appUserKey, appUserData);
    };

    const handleBack = () => {
        setModalTitle("Create an App");

        // Slide back to the first div
        setActiveDiv(1);
    };

    const handleNew = () => {
        // Slide to the second div
        setActiveDiv(2);

        // Focus on the title input after the slide
        setTimeout(() => {
            titleInputRef.current.focus();
        }, 300);
    };

    const handleNewFromTemplate = (key) => {

        // Find the selected template
        const template = appTemplates.find(t => t.key === key);
        setSelectedTemplate(template);

        // Update the modal title
        setModalTitle(getModalTitle(template.name));

        // Slide to the second div
        setActiveDiv(2);

        // Focus on the title input after the slide
        setTimeout(() => {
            titleInputRef.current.focus();
        }, 300);
    };

    // Utility function to determine if the first letter is a vowel
    const getModalTitle = (templateName) => {
        const firstLetter = templateName.charAt(0).toUpperCase(); // Get first letter and convert to uppercase
        if (vowels.includes(firstLetter)) {
            return `Create an ${templateName}`;
        } else {
            return `Create a ${templateName}`;
        }
    };

    return (
        <>
            {/* MODAL */}
            <Modal
                title={modalTitle}
                isOpen={appAddVisible}
                onClose={() => setAppAddVisible(false)}
                height="500px"
                width={isMobile ? "90%" : "380px"}>

                <div className="app-add-container ">

                    {/* OPTIONS */}
                    <div className={`app-add-slider-div app-add-options ${activeDiv === 1 ? 'visible' : ''}`}>

                        <AppAddItem
                            icon="PlusIcon"
                            name="Create your own"
                            height="70px"
                            onClick={handleNew}
                        />

                        <div className="app-add-template-title"
                            style={{
                                color: theme.foregroundColorFaded
                            }}>
                            Templates:
                        </div>

                        {appTemplates.map((template) => (

                            <AppAddItem
                                icon={template.icon}
                                name={template.name}
                                description={template.description}
                                onClick={() => handleNewFromTemplate(template.key)}
                            />
                        ))};

                    </div>

                    {/* FORM */}
                    <div className={`app-add-slider-div app-add-form ${activeDiv === 2 ? 'visible' : ''}`}>

                        {/* BACK BUTTON */}
                        <div
                            className="app-add-back-button"
                            onClick={handleBack}>

                            <BackIcon
                                color={theme.foregroundColor}
                                width="16"
                                height="16"
                            />

                        </div>

                        {/* TITLE */}
                        <div className="app-add-label" style={{ color: theme.foregroundColor }}>
                            Title
                        </div>
                        <input
                            type="text"
                            name="title"
                            className="app-add-input"
                            style={{
                                color: theme.foregroundColor,
                                backgroundColor: theme.backgroundColorFaded,
                                borderColor: theme.backgroundColorFaded,
                                fontFamily: selectedApp ? selectedApp.fontFamily : defaultFont
                            }}
                            value={title}
                            maxLength="100"
                            onChange={e => setTitle(e.target.value)}
                            ref={titleInputRef}
                        />

                        {/* DESCRIPTION */}
                        <div className="app-add-label" style={{ color: theme.foregroundColor }}>
                            Description
                        </div>
                        <textarea
                            name="description"
                            className="app-add-textarea"
                            style={{
                                color: theme.foregroundColor,
                                backgroundColor: theme.backgroundColorFaded,
                                borderColor: theme.backgroundColorFaded,
                                fontFamily: selectedApp.fontFamily || 'Open Sans'
                            }}
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                            placeholder="Description"
                            maxLength="250"></textarea>

                        {/* BUTTONS */}
                        <div className="app-add-buttons">
                            <button
                                type="button"
                                onClick={handleAdd}
                                className="app-add-button"
                                style={{
                                    color: theme.foregroundColor,
                                    fontFamily: selectedApp ? selectedApp.fontFamily : defaultFont
                                }}>
                                Continue...
                            </button>
                        </div>
                    </div>

                </div>

            </Modal>
        </>
    );
};

export default AppAdd;
