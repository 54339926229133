import React, { useContext } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './CalendarItem.css';

// Theme
import { useStyle, useTheme } from '../../../../ThemeContext';

// Images
import ClockIcon from '../../../svg/ClockIcon';

// Components
import CalendarItemMenu from '../calendaritemmenu/CalendarItemMenu';

// Inline Styles
const InlineStyles = useStyle`
    .calendar-item-container:hover {
        background-color: ${(props) => props.hoverColor} !important;
        border-color: ${(props) => props.hoverColor} !important;
    }
`;

/**
 * CalendarItem Component
 * 
 * This component renders a calendar item in the menu.
 * 
 * @param {object} calendar - The calendar to represent in the menu.
 * @param {boolean} isActive - Indicates if the item has been selected.
 * @param {function} onClick - The function to call on a click.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const CalendarItem = ({ calendar, title, isActive, onClick }) => {
    const { theme } = useTheme();
    const {
        selectedApp,
    } = useContext(Global);

    return (
        <>
            {/* STYLES */}
            <InlineStyles hoverColor={theme.backgroundColorFaded} />

            {/* CONTAINER */}
            <div
                className="calendar-item-container"
                style={{
                    backgroundColor: isActive ? theme.highlightBackgroundColor : 'transparent',
                }}>

                <div className="calendar-item-left"
                    onClick={onClick}>
                    <ClockIcon
                        color={isActive ? theme.highlightForegroundColor : theme.foregroundColorFaded}
                        width="12"
                        height="12"
                    />
                    <div className="calendar-item-text"
                        style={{
                            color: isActive ? theme.highlightForegroundColor : theme.foregroundColor,
                            fontFamily: selectedApp.fontFamily,
                        }}>
                        {title}
                    </div>
                </div>

                <div
                    className="calendar-item-edit-image">
                    <CalendarItemMenu calendar={calendar} isActive={isActive} />
                </div>

            </div>
        </>
    );
};

export default CalendarItem;
