import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Formatter
import { formatDaySpan } from '../../../utilities/Formatters';

// Styles
import './CalendarWeekAllDayEvent.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const CalendarWeekAllDayEvent = ({ event, daysToShow, overlayWidth }) => {
  
  // Theme
  const { theme } = useTheme();

  // Global
  const {
    setSelectedEvent,
    setEventVisible,
  } = useContext(Global);

  const [leftPosition, setLeftPosition] = useState(0);
  const [eventWidth, setEventWidth] = useState(0);

  useEffect(() => {

    const eventStart = event.startDate.toDate();
    const eventEnd = event.endDate.toDate();
  
    // Ensure overlayWidth is non-zero before calculating positions
    if (overlayWidth === 0) return null;
  
    // Calculate start and end day indexes for positioning
    const startDayIndex = Math.max(0, daysToShow.findIndex(day => day <= eventStart && eventStart < new Date(day.getTime() + 86400000)));
  
    // Updated endDayIndex logic
    let endDayIndex = daysToShow.findIndex(day => day <= eventEnd && eventEnd < new Date(day.getTime() + 86400000));
    if (endDayIndex === -1 || eventEnd > daysToShow[6]) {
      // If endDayIndex is -1 or if the event goes beyond the end of the week
      endDayIndex = 6;
    }
  
    // Calculate width and position for spanning event
    const dayWidth = overlayWidth / 7;
    setLeftPosition(startDayIndex * dayWidth);
    setEventWidth((endDayIndex - startDayIndex + 1) * dayWidth);

  }, [event]);

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setEventVisible(true);
  };

  return (
    <>
      <div
        className="calendar-week-multi-day-event"
        onClick={() => handleEventClick(event)}
        style={{
          backgroundColor: theme.highlightBackgroundColor,
          borderColor: theme.highlightBackgroundColor,
          left: `${leftPosition}px`,
          width: `${eventWidth}px`,
        }}>
        <div className="calendar-week-multi-day-event-title"
          style={{
            color: theme.highlightForegroundColor,
          }}>
          {event.title}
        </div>
        <div className="calendar-day-all-day-event-date"
          style={{
            color: theme.highlightForegroundColor,
          }}>
          &nbsp;-&nbsp;{formatDaySpan(event.startDate.toDate(), event.endDate.toDate())}
        </div>
      </div>
    </>
  );
};

export default CalendarWeekAllDayEvent;
