import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Firebase
import { doc, updateDoc } from 'firebase/firestore';
import { collections, db } from '../../../firebaseConfig';

// Activity
import { activity } from '../../../common/managers/ActivityManager';

// Styles
import './FieldRename.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import Modal from '../../../common/components/modal/Modal';
import ModalButton from '../../../common/components/modal/ModalButton';
import ModalButtons from '../../../common/components/modal/ModalButtons';
import ModalInput from '../../../common/components/modal/ModalInput';
import ModalLabel from '../../../common/components/modal/ModalLabel';
import ModalSection from '../../../common/components/modal/ModalSection';
import ModalTextarea from '../../../common/components/modal/ModalTextarea';

const FieldRename = ({
    field,
    fieldTitle,
    setFieldTitle,
    renameOpen,
    setRenameOpen
}) => {
    const { theme } = useTheme();
    const {
        formFields,
        selectedApp,
        setFormFields
    } = useContext(Global);

    const handleSave = async () => {
        const data = {
            title: fieldTitle
        };

        try {
            await updateDoc(doc(db, collections.fields, field.key), data);

            // Update the title in the formFields state
            const updatedFormFields = formFields.map(item =>
                item.key === field.key ? { ...item, title: fieldTitle } : item
            );

            setFormFields(updatedFormFields);

            activity.log(selectedApp.key, 'writes', 1);

            setRenameOpen(false);
        } catch (error) {
            console.error('Error renaming field:', error);
        }
    };

    return (

        <Modal title="Edit Field" isOpen={renameOpen} onClose={() => setRenameOpen(false)}>

            {/* TITLE */}
            <ModalSection>

                <ModalLabel text="Title" />

                <ModalInput
                    value={fieldTitle}
                    onChange={(e) => setFieldTitle(e.target.value)}
                    maxLength="100"
                />

            </ModalSection>

            {/* TITLE */}
            <ModalButtons>

                {/* SAVE */}
                <ModalButton
                    onClick={handleSave}
                    label="Save"
                />

            </ModalButtons>

        </Modal>

    );
};

export default FieldRename;
