import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './AppUserProfile.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import AppUserPhoto from './AppUserPhoto';

// Managers
import AppUserManager from '../../common/managers/AppUserManager';
import UserManager from '../../common/managers/UserManager';

const appUserManager = new AppUserManager();
const userManager = new UserManager();

const AppUserProfile = () => {
    const { theme } = useTheme();
    const {
        appUsers,
        changePage,
        currentUser,
        resetVisibility,
        selectedApp,
        selectedAppUser,
        setAppUsers,
        setCurrentCanvas,
        setCurrentDevice
    } = useContext(Global);

    const [user, setUser] = useState(null);

    useEffect(() => {
        if (!selectedAppUser) return;

        async function fetchUser() {
            // Fetch the user record
            const fetchedUser = await userManager.fetchUser(selectedAppUser.userKey);
            setUser(fetchedUser);
        }
        fetchUser();

    }, [selectedAppUser]);

    // Function to format date
    const formatDate = (date) => {
        // Check if the date is a Firebase Timestamp object
        if (date && date.toDate) {
            date = date.toDate(); // Convert Firebase Timestamp to JavaScript Date object
        }

        return date.toLocaleDateString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    const handleRemove = () => {

        // Call to remove user from backend or database
        appUserManager.removeAppUser(selectedApp.key, selectedAppUser)
            .then(() => {
                // If the removal is successful, update local state
                const updatedAppUsers = appUsers.filter(user => user.id !== selectedAppUser.key);
                setAppUsers(updatedAppUsers);

                resetVisibility();

                setCurrentDevice("MOBILE");
                setCurrentCanvas("FORM");

                if (currentUser) {
                    changePage('HOME');
                } 
            })
            .catch(error => {
                // Handle error if something goes wrong
                console.error('Failed to remove user:', error);
            });
    };

    return (

        <div className="app-user-profile-container">
            {user && selectedAppUser &&
                <>
                    <AppUserPhoto
                        user={user}
                        setUser={setUser}
                    />
                    <div className="app-user-profile-name"
                        style={{
                            color: theme.foregroundColor
                        }}
                    >
                        {user.firstName} {user.lastName}
                    </div>
                    <div className="app-user-profile-joined"
                        style={{
                            color: theme.foregroundColorFaded
                        }}
                    >
                        Joined {formatDate(selectedAppUser.dateJoined)}
                    </div>
                    {selectedApp.userKey !== user.key &&
                        <div className="app-user-profile-delete-button-container">
                            <button type="button" onClick={handleRemove}
                                className="app-user-profile-delete-button"
                                style={{
                                    backgroundColor: theme.highlightBackgroundColor,
                                    color: theme.highlightForegroundColor
                                }}>
                                {currentUser.userKey === user.key ? (
                                    <>
                                        Leave App
                                    </>
                                ) : (
                                    <>
                                        Remove from App
                                    </>
                                )}
                            </button>
                        </div>
                    }
                </>
            }
        </div>

    );
};

export default AppUserProfile;
