import React, { createContext, useState, useContext, useEffect } from 'react';

// Styled Components
import { createGlobalStyle } from 'styled-components';
export const useStyle = createGlobalStyle;

// Create a context for the theme
const ThemeContext = createContext();

// Default themes
export const defaultThemes = {
    dark: {
        backgroundColor: '#000000',
        backgroundColorFaded: '#222222',
        foregroundColor: '#ffffff',
        foregroundColorFaded: '#aaaaaa',
        highlightBackgroundColor: '#2f79ce',
        highlightForegroundColor: '#ffffff'
    },
    light: {
        backgroundColor: '#ffffff',
        backgroundColorFaded: '#f0f0f0',
        foregroundColor: '#000000',
        foregroundColorFaded: '#555555',
        highlightBackgroundColor: '#2f79ce',
        highlightForegroundColor: '#ff0000'
    }
};

// Create a provider component
export const ThemeProvider = ({ selectedApp, children }) => {
    const [currentTheme, setCurrentTheme] = useState('DARK');

    useEffect(() => {
        if (selectedApp?.currentTheme) {
            setCurrentTheme(selectedApp.currentTheme);
        }
    }, [selectedApp]);

    const themes = selectedApp?.themes || defaultThemes;
    const theme = currentTheme === 'DARK' ? themes.dark : themes.light;

    // Function to convert hex to rgba
    const hexToRgba = (hex, alpha) => {
        let r = 0, g = 0, b = 0;
        if (hex.length === 4) {
            r = parseInt(hex[1] + hex[1], 16);
            g = parseInt(hex[2] + hex[2], 16);
            b = parseInt(hex[3] + hex[3], 16);
        } else if (hex.length === 7) {
            r = parseInt(hex[1] + hex[2], 16);
            g = parseInt(hex[3] + hex[4], 16);
            b = parseInt(hex[5] + hex[6], 16);
        }
        return `rgba(${r},${g},${b},${alpha})`;
    };

    /**
    * Converts a hex color code to a grayscale percentage for CSS filter use.
    * @param {string} hex - The hex color code (e.g., "#AABBCC").
    * @returns {string} - A grayscale percentage (e.g., "50%").
    */
    const hexToGrayscalePercentage = (hex) => {
        // Remove the hash if present
        hex = hex.replace("#", "");

        // Parse RGB components from hex code
        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);

        // Calculate luminance (using Rec. 601 luma coefficients for perceived brightness)
        const luminance = 0.299 * r + 0.587 * g + 0.114 * b;

        // Convert luminance (0-255 range) to a grayscale percentage (0-100)
        const grayscalePercent = Math.round((luminance / 255) * 100);

        return `grayscale(${grayscalePercent}%)`;
    }

    // Helper to convert hex to HSL
    const hexToHsl = (hex) => {
        let r = parseInt(hex.slice(1, 3), 16) / 255;
        let g = parseInt(hex.slice(3, 5), 16) / 255;
        let b = parseInt(hex.slice(5, 7), 16) / 255;

        let max = Math.max(r, g, b);
        let min = Math.min(r, g, b);
        let h, s, l = (max + min) / 2;

        if (max === min) {
            h = s = 0; // achromatic
        } else {
            let d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch (max) {
                case r: h = (g - b) / d + (g < b ? 6 : 0); break;
                case g: h = (b - r) / d + 2; break;
                case b: h = (r - g) / d + 4; break;
                default: break;
            }
            h /= 6;
        }
        return [h * 360, s * 100, l * 100];
    };

    // Helper to convert HSL to hex
    const hslToHex = (h, s, l) => {
        s /= 100;
        l /= 100;

        let c = (1 - Math.abs(2 * l - 1)) * s;
        let x = c * (1 - Math.abs((h / 60) % 2 - 1));
        let m = l - c / 2;
        let [r, g, b] = h < 60 ? [c, x, 0] : h < 120 ? [x, c, 0] : h < 180 ? [0, c, x] : h < 240 ? [0, x, c] : h < 300 ? [x, 0, c] : [c, 0, x];
        [r, g, b] = [r + m, g + m, b + m].map(v => Math.round(v * 255));
        return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
    };

    // Function to generate similar shades
    const generateColorShades = (baseColor, arrayLength, darkMode) => {
        let [h, s, l] = hexToHsl(baseColor);

        // Set limits based on dark or light mode
        const minL = darkMode ? 40 : 10; // Adjust for min lightness
        const maxL = darkMode ? 60 : 45; // Adjust for max lightness

        // Adjust saturation to avoid extreme colors
        const minS = 60;
        const maxS = 80;

        // Generate the color shades
        const shades = Array.from({ length: arrayLength }, (_, i) => {
            let newL = l + (i - arrayLength / 2) * 5; // Incremental lightness
            let newS = s + (i - arrayLength / 2) * 3; // Incremental saturation

            // Clamp values to prevent extreme colors
            newL = Math.max(minL, Math.min(maxL, newL));
            newS = Math.max(minS, Math.min(maxS, newS));

            return hslToHex(h, newS, newL);
        });

        return shades.reverse();
    };

    /**
     * Converts a hex color code to a grayscale hex color.
     * @param {string} hex - The hex color code (e.g., "#AABBCC").
     * @returns {string} - The grayscale hex color (e.g., "#808080").
     */
    function hexToGrayscaleHex(hex) {
        // Remove the hash if present
        hex = hex.replace("#", "");

        // Parse RGB components from hex code
        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);

        // Calculate luminance (perceived brightness)
        const luminance = Math.round(0.299 * r + 0.587 * g + 0.114 * b);

        // Convert luminance to a two-digit hex string
        const grayHex = luminance.toString(16).padStart(2, '0');

        // Return the grayscale color in hex format
        return `#${grayHex}${grayHex}${grayHex}`;
    }


    // Function to map tokens to theme colors
    const getColorFromToken = (token) => {
        switch (token) {
            case '[foregroundColor]':
                return theme.foregroundColor;
            case '[foregroundColorFaded]':
                return theme.foregroundColorFaded;
            case '[highlightBackgroundColor]':
                return theme.highlightBackgroundColor;
            case '[highlightForegroundColor]':
                return theme.highlightForegroundColor;
            case '[backgroundColorFaded]':
                return theme.backgroundColorFaded;
            case '[backgroundColor]':
                return theme.backgroundColor;
            case '[transparent]':
                return 'transparent';
            default:
                console.warn(`Unknown color token: ${token}`);
                return theme.foregroundColor; // Fallback color
        }
    };

    // Function to process styles and replace tokens with actual colors
    const getProcessedStyles = (styles) => {
        const processed = Object.entries(styles).reduce((acc, [key, value]) => {
            if (typeof value === 'string' && value.startsWith('[') && value.endsWith(']')) {
                acc[key] = getColorFromToken(value);
            } else {
                acc[key] = value;
            }
            return acc;
        }, {});
        return processed;
    };

    // SUMMARY FIELDS
    const fieldDefaults = {
        checkbox: {
            width: '34px',
            height: '34px'
        },
        checklist: {
            width: '20%',
            height: '20px',
            color: theme.foregroundColor,
        },
        event: {
            width: '150px',
            height: '40px',
            color: theme.foregroundColor,
        },
        gallery: {
            width: '80px',
            height: '80px',
            backgroundColor: 'transparent'
        },
        image: {
            width: '80px',
            height: '80px',
            backgroundColor: 'transparent'
        },
        lookup: {
            width: '150px',
            height: '23px',
            color: theme.foregroundColor,
        },
        phone: {
            width: '150px',
            height: '23px',
            color: theme.foregroundColor,
        },
        text: {
            width: '150px',
            height: '25px',
            color: theme.foregroundColor,
            fontSize: '14pt',
            fontStyle: 'normal',
            fontWeight: 'normal',
            textAlign: 'left',
            textDecoration: 'none',
            letterSpacing: '0px',
            lineHeight: '1.5em',
            textTransform: 'none',
            textWrap: 'wrap',
            wordSpacing: '0px',
            zIndex: '0'
        },
        year: {
            width: '150px',
            height: '25px',
            color: theme.foregroundColor,
        },
    };

    return (
        <ThemeContext.Provider value={{
            theme,
            currentTheme,
            fieldDefaults,
            setCurrentTheme,
            getColorFromToken,
            getProcessedStyles, // Provide the getProcessedStyles function
            hexToRgba, // Provide the hexToRgba function
            hexToGrayscalePercentage, // Provide the hexToGrayscalePercentage function
            hexToGrayscaleHex, // Provide the hexToGrayscaleHex function
            generateColorShades
        }}>
            {children}
        </ThemeContext.Provider>
    );
};

// Custom hook to use the ThemeContext
export const useTheme = () => useContext(ThemeContext);