import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Device Detections
import { isMobile } from 'react-device-detect';

// Styles
import './Bookmarks.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import BookmarksToolBar from './BookmarksToolBar';
import Divider from '../../common/components/divider/Divider';
import Object from '../objects/Object';

const Bookmarks = () => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    bookmarks,
    models,
    resetVisibility,
    setSelectedModel,
    setSelectedObject,
  } = useContext(Global);

  const handleObjectClick = async (object) => {
    resetVisibility();
    const model = models.find(model => model.key === object.modelKey);
    setSelectedModel(model);
    setSelectedObject(object);
  };

  // Split into columns only for non-mobile devices
  const leftColumn = [];
  const rightColumn = [];

  if (!isMobile) {
    bookmarks.forEach((bookmark, index) => {
      if (index % 2 === 0) {
        leftColumn.push(bookmark);
      } else {
        rightColumn.push(bookmark);
      }
    });
  }

  return (
    <div className="bookmarks-container">
      <BookmarksToolBar />
      <div className={isMobile ? "bookmarks-wrapper-mobile" : "bookmarks-wrapper"}>
        {isMobile ? (
          // Single column for mobile
          <div className="bookmarks-column-mobile">
            {bookmarks.map((bookmark) => (
              <div key={bookmark.id} className="bookmarks-object" style={{ backgroundColor: theme.backgroundColor }}>
                <Object object={bookmark.object} onClick={() => handleObjectClick(bookmark.object)} />
                <Divider />
              </div>
            ))}
          </div>
        ) : (
          // Two columns for desktop
          <>
            <div className="bookmarks-column">
              {leftColumn.map((bookmark) => (
                <div key={bookmark.id} className="bookmarks-object" style={{ backgroundColor: theme.backgroundColor }}>
                  <Object object={bookmark.object} onClick={() => handleObjectClick(bookmark.object)} />
                  <Divider />
                </div>
              ))}
            </div>
            <Divider direction="vertical" />
            <div className="bookmarks-column">
              {rightColumn.map((bookmark) => (
                <div key={bookmark.id} className="bookmarks-object" style={{ backgroundColor: theme.backgroundColor }}>
                  <Object object={bookmark.object} onClick={() => handleObjectClick(bookmark.object)} />
                  <Divider />
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Bookmarks;
