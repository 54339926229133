import React from 'react';

// Styles
import './TextButton.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

// Images
import TextIcon from '../../../../svg/TextIcon';

const TextButton = ({ onClick }) => {

  // Themes
  const { theme } = useTheme();   

  return (
    <>
      {/* CONTAINER */}
      <div className="text-button-container"
        style={{
          backgroundColor: theme.backgroundColorFaded,
        }}
        onClick={onClick}>

        {/* ICON */}
        <TextIcon
          color={theme.foregroundColorFaded}
          width="18"
          height="18"
        />
      </div>
    </>
  );
};

export default TextButton;
