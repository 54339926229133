import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { collections } from '../../firebaseConfig';

// Activity
import { activity } from '../../common/managers/ActivityManager';

// Managers
import DataManager from '../../common/managers/DataManager';
const dataManager = new DataManager();

/**
 * Custom hook to load channels for a specific app.
 */
const usePreloadChannels = () => {

  // Global
  const { 
    selectedApp, 
    setChannels 
  } = useContext(Global);

  useEffect(() => {
    // Exit early if no selected app or app key
    if (!selectedApp || !selectedApp.key) {
      setChannels([]);
      return;
    }

    // Function to load channels
    const loadChannels = (appKey) => {
      const handleUpdate = (items) => {
        setChannels(items);
        activity.log(appKey, 'reads', items.length);
      };

      const sortFields = [
        "sort", // Ascending by default
      ];

      const unsubscribe = dataManager.listAndSubscribe(
        appKey,
        collections.channels,
        handleUpdate,
        [],
        sortFields
      );

      return unsubscribe;
    };

    // Subscribe to channels
    const unsubscribeChannels = loadChannels(selectedApp.key);

    // Cleanup when component unmounts or when dependencies change
    return () => {
      if (unsubscribeChannels) {
        unsubscribeChannels();
      }
    };
  }, [selectedApp, setChannels]);

};

export default usePreloadChannels;
