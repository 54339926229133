/**
 * Valid field types that can be used in summaries.
 */
export const validSummaryTypes = [
    'country',
    'currency',
    'day',
    'gallery',
    'lookup',
    'number',
    'phone',
    'state',
    'text',
    'time',
    'videogallery',
    'year'
];

/**
* A list of valid text summary field types that can be used in the summary view.
*/
export const validSummaryTextTypes = [
    'country',
    'currency',
    'day',
    'lookup',
    'number',
    'phone',
    'state',
    'text',
    'time',
    'year'
];