import React from 'react';

const MonthIcon = ({ color = "white", width = "19", height = "18" }) => (
    <svg
        version="1.1"
        width={width}
        height={height}
        viewBox="0 0 19 18" fill="none">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-375.000000, -2105.000000)">
                <g transform="translate(100.000000, 1960.000000)">
                    <g transform="translate(272.000000, 142.000000)">
                        <g>
                            <polygon points="0 0 24 0 24 24 0 24"></polygon>
                            <path d="M20,3 L5,3 C3.9,3 3,3.9 3,5 L3,19 C3,20.1 3.9,21 5,21 L20,21 C21.1,21 22,20.1 22,19 L22,5 C22,3.9 21.1,3 20,3 Z M20,5 L20,8 L5,8 L5,5 L20,5 Z M15,19 L10,19 L10,10 L15,10 L15,19 Z M5,10 L8,10 L8,19 L5,19 L5,10 Z M17,19 L17,10 L20,10 L20,19 L17,19 Z"
                                fill={color}></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default MonthIcon;
