import React from 'react';

const LinkIcon = ({ color = "white", width = "24", height = "24" }) => (
    <svg
        version="1.1"
        width={width}
        height={height}
        viewBox="0 0 24 24">
        <path d="M18.5104 3.86829C16.9483 2.30619 14.4156 2.30619 12.8535 3.86829L8.61089 8.11093C7.04879 9.67303 7.04879 12.2057 8.61089 13.7678C10.173 15.3299 12.7056 15.3299 14.2677 13.7678L14.7704 13.2651C14.7704 13.2651 14.5439 12.6297 14.2677 12.3536C13.9916 12.0774 13.3562 11.8509 13.3562 11.8509L12.8535 12.3536C12.0725 13.1346 10.8061 13.1346 10.0251 12.3536C9.24405 11.5725 9.24405 10.3062 10.0251 9.52514L14.2677 5.2825C15.0488 4.50145 16.3151 4.50145 17.0962 5.2825C17.8772 6.06355 17.8772 7.32988 17.0962 8.11093L16.3891 8.81804C16.3891 8.81804 16.8786 9.1363 17.1873 9.44504C17.4961 9.75378 17.8033 10.2323 17.8033 10.2323L18.5104 9.52514C20.0725 7.96305 20.0725 5.43039 18.5104 3.86829Z"
            fill={color} />
        <path d="M16.3891 10.2323C14.827 8.67015 12.2943 8.67015 10.7322 10.2323L10.1908 10.7736C10.1908 10.7736 10.4588 11.3731 10.735 11.6492C11.0111 11.9254 11.605 12.1878 11.605 12.1878L12.1464 11.6465C12.9275 10.8654 14.1938 10.8654 14.9748 11.6465C15.7559 12.4275 15.7559 13.6938 14.9748 14.4749L10.7322 18.7175C9.95116 19.4986 8.68483 19.4986 7.90378 18.7175C7.12273 17.9365 7.12273 16.6702 7.90378 15.8891L8.6827 15.1102C8.6827 15.1102 8.17992 14.751 7.90378 14.4749C7.62764 14.1987 7.26849 13.696 7.26849 13.696L6.48957 14.4749C4.92747 16.037 4.92747 18.5696 6.48957 20.1317C8.05166 21.6938 10.5843 21.6938 12.1464 20.1317L16.3891 15.8891C17.9512 14.327 17.9512 11.7943 16.3891 10.2323Z"
            fill={color} />
    </svg>
);

export default LinkIcon;
