import React from 'react';

// Styles
import './ViewCountSummary.css';

// Theme
import { useTheme } from '../../../ThemeContext';

const ViewCountSummary = ({ element, object }) => {
    const { getProcessedStyles } = useTheme();

    const { styles = {} } = element;

    // Filter out position and size styles handled by parent
    const { top, left, width, height, zIndex, ...filteredStyles } = styles;

    const processed = getProcessedStyles(filteredStyles);

    // Function to format view count
    const formatViewCount = (count) => {
        if (count < 1000) {
            return `${count} ${count === 1 ? 'view' : 'views'}`;
        } else if (count >= 1000 && count < 1000000) {
            return `${(count / 1000).toFixed(1)}k views`;
        } else if (count >= 1000000) {
            return `${(count / 1000000).toFixed(1)}m views`;
        }
        return `${count} views`;
    };

    return (
        <div className="viewcount-summary-container"
            style={processed}>
            {object.viewCount !== undefined &&
                <>
                    {formatViewCount(object.viewCount)}
                </>
            }
        </div>
    );
};

export default ViewCountSummary;
