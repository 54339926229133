import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Utilities
import { convertDecimalToPercentage } from '../../../common/utilities/Numbers';

// Styles
import './AppDot.css';

// Themes
import { useTheme } from '../../../ThemeContext';

/**
 * AppDot Component
 * 
 * This component renders an icon or letter representing an app.
 * 
 * @param {object} app - The app to represent.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const AppDot = ({ app }) => {

    // Theme
    const { currentTheme, hexToGrayscalePercentage, hexToGrayscaleHex, theme } = useTheme();

    // Global
    const { selectedApp } = useContext(Global);

    // State Variables
    const [font, setFont] = useState('');

    // Sets the font family for the app letter.
    useEffect(() => {
        if (app.fontFamily) {
            setFont(app.fontFamily);
        }
    }, [app, setFont]);

    // Size of the circle
    const size = 40;

    // Determine if app is selected
    const isSelected = selectedApp && selectedApp.key === app.key;

    // Calculate grayscale hex for background and letter colors if app is not selected
    const backgroundColor = currentTheme === 'DARK' ? app.themes.dark.backgroundColorFaded : app.themes.light.backgroundColorFaded;
    const letterColor = currentTheme === 'DARK' ? app.themes.dark.highlightBackgroundColor : app.themes.light.highlightBackgroundColor;

    const grayscaleBackgroundHex = isSelected ? backgroundColor : hexToGrayscaleHex(backgroundColor);
    const grayscaleLetterHex = isSelected ? letterColor : hexToGrayscaleHex(letterColor);

    return (
        <>
            {/* CIRCLE */}
            <div className="app-dot-container"
                style={{
                    backgroundColor: grayscaleBackgroundHex,
                    borderColor: theme.backgroundColorFaded,
                    opacity: isSelected ? 1 : 0.4,
                }}>

                {/* ICON */}
                {app.logoDark || app.logoLight ? (
                    <img
                        className="app-dot-image"
                        src={currentTheme === 'DARK' ? app.logoDark : app.logoLight}
                        style={{
                            filter: isSelected ? 'grayscale(0%)' : 'grayscale(100%)',
                            width: app.iconSize ? convertDecimalToPercentage(app.iconSize) : "100%"
                        }}
                        alt="App Logo"
                    />
                ) : (
                    <div className="app-dot-letter"
                        style={{
                            color: grayscaleLetterHex,
                            '--dot-size': size + 'px',
                            '--dot-font-size': size * 0.5 + 'px',  // 50% of size
                            height: size + 'px',
                            width: size + 'px',
                            fontFamily: font || 'sans-serif'
                        }}>
                        {app.title.charAt(0)}
                    </div>
                )}
            </div>
        </>
    );
};

export default AppDot;
