import React, { useEffect, useState } from 'react';

// Styles
import './MonthPicker.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import LeftIcon from '../../../common/svg/LeftIcon';
import RightIcon from '../../../common/svg/RightIcon';

const MonthPicker = ({
    isOpen,
    initialYear = new Date().getFullYear(),
    onClose,
    onMonthSelect }) => {

    // Theme
    const { theme } = useTheme();

    // State Variables
    const [isAnimating, setIsAnimating] = useState(false);
    const [selectedYear, setSelectedYear] = useState(initialYear);

    const startYear = 1900;
    const endYear = 2050;

    // Fade
    useEffect(() => {
        if (isOpen) {
            // Trigger the animation
            setIsAnimating(true);
        } else {
            setIsAnimating(false);
        }
    }, [isOpen]);

    const months = [
        'January', 'February', 'March', 'April',
        'May', 'June', 'July', 'August',
        'September', 'October', 'November', 'December'
    ];

    const handleYearChange = (change) => {
        setSelectedYear(selectedYear + change);
    };

    const handleYearSelect = (event) => {
        setSelectedYear(parseInt(event.target.value));
    };

    const handleMonthClick = (monthIndex) => {
        // Create a Date object for the selected year and month, set to midnight
        const selectedDate = new Date(selectedYear, monthIndex, 1, 0, 0, 0, 0);

        // Call onMonthSelect with the date object
        onMonthSelect(selectedDate);

        // Close the month picker
        onClose();
    };

    if (!isOpen && !isAnimating) return null;

    return (
        <div
            className="month-picker-overlay"
            onMouseDown={(e) => {
                onClose();
            }}>

            {/* CONTENT */}
            <div className="month-picker-content"
                onMouseDown={(e) => {
                    e.stopPropagation();
                }}
                style={{
                    backgroundColor: theme.backgroundColor,
                    color: theme.foregroundColor,
                    opacity: isAnimating ? 1 : 0, transition: 'opacity 300ms'
                }}>

                {/* HEADER */}
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>

                    {/* LEFT */}
                    <div className="month-picker-arrow" onClick={() => handleYearChange(-1)}>
                        <LeftIcon color={theme.foregroundColor} width="22" height="22" />
                    </div>

                    {/* YEAR */}
                    <select
                        className="month-picker-select"
                        style={{
                            color: theme.foregroundColor,
                        }}
                        onChange={handleYearSelect}
                        value={selectedYear}>
                        {[...Array(endYear - startYear + 1)].map((_, i) => {
                            const year = startYear + i;
                            return (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            );
                        })}
                    </select>

                    {/* RIGHT */}
                    <div className="month-picker-arrow" onClick={() => handleYearChange(1)}>
                        <RightIcon color={theme.foregroundColor} width="22" height="22" />
                    </div>

                </div>

                {/* MONTHS */}
                <div className="month-picker-months">
                    {months.map((month, index) => (
                        <div
                            key={index}
                            className="month-picker-month"
                            onClick={() => handleMonthClick(index)}>
                            {month}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default MonthPicker;
