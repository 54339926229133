import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { collections } from '../../firebaseConfig';

// Activity
import { activity } from '../../common/managers/ActivityManager';

// Managers
import DataManager from '../../common/managers/DataManager';
const dataManager = new DataManager();

/**
 * Custom hook to load user groups for a specific app.
 */
const usePreloadUserGroups = () => {

  // Global
  const { 
    selectedApp, 
    setUserGroups 
  } = useContext(Global);

  useEffect(() => {
    // Exit early if no selected app or app key
    if (!selectedApp || !selectedApp.key) {
      setUserGroups([]);
      return;
    }

    // Function to load user groups
    const loadUserGroups = (appKey) => {
      const handleUpdate = (items) => {
        setUserGroups(items);
        activity.log(appKey, 'reads', items.length);
      };

      const unsubscribe = dataManager.listAndSubscribe(
        appKey,
        collections.usergroups,
        handleUpdate,
      );

      return unsubscribe;
    };

    // Subscribe to user groups
    const unsubscribeUserGroups = loadUserGroups(selectedApp.key);

    // Cleanup when component unmounts or when dependencies change
    return () => {
      if (unsubscribeUserGroups) {
        unsubscribeUserGroups();
      }
    };
  }, [selectedApp, setUserGroups]);
  
};

export default usePreloadUserGroups;
