import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom'; // Import ReactDOM for createPortal

// Global
import { Global } from '../../../Global';

// Styles
import './Modal.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import CloseIcon from '../../svg/CloseIcon';

/**
 * Convert hex color to RGBA with the provided opacity.
 * 
 * @param {string} hex - The hex color string.
 * @param {number} opacity - The opacity value (0 to 1).
 * 
 * @returns {string} The rgba color string.
 */
const hexToRgba = (hex, opacity) => {
  let r = 0, g = 0, b = 0;

  // Check for 3-digit hex color
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  }
  // Check for 6-digit hex color
  else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

/**
 * Modal Component
 * 
 * This component renders a modal dialog.
 * 
 * @param {string} title - The title of the modal.
 * @param {boolean} isOpen - Whether the modal is open.
 * @param {function} onClose - The function to call when the modal is closed.
 * @param {JSX.Element} children - The children to render.
 * @param {string} width - The width of the modal.
 * @param {string} height - The height of the modal.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Modal = ({ title, isOpen, onClose, children, width, height }) => {

  // Theme
  const { currentTheme, theme } = useTheme();

  // Global
  const { selectedApp, studio } = useContext(Global);

  // State Variables
  const [isAnimating, setIsAnimating] = useState(false);

  // Fade
  useEffect(() => {
    if (isOpen) {
      // Trigger the animation
      setIsAnimating(true);
    } else {
      setIsAnimating(false);
    }
  }, [isOpen]);

  /**
   * Create a style object that includes the width and height if provided.
   */
  const modalStyle = {
    ...selectedApp ?
      ({ backgroundColor: theme.backgroundColor }) :
      ({ backgroundColor: studio && studio.backgroundColorFaded }),
    ...width && { width: width },
    ...height && { height: height },
    opacity: isAnimating ? 1 : 0, transition: 'opacity 300ms'
  };

  /**
   * Modal overlay style to apply background color with opacity.
   */
  const overlayStyle = {
    backgroundColor: hexToRgba(currentTheme === "DARK" ? "black" : "white", 0.8)  // 80% opacity
  };

  // If modal is not open and not animating, return null to render nothing
  if (!isOpen && !isAnimating) return null;

  // Define the modal content with overlay as a portal
  const modalContent = (
    <>
      {/* OVERLAY */}
      <div className="modal-overlay" style={overlayStyle} onMouseDown={onClose}>

        {/* CONTENT */}
        <div
          className="modal-content"
          style={modalStyle}
          onMouseDown={e => e.stopPropagation()}
          onClick={e => e.stopPropagation()}>

          {/* HEADER */}
          <div className="modal-header">

            {/* TITLE */}
            <div className="modal-title"
              style={{
                color: selectedApp ? theme.foregroundColor : studio.foregroundColor
              }}>
              {title}
            </div>

            {/* CLOSE BUTTON */}
            <div className="modal-close-button" onClick={onClose}>
              <CloseIcon
                color={selectedApp ?
                  theme.foregroundColor :
                  studio.foregroundColor
                }
                width="16"
                height="16"
              />
            </div>

          </div>

          {/* BODY */}
          <div className="modal-body">
            {children}
          </div>
        </div>

      </div>
    </>
  );

  // Use createPortal to render modalContent outside of the root hierarchy
  return ReactDOM.createPortal(modalContent, document.getElementById('portal-root'));
};

export default Modal;
