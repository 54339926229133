import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Firebase
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../firebaseConfig';

// Activity
import { activity } from '../../../managers/ActivityManager';

// Styles
import './CalendarItemMenu.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import DotsVerticalIcon from '../../../svg/DotsVerticalIcon';

// Images
import DeleteIcon from '../../../svg/DeleteIcon';
import DownIcon from '../../../svg/DownIcon';
import RenameIcon from '../../../svg/RenameIcon';
import UpIcon from '../../../svg/UpIcon';

// Components
import CalendarDelete from '../calendardelete/CalendarDelete';
import CalendarEdit from '../calendaredit/CalendarEdit';
import ContextMenuButton from '../../../components/contextmenu/ContextMenuButton';
import ContextMenuGroup from '../../../components/contextmenu/ContextMenuGroup';
import ContextMenuOption from '../../../components/contextmenu/ContextMenuOption';
import ContextMenuPopup from '../../../components/contextmenu/ContextMenuPopup';

const CalendarItemMenu = ({ calendar }) => {
    const { theme } = useTheme();
    const {
        calendars,
        hideProgress,
        selectedApp,
        selectedCalendar,
        showProgress
    } = useContext(Global);

    // State Variables
    const [menuVisible, setMenuVisible] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const [editOpen, setEditOpen] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);

    const toggleMenu = (event) => {
        if (!menuVisible) {
            event.preventDefault();
            setMenuPosition({ x: event.clientX, y: event.clientY });
            setMenuVisible(true);
        } else {
            setMenuVisible(false);
        }
    };

    const handleDeleteClick = (event) => {
        event.stopPropagation();
        setMenuVisible(false);

        setConfirmOpen(true);
    };

    const handleMoveUpClick = async (event) => {
        event.stopPropagation();
        setMenuVisible(false);

        sort('up');
    };

    const handleMoveDownClick = (event) => {
        event.stopPropagation();
        setMenuVisible(false);

        sort('down');
    };

    const handleEditClick = (event) => {
        event.stopPropagation();
        setMenuVisible(false);
        setEditOpen(true);
    };

    const sort = async (direction) => {
        showProgress("Moving...");
        const currentIndex = calendars.findIndex(item => item.key === calendar.key);
        const newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;

        if (newIndex >= 0 && newIndex < calendars.length) {
            const targetItem = calendars[newIndex];
            const currentSort = calendar.sort;
            calendar.sort = targetItem.sort;
            targetItem.sort = currentSort;

            try {
                await updateDoc(doc(db, calendars.calendars, calendar.key), { sort: calendar.sort });
                await updateDoc(doc(db, calendars.calendars, targetItem.key), { sort: targetItem.sort });

                activity.log(selectedApp.key, 'writes', 2);
            } catch (error) {
                console.error('Error updating sort order:', error);
            }
        }

        hideProgress();
    };

    return (
        <div className="calendar-item-menu-outer-container">

            {/* MENU BUTTON */}
            <div className="calendar-item-menu-container">
                <ContextMenuButton 
                    title="Calendar Options" 
                    icon={DotsVerticalIcon} 
                    iconSize={11}
                    onToggle={toggleMenu} 
                    dotsColor={selectedCalendar && selectedCalendar.key === calendar.key ? theme.highlightForegroundColor : theme.foregroundColorFaded}
                />
            </div>

            {/* MENU */}
            <ContextMenuPopup
                visible={menuVisible}
                setVisible={setMenuVisible}
                backgroundColor={theme.backgroundColorFaded}
                width={170}
                position={menuPosition}>

                {/* BASE OPTIONS */}
                <ContextMenuGroup title={calendar ? calendar.title : ""}>

                    <ContextMenuOption
                        icon={RenameIcon}
                        text="Edit"
                        onClick={handleEditClick}
                    />

                    <ContextMenuOption
                        icon={UpIcon}
                        text="Move Up"
                        onClick={handleMoveUpClick}
                    />

                    <ContextMenuOption
                        icon={DownIcon}
                        text="Move Down"
                        onClick={handleMoveDownClick}
                    />

                    <ContextMenuOption
                        icon={DeleteIcon}
                        text="Delete"
                        onClick={handleDeleteClick}
                    />

                </ContextMenuGroup>

            </ContextMenuPopup>

           <CalendarDelete
                calendar={calendar}
                isOpen={confirmOpen}
                setOpen={setConfirmOpen}
            />

            {/* CALENDAR EDIT */}
            <CalendarEdit 
                calendar={calendar}
                isOpen={editOpen}
                setOpen={setEditOpen}
            />

        </div>
    );
};

export default CalendarItemMenu;
