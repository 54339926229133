import React, { useContext } from 'react';

// Global
import { Global } from '../../../../Global';

// Formatter
import { formatDaySpan } from '../../../utilities/Formatters';

// Styles
import './CalendarMonthDayEvent.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const CalendarMonthDayEvent = ({ event, style }) => {
    // Theme
    const { theme } = useTheme();

    // Global
    const {
        setEventVisible,
        setSelectedEvent,
    } = useContext(Global);

    // Handle event click
    const handleEventClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setSelectedEvent(event);
        setEventVisible(true);
    };

    return (
        <div
            className="calendar-month-event-container"
            style={{
                ...style,
                backgroundColor: theme.highlightBackgroundColor,
                color: theme.highlightForegroundColor,
            }}
            onClick={(e) => handleEventClick(e)}>
            <div className="calendar-month-event-title"
                style={{
                    color: theme.highlightForegroundColor,
                }}>
                {event.title}
            </div>
            <div className="calendar-month-event-date"
                style={{
                color: theme.highlightForegroundColor,
                }}>
                &nbsp;-&nbsp;{formatDaySpan(event.startDate.toDate(), event.endDate.toDate())}
            </div>
        </div>
    );
};

export default CalendarMonthDayEvent;
