import React, { useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Firebase
import { Timestamp } from 'firebase/firestore';

// Utilities
import { formatDate } from '../../../../common/utilities/Formatters';

// Styles
import './DateField.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import DatePicker from '../../../components/datepicker/DatePicker';
import FieldContainer from '../../FieldContainer';
import FieldHeader from '../../FieldHeader';

const DateField = ({
    object,
    field,
    onUpdate,
    showFieldMenu = true,
    readOnly = false
}) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        selectedApp
    } = useContext(Global);

    // State Variables
    const [inputValue, setInputValue] = useState('');
    const [popupVisible, setPopupVisible] = useState(false);
    const inputRef = useRef(null);
    const [selectedDate, setSelectedDate] = useState(null);

    useEffect(() => {
        if (object && object[field.key]) {
            const date = object[field.key].toDate();
            setInputValue(formatDate(date));
            setSelectedDate(date);
        } else {
            setInputValue('');
            setSelectedDate(null);
        }
    }, [object, field.key]);

    const togglePopup = () => {
        if (readOnly) return;
        setPopupVisible(!popupVisible);
    };

    const handleSelect = (date) => {
        if (date) {
            setSelectedDate(date);
            const formattedDate = formatDate(date);
            setInputValue(formattedDate);
            const timestamp = Timestamp.fromDate(date);
            onUpdate(field, timestamp);
        } else {
            setSelectedDate(null);
            setInputValue('');
            onUpdate(field, '');
        }
        setPopupVisible(false);
    };

    return (

        <div className="date-field-outer-container">

            {/* CONTAINER */}
            <FieldContainer
                field={field}
                readOnly={readOnly}
                onClick={togglePopup}>

                {/* HEADER */}
                <FieldHeader
                    field={field}
                    readOnly={readOnly}
                    showFieldMenu={showFieldMenu}
                />

                {/* INPUT */}
                <input
                    type="text"
                    className="date-field-input"
                    style={{
                        color: theme.foregroundColor,
                        fontFamily: selectedApp.fontFamily,
                    }}
                    value={inputValue}
                    onClick={togglePopup}
                    readOnly
                    ref={inputRef}
                />

            </FieldContainer>

            {/* DATE PICKER POPUP */}
            <DatePicker
                isOpen={popupVisible}
                onClose={() => setPopupVisible(false)}
                selectedDate={selectedDate}
                onDateSelect={handleSelect}
            />
        </div>

    );
};

export default DateField;
