import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './ObjectsSearchButton.css';

// Theme
import { useStyle, useTheme } from '../../../ThemeContext';

// Images
import SearchIcon from '../../../common/svg/SearchIcon';

// Inline Styles
const InlineStyles = useStyle`
    .collection-search-container:hover {
        background-color: ${(props) => props.hoverColor} !important;
        border-color: ${(props) => props.hoverColor} !important;
    }
`;

/**
 * ObjectsSearchButton Component
 * 
 * This component renders a collection item in the menu.
 * 
 * @param {object} collection - The collection to represent in the menu.
 * @param {boolean} isActive - Indicates if the item has been selected.
 * @param {function} onClick - The function to call on a click.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ObjectsSearchButton = ({ onClick }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        selectedModel,
    } = useContext(Global);

    return (
        <>
            {/* STYLES */}
            <InlineStyles hoverColor={theme.backgroundColorFaded} />

            {/* CONTAINER */}
            <div
                className="objects-search-button-container">
                <div className="objects-search-button-left"
                    title={`Search ${selectedModel.title}`}
                    onClick={onClick}>
                    <SearchIcon
                        color={theme.foregroundColorFaded}
                        width="18"
                        height="18"
                    />
                </div>
            </div>
        </>
    );
};

export default ObjectsSearchButton;
