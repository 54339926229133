import React from 'react';

// Styles
import './ModalButtons.css';

/**
 * ModalButtons Component
 * 
 * This component renders a section container for modal buttons.
 * 
 * @param {JSX.Element} children - The children to render.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ModalButtons = ({ children }) => {

  return (
      <div className="modal-buttons-container">
        {children}
      </div>
  );
};

export default ModalButtons;
