// Firebase
import { collection, writeBatch, doc, getDoc, getDocs, onSnapshot, orderBy, query, setDoc, Timestamp, updateDoc, where } from 'firebase/firestore';
import { collections, db } from '../../firebaseConfig';

// Managers
import { activity } from './ActivityManager';

class CalendarManager {

    /**
     * Method to add a new calendar.
     * 
     * @param {string} key - App key.
     * @param {string} data - Calendar data.
     * @param {string} title - Title for the calendar.
     * @returns {calendar} - New calendar.
    */
    async add(appKey, key, data) {

        await setDoc(doc(db, collections.calendars, key), data);

        activity.log(appKey, 'writes', 1);

        return data;
    }

    /**
     * Deletes a calendar from the Firestore database.
     * 
     * @param {string} appKey - App key.
     * @param {string} calendarKey - The key of the calendar to delete.
     */
    async delete(appKey, calendarKey) {
        try {
            // Start a batch
            const batch = writeBatch(db);

            // Fetch the calendar to get the calendarKey
            const calendarRef = doc(db, collections.calendars, calendarKey);
            const calendarSnap = await getDoc(calendarRef);

            activity.log(appKey, 'reads', 1);

            if (!calendarSnap.exists()) {
                console.error("Calendar does not exist");
                return;
            }

            // Delete the selected calendar
            batch.delete(calendarRef);

            activity.log(appKey, 'deletes', 1);

            // Commit the batch
            await batch.commit();

        } catch (error) {
            console.error('Error deleting calendar:', error);
            // Return an error message
            return { success: false, message: "Error deleting calendar." };
        }
    }
    
}

export default CalendarManager;
