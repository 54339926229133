import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Utilities
import { UserPreference } from '../../utilities/Enums';

// Styles
import './Calendars.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import CalendarAddButton from './calendaraddbutton/CalendarAddButton';
import CalendarAdd from './calendaradd/CalendarAdd';
import CalendarItem from './calendaritem/CalendarItem';

// Managers
import UserManager from '../../managers/UserManager';

const userManager = new UserManager();

/**
 * Calendars Component
 * 
 * This component renders a calendar-related menu items in the menu.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Calendars = () => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        calendars,
        currentUser,
        resetVisibility,
        selectedApp,
        selectedCalendar,
        setCalendarAddOpen,
        setCalendarVisible,
        setCurrentUser,
        setSelectedCalendar,
        setSelectedCalendarType,
        setSelectedChannel,
        setSelectedModel,
    } = useContext(Global);

    /**
     * Handles a calendar click event.
     * 
     * @param {object} calendar - Calendar that was clicked on.
     */
    const handleCalendarClick = async (calendar) => {
        resetVisibility();
        setCalendarVisible(true);
        setSelectedCalendar(calendar);

        // Set the last page preference
        const page = {
            section: 'CALENDARS',
            calendarKey: calendar.key,
        }

        await userManager.setPreference(
            currentUser,
            setCurrentUser,
            selectedApp.key,
            UserPreference.LAST_PAGE,
            page
        );

        // Retrieve the saved LAST_CALENDAR_TYPE preference before displaying it.
        const lastCalendarType = userManager.getPreference(
            currentUser,
            selectedApp.key,
            UserPreference.LAST_CALENDAR_TYPE
        );

        if (lastCalendarType) {
            setSelectedCalendarType(lastCalendarType);
        }

    };

    /**
     * Handles an add calendar button click event.
     */
    const handleAddCalendarClick = () => {
        setSelectedChannel(null);
        setSelectedModel(null);
        setCalendarAddOpen(true);
    };

    return (
        <>
            {/* TITLE */}
            <div className="calendars-group-title"
                style={{
                    color: theme.foregroundColorFaded,
                    fontFamily: selectedApp.fontFamily
                }}>
                Calendars
            </div>

            {/* COLLECTION LIST */}
            <div className="calendars-group">

                {/* COLLECTIONS */}
                {calendars.map(calendar => (
                    <CalendarItem
                        key={calendar.key}
                        calendar={calendar}
                        title={calendar.title}
                        isActive={selectedCalendar && selectedCalendar.key === calendar.key}
                        onClick={() => handleCalendarClick(calendar)} />
                ))}

                {/* ADD COLLECTION */}
                <CalendarAddButton
                    onClick={handleAddCalendarClick}
                />

                {/* FORM */}
                <CalendarAdd />

            </div>
        </>
    );
};

export default Calendars;
