import React from 'react';

// Styles
import './UserPhotoSummaryPreview.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import UserDot from '../../../../common/components/userdot/UserDot';

const UserPhotoSummaryPreview = ({ element }) => {
    const { getProcessedStyles } = useTheme();

    const { styles = {} } = element;

    // Filter out top, left, width, height, and zIndex
    const { borderRadius, top, left, width, height, zIndex, ...filteredStyles } = styles;

    const user = {
        username: 'Kevin'
    };

    return (
        <div className="user-photo-summary-preview-container" style={getProcessedStyles(filteredStyles)}>
            <UserDot 
                user={user} 
                size="24"
            />
        </div>
    );
};

export default UserPhotoSummaryPreview;
