import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Device Detections
import { isMobile } from 'react-device-detect';

// Utilities
import { FormMode } from '../../utilities/Enums';

// Styles
import './NavBar.css';

// Images
import BookmarkIcon from '../../../common/svg/BookmarkIcon';
import FavoriteIcon from '../../../common/svg/FavoriteIcon';
import HomeIcon from '../../../common/svg/HomeIcon';
import MessagesIcon from '../../../common/svg/MessagesIcon';
import SettingsIcon from '../../../common/svg/SettingsIcon';
import UsersIcon from '../../../common/svg/UsersIcon';

// Components
import NavButton from './NavButton';

/**
 * NavBar Component
 * 
 * This component renders a nav button bar.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const NavBar = () => {

  // Global
  const {
    bookmarks,
    bookmarksVisible,
    favorites,
    favoritesVisible,
    messagesVisible,
    resetVisibility,
    runHomeVisible,
    setAppUsersVisible,
    setBookmarksVisible,
    setFavoritesVisible,
    setFormMode,
    setMenuVisible,
    setMessagesVisible,
    setRunHomeVisible,
    setSettingsVisible,
    appUsersVisible,
  } = useContext(Global);

  const handleItemClick = (visibilitySetter) => {
    resetVisibility();
    // Show the add form on the desktop
    if (!isMobile) {
      setFormMode(FormMode.ADD);
    }
    visibilitySetter(true);
  };

  const handleSettingsClick = () => {
    resetVisibility();
    setMenuVisible(false);
    setSettingsVisible(true);
  };

  return (
    <>
      <div className="nav-bar-container">

        <NavButton icon={HomeIcon} title="Home" size={isMobile ? 22 : 17} selected={runHomeVisible} onClick={() => handleItemClick(setRunHomeVisible)} />
        {bookmarks.length > 0 &&
          <NavButton icon={BookmarkIcon} title="Bookmarks" size={isMobile ? 20 : 15} selected={bookmarksVisible} onClick={() => handleItemClick(setBookmarksVisible)} />
        }
        {favorites.length > 0 &&
          <NavButton icon={FavoriteIcon} title="Favorites" size={isMobile ? 23 : 19} selected={favoritesVisible} onClick={() => handleItemClick(setFavoritesVisible)} />
        }
        <NavButton icon={MessagesIcon} title="Messages" size={isMobile ? 23 : 18} selected={messagesVisible} onClick={() => handleItemClick(setMessagesVisible)} />
        <NavButton icon={UsersIcon} title="App Members" size={isMobile ? 22 : 17} selected={appUsersVisible} onClick={() => handleItemClick(setAppUsersVisible)} />
        <NavButton icon={SettingsIcon} title="Settings" size={isMobile ? 21 : 17} onClick={handleSettingsClick} />

      </div>
    </>
  );
};

export default NavBar;

