import React from 'react';

const AlignLeftIcon = ({ color = "white", width = "24", height = "24" }) => (
    <svg
        version="1.1"
        width={width}
        height={height}
        viewBox="0 0 24 24">
        <path fill={color} fillRule="evenodd" d="M4,21 C4,21.5522847 3.55228475,22 3,22 C2.44771525,22 2,21.5522847 2,21 L2,3 C2,2.44771525 2.44771525,2 3,2 C3.55228475,2 4,2.44771525 4,3 L4,21 Z M6,12 L6.004,11.918 L6.02024007,11.7992742 L6.04973809,11.6878575 L6.09367336,11.5767785 L6.14599545,11.4792912 L6.19631351,11.4048407 L6.29289322,11.2928932 L10.2928932,7.29289322 C10.6834175,6.90236893 11.3165825,6.90236893 11.7071068,7.29289322 C12.0675907,7.65337718 12.0953203,8.22060824 11.7902954,8.61289944 L11.7071068,8.70710678 L9.415,11 L21,11 C21.5522847,11 22,11.4477153 22,12 C22,12.5522847 21.5522847,13 21,13 L9.415,13 L11.7071068,15.2928932 C12.0675907,15.6533772 12.0953203,16.2206082 11.7902954,16.6128994 L11.7071068,16.7071068 C11.3466228,17.0675907 10.7793918,17.0953203 10.3871006,16.7902954 L10.2928932,16.7071068 L6.29289322,12.7071068 L6.2514958,12.663147 L6.19633458,12.5953066 L6.12467117,12.4840621 L6.09365378,12.4230991 L6.05988586,12.3416504 L6.03584514,12.265993 L6.00683422,12.1174742 L6,12 Z" />
    </svg>
);

export default AlignLeftIcon;
