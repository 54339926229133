import React, { useContext, useEffect } from 'react';

// Global
import { Global } from '../../Global';

// Utilities
import { MenuType } from '../../common/utilities/Enums';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './Menu.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import AppBar from '../components/appbar/AppBar';
import MenuHeader from './MenuHeader';
import MenuItems from './MenuItems';

const Menu = () => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
      menuType,
      setMenuType
    } = useContext(Global);

    return (
        <div className="menu-container"
            style={{
                borderRightColor: theme.backgroundColorFaded,
                height: isMobile ? 'calc(100vh - 56px)' : '100%',
            }}>

            {/* APP BAR */}
            {isMobile &&
                <AppBar />
            }

            <div className="menu-right">

                {/* HEADER */}
                {(!isMobile && menuType === MenuType.LEFT) &&
                    <MenuHeader />
                }

                {/* MENU ITEMS */}
                <MenuItems />

            </div>

        </div>
    );
};

export default Menu;
