import React, { useState, useRef, useEffect } from 'react';

// ReactDOM
import ReactDOM from 'react-dom';

// Utilities
import { Section } from '../utilities/Enums';

// Styles
import './MenuBarButton.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Images
import CalendarIcon from '../../common/svg/CalendarIcon';
import ChevronDownIcon from '../../common/svg/ChevronDownIcon';
import CubeIcon from '../../common/svg/CubeIcon';
import HashtagIcon from '../../common/svg/HashtagIcon';
import ForumIcon from '../../common/svg/ForumIcon';
import VideoIcon from '../../common/svg/VideoIcon';

/**
 * MenuBarButton Component
 * 
 * This component represents a button with a dropdown menu, allowing the user to select
 * from a list of options. 
 * 
 * @returns {JSX.Element} The rendered component
 */
const MenuBarButton = ({ section, title, options, onSelect }) => {

    // Theme
    const { theme } = useTheme();

    // State Variables
    const [optionsVisible, setOptionsVisible] = useState(false);
    const [hoveredOption, setHoveredOption] = useState(null); // Track which option is hovered

    // References
    const optionsRef = useRef(null);
    const buttonRef = useRef(null);

    /**
     * Toggles the visibility of the options dropdown
     */
    const handleClick = () => {
        setOptionsVisible(!optionsVisible);
    };

    /**
     * Determines the appropriate icon based on the section and option type
     * 
     * @param {object} option - The option item to get the icon for
     * @returns {JSX.Element|null} The icon component or null if no match
     */
    const getChannelIcon = (option) => {
        if (section === Section.CALENDARS) {
            return CalendarIcon;
        } else if (section === Section.CHANNELS) {
            if (option.type === 'TEXT') return HashtagIcon;
            if (option.type === 'FORUM') return ForumIcon;
            if (option.type === 'VIDEO') return VideoIcon;
        } else if (section === Section.COLLECTIONS) {
            return CubeIcon;
        }
        return null;
    };

    /**
     * Closes the dropdown if clicking outside the dropdown or button area
     */
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                optionsRef.current && !optionsRef.current.contains(event.target) &&
                buttonRef.current && !buttonRef.current.contains(event.target)
            ) {
                setOptionsVisible(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    /**
     * Renders dropdown options, with hover and click functionality
     */
    const dropDownOptions = (
        <div
            ref={optionsRef}
            className="menu-bar-button-options"
            style={{
                backgroundColor: theme.backgroundColorFaded,
                position: 'absolute',
                left: buttonRef.current ? buttonRef.current.getBoundingClientRect().left : 0,
                top: buttonRef.current ? buttonRef.current.getBoundingClientRect().bottom : 0
            }}>
            {options.map((option, index) => {
                const IconComponent = getChannelIcon(option);
                return (
                    <React.Fragment key={index}>
                        <div
                            className="menu-bar-button-option"
                            onClick={() => {
                                onSelect(option.key);
                                setOptionsVisible(false);
                            }}
                            onMouseEnter={() => setHoveredOption(index)}  // Set hover index
                            onMouseLeave={() => setHoveredOption(null)}   // Reset hover
                            style={{
                                borderColor: theme.backgroundColorFaded,
                                color: theme.foregroundColor,
                                backgroundColor: hoveredOption === index ? theme.highlightBackgroundColor : 'transparent'
                            }}>
                            <div className="menu-bar-button-option-icon">
                                {IconComponent && (
                                    <IconComponent
                                        color={hoveredOption === index ? theme.highlightForegroundColor : theme.foregroundColorFaded}
                                        width="13"
                                        height="13"
                                    />
                                )}
                            </div>
                            <div className="menu-bar-button-option-item">
                                <div
                                    className="menu-bar-button-option-title"
                                    style={{
                                        color: hoveredOption === index ? theme.highlightForegroundColor : theme.foregroundColor
                                    }}>
                                    {option.title}
                                </div>
                                <div className="menu-bar-button-option-description"
                                    style={{
                                        color: hoveredOption === index ? theme.highlightForegroundColor : theme.foregroundColorFaded
                                    }}>
                                    {option.description}
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                );
            })}
        </div>
    );

    return (
        <div ref={buttonRef} 
            className="menu-bar-button-container" 
            onClick={handleClick}>
            <div 
                className="menu-bar-button-text" 
                style={{ 
                    color: theme.foregroundColor 
                }}>
                {title}
            </div>
            <div className="menu-bar-button-icon">
                <ChevronDownIcon 
                    color={theme.foregroundColorFaded} 
                    width="14" 
                    height="14" 
                />
            </div>
            {optionsVisible &&
                ReactDOM.createPortal(
                    dropDownOptions,
                    document.getElementById('portal-root')
                )
            }
        </div>
    );
};

export default MenuBarButton;
