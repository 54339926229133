import React from 'react';

// Config
import { validSummaryTextTypes } from '../../Config';

// Styles
import './ObjectBlock.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import ButtonSummary from './elements/ButtonSummary';
import CreatedSummary from './elements/CreatedSummary';
import GallerySummary from './elements/GallerySummary';
import LookupSummary from './elements/LookupSummary';
import TagSummary from './elements/TagSummary';
import TextSummary from './elements/TextSummary';
import UserNameSummary from './elements/UserNameSummary';
import UserPhotoSummary from './elements/UserPhotoSummary';
import VideoGallerySummary from './elements/VideoGallerySummary';
import ViewCountSummary from './elements/ViewCountSummary';

const ObjectBlock = ({ object, block }) => {

    // Theme
    const { theme } = useTheme();

    return (
        <div
            className="object-block-container"
            style={{
                justifyContent: block.align, // Use justify-content for alignment since flex is used
            }}>
            {block.content.length > 0
                ? block.content.map((modelElement) => (
                    <div key={modelElement.key}
                        className="object-block-element"
                        style={{
                            color: theme.foregroundColor,
                            justifyContent: 'center',
                        }}>

                            {/* TEXT */}
                            {(modelElement.type === "field" &&
                                validSummaryTextTypes.includes(modelElement.field.type)) &&
                                modelElement.field.type !== 'lookup' &&
                                <TextSummary element={modelElement} object={object} />
                            }

                            {/* LOOKUP */}
                            {(modelElement.type === "field" && modelElement.field.type === 'lookup') &&
                                <LookupSummary element={modelElement} object={object} />
                            }

                            {/* CREATED */}
                            {(modelElement.type === "static" && modelElement.elementType === 'created') &&
                                <CreatedSummary element={modelElement} object={object} />
                            }

                            {/* PHOTOS */}
                            {(modelElement.type === "field" && modelElement.field.type === 'gallery') &&
                                <GallerySummary element={modelElement} object={object} />
                            }

                            {/* VIDEOS */}
                            {(modelElement.type === "field" && modelElement.field.type === 'videogallery') &&
                                <VideoGallerySummary element={modelElement} object={object} />
                            }

                            {/* BUTTONS */}
                            {modelElement.type === "button" &&
                                <ButtonSummary element={modelElement} object={object} />
                            }

                            {/* USER PHOTO */}
                            {(modelElement.type === "static" && modelElement.elementType === 'userphoto') &&
                                <UserPhotoSummary element={modelElement} object={object} />
                            }

                            {/* USER NAME */}
                            {(modelElement.type === "static" && modelElement.elementType === 'username') &&
                                <UserNameSummary element={modelElement} object={object} />
                            }

                            {/* VIEW COUNT */}
                            {(modelElement.type === "static" && modelElement.elementType === 'viewcount') &&
                                <ViewCountSummary element={modelElement} object={object} />
                            }

                            {/* TAGS */}
                            {(modelElement.type === "static" && modelElement.elementType === 'tags') &&
                                <TagSummary element={modelElement} object={object} />
                            }

                    </div>
                )
                ) : (
                    <div className="object-block-empty"
                        style={{
                            color: theme.backgroundColorFaded,
                        }}>
                        DROP ITEMS HERE
                    </div>
                )
            }
        </div>
    );
};

export default ObjectBlock;

