import React from 'react';

// Styles
import './FieldContainer.css';

// Theme
import { useStyle, useTheme } from '../../ThemeContext';

// Styled Components
const InlineStyles = useStyle`
    .field-container:hover {
      border-color: ${(props) => props.hoverBorderColor} !important;
    }
`;

const FieldContainer = ({ readOnly, onClick, children }) => {
  const { theme } = useTheme();

  return (
    <>
      <InlineStyles
        hoverBorderColor={theme.highlightBackgroundColor}
      />
      <div
        className={readOnly ? "field-container-read-only" : "field-container"}
        style={{ borderColor: readOnly ? 'transparent' : theme.backgroundColorFaded }}
        onClick={onClick ? onClick : undefined}
      >
        {children}
      </div>
    </>
  );
};

export default FieldContainer;
