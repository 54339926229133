import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Firebase
import { collections } from '../../../../firebaseConfig';

// Styles
import './UserGroupEdit.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import Modal from '../../../components/modal/Modal';
import ModalButton from '../../../components/modal/ModalButton';
import ModalButtons from '../../../components/modal/ModalButtons';
import ModalInput from '../../../components/modal/ModalInput';
import ModalLabel from '../../../components/modal/ModalLabel';
import ModalSection from '../../../components/modal/ModalSection';
import ModalTextarea from '../../../components/modal/ModalTextarea';

// Managers
import DataManager from '../../../managers/DataManager';

const dataManager = new DataManager();

/**
 * UserGroupEdit Component
 * 
 * This component allows the user to edit a collection.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const UserGroupEdit = ({ userGroup, isOpen, setOpen }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        selectedApp,
    } = useContext(Global);

    // State Variables
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    // Load the user group attributes
    useEffect(() => {
        if (!userGroup) return;
        setTitle(userGroup.title);
        setDescription(userGroup.description || '');
    }, [userGroup]);

    const handleSave = async () => {

        const data = {
            title: title,
            description: description,
        };

        await dataManager.update(selectedApp.key, collections.usergroups, userGroup.key, data);

        setOpen(false);
    };

    if (!userGroup) return null;

    return (
        <>
            {/* MODAL */}
            <Modal
                title="Edit User Group"
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                width="330px"
                height="auto">

                {/* NAME */}
                <ModalSection>

                    <ModalLabel text="Name" />

                    <ModalInput
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        maxLength="100"
                    />

                </ModalSection>

                {/* DESCRIPTION */}
                <ModalSection>

                    <ModalLabel text="Description" />

                    <ModalTextarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        maxLength="255"
                        lines="2"
                    />

                </ModalSection>

                {/* BUTTONS */}
                <ModalButtons>

                    {/* SAVE */}
                    <ModalButton
                        onClick={handleSave}
                        label="Save"
                    />

                </ModalButtons>

            </Modal>
        </>
    );
};

export default UserGroupEdit;
