import React from 'react';

const DockDownIcon = ({ color = "white", width = "21", height = "17" }) => (
    <svg
        version="1.1"
        width={width}
        height={height}
        viewBox="0 0 21 17">
        <path d="M17.227 9.999H12.771C12.581 9.99894 12.3981 9.92676 12.2592 9.79703C12.1204 9.6673 12.0359 9.4897 12.023 9.30012C12.01 9.11054 12.0695 8.92311 12.1894 8.7757C12.3093 8.6283 12.4807 8.5319 12.669 8.506L12.771 8.499H17.227C17.899 8.499 18.43 8.049 18.494 7.498L18.5 7.386L18.521 2.61C18.521 2.049 18.031 1.564 17.381 1.506L17.248 1.5H2.794C2.122 1.5 1.591 1.95 1.527 2.501L1.521 2.613L1.501 7.389C1.501 7.95 1.989 8.435 2.641 8.493L2.773 8.499H7.271C7.46093 8.49931 7.64368 8.57167 7.78234 8.70148C7.921 8.83129 8.00524 9.00887 8.01806 9.19838C8.03088 9.38788 7.97132 9.5752 7.85141 9.7225C7.7315 9.8698 7.56016 9.96611 7.372 9.992L7.271 9.999H2.773C1.313 9.999 0.0969967 8.933 0.0059967 7.553L-3.8147e-06 7.386L0.0209961 2.61C0.0209961 1.21 1.181 0.090003 2.62 0.00500298L2.794 1.90735e-06H17.248C18.708 1.90735e-06 19.924 1.067 20.015 2.446L20.021 2.613L20.001 7.389C20.001 8.789 18.841 9.91 17.401 9.994L17.227 9.999ZM9.408 16.79L9.314 16.707L5.591 12.984C5.41165 12.804 5.30752 12.5626 5.29977 12.3086C5.29201 12.0547 5.38121 11.8073 5.54925 11.6167C5.71728 11.4262 5.95155 11.3067 6.20447 11.2826C6.45739 11.2585 6.71 11.3316 6.911 11.487L7.005 11.57L9.022 13.586L9.021 4.996C9.02103 4.75107 9.11095 4.51466 9.27371 4.33163C9.43647 4.14859 9.66075 4.03166 9.904 4.003L10.021 3.996C10.2659 3.99603 10.5023 4.08596 10.6854 4.24872C10.8684 4.41148 10.9853 4.63575 11.014 4.879L11.021 4.996V13.586L13.038 11.57C13.2102 11.3978 13.4393 11.2944 13.6823 11.2791C13.9253 11.2638 14.1656 11.3378 14.358 11.487L14.452 11.57C14.6242 11.7422 14.7276 11.9713 14.7429 12.2143C14.7582 12.4573 14.6842 12.6976 14.535 12.89L14.452 12.984L10.729 16.707C10.5568 16.8792 10.3277 16.9826 10.0847 16.9979C9.84165 17.0132 9.6004 16.9393 9.408 16.79Z"
            fill={color} />
    </svg>
);

export default DockDownIcon;
