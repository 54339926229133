import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { collections } from '../../firebaseConfig';

// Activity
import { activity } from '../../common/managers/ActivityManager';

// Managers
import DataManager from '../../common/managers/DataManager';
const dataManager = new DataManager();

/**
 * Custom hook to load app fields for a specific app.
 */
const usePreloadAppFields = () => {

  // Global
  const { 
    selectedApp, 
    setAppFields 
  } = useContext(Global);

  useEffect(() => {
    // Exit early if no selected app or app key
    if (!selectedApp || !selectedApp.key) {
      setAppFields([]);
      return;
    }

    const sortFields = [
      "sort", // Ascending by default
    ];

    // Function to load app fields
    const loadAppFields = (appKey) => {
      const handleUpdate = (items) => {
        setAppFields(items);
        activity.log(appKey, 'reads', items.length);
      };

      const unsubscribe = dataManager.listAndSubscribe(
        appKey,
        collections.fields,
        handleUpdate,
        [],
        sortFields
      );

      return unsubscribe;
    };

    // Subscribe to app fields
    const unsubscribeAppFields = loadAppFields(selectedApp.key);

    // Cleanup when component unmounts or when dependencies change
    return () => {
      if (unsubscribeAppFields) {
        unsubscribeAppFields();
      }
    };
  }, [selectedApp, setAppFields]);

};

export default usePreloadAppFields;
