import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../Global';

// Utilities
import { Page } from '../../common/utilities/Enums';

// Firebase
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from '../../firebaseConfig';

// Managers
import UserManager from '../../common/managers/UserManager';
const userManager = new UserManager();

/**
 * Custom hook to detect a person's login status and route them accordingly.
 */
const useProcessLoginStatus = () => {

  // Global
  const { 
    setCurrentUser,
    setPage
  } = useContext(Global);

  useEffect(() => {
    
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const combinedUser = await userManager.fetchCurrentUser(user);
          if (combinedUser) {
            setCurrentUser(combinedUser);
            setPage(Page.HOME);
          } else {
            setPage(Page.WELCOME);
          }
        } catch (error) {
          setCurrentUser(null);
          setPage(Page.WELCOME);
        }
      } else {
        setCurrentUser(null);
        setPage(Page.WELCOME);
      }
    });

    return () => unsubscribe(); // Cleanup on unmount

  }, [setCurrentUser, setPage]);
  
};

export default useProcessLoginStatus;
