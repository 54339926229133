import React from 'react';

// Styles
import './ModalButton.css';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * ModalButton Component
 * 
 * This component renders a button for a modal dialog.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ModalButton = ({ label, onClick }) => {

  // Theme
  const { theme } = useTheme();

  return (
    <button 
      onClick={onClick} 
      className="modal-button-container"
      style={{
        color: theme.foregroundColor
      }}>
      {label}
    </button>
  );
};

export default ModalButton;
