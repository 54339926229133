import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './CalendarMenu.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Utilities
import { UserPreference } from '../utilities/Enums';

// Images
import AgendaIcon from '../svg/AgendaIcon';
import DayIcon from '../svg/DayIcon';
import GanttIcon from '../svg/GanttIcon';
import MonthIcon from '../svg/MonthIcon';
import StorylineIcon from '../svg/StorylineIcon';
import WeekIcon from '../svg/WeekIcon';

// Components
import ContextMenuButton from '../../common/components/contextmenu/ContextMenuButton';
import ContextMenuOption from '../../common/components/contextmenu/ContextMenuOption';
import ContextMenuPopup from '../../common/components/contextmenu/ContextMenuPopup';

// Managers
import UserManager from '../../common/managers/UserManager';

const userManager = new UserManager();

const CalendarMenu = () => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    currentUser,
    selectedApp,
    setCurrentUser,
    setSelectedCalendarType
  } = useContext(Global);

  const [menuVisible, setMenuVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

  const calendarOptions = [
    { key: 'SCHEDULE', icon: AgendaIcon, text: 'All Events' },
    { key: 'DAY', icon: DayIcon, text: 'Day' },
    { key: 'WEEK', icon: WeekIcon, text: 'Week' },
    { key: 'MONTH', icon: MonthIcon, text: 'Month' },
    { key: 'STORYLINE', icon: StorylineIcon, text: 'Storyline', iconSize: 15 },
    { key: 'GANTT', icon: GanttIcon, text: 'Gantt', iconSize: 17 }
  ];

  /**
   * Toggles visibility of the context menu.
   */
  const toggleMenu = (event) => {
    if (!menuVisible) {
      event.preventDefault();
      setMenuPosition({ x: event.clientX, y: event.clientY });
      setMenuVisible(true);
    } else {
      setMenuVisible(false);
    }
  };

  const handleCalendarSelection = async (key) => {
    setSelectedCalendarType(key);
    setMenuVisible(false);

    // Call the setPreference method to update LAST_CALENDAR_TYPE settings
    await userManager.setPreference(
      currentUser,
      setCurrentUser,
      selectedApp.key,
      UserPreference.LAST_CALENDAR_TYPE,
      key
    );
  };

  return (
    <div className="calendar-menu-outer-container">

      {/* MENU BUTTON */}
      <div className="calendar-menu-container">
        <ContextMenuButton
          title="Calendar Views"
          onToggle={toggleMenu}
        />
      </div>

      {/* MENU */}
      <ContextMenuPopup
        visible={menuVisible}
        setVisible={setMenuVisible}
        backgroundColor={theme.backgroundColorFaded}
        width={140}
        position={menuPosition}
      >
        {calendarOptions.map(({ key, icon, text, iconSize = 16 }) => (
          <ContextMenuOption
            key={key}
            icon={icon}
            text={text}
            onClick={() => handleCalendarSelection(key)}
            iconSize={iconSize}
          />
        ))}
      </ContextMenuPopup>

    </div>
  );
};

export default CalendarMenu;
