import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { doc, updateDoc } from 'firebase/firestore';
import { collections, db, storage } from '../../firebaseConfig';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';

// Activity
import { activity } from '../../common/managers/ActivityManager';

// Styles
import './AccountAboutPhoto.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import UserDot from '../../common/components/userdot/UserDot';

const AccountAboutPhoto = () => {
    const { theme } = useTheme();
    const {
        currentUser,
        hideProgress,
        selectedApp,
        setCurrentUser,
        showProgress,
        studio
    } = useContext(Global);

    const handleFileUpload = async (event) => {
        showProgress("Uploading...");

        const file = event.target.files[0];
        const fileExtension = file.name.split('.').pop(); // Extract the file extension

        const image = document.createElement('img');
        image.onload = async () => {
            // Create canvas
            const maxDimension = 1000;
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const ratio = Math.min(maxDimension / image.width, maxDimension / image.height);
            const width = image.width * ratio;
            const height = image.height * ratio;

            canvas.width = width;
            canvas.height = height;

            // Draw the image scaled down
            ctx.drawImage(image, 0, 0, width, height);

            // Convert canvas to blob
            canvas.toBlob(async (blob) => {

                if (selectedApp) {
                    // Log the size of the blob in bytes
                    activity.log(selectedApp.key, 'uploads', blob.size);
                }

                try {

                    // Upload the new file
                    const storageRef = ref(storage, `users/profile/${currentUser.userKey}.${fileExtension}`); // Use the original extension
                    await uploadBytes(storageRef, blob); // Upload the blob
                    const fileUrl = await getDownloadURL(storageRef); // Get the URL
                    setCurrentUser({ ...currentUser, photo: fileUrl });

                    const data = { photo: fileUrl };
                    await updateDoc(doc(db, collections.users, currentUser.userKey), data);

                    hideProgress();
                    
                } catch (error) {
                    console.error("Error handling user photo upload:", error);
                    hideProgress();
                }
            }, `image/${fileExtension}`); // Specify the correct MIME type
        };

        image.src = URL.createObjectURL(file);
    };

    return (
        <div className="account-about-photo-container"
            style={{
                backgroundColor: selectedApp ? theme.backgroundColor : studio.backgroundColor
            }}
            >
            <label htmlFor="account-about-photo-upload" 
                className="account-about-photo-wrapper">
                {currentUser && currentUser.photo ? (
                    <img
                    src={currentUser.photo}
                    className="account-about-photo"
                    alt="User"
                />
                ) : (

                    <UserDot
                        user={currentUser}
                        size="150"
                    />
                )
                    
                }
            </label>
            <input id="account-about-photo-upload"
                type="file"
                onChange={handleFileUpload}
                style={{ display: 'none' }}
            />
        </div>
    );
};

export default AccountAboutPhoto;

