import React, { useState } from 'react';

// Styles
import './UserGroup.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import UserGroupDelete from '../usergroupdelete/UserGroupDelete';
import UserGroupEdit from '../usergroupedit/UserGroupEdit';
import UserGroupMenu from '../usergroupmenu/UserGroupMenu';

/**
 * UserGroup Component
 * 
 * This component renders a user group.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const UserGroup = ({ userGroup }) => {

    // Theme
    const { theme } = useTheme();

    const [deleteVisible, setDeleteVisible] = useState(false);
    const [editVisible, setEditVisible] = useState(false);

    const handleEditClick = async () => {
        setEditVisible(true);
    };

    const handleDeleteClick = async () => {
        setDeleteVisible(true);
    };

    return (
        <div className="user-group-container">

            {/* LEFT */}
            <div className="user-group-left">

                {/* TITLE */}
                <div className="user-group-title"
                    style={{
                        color: theme.foregroundColor
                    }}>
                    {userGroup.title}
                </div>

                {/* DESCRIPTION */}
                <div className="user-group-description"
                    style={{
                        color: theme.foregroundColorFaded
                    }}>
                    {userGroup.description}
                </div>

            </div>

            {/* RIGHT */}
            <div className="user-group-right">
                <UserGroupMenu
                    onEditClick={handleEditClick}
                    onDeleteClick={handleDeleteClick} />

            </div>

            {/* EDIT FORM */}
            {editVisible &&
                <UserGroupEdit
                    userGroup={userGroup}
                    isOpen={editVisible}
                    setOpen={setEditVisible}
                />
            }

            {/* DELETE FORM */}
            {deleteVisible &&
                <UserGroupDelete
                    userGroup={userGroup}
                    isOpen={deleteVisible}
                    setOpen={setDeleteVisible}
                />
            }

        </div>
    );
};

export default UserGroup;
