import React, { useContext } from 'react';

// Global
import { Global } from '../../../../Global';

// Firebase
import { collections } from '../../../../firebaseConfig';

// Managers
import { activity } from '../../../managers/ActivityManager';

// Styles
import './CalendarDelete.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import Modal from '../../../components/modal/Modal';
import ModalButton from '../../../components/modal/ModalButton';
import ModalButtons from '../../../components/modal/ModalButtons';
import ModalMessage from '../../../components/modal/ModalMessage';

// Managers
import DataManager from '../../../managers/DataManager';

const dataManager = new DataManager();

/**
 * CalendarDelete Component
 * 
 * This component confirms a delete prior to deleting the calendar.
 * 
 * @param {object} calendar - The calendar object to edit.
 * @param {boolean} isOpen - Determines if the modal state is open.
 * @param {function} setOpen - Sets the isOpen state.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const CalendarDelete = ({ calendar, isOpen, setOpen }) => {
    const { theme } = useTheme();
    const {
        hideProgress,
        selectedApp,
        showProgress
    } = useContext(Global);

    /** 
     * Deletes the calendar.
     */
    const handleDelete = async () => {

        showProgress("Deleting calendar...");

        try {
            const calendarKey = calendar.key;

            await dataManager.delete(selectedApp.key, collections.calendars, calendarKey);

            // Log the activity
            activity.log(selectedApp.key, 'deletes', 1);

        } catch (error) {
            console.error("Error deleting calendar: ", error);
        }

        hideProgress();
    };

    return (
        <>
            {/* MODAL */}
            <Modal title="Delete Calendar" width={400} isOpen={isOpen} onClose={() => setOpen(false)}>

                {/* CONFIRM MESSAGE */}
                <ModalMessage text="This will permanently delete all events in this calendar. Continue?" />

                {/* BUTTONS */}
                <ModalButtons>

                    {/* DELETE BUTTON */}
                    <ModalButton
                        onClick={handleDelete}
                        label="Delete"
                    />

                </ModalButtons>

            </Modal>
        </>
    );
};

export default CalendarDelete;
