import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { doc, Timestamp, updateDoc } from 'firebase/firestore';
import { collections, db, storage } from '../../firebaseConfig';
import { deleteObject, getDownloadURL, listAll, ref, uploadBytes } from 'firebase/storage';

// Utilities
import { Page } from '../../common/utilities/Enums';
import { convertDecimalToPercentage } from '../../common/utilities/Numbers';

// Theme
import { useTheme } from '../../ThemeContext';

// Styles
import './Settings.css';

// Components
import AppSettingsFont from '../../common/appsettings/AppSettingsFont';
import AppSettingsTitle from '../../common/appsettings/AppSettingsTitle';
import AppSettingsDescription from '../../common/appsettings/AppSettingsDescription';
import AppLogoEdit from '../../common/applogo/AppLogoEdit';
import Slider from '../../common/components/slider/Slider';
import Switch from '../../common/components/switch/Switch';
import ThemeEditor from '../../common/appsettings/AppSettingsThemeEditor';

// Managers
import { activity } from '../../common/managers/ActivityManager';
import AppManager from '../../common/managers/AppManager';

const appManager = new AppManager();

/**
 * Settings Component
 * 
 * This component is used on mobile to set app setting and administer them if
 * the current user is an administrator.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Settings = () => {
  const { currentTheme, theme, setCurrentTheme } = useTheme();
  const {
    hideProgress,
    selectedApp,
    setPage,
    setSelectedApp,
    setSettingsVisible,
    setStatusMessage,
    showProgress,
  } = useContext(Global);

  // State Variables
  const [switchState, setSwitchState] = useState(currentTheme === "DARK");
  const [tempThemes, setTempThemes] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [font, setFont] = useState(null);
  const [size, setSize] = useState(1.0);
  const [tempSize, setTempSize] = useState(1.0);

  /**
   * Initializes the settings when the selected app changes.
   */
  useEffect(() => {
    if (!selectedApp) return;

    // Set the switch state
    setSwitchState(currentTheme === "DARK");

    // Initialize about fields
    setTitle(selectedApp.title);
    setDescription(selectedApp.description);

    // Initialize icon size
    setSize(selectedApp.iconSize || 1.0);
    setTempSize(selectedApp.iconSize || 1.0);

    // Initialize temp themes
    if (!tempThemes) {
      setTempThemes(selectedApp.themes);
    }
  }, [selectedApp, currentTheme, tempThemes]);

  /**
   * Saves the theme any time tempThemes changes.
   */
  useEffect(() => {

    async function saveThemes() {

      // Current timestamp
      const now = Timestamp.now();

      const data = {
        themes: tempThemes,
        fontFamily: font,
        iconSize: size,
        dateModified: now,
      };

      // Call updateApp to update the database
      const result = await appManager.updateApp(selectedApp.key, data);

      if (result.success) {
        // Update state
        setSelectedApp((prevApp) => ({
          ...prevApp,
          themes: tempThemes,
          fontFamily: font,
          iconSize: size,
        }));
      }
    }

    if (tempThemes) {
      saveThemes();
    }

  }, [tempThemes, font, size, selectedApp.key, setSelectedApp]);

  /**
   * Handles theme switch changes from dark mode to light mode.
   */
  const handleSwitchChange = (newValue) => {
    setSwitchState(newValue);
    setCurrentTheme(newValue ? "DARK" : "LIGHT");
  };

  /**
   * Handles theme changes.
   */
  const handleThemeChange = (themeType, updatedTheme) => {
    const newThemes = {
      ...tempThemes,
      [themeType]: updatedTheme
    };
    setTempThemes(newThemes);
  };

  /**
   * Handles the title blur event by saving data and updating state.
   */
  const handleTitleBlur = async () => {
    const now = Timestamp.now();

    const data = {
      title: title,
      dateModified: now,
    };

    const result = await appManager.updateApp(selectedApp.key, data);

    if (result.success) {
      setSelectedApp((prevApp) => ({
        ...prevApp,
        title: title,
      }));
    }
  };

  /**
   * Handles the description blur event by saving data and updating state.
   */
  const handleDescriptionBlur = async () => {
    const now = Timestamp.now();

    const data = {
      description: description,
      dateModified: now,
    };

    const result = await appManager.updateApp(selectedApp.key, data);

    if (result.success) {
      setSelectedApp((prevApp) => ({
        ...prevApp,
        description: description,
      }));
    }
  };

  /**
   * Handles font changes by saving data and updating state.
   */
  const handleFontChange = async (fontFamily) => {
    const now = Timestamp.now();

    const data = {
      fontFamily: fontFamily,
      dateModified: now,
    };

    await appManager.updateApp(selectedApp.key, data);

    setFont(fontFamily);
  };

  /**
   * Handles complete delete of the app.
   */
  const handleDelete = async () => {
    showProgress('Deleting app...');

    if (!selectedApp) {
      alert('No app selected!');
      hideProgress();
      return;
    }

    const message = selectedApp.title + " and all of its data will be permanently deleted. Are you sure you would like to continue?";

    if (!window.confirm(message)) {
      hideProgress();
      return;
    }

    try {
      const appKey = selectedApp.key;
      const appManager = new AppManager();
      await appManager.deleteApp(appKey);

      setSettingsVisible(false);
      setSelectedApp(null);
      setPage(Page.HOME);
      setStatusMessage('App deleted successfully.');
    } catch (error) {
      console.error('Error deleting object and related data: ', error);
      setStatusMessage('Error deleting object and related data');
    }

    hideProgress();
  };

  /**
   * Method to handle a dark icon selection.
   * 
   * @param {file} file - The selected file.
   */
  const handleDarkIconSelect = async (file) => {
    await uploadIcon("DARK", file);
  };

  /**
   * Method to upload an icon to Firebase Storage.
   * 
   * @param {string} mode - "DARK" or "LIGHT".
   */
  const uploadIcon = async (mode, file) => {
    const fileExtension = file.name.split('.').pop();

    const createResizedImageBlob = (file) => {
      return new Promise((resolve, reject) => {
        const img = document.createElement('img');
        img.onload = () => {
          const maxDimension = 1000;
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const ratio = Math.min(maxDimension / img.width, maxDimension / img.height);
          const width = img.width * ratio;
          const height = img.height * ratio;

          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            if (blob) {
              resolve(blob); // Resolve the promise with the blob
            } else {
              reject(new Error("Failed to create blob"));
            }
          }, `image/${fileExtension}`);
        };
        img.onerror = reject; // Handle any errors that occur when loading the image
        img.src = URL.createObjectURL(file); // Set the image source to the selected file
      });
    };

    try {
      const resizedBlob = await createResizedImageBlob(file); // Resize the image

      const logoFolderRef = ref(storage, `logos/${mode.toLowerCase()}/`);

      // List all files in the folder and delete existing ones
      const listResults = await listAll(logoFolderRef);
      const existingFiles = listResults.items.filter((item) =>
        item.name.startsWith(selectedApp.key)
      );
      for (const fileRef of existingFiles) {
        await deleteObject(fileRef);
      }

      // Upload the new file
      const storageRef = ref(storage, `logos/${mode.toLowerCase()}/${selectedApp.key}.${fileExtension}`);
      await uploadBytes(storageRef, resizedBlob); // Upload the resized blob
      const fileUrl = await getDownloadURL(storageRef); // Get the URL

      // Update the selected app object with the new logo
      const updatedApp = {
        ...selectedApp,
        [mode === 'DARK' ? 'logoDark' : 'logoLight']: fileUrl,
      };

      setSelectedApp(updatedApp); // Update app state

      // Update Firestore document
      await updateDoc(doc(db, collections.apps, selectedApp.key), updatedApp);

      activity.log(selectedApp.key, 'writes', 1);
    } catch (error) {
      console.error("Error handling app logo upload:", error);
    }
  };

  /**
   * Method to handle the slider change event.
   * 
   * @param {decimal} value - Slider value, on a scale of 0 to 1.
   */
  const handleSliderChange = (value) => {
    setTempSize(value);
  };

  /**
   * Method to handle the slider blur event.
   */
  const handleSliderBlur = () => {
    setSize(tempSize);
  };

  return (
    <>
      {/* CONTAINER */}
      <div className="settings-container">


        {/* DARK/LIGHT MODE */}
        <div className="settings-setting-wrapper">
          <div className="settings-setting-label"
            style={{ color: theme.foregroundColor }}
          >
            Use Dark Mode
          </div>
          <Switch initialChecked={switchState} onChange={handleSwitchChange} />
        </div>

        {/* ADMIN FUNCTIONALITY */}
        <div className="settings-setting-wrapper">

          <div className="settings-setting-about-wrapper">

            <div className="settings-setting-label"
              style={{ color: theme.foregroundColor }}>
              About
            </div>

            <div className="settings-setting-about-fields">

              {/* TITLE */}
              <AppSettingsTitle
                title={title}
                setTitle={setTitle}
                onBlur={handleTitleBlur}
              />

              {/* DESCRIPTION */}
              <AppSettingsDescription
                description={description}
                setDescription={setDescription}
                onBlur={handleDescriptionBlur}
              />
            </div>
          </div>
        </div>

        {/* THEMES AND LOGOS */}
        <div className="settings-setting-wrapper">

          <div className="settings-setting-themes-wrapper">

            {/* LOGOS */}
            <div className="settings-setting-label"
              style={{ color: theme.foregroundColor }}>
              Icon
            </div>

            <div className="settings-setting-logos">

              {tempThemes &&
                <>
                  <AppLogoEdit
                    title="Dark Mode Icon"
                    backgroundColor={tempThemes.dark.backgroundColor}
                    borderColor={tempThemes.dark.backgroundColorFaded}
                    mode="DARK"
                    size={convertDecimalToPercentage(size)}
                    onSelect={handleDarkIconSelect}
                  />

                  <AppLogoEdit
                    title="Light Mode Icon"
                    backgroundColor={tempThemes.light.backgroundColor}
                    borderColor={tempThemes.light.backgroundColorFaded}
                    mode="LIGHT"
                    size={convertDecimalToPercentage(size)}
                    onSelect={handleDarkIconSelect}
                  />
                </>
              }

            </div>

            {/* SLIDER */}
            <div className="settings-setting-logo-slider">

              <Slider
                value={tempSize}
                onChange={handleSliderChange}
                onBlur={handleSliderBlur}
              />

              {/* SLIDER LABEL */}
              <div className="settings-setting-icon-percentage"
                style={{
                  color: theme.foregroundColor,
                  fontFamily: selectedApp.fontFamily
                }}>
                Size: {convertDecimalToPercentage(tempSize)}
              </div>

            </div>

            {/* THEMES */}
            <div className="settings-setting-label"
              style={{ color: theme.foregroundColor }}>
              Themes
            </div>

            {/* DARK MODE */}
            <div className="settings-setting-theme-wrapper">
              {tempThemes &&
                <>
                  <ThemeEditor
                    title="Dark Mode Colors"
                    adjustTheme={tempThemes.dark}
                    onThemeChange={(updatedTheme) => handleThemeChange("dark", updatedTheme)}
                  />
                </>
              }
            </div>

            {/* LIGHT MODE */}
            <div className="settings-setting-theme-wrapper">
              {tempThemes &&
                <>
                  <ThemeEditor
                    title="Light Mode Colors"
                    adjustTheme={tempThemes.light}
                    onThemeChange={(updatedTheme) => handleThemeChange("light", updatedTheme)}
                  />
                </>
              }
            </div>

          </div>
        </div>

        {/* FONT */}
        <div className="settings-setting-label"
          style={{ color: theme.foregroundColor }}>
          Font
        </div>
        <div className="settings-setting-wrapper">
          <AppSettingsFont fontFamily={selectedApp.fontFamily} onChange={handleFontChange} />
        </div>

        {/* DELETE BUTTON */}
        <button
          type="button"
          onClick={handleDelete}
          className="settings-delete"
          style={{ color: theme.highlightBackgroundColor }}
        >
          Delete App
        </button>

      </div>
    </>
  );
};

export default Settings;
