import axios from 'axios';

// Firebase
import { Timestamp } from 'firebase/firestore';

// Utilities
import { generateKey } from '../utilities/Keys';
import { defaultSummaryTitleStyles } from '../utilities/Defaults';
import { FormMode } from '../utilities/Enums';

// Managers
import AdminManager from './AdminManager';
import FieldManager from './FieldManager';
import ModelManager from './ModelManager';
import ObjectManager from './ObjectManager';

const adminManager = new AdminManager();
const fieldManager = new FieldManager();
const modelManager = new ModelManager();
const objectManager = new ObjectManager();

class PromptManager {

    async send(preparedPrompt) {
        const apiKey = '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'; // Use your actual API key
        const endpoint = 'https://api.openai.com/v1/chat/completions'; // API endpoint URL

        try {
            const response = await axios.post(
                endpoint,
                {
                    model: "gpt-4o", // Model specification, adjust if necessary
                    messages: [{
                        role: "user",
                        content: preparedPrompt
                    }]
                },
                {
                    headers: {
                        'Authorization': `Bearer ${apiKey}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            // Check if the response has the expected structure and content
            if (response.data && response.data.choices && response.data.choices.length > 0) {
                return response.data.choices[0].message.content; // Return the content as a string
            }
            return 'No response content found.'; // Return a default message if the response structure is unexpected
        } catch (error) {
            console.error('Error calling the ChatGPT API:', error);
            if (error.response.status === 429) {
                const retryAfter = error.response.headers['retry-after'];
                console.log(`Rate limit exceeded. Retry after ${retryAfter} seconds.`);
            }
            return 'Failed to get response from the API.'; // Return error message as a string
        }
    }

    async prepareAppPrompt(template, title, description) {

        let instructions = `
[APP CREATION - START PRELIMINARY INSTRUCTIONS]

[GOAL]
The goal of your response is to define the scaffolding of an app that the user is looking for. 
Your response MUST contain ONLY JSON, and no surrounding explanation or any other text. 
Please do not add comments or characters that might lead to the inability to parse the JSON.

The template information will be the basis for the app. The user will provide a title and description for the app,
which you can use to fine-tune the app's purpose, structure and feature set.

[TEMPLATE INFORMATION]
KEY: ${template.key}
NAME: ${template.name}
DESCRIPTION: ${template.description}

[TITLE AND APP DESCRIPTION]
TITLE: ${title}
DESCRIPTION: ${description}

[CHANNELS]
Most, but not all, apps will need channels for communication purposes. There are three types of channels: TEXT, FORUM, and VIDEO. These channels are similar to Discord channels. 
Please provide a list of pre-built channels that might be useful for users the app.

[MODELS]
The data portion of the app is based upon models. The models will have fields eventually, but for now I simply want to identify as many models
as possible that could make as comprehensive and useful an app as possible. Model titles should be plural. Titles will be used
as menu items in the app, so please be user friendly with the titles. Model descriptions will
provide some useful information about the purpose of each model in the app.

It is not necessary to create the following types of models, as they are already build into the system:
- User
- Calendar/Scheduling
- Messaging/Chat
- Payments and Commerce

[THEME]
The app will also need a theme. We need two color schemes for the app, one for dark mode and one for light mode.
Please choose a color scheme for the app that might appeal to potential users and that reflects the purpose of the app. 
Please provide unique hex values for the following:
- backgroundColorDark
- backgroundColorDarkFaded
- foregroundColorDark
- foregroundColorDarkFaded
- highlightBackgroundColorDark (button backgrounds)
- highlightForegroundColorDark (button foregrounds)
- backgroundColorLight
- backgroundColorLightFaded
- foregroundColorLight
- foregroundColorLightFaded
- highlightBackgroundColorLight
- highlightForegroundColorLight

Please return the response in JSON format exactly as I have outlined. Do not add any nesting, do not introduce any new fields, and do not structure the response differently from the example provided below. Here's the exact format:

[EXAMPLE RESPONSE]
{
    "appTitle": "${title}",
    "appDescription": "${description}",
    "templateKey: "${template.key}",
    "backgroundColorDark": "#1F2937",
    "backgroundColorDarkFaded": "#374151",
    "foregroundColorDark": "#FFFFFF",
    "foregroundColorDarkFaded": "#999999",
    "highlightColorDark": "#C9700F",
    "backgroundColorLight": "#111111",
    "backgroundColorLightFaded": "#444444",
    "foregroundColorLight": "#222222",
    "foregroundColorLightFaded": "#444444",
    "highlightColorLight": "#C9700F",
    "models": [
        {
            "title": "Realtors",
            "description": "The realtors in our group."
        },
        {
            "title": "Listings",
            "description": "All listings in our firm."
        },
        {
            "title": "Leads",
            "description": "A list of potential buyers."
        },
        {
            "title": "Contacts",
            "description": "Helpful list of contacts for our realtors."
        },
        {
            "title": "Appointments",
            "description": "Scheduling for all realtors."
        },
    ],
    "channels": [
        {
            "type": "CHAT",
            "title": "General Chat",
            "description": "General chat for realtors in our group."
        },
        {
            "type": "FORUM",
            "title": "Client Guidelines",
            "description": "Key guidelines for our clients."
        }
    ]
}

[END INSTRUCTIONS]

        `;

        return instructions;
    }

    async prepareModelPrompt(appTitle, appDescription, modelTitle, modelDescription) {

        let instructions = `
        [GOAL - CREATE A MODEL FIELD LIST]
        The purpose of our system is to utilize AI to assist users in creating useful apps based upon models. Each model has fields, and each field represents an attribute of the model.
        
        The purpose of this query is to identify as many fields as possible for the given model and to provide a **comprehensive list of data entries**. Each data entry should contain values matching the fields generated, allowing us to pre-populate the app with meaningful data.
        
        Please provide your response in JSON format only, with no surrounding explanation or text. All formatting must be valid JSON to ensure it can be parsed. Avoid any comments or unnecessary characters.
        
        For context, here is some preliminary information that describes the app and model.
        
        [APP INFORMATION]
        App Title: "${appTitle}"
        App Description: "${appDescription}"

        [MODEL INFORMATION]
        Model Title: "${modelTitle}"
        `;

        if (modelDescription !== "") {
            instructions += `Model Description: "${modelDescription}"`
        }

        instructions += `
        
        Each model should have:
        - A title field (type "text") as the primary field for identifying items.
        - Any other appropriate fields identified based on the model description.
        
        [FIELD REQUIREMENTS]
        Each field should include:
           - **title**: the name of the field.
           - **description**: a short explanation of the field.
           - **type**: the field's data type, selected from the available types below.
           - **fieldID** (optional): a unique identifier for each field.

        It is very important that we have one field as the priority 1 field for the model. This field must be of 
        type "text". This field will be used as the identifying title field for any objects of this model type. This field 
        must be sorted in your response before any other text types. For example, for a person 
        it might be "Full Name". For a movie it might be "Title". When returning the field list, place the priority 1
        field before any other text fields. You can have other fields before it, just don't put any other text fields
        before it. When processing your response, I will search for the first "text" field, and process it as the priority one field.

        Field types available:
        - checkbox
        - checklist
        - comments
        - countries
        - currency
        - date
        - day
        - documents
        - gallery
        - image
        - lookup
        - month
        - number
        - password
        - phone
        - states
        - text
        - time
        - year
        
        If unsure of a data type, default to "text."
        
        Additionally:
        1. The first "text" type field should be the main identifier (e.g., "Title").
        2. Any relevant field values with defined options should use "lookup" with specific options.
        3. The model needs an array of tags to categorize the items and objects should be tagged accordingly.
        
        [DATA AUTO-POPULATION]
        For this model, please generate a **detailed list of data entries** for as many well-known items as possible, up to approximately 40 entries (if available). Each data entry should populate fields that match the generated fields for this model, making the data immediately useful in the app.

        Each entry in "data" should contain values that correspond to each "field" by title. If "fieldID" is used in the "fields" section, use this to identify each corresponding field value in "data".

        ---
        
        Here's the example JSON structure:
        
        {
            "fields": [
                {
                    "title": "Exercise Name",
                    "description": "Name of the CrossFit exercise.",
                    "type": "text",
                    "fieldID": "field1"
                },
                {
                    "title": "Exercise Description",
                    "description": "Description of the CrossFit exercise.",
                    "type": "text",
                    "fieldID": "field2"
                },
                {
                    "title": "Exercise Type",
                    "description": "Type of CrossFit exercise.",
                    "type": "lookup",
                    "options": ["Girls", "Heroes"],
                    "fieldID": "field3"
                },
            ],
            "tags": [
                "Difficult", 
                "Moderate",
                "Easy",
            ],
            "data": [
                {
                    "field1": "Fran",
                    "field2": "21-15-9 reps for time: Thrusters (95/65 lb) and Pull-ups",
                    "field3": "Girls",
                    "tags": ["Moderate"]
                },
                ...
            ]
        }
        `;

        return instructions;
    }

    async processModelResponse(
        r,
        app,
        model,
        showProgress,
        setSelectedModel,
        setFormMode,
        resetVisibility,
        currentUser
    ) {
        try {
            showProgress("Generating " + model.title + " user interface...");

            setSelectedModel(model);

            console.log(r);

            const jsonString = this.cleanJSON(r);
            const jsonData = JSON.parse(jsonString);

            if (jsonData.fields && Array.isArray(jsonData.fields)) {
                let fieldSort = 0;
                let fieldIndex = 0;
                let titleFieldKey = ""; // Temporary variable to store the title field key
                let titleField = null; // Store title field data

                const fieldIDtoKeyMap = {};

                jsonData.fields.forEach((field) => {
                    const modelKey = model.key;
                    const fieldKey = generateKey(); // Generate system-specific key for this field

                    // Map the fieldID or title to the generated key
                    if (field.fieldID) {
                        fieldIDtoKeyMap[field.fieldID] = fieldKey;
                    } else {
                        fieldIDtoKeyMap[field.title] = fieldKey;
                    }

                    const options = [];
                    if (field.options && Array.isArray(field.options)) {
                        field.options.forEach((optionTitle) => {
                            options.push({ key: generateKey(), title: optionTitle });
                        });
                    }

                    const type = field.type;

                    const data = {
                        appKey: app.key,
                        key: fieldKey,
                        title: field.title,
                        description: field.description,
                        type: type,
                        sort: fieldSort,
                        modelKey: model.key,
                        modelTitle: model.title,
                        modelDescription: model.description,
                        options: options
                    };

                    // Identify the first "text" type field as the title field
                    const appropriateTypes = ["countries", "phone", "states", "text"];
                    if (appropriateTypes.includes(type) && fieldIndex === 0) {
                        titleField = data;
                        titleFieldKey = fieldKey; // Set this fieldKey as title field key
                        fieldIndex++;
                    }

                    // Store field data in the field manager
                    fieldManager.add(app.key, fieldKey, modelKey, data);

                    fieldSort++;
                });

                titleField = {
                    appKey: app.key,
                    key: titleFieldKey,
                    title: "Title",
                    description: "Title of the item.",
                    type: "text",
                    sort: 0,
                    modelKey: model.key,
                    modelTitle: model.title,
                    modelDescription: "",
                    options: []
                };

                const titleFieldContentKey = generateKey();
                const titleFieldBlock = {
                    id: generateKey(),
                    content: [{
                        id: titleFieldContentKey,
                        key: titleFieldContentKey,
                        title: "Title",
                        field: titleField,
                        type: "field"
                    }],
                    align: 'left'
                };

                await fieldManager.add(app.key, titleFieldKey, model.key, titleField);

                const modelData = {
                    titleFieldKey: titleFieldKey,
                    rows: [{ id: generateKey(), blocks: [titleFieldBlock] }]
                };

                await modelManager.update(app.key, model.key, modelData);

                const updatedModel = { ...model, ...modelData };
                setSelectedModel(updatedModel);

                // Process auto-populated data if available
                if (jsonData.data && Array.isArray(jsonData.data)) {
                    jsonData.data.forEach(async (dataEntry) => {
                        const currentObject = {};

                        Object.entries(dataEntry).forEach(([fieldID, value]) => {
                            const mappedFieldKey = fieldIDtoKeyMap[fieldID];
                            if (mappedFieldKey) {
                                currentObject[mappedFieldKey] = value;
                                console.log("Mapped field key:", mappedFieldKey, "Value:", value);
                            }
                        });

                        // Current timestamp
                        const now = Timestamp.now();

                        const data = {
                            key: generateKey(),
                            appKey: app.key,
                            modelKey: model.key,
                            userKey: currentUser.userKey,
                            username: currentUser.username,
                            tags: [],
                            dateCreated: now,
                            dateModified: now,
                            ...currentObject
                        };

                        await objectManager.add(app.key, model.key, data.key, data);

                        setSelectedModel(updatedModel);
                    });
                }

                if (resetVisibility) {
                    resetVisibility();
                }

                setFormMode(FormMode.ADD);

            } else {
                console.log("No fields found or invalid format.");
            }
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    }

    /*
    async processModelResponse(
        r,
        app,
        model,
        showProgress,
        setSelectedModel,
        setFormMode,
        resetVisibility
    ) {
        try {

            showProgress("Generating " + model.title + " user interface...");

            setSelectedModel(model);

            console.log(r);

            const jsonString = this.cleanJSON(r);
            const jsonData = JSON.parse(jsonString);

            if (jsonData.fields && Array.isArray(jsonData.fields)) {
                var fieldSort = 0;
                var fieldIndex = 0; // Counter for the number of fields selected for the summary

                var titleFieldKey = "";

                let titleField = null;

                jsonData.fields.forEach((field) => {
                    const modelKey = model.key;
                    const fieldKey = generateKey();
                    const options = [];
                    if (field.options && Array.isArray(field.options)) {
                        // Iterate over each option title
                        field.options.forEach((optionTitle) => {
                            // Generate a key for each option
                            const optionKey = generateKey();
                            // Add the option with key and title to the options array
                            options.push({ key: optionKey, title: optionTitle });
                        });
                    }

                    let type = field.type;

                    const data = {
                        appKey: app.key,
                        key: fieldKey,
                        title: field.title,
                        description: field.description,
                        type: type,
                        sort: fieldSort,
                        modelKey: model.key,
                        modelTitle: model.title,
                        modelDescription: model.description,
                        options: options
                    }

                    // Check if the field type is appropriate for the summary
                    const appropriateTypes = ["countries", "phone", "states", "text"];
                    if (appropriateTypes.includes(type)) {
                        if (fieldIndex === 0) {
                            // Priority #1 field
                            titleField = data;
                            titleFieldKey = fieldKey;
                            styles = defaultSummaryTitleStyles;
                            fieldIndex++;
                        }
                    }

                    fieldManager.add(
                        app.key,
                        fieldKey,
                        modelKey,
                        data
                    );

                    fieldSort++;
                });

                // We need to create a default title field if autogen is disabled
                const titleFieldData = {
                    appKey: app.key,
                    key: titleFieldKey,
                    title: "Title",
                    description: "Title of the item.",
                    type: "text",
                    sort: 0,
                    modelKey: model.key,
                    modelTitle: model.title,
                    modelDescription: "",
                    options: [],
                };
        
                const titleFieldContentKey = generateKey();
        
                const titleFieldBlock = {
                    id: generateKey(),
                    content: [{
                        id: titleFieldContentKey,
                        key: titleFieldContentKey,
                        title: "Title",
                        field: titleFieldData,
                        type: "field",
                    }], 
                    align: 'left',
                }

                // Update the title field key in the model
                const modelData = {
                    titleFieldKey: titleFieldKey,
                    rows: [{ id: generateKey(), blocks: [titleFieldBlock] }],
                }

                await modelManager.update(app.key, model.key, modelData);

                // Merge the original model with the new property
                const updatedModel = {
                    ...model,
                    ...modelData
                };

                if (resetVisibility) {
                    resetVisibility();
                }

                // Set the selected model with the merged data
                setSelectedModel(updatedModel);

                setFormMode(FormMode.ADD);

            } else {
                console.log("No models found or invalid format.");
            }

        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
    */





    async prepareSummaryPrompt(app, model, fields) {

        let prompt = `

In my app I have models, and each model has fields. Users can generate models and their fields, so it is a flexible system in that regard.

In my UI I have a list of items, and I would like to display summaries of these items in a scrolling list. I need some UI layout help for the summary.

The items in the layout will be positioned absolutely. The width will be 420px, and the height will be 400px. Please confine all items within these constraints.
Provide me with the top, left, width, and height for each item in the summary layout.

Within width and height layout constraints of the entire card, I need to lay out attributes for the listing that might be important for a summary. For example, if the model is a real-estate listing, the user might want to see (within constraints of 420px x 400px.
- Photo (gallery)
- Title (text)
- Address (text)
- # Bedrooms
- # Bathrooms 
- Square feet

It's important that the layout is visually appealing and that the most important information is displayed prominently.

It's not necessary to include all fields in the response. Things need to fit comfortably within the 420px x 400px constraints once they are populated with data.

You will want to include only fields of the following types:

- currency
- date
- day 
- gallery
- lookup
- month 
- number
- phone
- states
- text
- time
- year

[EXAMPLE RESPONSE]
{
    "fields": [
        {
            "key": "4402983ut-02389ut",
            "title": "Photo Gallery",
            "type": "gallery",
            "top": "0px"
            "left": "0px"
            "width": "420px"
            "height": "300px"
        },
        {
            "key": "4402983ut-02389ut",
            "title": "Address",
            "type": "text",
            "top": "200px"
            "left": "0px"
            "width": "420px"
            "height": "50px"
        },
        {
            "key": "4402983ut-02389ut",
            "title": "Number of Bedrooms",
            "type": "text",
            "top": "240px"
            "left": "0px"
            "width": "140px"
            "height": "50px"
        },
        {
            "key": "4402983ut-02389ut",
            "title": "Number of Bathrooms",
            "type": "text",
            "top": "240px"
            "left": "140px"
            "width": "140px"
            "height": "50px"
        },
        {
            "key": "4402983ut-02389ut",
            "title": "Square Feet",
            "type": "text",
            "top": "240px"
            "left": "280px"
            "width": "140px"
            "height": "50px"
        },
    ]
}

For context, I will give you information about the app, the model, and each possible field for the model.

        App Title: "` + app.title + `"
        App Description: "` + app.description + `"
        Model Name: "` + model.title + `"
        Model Description: "` + model.description + `"
        Model Fields:`;

        for (var i = 0; i < fields.length; i++) {
            prompt += ` - "` + fields[i].title + `" (type: ` + fields[i].type + `) (key: ` + fields[i].key + `)\n`;
        }

        return prompt;
    }






















    cleanJSON = (jsonString) => {
        try {
            // Remove any characters before the first '{'
            const startIndex = jsonString.indexOf('{');
            // Remove any characters after the last '}'
            const endIndex = jsonString.lastIndexOf('}') + 1;

            // Extract the valid JSON string
            const cleanString = jsonString.substring(startIndex, endIndex);

            // Parse the cleaned JSON string
            return cleanString;
        } catch (e) {
            throw new Error('Invalid JSON string');
        }
    };

    async prepareFieldsPrompt(app, models, model, fields) {

        let prompt = `

        App Title: "${app.title}"
        App Description: "${app.description}"
        
        Model Title: "${model.title}"

        Existing Fields:
        `;

        for (var i = 0; i < fields.length; i++) {
            prompt += ` - "` + fields[i].title + `"
            `;
        }

        prompt += `

        Existing Models:
        `;

        for (var x = 0; x < models.length; x++) {
            prompt += ` - "${models[x].title}" (key: ${models[x].key})
        `;
        }

        const instructions = await adminManager.fetchSetting("FIELDS_PROMPT");
        return instructions.value + " " + prompt;
    };
}



export default PromptManager;

