import React, { useContext } from 'react';

// Styles
import './UserDot.css';

// Global
import { Global } from '../../../Global';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * UserDot Component
 * 
 * This component renders a circled letter that can be associated with a user.
 * 
 * @param {string} letter - The letter to display.
 * @param {number} size - The size of the dot.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const UserDot = ({ user, size }) => {
    const { theme } = useTheme();
    const {
        selectedApp,
        studio
    } = useContext(Global);

    const letter = user.username.charAt(0).toUpperCase();

    const style = {
        backgroundColor: selectedApp ? theme.highlightBackgroundColor : studio.highlightBackgroundColor,
        color: selectedApp ? theme.highlightForegroundColor : studio.highlightForegroundColor,
        '--dot-size': size + 'px',
        '--dot-font-size': size * 0.5 + 'px',  // 50% of size
        height: size + 'px',
        width: size + 'px',
    };

    return (
        <div
            className="user-dot-container"
            style={style}>
                {letter}
        </div>
    );
};

export default UserDot;
