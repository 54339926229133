import React, { useContext } from 'react';

// Global context and styles
import { Global } from '../../../../Global';

// Firebase
import { Timestamp } from 'firebase/firestore';

// Styles
import './CalendarDayHourBlock.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Managers
import EventManager from '../../../managers/EventManager';

const eventManager = new EventManager();

/**
 * CalendarDayHourBlock Component
 * 
 * This component renders a row in a daily calendar, including the hour label on
 * the left-hand side, and a content block on the right side.
 * 
 * @param {integer} hour - The hour of the day to display.
 * @param {integer} index - The index of the hour in the day.
 * @param {integer} labelWidth - The width of the label.
 * @param {integer} useDrop - Enables drop functionality.
 * @param {function} onHourClick - Handles click events on the hour block.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const CalendarDayHourBlock = ({ hour, index, labelWidth, useDrop }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        activeDate,
        selectedApp,
        setSelectedTime,
        setEventVisible,
        setSelectedEvent
    } = useContext(Global);

    // Drop functionality
    const [, drop] = useDrop(() => ({
        accept: 'event',
        drop: (item) => handleDrop(item, index),
    }));

    const handleDrop = async (item, hourIndex) => {
        // Create a new date object from activeDate for the selected start time
        const selected = new Date(activeDate.getTime());
        selected.setHours(hourIndex);
        selected.setMinutes(0);
        selected.setSeconds(0);

        // Calculate the duration of the event
        const originalStart = item.event.startDate.toDate();
        const originalEnd = item.event.endDate.toDate();
        const duration = originalEnd - originalStart; // Duration in milliseconds

        // Calculate the new end time based on the duration
        const newEnd = new Date(selected.getTime() + duration);

        // Create the updated data object
        const data = {
            ...item.event,
            startDate: Timestamp.fromDate(selected),
            endDate: Timestamp.fromDate(newEnd)
        };

        try {
            // Update the event in the database
            await eventManager.update(selectedApp.key, item.event.key, data);
        } catch (error) {
            console.error("Error updating event: ", error);
        }
    }

    // Handles hour click
    const handleHourClick = async (hourIndex) => {
        // Clear the currently selected event
        setSelectedEvent(null);

        // Create a new date object from activeDate
        const selected = new Date(activeDate.getTime());
        selected.setHours(hourIndex);
        selected.setMinutes(0);
        selected.setSeconds(0);
        setSelectedTime(selected);

        // Display the event form
        setEventVisible(true);
    }

    return (
        <div className="hour-block-container" key={index}
            onClick={() => handleHourClick(index)}
            ref={drop}>

            {/* LABEL */}
            <div className="hour-block-label"
                style={{
                    color: theme.foregroundColor,
                    borderRightColor: theme.backgroundColorFaded,
                    width: labelWidth + "px"
                }}>
                {hour}
            </div>

            {/* CONTENTS */}
            <div className="hour-block-contents"
                style={{ borderBottomColor: theme.backgroundColorFaded }}
            ></div>
            
        </div>
    );
};

export default CalendarDayHourBlock;