import React, { useContext } from 'react';

// Global
import { Global } from '../../../../Global';

// Firebase
import { collections } from '../../../../firebaseConfig';

// Styles
import './UserGroupDelete.css';

// Managers
import { activity } from '../../../managers/ActivityManager';

// Components
import Modal from '../../../components/modal/Modal';
import ModalButton from '../../../components/modal/ModalButton';
import ModalButtons from '../../../components/modal/ModalButtons';
import ModalMessage from '../../../components/modal/ModalMessage';

// Managers
import DataManager from '../../../managers/DataManager';

const dataManager = new DataManager();

/**
 * UserGroupDelete Component
 * 
 * This component confirms a delete prior to deleting a user group.
 * 
 * @param {object} userGroup - The user group object to edit.
 * @param {boolean} isOpen - Determines if the modal state is open.
 * @param {function} setOpen - Sets the isOpen state.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const UserGroupDelete = ({ userGroup, isOpen, setOpen }) => {

    // Global
    const {
        hideProgress,
        selectedApp,
        showProgress
    } = useContext(Global);

    /** 
     * Deletes the user group.
     */
    const handleDelete = async () => {

        showProgress("Deleting user group...");

        try {

            await dataManager.delete(selectedApp.key, collections.usergroups, userGroup.key);

            // Log the activity
            activity.log(selectedApp.key, 'deletes', 1);

        } catch (error) {
            console.error("Error deleting user group: ", error);
        }

        hideProgress();
    };

    return (
        <>
            {/* MODAL */}
            <Modal title="Delete User Group"
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                width={400}>

                {/* CONFIRM MESSAGE */}
                <ModalMessage text="This will permanently delete this user group. Continue?" />

                {/* BUTTONS */}
                <ModalButtons>

                    {/* DELETE BUTTON */}
                    <ModalButton
                        onClick={handleDelete}
                        label="Delete"
                    />

                </ModalButtons>

            </Modal>
        </>
    );
};

export default UserGroupDelete;
