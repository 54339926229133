import React from 'react';

const CheckOffIcon = ({ color = "white", width = "18", height = "18" }) => (
    <svg
        version="1.1"
        width={width}
        height={height}
        viewBox="0 0 18 18">
        <g id="Icons" stroke="none" fill="none">
            <g id="Rounded" transform="translate(-409.000000, -4323.000000)">
                <g id="Toggle" transform="translate(100.000000, 4266.000000)">
                    <g id="-Round-/-Toggle-/-check_box_outline_blank" transform="translate(306.000000, 54.000000)">
                        <g>
                            <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                            <path d="M18,19 L6,19 C5.45,19 5,18.55 5,18 L5,6 C5,5.45 5.45,5 6,5 L18,5 C18.55,5 19,5.45 19,6 L19,18 C19,18.55 18.55,19 18,19 Z M19,3 L5,3 C3.9,3 3,3.9 3,5 L3,19 C3,20.1 3.9,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,5 C21,3.9 20.1,3 19,3 Z"
                                fill={color} />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default CheckOffIcon;
