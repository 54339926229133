import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Firebase
import { collections } from '../../../../firebaseConfig';

// Utilities
import { generateKey } from '../../../utilities/Keys';

// Styles
import './UserGroupAdd.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import Modal from '../../../components/modal/Modal';
import ModalButton from '../../../components/modal/ModalButton';
import ModalButtons from '../../../components/modal/ModalButtons';
import ModalInput from '../../../components/modal/ModalInput';
import ModalLabel from '../../../components/modal/ModalLabel';
import ModalSection from '../../../components/modal/ModalSection';
import ModalTextarea from '../../../components/modal/ModalTextarea';

// Managers
import DataManager from '../../../managers/DataManager';

const dataManager = new DataManager();

/**
 * UserGroupAdd Component
 * 
 * This component allows the user to add a user group.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const UserGroupAdd = ({ isVisible, setVisible }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        currentUser,
        selectedApp,
    } = useContext(Global);

    // State Variables
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const handleAdd = async () => {

        // Require title
        if (!title || title.trim() === '') {
            alert('Please enter a group name.');
            return;
        }

        // Require description
        if (!description || description.trim() === '') {
            alert('Please enter a description of the group.');
            return;
        }

        // Generate key
        const key = generateKey();

        // Create record
        const data = {
            key: key,
            appKey: selectedApp.key,
            userKey: currentUser.userKey,
            title: title,
            description: description,
        };

        // Add to database
        await dataManager.add(
            selectedApp.key,
            key,
            collections.usergroups,
            data
        );

        // Close the modal
        setVisible(false);

    };

    return (
        <>
            {/* MODAL */}
            <Modal title="New User Group"
                isOpen={isVisible}
                onClose={() => setVisible(false)}
                width="330px"
                height="auto">

                {/* NAME */}
                <ModalSection>

                    <ModalLabel text="Group Name" />

                    <ModalInput
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        maxLength="100"
                        autoFocus={true}
                    />

                </ModalSection>

                {/* DESCRIPTION */}
                <ModalSection>

                    <ModalLabel text="Description" />

                    <ModalTextarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        maxLength="255"
                        lines="2"
                    />

                </ModalSection>

                {/* BUTTONS */}
                <ModalButtons>

                    {/* ADD BUTTON */}
                    <ModalButton
                        onClick={handleAdd}
                        label="Add Group"
                    />

                </ModalButtons>

            </Modal>
        </>
    );
};

export default UserGroupAdd;
