import React from 'react';

const AlignRightIcon = ({ color = "white", width = "24", height = "24" }) => (
    <svg
        version="1.1"
        width={width}
        height={height}
        viewBox="0 0 24 24">
        <path fill={color} fillRule="evenodd" d="M20,3 C20,2.44771525 20.4477153,2 21,2 C21.5522847,2 22,2.44771525 22,3 L22,21 C22,21.5522847 21.5522847,22 21,22 C20.4477153,22 20,21.5522847 20,21 L20,3 Z M18,12 L17.996,12.082 L17.9797599,12.2007258 L17.9502619,12.3121425 L17.9063266,12.4232215 L17.8540045,12.5207088 L17.8036865,12.5951593 L17.7071068,12.7071068 L13.7071068,16.7071068 C13.3165825,17.0976311 12.6834175,17.0976311 12.2928932,16.7071068 C11.9324093,16.3466228 11.9046797,15.7793918 12.2097046,15.3871006 L12.2928932,15.2928932 L14.585,13 L3,13 C2.44771525,13 2,12.5522847 2,12 C2,11.4477153 2.44771525,11 3,11 L14.585,11 L12.2928932,8.70710678 C11.9324093,8.34662282 11.9046797,7.77939176 12.2097046,7.38710056 L12.2928932,7.29289322 C12.6533772,6.93240926 13.2206082,6.90467972 13.6128994,7.20970461 L13.7071068,7.29289322 L17.7071068,11.2928932 L17.7485042,11.336853 L17.8036654,11.4046934 L17.8753288,11.5159379 L17.9063462,11.5769009 L17.9401141,11.6583496 L17.9641549,11.734007 L17.9931658,11.8825258 L18,12 Z" />
    </svg>
);

export default AlignRightIcon;
