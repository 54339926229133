import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Utilities
import { FormMode, UserPreference } from '../../utilities/Enums';

// Styles
import './FormMenu.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import DeleteIcon from '../../svg/DeleteIcon';
import EyeIcon from '../../svg/EyeIcon';
import FeatureIcon from '../../svg/FeatureIcon';
import PencilIcon from '../../svg/PencilIcon';
import SaveIcon from '../../svg/SaveIcon';

// Components
import ContextMenuButton from '../../components/contextmenu/ContextMenuButton';
import ContextMenuGroup from '../../components/contextmenu/ContextMenuGroup';
import ContextMenuOption from '../../components/contextmenu/ContextMenuOption';
import ContextMenuPopup from '../../components/contextmenu/ContextMenuPopup';

// Managers
import UserManager from '../../managers/UserManager';

const userManager = new UserManager();

const FormMenu = ({ onFeatureClick, onSaveClick, onDeleteClick }) => {
    const { theme } = useTheme();
    const {
        currentUser,
        formMode,
        selectedApp,
        selectedModel,
        selectedObject,
        setCurrentUser,
        setFormMode,
    } = useContext(Global);

    // State Variables
    const [menuVisible, setMenuVisible] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

    /**
     * Toggles visibility of the context menu.
     */
    const toggleMenu = (event) => {
        if (!menuVisible) {
            event.preventDefault();
            setMenuPosition({ x: event.clientX, y: event.clientY });
            setMenuVisible(true);
        } else {
            setMenuVisible(false);
        }
    };

    /**
     * Handles click on the add feature option.
     */
    const handleAddFeature = async () => {
        setMenuVisible(false);
        onFeatureClick();
    };

    /**
     * Handles click on the save option.
     */
    const handleSave = async () => {
        setMenuVisible(false);
        onSaveClick();
    };

    /**
     * Handles click on the delete option.
     */
    const handleDelete = async () => {
        setMenuVisible(false);
        onDeleteClick();
    };

    /**
     * Handles click on the view mode option.
     */
    const handleViewMode = async () => {
        setMenuVisible(false);
        setFormMode(FormMode.VIEW);

        // Update LAST_FORM_MODE settings
        await userManager.setPreference(
            currentUser,
            setCurrentUser,
            selectedApp.key,
            UserPreference.LAST_FORM_MODE,
            FormMode.VIEW
        );
    };

    /**
     * Handles click on the edit mode option.
     */
    const handleEditMode = async () => {
        setMenuVisible(false);
        setFormMode(FormMode.EDIT);

        // Update LAST_FORM_MODE settings
        await userManager.setPreference(
            currentUser,
            setCurrentUser,
            selectedApp.key,
            UserPreference.LAST_FORM_MODE,
            FormMode.EDIT
        );
    };

    // Make sure a model is selected
    if (!selectedModel) {
        return null;
    }

    return (
        <>
            {/* CONTAINER */}
            <div className="form-menu-outer-container">

                {/* MENU BUTTON */}
                <div className="form-menu-container">
                    <ContextMenuButton
                        title="Form Options"
                        onToggle={toggleMenu}
                    />
                </div>

                {/* MENU */}
                <ContextMenuPopup
                    visible={menuVisible}
                    setVisible={setMenuVisible}
                    backgroundColor={theme.backgroundColorFaded}
                    width={190}
                    position={menuPosition}>

                    {/* EDIT MODE */}
                    {selectedModel && selectedObject &&
                        <>
                            {/* OBJECT OPTIONS */}
                            <ContextMenuGroup title={selectedObject[selectedModel.titleFieldKey]}>

                                {/* SAVE */}
                                <ContextMenuOption
                                    icon={SaveIcon}
                                    text="Save Changes"
                                    onClick={handleSave}
                                />

                                {/* DELETE */}
                                <ContextMenuOption
                                    icon={DeleteIcon}
                                    text="Delete Record"
                                    onClick={handleDelete}
                                />

                            </ContextMenuGroup>

                            {/* VIEW/EDIT MODE TOGGLE */}
                            <ContextMenuGroup title="Mode">

                                {formMode === FormMode.EDIT ? (
                                    <>
                                        {/* VIEW */}
                                        <ContextMenuOption
                                            icon={EyeIcon}
                                            text="View"
                                            onClick={handleViewMode}
                                        />
                                    </>
                                ) : (
                                    <>

                                        {/* EDIT */}
                                        <ContextMenuOption
                                            icon={PencilIcon}
                                            text="Edit"
                                            onClick={handleEditMode}
                                        />
                                    </>
                                )}

                            </ContextMenuGroup>
                        </>
                    }

                    {/* FORM OPTIONS */}
                    <ContextMenuGroup title={`${selectedModel.title} Form`}>

                        {/* ADD WIDGET */}
                        <ContextMenuOption
                            icon={FeatureIcon}
                            text="Add Field"
                            onClick={handleAddFeature}
                        />

                    </ContextMenuGroup>

                </ContextMenuPopup>

            </div>
        </>
    );
};

export default FormMenu;
