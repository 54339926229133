import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Utilities
import { Page } from '../../../common/utilities/Enums';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './AppBarItem.css';

// Themes
import { useTheme } from '../../../ThemeContext';

// Components
import AppDot from '../appdot/AppDot';

const AppBarItem = ({ app, count = 0 }) => {

    // Theme
    const { 
        theme 
    } = useTheme();

    // Global
    const {
        resetVisibility,
        selectedApp,
        setMenuVisible,
        setPage,
        setRunHomeVisible,
        setSelectedApp
    } = useContext(Global);

    /**
     * Handles click on the app dot.
     */
    const handleAppClick = async (app) => {
        resetVisibility();
        setSelectedApp(app);
        setRunHomeVisible(true);
        setPage(Page.RUN);

        if (isMobile) {
            setMenuVisible(true);
        }
    };

    return (
        <>
            {/* CONTAINER */}
            <div
                key={app.key}
                className="app-bar-item-container"
                onClick={() => handleAppClick(app)}
                style={{
                    borderLeftColor: app.key === selectedApp.key ? theme.highlightBackgroundColor : "transparent"
                }}>

                <div className="app-bar-item-app-container">

                    {/* APP DOT */}
                    <div className="app-bar-item-dot">
                        <AppDot
                            app={app}
                        />
                    </div>

                    {/* COUNT */}
                    {count > 0 &&
                        <div className="app-bar-item-count"
                            style={{
                                backgroundColor: theme.highlightBackgroundColor,
                                borderColor: theme.backgroundColor,
                                color: theme.highlightForegroundColor
                            }}>
                            {count}
                        </div>
                    }

                </div>

            </div>
        </>
    );
};

export default AppBarItem;
