import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global';

// Utilities
import { FormMode } from '../utilities/Enums';

// Device detection
import { isMobile } from 'react-device-detect';

// Styles
import './FormToolBar.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Image
import CloseIcon from '../../common/svg/CloseIcon';

// Components
import FormMenu from './formmenu/FormMenu';

import BookmarkButton from '../../common/objects/elements/buttons/BookmarkButton';
import CartButton from '../../common/objects/elements/buttons/CartButton';
import CommentButton from '../../common/objects/elements/buttons/CommentButton';
import DislikeButton from '../../common/objects/elements/buttons/DislikeButton';
import DownloadButton from '../../common/objects/elements/buttons/DownloadButton';
import DownvoteButton from '../../common/objects/elements/buttons/DownvoteButton';
import EmojiButton from '../../common/objects/elements/buttons/EmojiButton';
import FavoriteButton from '../../common/objects/elements/buttons/FavoriteButton';
import FollowButton from '../../common/objects/elements/buttons/FollowButton';
import LikeButton from '../../common/objects/elements/buttons/LikeButton';
import PrintButton from '../../common/objects/elements/buttons/PrintButton';
import PriorityButton from '../../common/objects/elements/buttons/PriorityButton';
import ShareButton from '../../common/objects/elements/buttons/ShareButton';
import UpvoteButton from '../../common/objects/elements/buttons/UpvoteButton';

const FormToolBar = ({ onFeatureClick, onSaveClick, onDeleteClick, onTagChange }) => {
    const { theme } = useTheme();
    const {
        formMode,
        selectedModel,
        selectedObject,
        setFormMode,
    } = useContext(Global);

    const [buttonElements, setButtonElements] = useState([]);

    // Map of button types to corresponding components
    const componentMap = {
        bookmark: BookmarkButton,
        cart: CartButton,
        comment: CommentButton,
        dislike: DislikeButton,
        download: DownloadButton,
        downvote: DownvoteButton,
        emoji: EmojiButton,
        favorite: FavoriteButton,
        follow: FollowButton,
        like: LikeButton,
        print: PrintButton,
        priority: PriorityButton,
        share: ShareButton,
        upvote: UpvoteButton,
    };

    // Effect to populate buttonElements when selectedModel changes
    useEffect(() => {
        if (selectedModel && selectedModel.rows) {
            // Iterate over rows and their nested structure to find button elements
            const buttons = selectedModel.rows.flatMap(row =>
                row.blocks.flatMap(block =>
                    block.content.filter(content => content.type === "button")
                )
            );

            setButtonElements(buttons);
        } else {
            // Reset the buttonElements if selectedModel is not available
            setButtonElements([]);
        }
    }, [selectedModel]);

    /**
     * Handles click on the close button on mobile.
     */
    const onCloseClick = () => {
        setFormMode(FormMode.EDIT);
    };

    return (
        <>
            <div className="form-toolbar-container">

                {/* TOOLBAR LEFT */}
                <div className="form-toolbar-left"
                    style={{
                        color: theme.foregroundColorFaded
                    }}>
                    {formMode === FormMode.ADD ? (
                        <>
                            Add
                        </>
                    ) : (
                        <>
                            {selectedObject && buttonElements.length > 0 && (

                                <div className="form-toolbar-buttons">

                                    {/* Iterate over the buttonElements array and render the buttons */}
                                    {buttonElements.map((button, index) => {
                                        // Get the corresponding component from the component map
                                        const ButtonComponent = componentMap[button.buttonType.toLowerCase()];

                                        // Only render the button if the component exists in the map
                                        return ButtonComponent ? (
                                            <div key={index} className="form-toobar-button">
                                                <ButtonComponent
                                                    element={button}
                                                    object={selectedObject}
                                                />
                                            </div>
                                        ) : null;
                                    })}

                                </div>

                            )}
                        </>
                    )}

                </div>

                {/* TOOLBAR RIGHT */}
                <div className="form-toolbar-right">
                    {selectedObject ? (
                        <FormMenu
                            onFeatureClick={onFeatureClick}
                            onSaveClick={onSaveClick}
                            onDeleteClick={onDeleteClick}
                        />
                    ) : (
                        <>
                            {isMobile ? (
                                <div className="form-toolbar-close-button"
                                    title="Close"
                                    onClick={onCloseClick}>
                                    <CloseIcon
                                        color={theme.foregroundColorFaded}
                                        width="16"
                                        height="16"
                                    />
                                </div>
                            ) : (
                                <FormMenu
                                    onFeatureClick={onFeatureClick}
                                    onSaveClick={onSaveClick}
                                    onDeleteClick={onDeleteClick}
                                />)
                            }
                        </>
                    )}
                </div>

            </div>
        </>
    );
};

export default FormToolBar;
