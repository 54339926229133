export const CalendarType = {
    ALL: 'ALL',
    MONTH: 'MONTH',
    WEEK: 'WEEK',
    DAY: 'DAY',
    SCHEDULE: 'SCHEDULE',
    STORYLINE: 'STORYLINE',
};

export const ChannelType = {
    TEXT: 'TEXT',
    FORUM: 'FORUM',
    VOICE: 'VOICE',
    VIDEO: 'VIDEO',
};

export const FormMode = {
    ADD: 'ADD',
    EDIT: 'EDIT',
    VIEW: 'VIEW',
};

export const LoginMode = {
    LOGIN: 'LOGIN',
    SIGNUP: 'SIGNUP'
};

export const MenuType = {
    LEFT: 'LEFT',
    TOP: 'TOP',
};

export const Page = {
    HOME: 'HOME',
    ROOT: 'ROOT',
    RUN: 'RUN',
    WELCOME: 'WELCOME',
};

export const PostMode = {
    ADD: 'ADD',
    EDIT: 'EDIT',
};

export const Section = {
    CALENDARS: 'CALENDARS',
    CHANNELS: 'CHANNELS',
    COLLECTIONS: 'COLLECTIONS',
};

export const UserPreference = {
    LAST_CALENDAR_TYPE: 'LAST_CALENDAR_TYPE',
    LAST_FORM_MODE: 'LAST_FORM_MODE',
    LAST_OBJECT_LIST_TAGS: 'LAST_OBJECT_LIST_TAGS',
    LAST_THEME: 'LAST_THEME',
    LAST_PAGE: 'LAST_PAGE',
    LAST_MENU_TYPE: 'LAST_MENU_TYPE',
};