import React from 'react';

const ChevronDownIcon = ({ color = "white", width = "24", height = "24" }) => (

  <svg
    version="1.1"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24">
    <path d="M4 9L12 17L20 9" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);

export default ChevronDownIcon;
