import React from 'react';

// Styles
import './VideoGallerySummaryPreview.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import teacher from '../../../../common/images/teacher.png';

const VideoGallerySummaryPreview = ({ element }) => {
    const { theme } = useTheme();

    const { styles = {} } = element;

    // Filter out top, left, width, height, and zIndex
    const { top, left, width, height, zIndex, ...filteredStyles } = styles;

    return (
        <div className="video-gallery-summary-preview-container"
            style={{ backgroundColor: theme.backgroundColorFaded }}>
            <div className="video-gallery-summary-preview-wrapper" style={filteredStyles}>
                <img
                    src={teacher}
                    alt="Video"
                    className="video-gallery-summary-preview-image"
                />
            </div>
        </div>
    );
};

export default VideoGallerySummaryPreview;
