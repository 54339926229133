import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './VideoChatUsers.css';

// Components
import VideoChatUser from './VideoChatUser';

const VideoChatUsers = () => {
  const {
    appUsers
  } = useContext(Global);

  return (
    <div className="video-chat-users-container">

      {appUsers.map((appUser) => (
        <VideoChatUser
          key={appUser.key}
          appUser={appUser}
        />
      ))}

    </div>
  );
};

export default VideoChatUsers;
