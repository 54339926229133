import React, { useState, useContext, useEffect } from 'react';

// Global
import { Global } from '../../../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './UserSelector.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import AppUserSummary from '../../appusers/AppUserSummary';
import Modal from '../modal/Modal';
import CheckOffIcon from '../../svg/CheckOffIcon';
import CheckOnIcon from '../../svg/CheckOnIcon';

const UserSelector = ({ selectedUsers, isOpen, onClose, onSelect, onSelectComplete }) => {
    const { theme } = useTheme();
    const { appUsers } = useContext(Global);

    // State to track selected users directly as objects
    const [checkedUsers, setCheckedUsers] = useState([]);

    /** 
     * Initializes checkedUsers directly from selectedUsers array when modal opens.
     */
    useEffect(() => {
        setCheckedUsers(selectedUsers);
    }, [selectedUsers]);

    /** 
     * Toggles selection of a user.
     */
    const handleCheck = (appUser) => {
        const isChecked = checkedUsers.find(user => user.userKey === appUser.userKey);
        const updatedCheckedUsers = isChecked 
            ? checkedUsers.filter(user => user.userKey !== appUser.userKey) // Deselect user
            : [...checkedUsers, appUser]; // Select user

        setCheckedUsers(updatedCheckedUsers);

        if (onSelect) {
            onSelect(updatedCheckedUsers);
        }
    };

    /** 
     * Handles click on the done button.
     */
    const handleComplete = () => {
        if (onSelectComplete) {
            onSelectComplete(checkedUsers); // Directly pass selected user objects
        }
        onClose();
    };

    return (
        <Modal
            title="Select Users"
            isOpen={isOpen}
            onClose={onClose}
            height="90%"
            width="90%">

            {/* CONTAINER */}
            <div className="user-selector-container" onClick={(e) => e.stopPropagation()}>

                {/* USERS */}
                <div className="user-selector-users">
                    {appUsers.map((appUser, index) => {
                        const isChecked = checkedUsers.some(user => user.userKey === appUser.userKey);
                        return (
                            <div key={appUser.userKey || index} className="user-selector-user"
                                style={{
                                    backgroundColor: theme.backgroundColorFaded,
                                    borderColor: isChecked ? theme.highlightBackgroundColor : theme.backgroundColorFaded,
                                    width: isMobile ? '100%' : '33%',
                                }}>

                                {/* CHECKBOX */}
                                <div className="user-selector-checkbox" onClick={() => handleCheck(appUser)}>
                                    {isChecked ? (
                                        <CheckOnIcon color={theme.highlightBackgroundColor} checkColor={theme.foregroundColor} width="22" height="22" />
                                    ) : (
                                        <CheckOffIcon color={theme.highlightBackgroundColor} width="22" height="22" />
                                    )}
                                </div>

                                {/* USER SUMMARY */}
                                <div className="user-selector-summary-wrapper" onClick={() => handleCheck(appUser)}>
                                    <AppUserSummary appUser={appUser} />
                                </div>

                            </div>
                        );
                    })}
                </div>

                {/* FOOTER */}
                <div className="user-selector-footer">

                    {/* DONE BUTTON */}
                    <div
                        className="user-selector-done-button"
                        style={{
                            color: theme.highlightForegroundColor,
                            backgroundColor: theme.highlightBackgroundColor
                        }}
                        onClick={handleComplete}>
                        Done
                    </div>

                </div>

            </div>

        </Modal>
    );
};

export default UserSelector;
