export const appTemplates = [
  {
    key: "SOCIAL_NETWORK",
    icon: "UsersIcon",
    name: "Social Network",
    description: "Build a space where users can post updates, chat in groups or one-on-one, and organize events."
  },
  {
    key: "SCHOOL",
    icon: "SchoolIcon",
    name: "Online Learning Platform",
    description: "Start a system for educators and students, featuring course collections, live lessons, and scheduling."
  },
  {
    key: "CHAT_SERVER",
    icon: "ForumIcon",
    name: "Chat Server",
    description: "Create a platform in which your users can chat, screenshare, and share audio/video in real-time."
  },
  {
    key: "PODCAST",
    icon: "PodcastIcon",
    name: "Podcasting Platform",
    description: "Create a space for podcasters to upload episodes, interact with listenerss, schedule episodes, and accept donations or payments."
  },
  {
    key: "CLUB",
    icon: "ClubIcon",
    name: "Club/Community Organization",
    description: "Manage members with event scheduling, real-time and forum-based discussions, and handling membership dues."
  },
  {
    key: "MUSIC",
    icon: "MusicIcon",
    name: "Music Collaboration Platform",
    description: "Create a space for musicians to share assets and ideas via chat, audio or video calls."
  },
  {
    key: "JOB_BOARD",
    icon: "JobIcon",
    name: "Freelance Job Board",
    description: "Build a marketplace where users can post and respond to job listings."
  },
  {
    key: "GAMING",
    icon: "GamingIcon",
    name: "Gaming Hub",
    description: "Allow users to share screens and video, and chat with one another while gaming."
  },
  {
    key: "STUDY",
    icon: "StudyIcon",
    name: "Study Group",
    description: "Create a system that allows you and your and study partners to collaborate and share notes with one another."
  },
  {
    key: "HEALTH",
    icon: "HealthIcon",
    name: "Personal Training Platform",
    description: "Chat with trainers, schedule workouts, and handle payments for remote sessions or fitness products."
  },
  {
    key: "REAL_ESTATE",
    icon: "HomeIcon",
    name: "Real Estate Listings App",
    description: "Browse property listings, chat with agents, schedule viewing events, and handle transactions or deposits."
  },
  /*
  {
    name: "CRM (Customer Relationship Management) App",
    description: "Manage customer data, track communication channels, schedule follow-ups, and process payments for services."
  },
  {
    name: "Project Management Tool",
    description: "A workspace like Trello or Asana with task collections, team chats, event scheduling for project deadlines, and payment tracking for contractors or freelancers."
  },
  {
    name: "Event Planning & Ticketing App",
    description: "Organize events, sell tickets, handle payments, and manage event updates or discussions through forums and chats."
  },
  {
    name: "Subscription Service App",
    description: "Manage subscriptions like Patreon, interact with subscribers via forums or video chats, release scheduled content, and process recurring payments."
  },
  {
    name: "Community Forum",
    description: "A Reddit-like app where users create topic-based collections, discuss in forums, hold real-time chats, and make payments for premium content or contributions."
  },
  {
    name: "Non-Profit/Charity Hub",
    description: "Organize events, discuss causes in forums and channels, track donations, and set up recurring payments for regular contributors."
  },
  {
    name: "Crowdfunding App",
    description: "Like Kickstarter, share project ideas, chat with backers, schedule project milestones, and process payments or pledges."
  },
  {
    name: "Book Club App",
    description: "Discuss books in forums or chat, schedule meetings, and collect payments for events or book purchases."
  },
  {
    name: "Online Marketplace for Services",
    description: "Like Fiverr or TaskRabbit, post and hire for services, chat about project details, schedule service appointments, and process payments."
  },
  {
    name: "E-Commerce Store",
    description: "Create an online store similar to Shopify or Etsy, complete with inventory management, payment gateways, chat support, and event-based sales."
  },
  {
    name: "Personal Finance Management Tool",
    description: "Track expenses, set financial goals, schedule bill reminders, discuss finance topics in forums or chat, and make payments for personal finance services or consultations."
  }
    */
];
