/**
 * Function to load only the regular variant of a font.
 * 
 * @param {string} fontName - Name of the font.
 */
export const loadFont = (fontName) => {
    const fontFamily = fontName.split(' ').join('+');  // Format font name for URL
    const link = document.createElement('link');
    link.href = `https://fonts.googleapis.com/css2?family=${fontFamily}:wght@400&display=swap`;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
};