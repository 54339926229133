import React, { useContext } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './UserGroupAddButton.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import NewIcon from '../../../svg/NewIcon';

/**
 * UserGroupAddButton Component
 * 
 * This component renders a button for adding a user-group.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const UserGroupAddButton = ({ onClick }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        selectedApp,
    } = useContext(Global);

    return (
        <>
            {/* CONTAINER */}
            <div className="user-group-add-button-container"
                onClick={onClick}>

                {/* ICON */}
                <div className="user-group-add-button-image">
                    <NewIcon
                        color={theme.foregroundColor}
                        width="18"
                        height="14"
                    />
                </div>

                {/* TEXT */}
                <div className="user-group-add-button-text"
                    style={{
                        color: theme.foregroundColor,
                        fontFamily: selectedApp.fontFamily,
                    }}>
                    Add User Group
                </div>
                
            </div>
        </>
    );
};

export default UserGroupAddButton;
