import React, { useContext, useEffect } from 'react';

// Global
import { Global } from '../Global';

// Utilities
import { defaultFont } from '../common/utilities/Defaults.js';
import { FormMode, MenuType } from '../common/utilities/Enums';
import { loadFont } from '../common/utilities/Fonts';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './Desktop.css';

// Theme
import { useTheme } from '../ThemeContext';

// Components
import AccountAbout from '../account/about/AccountAbout';
import AccountBilling from '../account/billing/AccountBilling';
import AccountMenu from '../account/menu/AccountMenu';
import AppBar from '../common/components/appbar/AppBar';
import AppSettings from '../common/appsettings/AppSettings';
import AppUsers from '../common/appusers/AppUsers';
import AppUserProfile from '../common/appusers/AppUserProfile';
import Bookmarks from '../common/bookmarks/Bookmarks';
import Calendar from '../common/calendar/Calendar';
import Chat from '../common/channels/chat/Chat';
import Favorites from '../common/favorites/Favorites';
import FieldSelector from '../common/form/fieldselector/FieldSelector';
import Form from '../common/form/Form';
import Forum from '../common/channels/forum/Forum';
import Menu from '../common/menu/Menu';
import MenuBar from '../common/menu/MenuBar';
import Messages from '../common/messages/Messages';
import Objects from '../common/objects/Objects';
import RunHome from '../run/forum/RunHome';
import TitleBar from '../common/titlebar/TitleBar';
import VideoChat from '../common/channels/videochat/VideoChat';

// Dynamic Styles
import { DesktopStyles } from './DesktopStyles';

/**
 * Desktop Component
 * 
 * This component renders a desktop version of the app.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Desktop = () => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    accountAboutVisible,
    accountVisible,
    appUsersVisible,
    bookmarksVisible,
    calendarVisible,
    chatVisible,
    favoritesVisible,
    fieldSelectorVisible,
    forumVisible,
    menuType,
    messagesVisible,
    runHomeVisible,
    selectedApp,
    setFormMode,
    setSettingsVisible,
    settingsVisible,
    videoChatVisible,
  } = useContext(Global);

  /** 
   * In desktop mode, show the add form on the right side of the screen
   */
  useEffect(() => {
    if (!isMobile) {
      setFormMode(FormMode.ADD);
    }
  }, [setFormMode]);

  const renderContent = () => {
    if (chatVisible) return <Chat />;
    if (forumVisible) return <Forum />;
    if (videoChatVisible) return <VideoChat />;
    if (runHomeVisible) return <RunHome />;
    if (calendarVisible) return <Calendar />;
    if (bookmarksVisible) return <Bookmarks />;
    if (favoritesVisible) return <Favorites />;
    if (messagesVisible) return <Messages />;
    if (appUsersVisible) return (
      <>
        <AppUsers />
        <AppUserProfile />
      </>
    );
    if (accountVisible) return (
      <>
        <AccountMenu />
        {accountAboutVisible ? <AccountAbout /> : <AccountBilling />}
      </>
    );
    return (
      <>
        <Objects />
        {fieldSelectorVisible ? (
          <div className="desktop-field-selector">
            <div className="desktop-field-selector-header"
              style={{ color: theme.foregroundColorFaded }}>
              Add Field
            </div>
            <FieldSelector
              size="LARGE"
            />
          </div>
        ) : (
          <Form />
        )}
      </>
    );
  };

  useEffect(() => {
    if (!selectedApp) return;
    loadFont(selectedApp.fontFamily || defaultFont);
  }, [selectedApp]);

  return (
    <>
      <DesktopStyles
        fontFamily={selectedApp && selectedApp.fontFamily || defaultFont}
        selectionBackground={theme.highlightBackgroundColor}
        selectionForeground={theme.highlightForegroundColor}
      />
      <div
        className="desktop-container"
        style={{
          backgroundColor: theme.backgroundColor 
        }}>

        {/* TITLE BAR */}
        {selectedApp && (
          <div className="desktop-title-bar">
            <TitleBar />
          </div>
        )}

        <div className="desktop-main">

          {/* APP BAR */}
          <div className="desktop-left">
            <AppBar />
          </div>

          <div className="desktop-right">

            {/* MENU BAR */}
            {menuType === MenuType.TOP && (
              <div className="desktop-menu-bar-wrapper">
                <MenuBar />
              </div>
            )}

            <div className="desktop-middle"
              style={{
                height: menuType === MenuType.LEFT ? "calc(100vh - 50px)" : "calc(100vh - 100px)"
              }}>

              {/* MENU */}
              {menuType === MenuType.LEFT &&
                <div className="desktop-menu-wrapper">
                  <Menu />
                </div>
              }

              {/* CONTENT */}
              {renderContent()}
            </div>
          </div>
        </div>
      </div>

      {/* SETTINGS DIALOG */}
      {selectedApp &&
        <AppSettings
          appKey={selectedApp.key}
          settingsVisible={settingsVisible}
          setSettingsVisible={setSettingsVisible}
        />
      }
    </>
  );
};

export default Desktop;
