// Firebase
import { collection, deleteDoc, doc, orderBy, onSnapshot, query, setDoc, Timestamp, updateDoc, where } from 'firebase/firestore';
import { collections, db } from '../../firebaseConfig';

// Activity
import { activity } from './ActivityManager';

class ChannelManager {

    /**
     * Deletes a channel from the Firestore database.
     * 
     * @param {string} appKey - App key.
     * @param {string} channelKey - The key of the channel to delete.
     */
    async delete(appKey, channelKey) {
        try {
            // Delete the selected channel from Firestore
            await deleteDoc(doc(db, collections.channels, channelKey));

            // Log the delete activity
            activity.log(appKey, 'deletes', 1);

            // Return success message
            return { success: true, message: "Channel deleted successfully." };

        } catch (error) {
            console.error('Error deleting channel:', error);
            // Return an error message
            return { success: false, message: "Error deleting channel." };
        }
    }

    /**
      * Fetches channels by app key and subscribes to real-time updates.
      * 
      * @param {string} appKey - App key.
      * @param {function} onUpdate - Callback function that handles the update.
      */
    listAndSubscribe(appKey, onUpdate) {
        try {
            // Create a reference to the models collection
            const channelsCollection = collection(db, collections.channels);

            // Create a query to find models by appKey and sort them by title
            const q = query(channelsCollection, where("appKey", "==", appKey), orderBy("sort"));

            // Subscribe to real-time updates
            const unsubscribe = onSnapshot(q, snapshot => {
                const channelList = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                // Call the onUpdate callback with the updated list
                if (onUpdate) {
                    onUpdate(channelList);
                    activity.log(appKey, 'reads', channelList.length);
                }
            }, error => {
                console.error("Error fetching app channels:", error);
            });

            // Return the unsubscribe function to allow the caller to unsubscribe later
            return unsubscribe;
        } catch (error) {
            console.error("Error setting up real-time updates:", error);
            throw error;
        }
    }

    /**
     * Updates a channel's data in the Firestore database.
     *
     * @param {string} appKey - App key.
     * @param {string} channelKey - The key (document ID) of the channel to update.
     * @param {object} data - An object containing the channel properties and their new values.
     */
    async update(appKey, channelKey, data) {
        try {
            const now = Timestamp.now();

            // Ensure dateModified is always updated to the current timestamp
            data.dateModified = now;

            // Update the document in Firestore
            await updateDoc(doc(db, collections.channels, channelKey), data);

            // Log the activity - assuming an activity logging function exists
            activity.log(appKey, 'writes', 1);

            // Return a success message
            return { success: true, message: "Channel updated successfully." };
        } catch (error) {
            console.error('Error updating channel:', error);
            // Return an error message
            return { success: false, message: "" };
        }
    }

}

export default ChannelManager;
