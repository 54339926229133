import React from 'react';

// Device Detections
import { isMobile } from 'react-device-detect';

// Styles
import './NavButton.css';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * NavButton Component
 * 
 * This component renders a nav button.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const NavButton = ({ icon: IconComponent, title, size, selected = false, onClick }) => {

  // Theme
  const { theme } = useTheme();

  return (
    <>
        <div className="nav-button-container"
          style={{
            borderBottomColor: !isMobile && selected ? theme.highlightBackgroundColor : "transparent"
          }}
          onClick={onClick}
          title={title}>
          <IconComponent
            color={isMobile ? theme.foregroundColor : selected ? theme.foregroundColor : theme.foregroundColorFaded}
            width={size}
            height={size}
          />
        </div>
    </>
  );
};

export default NavButton;

