import React, { useRef } from 'react';

// Styles
import './PhotoButton.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

// Images
import ImageIcon from '../../../../svg/ImageIcon';

const PhotoButton = ({ localPhotos, setLocalPhotos, photoUrls, setPhotoUrls }) => {
  const { theme } = useTheme();

  // References
  const photoInputRef = useRef(null);

  const handlePhotoUpload = (event) => {
    const files = Array.from(event.target.files);
    const newLocalPhotos = files.map(file => URL.createObjectURL(file));
    setLocalPhotos([...localPhotos, ...files]);
    setPhotoUrls([...photoUrls, ...newLocalPhotos]);
    photoInputRef.current.value = "";
  };

  return (
    <>
      {/* CONTAINER */}
      <div className="photo-button-container"
        style={{
          backgroundColor: theme.backgroundColorFaded,
        }}
        onClick={() => photoInputRef.current.click()}>

        {/* ICON */}
        <ImageIcon
          color={theme.foregroundColorFaded}
          width="18"
          height="18"
        />

        {/* INPUT */}
        <input
          ref={photoInputRef}
          id="photo-upload"
          type="file"
          accept="image/*"
          onChange={handlePhotoUpload}
          style={{ display: 'none' }}
          multiple
        />
      </div>
    </>
  );
};

export default PhotoButton;
