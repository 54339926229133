import { createGlobalStyle } from 'styled-components';

export const DesktopStyles = createGlobalStyle`

    body {
      margin: 0;
      font-family: ${(props) => props.fontFamily};
      display: flex;
      flex-direction: column;
    }

    ::selection {
        background: ${(props) => props.selectionBackground};
        color: ${(props) => props.selectionForeground};
    }

    input::selection, textarea::selection {
      background: ${(props) => props.selectionBackground};
      color: ${(props) => props.selectionForeground};
    }

    input::-moz-selection, textarea::-moz-selection {
      background: ${(props) => props.selectionBackground};
      color: ${(props) => props.selectionForeground};
    }
    
`;

