import React, { useContext, useEffect, useState, useCallback } from 'react';

// Global
import { Global } from '../../Global';
// Utilities
import { FormMode, UserPreference } from '../utilities/Enums';

// Device Detection
import { isMobile, isTablet } from 'react-device-detect';

// Debounce
import { debounce } from 'lodash'; 

// Styles
import './Objects.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import AddButton from '../../common/components/addbutton/AddButton';
import Divider from '../../common/components/divider/Divider';
import Object from './Object';
import ObjectsToolBar from './ObjectsToolBar';
import Table from '../../desktop/table/Table';
import TagSelector from '../../common/components/tagselector/TagSelector';

// Managers
import ObjectManager from '../../common/managers/ObjectManager';
import UserManager from '../../common/managers/UserManager';

const objectManager = new ObjectManager();
const userManager = new UserManager();

const Objects = () => {
    const { theme } = useTheme();
    const {
        currentUser,
        formMode,
        models,
        objects,
        objectsView,
        selectedApp,
        selectedModel,
        selectedObjectTags,
        setCurrentUser,
        setFormMode,
        setObjects,
        setObjectsView,
        setSelectedObject,
        setSelectedObjectTags,
        setSortField,
        sortField,
        sortDirection
    } = useContext(Global);

    // State Variables
    const [width, setWidth] = useState("50%");
    const [viewedItems, setViewedItems] = useState([]);  // Track items that have been viewed
    
    // Debounce function for batch view updates
    const batchUpdateViews = useCallback(
        debounce((items) => {
            if (items.length > 0) {
                objectManager.updateViewCount(selectedApp.key, selectedModel.key, items);
            }
        }, 1000),
        []
    );

    // Set the default view mode to the title
    useEffect(() => {
        if (!selectedModel) return;
        setSortField(selectedModel.titleFieldKey);
    }, [selectedModel]);

    // Adjust the width and list height based on view type and device
    useEffect(() => {
        setWidth(objectsView === "TABLE" 
            ? "100%"
            : (isMobile || isTablet ? "100%" : "50%"));
    }, [objectsView]);

    // Handle fetching objects and real-time updates
    useEffect(() => {
        if (!models || models.length === 0 || !selectedModel) {
            setObjects([]);
            return;
        }
    
        const handleUpdate = (items) => {
            items.sort((a, b) => {
                let valueA, valueB;
                switch (sortField) {
                    case 'CREATED':
                        valueA = a.dateCreated;
                        valueB = b.dateCreated;
                        break;
                    case 'MODIFIED':
                        valueA = a.dateModified;
                        valueB = b.dateModified;
                        break;
                    default:
                        valueA = a[sortField]?.toLowerCase() || '';
                        valueB = b[sortField]?.toLowerCase() || '';
                }
    
                return sortDirection === 'ASC' 
                    ? valueA.localeCompare(valueB) 
                    : valueB.localeCompare(valueA);
            });
    
            setObjects(prevObjects => 
                JSON.stringify(prevObjects) !== JSON.stringify(items) ? items : prevObjects
            );
        };
    
        // Subscribe to real-time updates, optionally filtered by tags
        const unsubscribe = objectManager.listAndSubscribe(
            selectedApp.key,
            selectedModel.key,
            handleUpdate,
            selectedObjectTags
        );
    
        // Cleanup: Ensure that unsubscribe is a function before calling it
        return () => {
            if (typeof unsubscribe === 'function') {
                unsubscribe();  // Ensure unsubscribe is called only if it's a function
            }
        };
    
    }, [selectedModel, sortField, sortDirection, models, selectedApp.key, setObjects, selectedObjectTags]);
    
    // Handle view count updates
    /*
    const handleItemsRendered = (start, end) => {
        const newlyViewedKeys = objects.slice(start, end + 1)
            .map(obj => obj.key)
            .filter(key => !viewedItems.includes(key));

        if (newlyViewedKeys.length > 0) {
            setViewedItems(prev => [...prev, ...newlyViewedKeys]);
            batchUpdateViews(newlyViewedKeys);
        }
    };
    */

    // Handle the click event for the add button
    const handleAddClick = () => {
        setObjectsView("LIST");
        setSelectedObject(null);
        setFormMode(FormMode.ADD);
    };
    
    // Set the tags according to saved preferences for the selected model
    useEffect(() => {
        if (!selectedModel || !currentUser || !selectedApp.key) return;
        
        const fetchTagsPreference = async () => {
            // Retrieve the saved preference object containing tags for all models
            const lastTagsObject = await userManager.getPreference(
                currentUser,
                selectedApp.key,
                UserPreference.LAST_OBJECT_LIST_TAGS
            );
    
            // Check if there are saved tags for the current selectedModel
            if (lastTagsObject && lastTagsObject[selectedModel.key]) {
                setSelectedObjectTags(lastTagsObject[selectedModel.key]); // Set tags for the current model
            } else {
                setSelectedObjectTags([]); // Default to an empty array if no tags are saved for this model
            }
        };
    
        fetchTagsPreference();
    }, [selectedModel, currentUser, selectedApp.key, setSelectedObjectTags]);
    
    // Handle tag selection updates and save preferences for the selected model
    const handleTagSelection = async (tags) => {
        setSelectedObjectTags(tags); // Update local state
    
        // Retrieve the current preference object, if it exists
        const lastTagsObject = await userManager.getPreference(
            currentUser,
            selectedApp.key,
            UserPreference.LAST_OBJECT_LIST_TAGS
        ) || {}; // Default to an empty object if no preference is saved
    
        // Update the preference object with tags for the selected model key
        lastTagsObject[selectedModel.key] = tags;
    
        // Save the updated preference object
        await userManager.setPreference(
            currentUser,
            setCurrentUser,
            selectedApp.key,
            UserPreference.LAST_OBJECT_LIST_TAGS,
            lastTagsObject
        );
    };
    

    return (
        <div 
            className={isMobile || isTablet ? "objects-container-mobile" : "objects-container"}
            style={{ borderRightColor: theme.backgroundColorFaded, width }}
        >
            {/* TOOLBAR */}
            <ObjectsToolBar />

            {/* TAG SELECTOR */}
            <div className="objects-tag-selector">
                <TagSelector
                    tags={selectedModel?.tags || []}
                    selectedTags={selectedObjectTags}
                    onTagClick={handleTagSelection}
                />
            </div>

            {/* RENDER LIST OF OBJECTS */}
            {objectsView === "TABLE" ? (
                <Table />
            ) : (
                <div 
                    className="objects-list">
                    {objects.map((object, index) => (
                        <div key={object.key}>
                            <Object object={object} />
                            <Divider />
                        </div>
                    ))}
                </div>
            )}

            {/* ADD BUTTON */}
            {selectedModel && formMode !== FormMode.ADD && (
                <div className="objects-add-button">
                    <AddButton onClick={handleAddClick} />
                </div>
            )}
        </div>
    );
};

export default Objects;
