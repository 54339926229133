import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './AccountMenuToolBar.css';

// Theme
import { useTheme } from '../../ThemeContext';

const AccountMenuToolBar = () => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        selectedApp,
    } = useContext(Global);

    return (

        <div className="account-menu-toolbar-container">
            <div className="account-menu-toolbar-left"
                style={{ 
                    color: theme.foregroundColorFaded,
                    fontFamily: selectedApp && selectedApp.fontFamily,
                }}>
                My Account
            </div>
            <div className="account-menu-toolbar-right" >
                
            </div>
        </div>

    );
};

export default AccountMenuToolBar;
