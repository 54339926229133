import React, { createContext, useEffect, useState } from 'react';

// Utilities
import { CalendarType, FormMode, MenuType } from './common/utilities/Enums';

export const Global = createContext();

export const GlobalProvider = ({ children }) => {

  // Form Mode
  /**
   * Indicates the mode of the form. Possible values are:
   * - ADD
   * - EDIT
   * - VIEW
   */
  const [formMode, setFormMode] = useState(FormMode.VIEW);

  // Navigation
  /**
   * Handles click events on the back button.
   * - Sets visibility of the back button.
   * - Sets the function to execute on back button click.
   */
  const [backVisible, setBackVisible] = useState(false);
  const [backButtonAction, setBackButtonAction] = useState(null);

  // App
  const [appAddVisible, setAppAddVisible] = useState(false);
  const [settingsVisible, setSettingsVisible] = useState(false);

  // Menu
  const [menuType, setMenuType] = useState(MenuType.TOP);

  // User Groups
  const [userGroups, setUserGroups] = useState([]);

  const [selectedForumTags, setSelectedForumTags] = useState([]);
  const [selectedObjectTags, setSelectedObjectTags] = useState([]);

  const [appTitle, setAppTitle] = useState("Create your own platform.");
  const [currentUser, setCurrentUser] = useState(null);
  const [eventVisible, setEventVisible] = useState(null);
  const [fieldVisible, setFieldVisible] = useState(false);
  const [invite, setInvite] = useState(null);
  const [loginMode, setLoginMode] = useState("SIGNUP");
  const [menuVisible, setMenuVisible] = useState(false);
  const [page, setPage] = useState("ROOT");
  const [progressVisible, setProgressVisible] = useState(false);
  const [statusMessage, setStatusMessage] = useState("Ready.");
  const [userAccountOpen, setUserAccountOpen] = useState(false);
  const [userSearchVisible, setUserSearchVisible] = useState(false);

  // Bookmarks
  const [bookmarks, setBookmarks] = useState([]); // Bookmarks for the current user
  const [bookmarksVisible, setBookmarksVisible] = useState(false);

  // Favorites
  const [favorites, setFavorites] = useState([]); // Favorites for the current user
  const [favoritesVisible, setFavoritesVisible] = useState(false);

  // Studio
  const [currentCanvas, setCurrentCanvas] = useState("FORM");
  const [currentDevice, setCurrentDevice] = useState("MOBILE");
  const [menuCollapsed, setMenuCollapsed] = useState(false);
  const [studio, setStudio] = useState(null);

  // Apps
  const [appModalOpen, setAppModalOpen] = useState(false);
  const [apps, setApps] = useState([]);
  const [selectedApp, setSelectedApp] = useState(null);

  // Calendars
  const [calendars, setCalendars] = useState([]);
  const [calendarAddOpen, setCalendarAddOpen] = useState(false);
  const [selectedCalendar, setSelectedCalendar] = useState(null);
  const [selectedCalendarTags, setSelectedCalendarTags] = useState([]);
  const [selectedCalendarType, setSelectedCalendarType] = useState(CalendarType.DAY);
  const [defaultEventTag, setDefaultEventTag] = useState(false);

  // Collections
  const [models, setModels] = useState([]);
  const [collectionAddOpen, setCollectionAddOpen] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);

  // Fields
  const [appFields, setAppFields] = useState([]);
  const [fields, setFields] = useState([]);
  const [fieldSelectorVisible, setFieldSelectorVisible] = useState(false);
  const [summaryFields, setSummaryFields] = useState([]);
  const [selectedField, setSelectedField] = useState(null);
  const [fieldFilter, setFieldFilter] = useState("");

  // Run
  const [appSummaries, setAppSummaries] = useState([]); // By app
  const [changesMade, setChangesMade] = useState(false);
  const [currentObject, setCurrentObject] = useState({}); // Default ADD object is empty
  const [donateVisible, setDonateVisible] = useState(false);
  const [formFields, setFormFields] = useState([]);
  const [lastCamera, setLastCamera] = useState(""); // Barcode Scanning
  const [objects, setObjects] = useState([]); // For currently selected model
  const [runHomeVisible, setRunHomeVisible] = useState(true);
  const [selectedObject, setSelectedObject] = useState(null);
  const [sortField, setSortField] = useState(''); // Object sorting
  const [sortDirection, setSortDirection] = useState('ASC'); // Object sorting
  const [objectsView, setObjectsView] = useState("LIST");

  // Calendar
  const [activeDate, setActiveDate] = useState(new Date());
  const [calendarTitle, setCalendarTitle] = useState("Calendar");
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [currentYear, setCurrentYear] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(null);
  const [currentWeek, setCurrentWeek] = useState(null);
  const [currentDay, setCurrentDay] = useState(null);
  const [defaultEventDate, setDefaultEventDate] = useState(null);
  const [events, setEvents] = useState([]);
  const [messagesVisible, setMessagesVisible] = useState(false);
  const [monthPickerVisible, setMonthPickerVisible] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  // Conversations
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);

  // Financials
  const [targetApp, setTargetApp] = useState("ALL");
  const [timespan, setTimespan] = useState("LAST24"); // CURRENT, LAST60, LAST24, LAST7, LAST30
  const [userApps, setUserApps] = useState([]);

  // Account
  const [accountVisible, setAccountVisible] = useState(false);
  const [accountAboutVisible, setAccountAboutVisible] = useState(false);
  const [accountBillingVisible, setAccountBillingVisible] = useState(false);

  // App Users
  const [appUsers, setAppUsers] = useState([]);
  const [appUsersVisible, setAppUsersVisible] = useState(false);
  const [selectedAppUser, setSelectedAppUser] = useState(null);

  // Headlines
  const [headlines, setHeadlines] = useState([]);
  const [editingHeadlines, setEditingHeadlines] = useState(false);

  // Designer
  const [elements, setElements] = useState([]);
  const [selectedElement, setSelectedElement] = useState(null);

  // User Elements
  const [userElements, setUserElements] = useState([]);

  // Groups
  const [groups, setGroups] = useState([]);
  const [selectedLookup, setSelectedLookup] = useState(null);

  // Channels
  const [channels, setChannels] = useState([]);
  const [chatVisible, setChatVisible] = useState(false);
  const [forumVisible, setForumVisible] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [channelAddOpen, setChannelAddOpen] = useState(false);
  const [selectedForumPost, setSelectedForumPost] = useState(null);
  const [videoChatVisible, setVideoChatVisible] = useState(false);
  const [selectedBlock, setSelectedBlock] = useState(null);

  // Set up the studio theme
  useEffect(() => {

    const data = {
      backgroundColor: "#0f0f0f",
      backgroundColorFaded: "#1F1F1F",
      highlightBackgroundColor: "#5E3023", //"#cc3e20", //"#5087ed"
      highlightForegroundColor: "#FFFFFF", //"#8d4834", 
      foregroundColor: "#FFFFFF",
      foregroundColorFaded: "#999999"
    };

    /* hudl.com
    const data = {
      backgroundColor: "#1a1f24",
      backgroundColorFaded: "#242a30",
      highlightBackgroundColor: "#d7591a", //"#cc3e20", //"#5087ed"
      highlightForegroundColor: "#FFFFFF", //"#8d4834", 
      foregroundColor: "#FFFFFF",
      foregroundColorFaded: "#999999"
    };
    */

    setStudio(data);

  }, []);

  // Method to show the progress panel
  const showProgress = (message) => {
    setStatusMessage(message);
    setProgressVisible(true);
  };

  // Method to hide the progress panel
  const hideProgress = () => {
    setProgressVisible(false);
    setStatusMessage("Ready.");
  };

  const resetVisibility = () => {
    setAccountVisible(false);
    setAppUsersVisible(false);
    setBookmarksVisible(false);
    setCalendarVisible(false);
    setChatVisible(false);
    setFavoritesVisible(false);
    setForumVisible(false);
    setDonateVisible(false);
    setFieldSelectorVisible(false);
    setMenuVisible(false);
    setMessagesVisible(false);
    setRunHomeVisible(false);
    setSelectedAppUser(null);
    setSelectedCalendar(null);
    setSelectedChannel(null);
    setSelectedConversation(null);
    setSelectedForumPost(null);
    setSelectedModel(null);
    setSelectedObject(null);
    setSettingsVisible(false);
    setVideoChatVisible(false);
  };

  // Navigation
  const changePage = (newPage) => {
    // Check the value of the new page
    if (newPage === "RUN") {
      setSelectedField(null);
    } else if (newPage === "STUDIO") {
      setSelectedModel(null);
      setSelectedObject(null);
    } else if (newPage === "HOME") {
      setSelectedApp(null);
      setSelectedModel(null);
      setSelectedField(null);
      setSelectedModel(null);
      setSelectedObject(null);
    }
    // Finally, set the new page
    setPage(newPage);
  };

  // Utility to retrieve an app user from the appUsers cache
  const getAppUser = (userKey) => {
    const user = appUsers.find((appUser) => 
      appUser.appKey === selectedApp.key && 
      appUser.userKey === userKey);
    return user || null;
  };

  return (
    <Global.Provider value={{

      // Functions
      changePage,
      getAppUser,
      resetVisibility,

      // Form Mode
      formMode, setFormMode,

      // Navigation
      backVisible, setBackVisible,
      backButtonAction, setBackButtonAction,

      // App
      appAddVisible, setAppAddVisible,
      settingsVisible, setSettingsVisible,

      // Menu
      menuType, setMenuType,

      // User Groups
      userGroups, setUserGroups,

      // Selected Tags
      selectedForumTags, setSelectedForumTags,
      selectedObjectTags, setSelectedObjectTags,

      appTitle, setAppTitle,
      currentUser, setCurrentUser,
      eventVisible, setEventVisible,
      invite, setInvite,
      loginMode, setLoginMode,
      menuVisible, setMenuVisible,
      page, setPage,
      progressVisible, showProgress, hideProgress,
      statusMessage, setStatusMessage,
      userAccountOpen, setUserAccountOpen,
      userSearchVisible, setUserSearchVisible,
      fieldVisible, setFieldVisible,

      // Bookmarks
      bookmarks, setBookmarks,
      bookmarksVisible, setBookmarksVisible,

      // Favorites
      favorites, setFavorites,
      favoritesVisible, setFavoritesVisible,

      // Studio
      currentCanvas, setCurrentCanvas,
      currentDevice, setCurrentDevice,
      menuCollapsed, setMenuCollapsed,
      studio, setStudio,

      // Apps
      appModalOpen, setAppModalOpen,
      apps, setApps,
      selectedApp, setSelectedApp,

      // Calendars
      calendars, setCalendars,
      calendarAddOpen, setCalendarAddOpen,
      selectedCalendar, setSelectedCalendar,
      selectedCalendarTags, setSelectedCalendarTags,
      selectedCalendarType, setSelectedCalendarType,
      defaultEventTag, setDefaultEventTag,

      // Collections
      models, setModels,
      collectionAddOpen, setCollectionAddOpen,
      selectedModel, setSelectedModel,

      // Fields
      appFields, setAppFields,
      fields, setFields,
      fieldSelectorVisible, setFieldSelectorVisible,
      summaryFields, setSummaryFields,
      selectedField, setSelectedField,
      fieldFilter, setFieldFilter,

      // Run
      appSummaries, setAppSummaries,
      changesMade, setChangesMade,
      currentObject, setCurrentObject,
      donateVisible, setDonateVisible,
      formFields, setFormFields,
      lastCamera, setLastCamera,
      objects, setObjects,
      runHomeVisible, setRunHomeVisible,
      selectedObject, setSelectedObject,
      sortField, setSortField,
      sortDirection, setSortDirection,
      objectsView, setObjectsView,

      // Calendar
      activeDate, setActiveDate,
      calendarTitle, setCalendarTitle,
      calendarVisible, setCalendarVisible,
      defaultEventDate, setDefaultEventDate,
      events, setEvents,
      messagesVisible, setMessagesVisible,
      monthPickerVisible, setMonthPickerVisible,
      selectedEvent, setSelectedEvent,
      currentYear, setCurrentYear,
      currentMonth, setCurrentMonth,
      currentWeek, setCurrentWeek,
      currentDay, setCurrentDay,
      selectedTime, setSelectedTime,

      // Conversations
      conversations, setConversations,
      selectedConversation, setSelectedConversation,

      // Financials
      targetApp, setTargetApp,
      timespan, setTimespan,
      userApps, setUserApps,

      // Account
      accountVisible, setAccountVisible,
      accountAboutVisible, setAccountAboutVisible,
      accountBillingVisible, setAccountBillingVisible,

      // App Users
      appUsers, setAppUsers,
      appUsersVisible, setAppUsersVisible,
      selectedAppUser, setSelectedAppUser,

      // Headlines
      headlines, setHeadlines,
      editingHeadlines, setEditingHeadlines,

      // Designer
      elements, setElements,
      selectedElement, setSelectedElement,

      // User Elements
      userElements, setUserElements,

      // Groups
      groups, setGroups,
      selectedLookup, setSelectedLookup,

      // Channels
      channels, setChannels,
      chatVisible, setChatVisible,
      forumVisible, setForumVisible,
      videoChatVisible, setVideoChatVisible,
      selectedChannel, setSelectedChannel,
      channelAddOpen, setChannelAddOpen,
      selectedForumPost, setSelectedForumPost,
      selectedBlock, setSelectedBlock,

    }}>
      {children}
    </Global.Provider>
  );
};
