import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './AppSettingsDescription.css';

// Theme
import { useTheme } from '../../ThemeContext';

const AppSettingsDescription = ({ description, setDescription, onBlur }) => {
    const { theme } = useTheme();
    const {
        selectedApp
    } = useContext(Global);

    const [currentCount, setCurrentCount] = useState(0);

    const maxCount = 160;

    useEffect(() => {
        if (!selectedApp) return;
        setCurrentCount(description.length);
    }, [description, selectedApp]);

    const handleChange = (e) => {
        const text = e.target.value;
        setDescription(text);
        setCurrentCount(text.length);
    };

    const handleBlur = (e) => {
        const text = e.target.value;
        if (onBlur) onBlur(text);
    };

    return (

        <div className="app-settings-description-container">
            <div className="app-settings-description-label"
                style={{
                    color: theme.foregroundColorFaded,
                    fontFamily: selectedApp.fontFamily
                }}>
                Description
            </div>
            <textarea
                name="description"
                className="app-settings-description-textarea"
                style={{
                    color: theme.foregroundColor,
                    backgroundColor: theme.backgroundColorFaded,
                    borderColor: theme.backgroundColorFaded,
                    fontFamily: selectedApp.fontFamily || 'Open Sans',
                    width: isMobile ? '100%' : '350px'
                }}
                value={description}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Description"
                maxLength="250"
            ></textarea>
            <div className="app-settings-description-count"
                style={{ color: theme.foregroundColorFaded }}
            >
                {`${currentCount}/${maxCount}`}
            </div>
        </div>

    );
};

export default AppSettingsDescription;
