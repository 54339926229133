import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './UserFieldsGroup.css';

// Theme
import { useTheme } from '../../../ThemeContext';

const UserFieldsGroup = ({ title, children }) => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
      selectedApp,
  } = useContext(Global);

  return (
    <>
      <div className="user-fields-group-section"
        style={{
          borderColor: theme.backgroundColorFaded
        }}>
        {title &&
          <div className="user-fields-group-title"
            style={{
              color: theme.foregroundColorFaded,
              fontFamily: selectedApp.fontFamily
            }}>
            {title}
          </div>
        }
        {children}
      </div>
    </>
  );
};

export default UserFieldsGroup;
