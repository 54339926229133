import React, { useEffect, useState } from 'react';

// Styles
import './DatePicker.css';

// Theme
import { useStyle, useTheme } from '../../../ThemeContext';

// DayPicker
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

const DayPickerStyles = useStyle`
    .rdp {
        --rdp-accent-color: ${(props) => props.highlightColor} !important;
        --rdp-background-color: transparent !important;
    }
    .rdp-caption {
        padding: 12px !important;
    }
    .rdp-caption-button {
        background-color: transparent !important;
        cursor: pointer;
    }
    .rdp-caption-select {
        background-color: transparent !important;
        border: none;
        cursor: pointer;
        font-size: 12pt;
        font-weight: bold;
    }
    .rdp-caption-title {
        font-size: 12pt;
        font-weight: bold;
    }
`;

// Custom caption component with year selector
const CustomCaption = ({ currentMonth, onMonthChange, onYearChange }) => {
    const { theme } = useTheme();
    if (!currentMonth) {
        currentMonth = new Date(); // Default to the current date if undefined
    }

    const currentYear = currentMonth.getFullYear();
    const years = [];
    for (let i = currentYear - 120; i <= currentYear + 10; i++) {
        years.push(i);
    }

    const handleYearChange = (e) => {
        const newYear = parseInt(e.target.value, 10);
        onYearChange(newYear);
    };

    const handleMonthChange = (delta) => {
        onMonthChange(delta);
    };

    return (
        <div className="rdp-caption">
            <span className="rdp-caption-title">{currentMonth.toLocaleString('default', { month: 'long' })}</span>
            <div
                className="rdp-caption-button"
                style={{ color: theme.foregroundColor }}
                onClick={() => handleMonthChange(-1)}>{'<'}</div>
            <div
                className="rdp-caption-button"
                style={{ color: theme.foregroundColor }}
                onClick={() => handleMonthChange(1)}>{'>'}</div>
            <select
                className="rdp-caption-select"
                style={{ color: theme.foregroundColor }}
                value={currentYear}
                onChange={handleYearChange}>
                {years.map((year) => (
                    <option key={year} value={year}>
                        {year}
                    </option>
                ))}
            </select>
        </div>
    );
};

const DatePicker = ({
    isOpen,
    selectedDate,
    onClose,
    onDateSelect
}) => {

    // Theme
    const { theme } = useTheme();

    // State Variables
    const [isAnimating, setIsAnimating] = useState(false);
    const [currentMonth, setCurrentMonth] = useState(new Date());

    // Fade
    useEffect(() => {
        if (isOpen) {
            // Trigger the animation
            setIsAnimating(true);
        } else {
            setIsAnimating(false);
        }
    }, [isOpen]);

    const handleMonthChange = (delta) => {
        const newDate = new Date(currentMonth);
        newDate.setMonth(newDate.getMonth() + delta);
        setCurrentMonth(newDate);
    };

    const handleYearChange = (newYear) => {
        const newDate = new Date(currentMonth);
        newDate.setFullYear(newYear);
        setCurrentMonth(newDate);
    };

    const handleSelect = (date) => {
        if (date) {
            onDateSelect(date);
        }
    };

    if (!isOpen && !isAnimating) return null;

    return (
        <div
            className="date-picker-overlay"
            onMouseDown={(e) => {
                onClose();
            }}>
            <div
                className="date-picker-content"
                onMouseDown={(e) => {
                    e.stopPropagation();
                }}
                style={{
                    backgroundColor: theme.backgroundColor,
                    color: theme.foregroundColor,
                    opacity: isAnimating ? 1 : 0, transition: 'opacity 300ms'
                }}>

                <DayPickerStyles highlightColor={theme.highlightBackgroundColor} />

                <DayPicker
                    mode="single"
                    selected={selectedDate}
                    onSelect={handleSelect}
                    month={currentMonth}
                    captionLayout="dropdown"
                    components={{
                        Caption: (props) => (
                            <CustomCaption
                                currentMonth={currentMonth}
                                onMonthChange={handleMonthChange}
                                onYearChange={handleYearChange}
                            />
                        )
                    }}
                />
            </div>
        </div>
    );
};

export default DatePicker;
