// Firebase
import { collection, doc, deleteDoc, getDocs, query, setDoc, updateDoc, where } from 'firebase/firestore';
import { collections, db } from '../../firebaseConfig';

// Activity
import { activity } from './ActivityManager';

class LinkManager {

    /**
     * Method to add a new link.
     * 
     * @param {string} key - The new key of the link.
     * @param {string} data - Link data.
     * @returns {object} - New link.
     */
    async add(key, data) {

        await setDoc(doc(db, collections.links, key), data);

        activity.log(key, 'writes', 1);

        return data;
    }

    /**
     * Fetches an link with the specified url.
     * 
     * @param {string} url - Url to check for.
     * @returns {Promise<Object|null>} A promise that resolves to the link object if found, or null if not found.
    */
    async fetchByUrl(url) {
        try {
            const coll = collection(db, collections.links);
            const q = query(coll, where("url", "==", url));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                return querySnapshot.docs[0].data();
            } else {
                return null;
            }
        } catch (error) {
            console.error("Error fetching link by url:", error);
            throw error;
        }
    }

    /**
     * Fetches an link.
     * 
     * @param {string} key - Link key.
     * @returns {Promise<Object|null>} A promise that resolves to the link object if found, or null if not found.
    */
    async fetch(key) {
        try {
            const coll = collection(db, collections.links);
            const q = query(coll, where("key", "==", key));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                return querySnapshot.docs[0].data();
            } else {
                return null;
            }
        } catch (error) {
            console.error("Error fetching link:", error);
            throw error;
        }
    }

    /**
     * Updates an link's data in the database.
     * 
     * @param {string} key - The key (document ID) of the link to update.
     * @param {string} data - Link-related data.
     * @returns {Promise<Object>} A promise that resolves to an object indicating the operation's success or failure.
     */
    async update(key, data) {
        try {

            // Update the document in Firestore
            await updateDoc(doc(db, collections.links, key), data);

            activity.log(key, 'writes', 1);

            // Return a success message
            return { success: true, message: "Link updated successfully." };
        } catch (error) {
            console.error('Error updating link:', error);
            // Return an error message
            return { success: false, message: "Error updating link." };
        }
    }

    /**
     * Deletes a link.
     * 
     * @param {string} key - link key.
     */
    async delete(key) {
        try {

            await deleteDoc(doc(db, collections.links, key));

        } catch (error) {
            console.error('Error deleting link:', error);
            return { success: false, message: "Error deleting link." };
        }
    }
}

export default LinkManager;
