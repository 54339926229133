import React from 'react';

// Styles
import './ModalMessage.css';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * ModalMessage Component
 * 
 * This component renders a message for a modal item.
 * 
 * @param {string} text - The text of the message.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ModalMessage = ({ text }) => {

  // Theme
  const { theme } = useTheme();

  return (
    <div className="modal-message-container"
      style={{
        color: theme.foregroundColor
      }}>
      {text}
    </div>
  );
};

export default ModalMessage;
