import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './LoginUsername.css';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * LoginUsername Component
 * 
 * This component renders an input for a new user's username.
 * 
 * @param {function} onChange - The function to call with updated values.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const LoginUsername = ({ onChange }) => {
  const { theme } = useTheme();
  const {
    selectedApp,
    studio
  } = useContext(Global);

  /**
   * Method to handle username input change.
   * 
   * @param {Event} e - Change event
   */
  const handleUsernameChange = async (e) => {
    onChange(e.target.value.trim());
  };

  return (
    <>

      {/* CONTAINER */}
      <div className="login-username-container">

        {/* USERNAME LABEL */}
        <div
          className="login-username-label"
          style={{
            color: selectedApp ? theme.foregroundColorFaded : studio.foregroundColorFaded
          }}>
          Username, Email, or Phone # 
        </div>

        {/* USERNAME INPUT */}
        <input
          type="text"
          className="login-username-input"
          style={{
            color: studio.foregroundColor,
            backgroundColor: selectedApp ? theme.backgroundColorFaded : studio.backgroundColorFaded
          }}
          onChange={handleUsernameChange}
          required
        />

      </div>

    </>
  );
};

export default LoginUsername;
