import React, { useRef } from 'react';

// Styles
import './TextSummary.css';

// Theme
import { useTheme } from '../../../ThemeContext';

const TextSummary = ({ element, object }) => {
    const { theme, getProcessedStyles } = useTheme();
    const containerRef = useRef(null);

    const { styles = {} } = element;

    // Filter out position and size styles handled by parent
    const { top, left, width, height, zIndex, ...filteredStyles } = styles;

    const processed = getProcessedStyles(filteredStyles);

    return (
        <>
            <div
                className={`text-summary-container`}
                ref={containerRef}
                style={{ ...processed, height }}>
                {object[element.field.key]}
            </div>
        </>
    );
};

export default TextSummary;
