import React, { useContext, useEffect, useState } from 'react';

// Global context and styles
import { Global } from '../../../../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Utilities
import { dayNamesLetter, dayNamesShort } from '../../../utilities/DateTime';

// Utilities
import { CalendarType } from '../../../utilities/Enums';

// Styles
import './CalendarWeekHeader.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

/**
 * CalendarWeekHeader Component
 * 
 * @returns {JSX.Element} The rendered component.
 */
const CalendarWeekHeader = ({ daysToShow }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        setActiveDate,
        setSelectedCalendarType,
    } = useContext(Global);

    const [dayNames, setDayNames] = useState([]);

    // Sets day names according to device type
    useEffect(() => {
        if (isMobile) {
            setDayNames(dayNamesLetter);
        } else {
            setDayNames(dayNamesShort);
        }
    }, []);

    // Get the current date without time
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const handleDayHeaderClick = (day) => {
        setActiveDate(day);
        setSelectedCalendarType(CalendarType.DAY);
    };

    return (
        <>
            {daysToShow.map((day, index) => {
                // Remove time from `day` for date-only comparison
                const isCurrentDate = day.toDateString() === today.toDateString();

                return (
                    <div key={index}
                        className="calendar-week-header-day">
                        <div
                            onClick={() => handleDayHeaderClick(day)}
                            className="calendar-week-header-day-text"
                            style={{
                                backgroundColor: isCurrentDate ? theme.highlightBackgroundColor : "transparent",
                                color: isCurrentDate ? theme.highlightForegroundColor : theme.foregroundColor,
                            }}>
                            <span>{dayNames[index]}</span>
                            <div className="calendar-week-header-day-date">{day.getDate()}</div>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default CalendarWeekHeader;
