import React from 'react';

// Styles
import './CanvasButtons.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import AddRowIcon from '../../../../common/svg/AddRowIcon';
import AlignLeftIcon from '../../../../common/svg/AlignLeftIcon';
import AlignCenterIcon from '../../../../common/svg/AlignCenterIcon';
import AlignRightIcon from '../../../../common/svg/AlignRightIcon';
import DeleteIcon from '../../../../common/svg/DeleteIcon';
import SplitVerticalIcon from '../../../../common/svg/SplitVerticalIcon';

// Components
import CanvasButton from './CanvasButton';

const CanvasButtons = ({ 
    selectedRow,
    selectedBlock,
    selectedElement,
    onAddRow,
    onDeleteRow, 
    onSplitBlock,
    onDeleteBlock,
    onAlignLeft,
    onAlignCenter,
    onAlignRight,
    onDeleteElement
}) => {
    // Theme
    const { theme } = useTheme();

    return (
        <div className="canvas-buttons-container"
            style={{
                color: theme.foregroundColor,
            }}>

            {/* ADD ROW */}
            <CanvasButton
                title="Add Row"
                icon={AddRowIcon}
                onClick={onAddRow}
            />

            {/* DELETE ROW */}
            {selectedRow && (
                <CanvasButton
                    title="Delete Row"
                    icon={DeleteIcon}
                    onClick={onDeleteRow}
                />
            )}

            {/* Controls for Selected Block */}
            {selectedBlock && (
                <>
                    <CanvasButton
                        title="Split Block"
                        icon={SplitVerticalIcon}
                        onClick={onSplitBlock}
                    />
                    <CanvasButton
                        title="Delete Block"
                        icon={DeleteIcon}
                        onClick={onDeleteBlock}
                    />
                    <CanvasButton
                        title="Align Left"
                        icon={AlignLeftIcon}
                        onClick={onAlignLeft}
                    />
                    <CanvasButton
                        title="Align Center"
                        icon={AlignCenterIcon}
                        onClick={onAlignCenter}
                    />
                    <CanvasButton
                        title="Align Right"
                        icon={AlignRightIcon}
                        onClick={onAlignRight}
                    />
                </>
            )}

            {/* Controls for Selected Element */}
            {selectedElement && (
                <CanvasButton
                    title="Delete"
                    icon={DeleteIcon}
                    onClick={onDeleteElement}
                />
            )}

        </div>
    );
};

export default CanvasButtons;

