import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Device Detections
import { isMobile } from 'react-device-detect';

// Utilities
import { FormMode, UserPreference } from '../../utilities/Enums';

// Styles
import './Collections.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import CollectionsSearchButton from './collectionssearchbutton/CollectionsSearchButton';
import CollectionAddButton from './collectionaddbutton/CollectionAddButton';
import CollectionAdd from './collectionadd/CollectionAdd';
import CollectionItem from './collectionitem/CollectionItem';
import Search from '../../../common/search/Search';

// Managers
import ObjectManager from '../../../common/managers/ObjectManager';
import UserManager from '../../../common/managers/UserManager';

const objectManager = new ObjectManager();
const userManager = new UserManager();

/**
 * Collections Component
 * 
 * This component renders a collection-related menu items in the menu.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Collections = () => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        currentUser,
        models,
        resetVisibility,
        selectedApp,
        selectedModel,
        setCollectionAddOpen,
        setCurrentUser,
        setFormMode,
        setObjectsView,
        setSelectedModel,
        setSelectedObject,
    } = useContext(Global);

    // State Variables
    const [searchVisible, setSearchVisible] = useState(false);

    /**
     * Handles a collection click event.
     * 
     * @param {object} collection - Collection that was clicked on.
     */
    const handleCollectionClick = async (model) => {
        resetVisibility();

        // Show the add form on the desktop
        if (!isMobile) {
            setFormMode(FormMode.ADD);
        }

        setSelectedModel(model);

        // Set the last page preference
        const page = {
            section: 'COLLECTIONS',
            modelKey: model.key,
        }

        await userManager.setPreference(
            currentUser,
            setCurrentUser,
            selectedApp.key,
            UserPreference.LAST_PAGE,
            page
        );
    };

    /**
     * Handles an add collection button click event.
     */
    const handleAddCollectionClick = () => {
        setCollectionAddOpen(true);
    };

    const handleSearchClick = () => {
        setSearchVisible(true);
    };

    const handleSearch = async (entry) => {

        // Reset the visibility
        resetVisibility();

        // Make sure the form is selected instead of table
        setObjectsView("LIST");

        // Find the model in the models array and set it to active
        const model = models.find(model => model.key === entry.modelKey);
        setSelectedModel(model);

        // Fetch the selected object and set it to active
        const object = await objectManager.fetch(selectedApp.key, entry.modelKey, entry.objectKey);
        setSelectedObject(object);

        // Close the search dialog
        setSearchVisible(false);
    };

    return (
        <>
            {/* TITLE */}
            <div className="collections-group-title"
                style={{
                    color: theme.foregroundColorFaded,
                    fontFamily: selectedApp.fontFamily
                }}>
                Collections
            </div>

            {/* COLLECTION LIST */}
            <div className="collections-group">

                {/* SEARCH BUTTON */}
                <CollectionsSearchButton
                    isActive={false}
                    onClick={() => handleSearchClick()} />

                {/* COLLECTIONS */}
                {models.map(model => (
                    <CollectionItem
                        key={model.key}
                        model={model}
                        title={model.title}
                        isActive={selectedModel && selectedModel.key === model.key}
                        onClick={() => handleCollectionClick(model)} />
                ))}
                
                {/* ADD COLLECTION */}
                <CollectionAddButton
                    onClick={handleAddCollectionClick}
                />

                {/* FORM */}
                <CollectionAdd />

            </div>

            {/* SEARCH FORM */}
            <Search
                onSearch={handleSearch}
                isVisible={searchVisible}
                setVisible={setSearchVisible}
            />
        </>
    );
};

export default Collections;
