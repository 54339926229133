import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Utilities
import { MenuType, UserPreference } from '../../common/utilities/Enums';

// Styles
import './MenuHeader.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Images
import DockUpIcon from '../../common/svg/DockUpIcon';

// Managers
import UserManager from '../../common/managers/UserManager';

const userManager = new UserManager();

const MenuHeader = () => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        currentUser,
        selectedApp,
        setCurrentUser,
        setMenuType,
    } = useContext(Global);

    const handleDockUpClick = async () => {
        setMenuType(MenuType.TOP);

        // Call the setPreference method to update LAST_THEME settings
        await userManager.setPreference(
            currentUser,
            setCurrentUser,
            selectedApp.key,
            UserPreference.LAST_MENU_TYPE,
            MenuType.TOP
        );
    };

    return (

        <div className="menu-header-container">

            <div className="menu-header-dock-up-button"
                onClick={handleDockUpClick}>
                <DockUpIcon
                    color={theme.foregroundColorFaded}
                    width="23"
                    height="23"
                />
            </div>

        </div>
    );
};

export default MenuHeader;
