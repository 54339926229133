import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './ConversationToolBar.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Image
import DeleteIcon from '../../common/svg/DeleteIcon';
import BackIcon from '../../common/svg/BackIcon';

// Managers
import ConversationManager from '../../common/managers/ConversationManager';

const conversationManager = new ConversationManager();

const ConversationToolBar = () => {
    const { theme } = useTheme();
    const {
        selectedApp,
        selectedConversation,
        setSelectedConversation,
        currentUser
    } = useContext(Global);

    // Get the non-current-user participants
    const nonCurrentUserParticipants = selectedConversation?.participants
        ? selectedConversation.participants.filter(p => p.userKey !== currentUser.userKey)
        : [];

    // Get the first participant who isn't the current user
    const firstParticipant = nonCurrentUserParticipants.length > 0
        ? nonCurrentUserParticipants[0]
        : null;

    // Get the number of additional participants
    const additionalParticipantsCount = nonCurrentUserParticipants.length - 1;

    // Set the display name for the conversation toolbar
    const displayName = firstParticipant
        ? `${firstParticipant.userName}${additionalParticipantsCount > 0 ? ` + ${additionalParticipantsCount} Others` : ''}`
        : '';

    const handleDelete = async () => {
        await conversationManager.delete(selectedApp.key, selectedConversation.key);
        setSelectedConversation(null);
    };

    const handleBack = async () => {
        setSelectedConversation(null);
    };

    return (
        <div className="conversation-toolbar-container">
            {isMobile &&
                <div className="conversation-toolbar-back" onClick={handleBack}>
                    <BackIcon
                        color={theme.foregroundColor}
                        width="16"
                        height="16"
                    />
                </div>
            }
            <div className="conversation-toolbar-left"
                style={{ color: theme.foregroundColorFaded }}>
                {displayName}
            </div>
            <div className="conversation-toolbar-right">
                <div className="conversation-toolbar-button"
                    title="Delete Conversation"
                    onClick={handleDelete}>
                    <DeleteIcon
                        color={theme.foregroundColorFaded}
                        width="18"
                        height="18"
                    />
                </div>
            </div>
        </div >
    );
};

export default ConversationToolBar;
