import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../Global';

// Utilities
import { LoginMode } from '../../common/utilities/Enums';

// Router
import { useNavigate, useLocation } from 'react-router-dom';

// Managers
import InviteManager from '../../common/managers/InviteManager';
import UserManager from '../../common/managers/UserManager';

const inviteManager = new InviteManager();
const userManager = new UserManager();

/**
 * Custom hook to pre-process an invite on landing.
 */
const usePreprocessInvite = () => {

  // Global
  const { 
    currentUser,
    invite,
    setInvite,
    setLoginMode,
    setPage,
    setSelectedApp,
  } = useContext(Global);

  // If an invite exists, we were sent back to root without a querystring.
  // At this point, we can start processing the invite, and then clear
  // the invite when finished.
  useEffect(() => {

    if (invite) {

      async function preprocessInvite() {

        // Determine if a user with the email exists in the system yet. If not, they'll need
        // to sign up in order to continue.

        const user = await userManager.fetchUserWithEmail(invite.email);
        if (user) {

          if (!currentUser) {
            setLoginMode(LoginMode.LOGIN);
          } else {

            // User is currently signed in.

            // THE REMANING CODE HERE WILL ALSO BE RUN POST-LOGIN AND POST-SIGN IN IF
            // AN INVITE EXISTS IN STATE.

            await inviteManager.processInvite(
              currentUser,
              invite,
              setInvite,
              setSelectedApp,
              setPage
            );
          }
        } else {
          // Since the user doesn't exist yet, the process will be:
          // 1) Sign up and add user. Don't delete invite,
          //    as it will be detected post login to continue with steps 2 and 3.
          // 2) Add app user
          setLoginMode("SIGNUP");
        }
      }

      preprocessInvite();
    }
  }, [currentUser, invite, setInvite, setLoginMode, setPage, setSelectedApp]);
  
};

export default usePreprocessInvite;
