import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../Global';

// Utilities
import { UserPreference } from '../../common/utilities/Enums';

// Theme
import { useTheme } from '../../ThemeContext';

// Managers
import UserManager from '../../common/managers/UserManager';
const userManager = new UserManager();

/**
 * Custom hook to load a user's theme for the selected app.
 */
const useLoadThemePreference = () => {

  // Theme
  const {
    setCurrentTheme,
  } = useTheme();

  // Global
  const { 
    currentUser,
    selectedApp, 
  } = useContext(Global);

  useEffect(() => {

    if (!selectedApp) return;

    const lastTheme = userManager.getPreference(
      currentUser,
      selectedApp.key,
      UserPreference.LAST_THEME
    );

    if (lastTheme) {
      setCurrentTheme(lastTheme);
    }

  }, [selectedApp]);
  
};

export default useLoadThemePreference;
