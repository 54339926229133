import React from 'react';

// Styles
import './EmojiButton.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import EmojiIcon from '../../../../common/svg/EmojiIcon';

const EmojiButton = ({ element, object }) => {
    const { theme } = useTheme();

    return (
        <div className="emoji-button-container">
            <EmojiIcon
                color={theme.foregroundColorFaded}
                width="16"
                height="16"
            />
        </div>
    );
};

export default EmojiButton;
