import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Utilities
import { CalendarType } from '../../utilities/Enums';

// Styles
import './CalendarNav.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import LeftIcon from '../../svg/LeftIcon';
import RightIcon from '../../svg/RightIcon';

const CalendarNav = () => {
    const { theme } = useTheme();
    const {
        activeDate,
        setActiveDate,
        selectedCalendarType,
    } = useContext(Global);

    const handleLeftClick = () => {
        const newDate = new Date(activeDate);
        
        switch (selectedCalendarType) {
            case CalendarType.YEAR:
                newDate.setFullYear(activeDate.getFullYear() - 1);
                break;

            case CalendarType.MONTH:
                newDate.setMonth(activeDate.getMonth() - 1);
                break;

            case CalendarType.WEEK:
                newDate.setDate(activeDate.getDate() - 7); // Move back by 1 week
                break;

            case CalendarType.THREE:
                newDate.setDate(activeDate.getDate() - 3); // Move back by 3 days
                break;

            case CalendarType.DAY:
                newDate.setDate(activeDate.getDate() - 1); // Move back by 1 day
                break;

            default:
                break;
        }

        setActiveDate(newDate);
    };

    const handleRightClick = () => {
        const newDate = new Date(activeDate);
        
        switch (selectedCalendarType) {
            case CalendarType.YEAR:
                newDate.setFullYear(activeDate.getFullYear() + 1);
                break;

            case CalendarType.MONTH:
                newDate.setMonth(activeDate.getMonth() + 1);
                break;

            case CalendarType.WEEK:
                newDate.setDate(activeDate.getDate() + 7); // Move forward by 1 week
                break;

            case CalendarType.THREE:
                newDate.setDate(activeDate.getDate() + 3); // Move forward by 3 days
                break;

            case CalendarType.DAY:
                newDate.setDate(activeDate.getDate() + 1); // Move forward by 1 day
                break;

            default:
                break;
        }

        setActiveDate(newDate);
    };

    return (
        <div className="calendar-nav-container">
            {!isMobile &&
                <>
                    {/* LEFT */}
                    <div className="calendar-nav-button" onClick={handleLeftClick}>
                        <LeftIcon color={theme.foregroundColor} width="22" height="22" />
                    </div>

                    {/* RIGHT */}
                    <div className="calendar-nav-button" onClick={handleRightClick}>
                        <RightIcon color={theme.foregroundColor} width="22" height="22" />
                    </div>
                </>
            }
        </div>
    );
};

export default CalendarNav;
