import React, { useContext, useEffect, useState, useRef } from 'react';

// Global
import { Global } from '../../../../Global';

// Utilities
import { adjustTextareaHeight } from '../../../../common/utilities/Forms';

// Styles
import './Text.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import FieldContainer from '../../FieldContainer';
import FieldHeader from '../../FieldHeader';

/**
 * Text Component
 * 
 * This component renders a multi-line text input field.
 * 
 * - Default # lines: 6.
 * - Default maximum length: 10000 characters.
 * 
 * @param {object} object - The object containing field values.
 * @param {object} field - The field metadata.
 * @param {function} onUpdate - The function to call with updated values.
 * @param {boolean} showFieldMenu - Indicates whether the menu should be shown.
 * @param {boolean} readOnly - Indicates whether the input should be read-only.
 * @param {integer} lines - Number of lines.
 * @param {integer} maxLength - Maximum text length.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Text = ({
    object,
    field,
    onUpdate,
    showFieldMenu = true,
    readOnly = false,
    lines = 1,
    maxLength = 10000
}) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        selectedApp,
        selectedModel,
        setChangesMade
    } = useContext(Global);

    // State Variables
    const [value, setValue] = useState(''); // Default ADD mode value
    const [isTitle, setIsTitle] = useState(false);

    // References
    const inputRef = useRef(null);

    /**
     * Initializes the field
     */
    useEffect(() => {
        if (selectedModel.titleFieldKey === field.key) {
            setIsTitle(true);
        }
    }, []);

    /**
     * Initializes the field value.
     */
    useEffect(() => {
        setValue(object?.[field.key] || '');
    }, [object, field.key]);

    /**
     * Adusts the height of the input depending upon the length of the text.
     */
    useEffect(() => {
        if (inputRef.current) {
            adjustTextareaHeight(inputRef);
        }
    }, [value, readOnly]);

    /**
     * Method to focus the input when the container is clicked.
     * 
     * @param {string} e - Click event.
     */
    const handleContainerClick = (e) => {
        if (readOnly) return;
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    /**
     * Method to handle the blur of the field, which sends the update.
     */
    const handleBlur = () => {
        if (readOnly) return;
        onUpdate(field, value);
    };

    /**
     * Method to handle the change in value.
     * 
     * @param {string} e - Change event.
     */
    const handleChange = (e) => {
        setValue(e.target.value);
        setChangesMade(true);
    };

    /**
     * Method to replace line breaks in the text value with <br />.
     * 
     * @param {string} text - Text to format.
     */
    function replaceLineBreaks(text) {
        // Replace line breaks (newlines) with <br/> tags
        return text.split('\n').map((line, index) => (
            <span key={index}>
                {line}
                <br />
            </span>
        ));
    }
    
    return (
        <>
            {/* CONTAINER */}
            <FieldContainer
                field={field}
                readOnly={readOnly}
                onClick={handleContainerClick}>

                {/* HEADER */}
                {!(readOnly && selectedModel && selectedModel.titleFieldKey === field.key) &&
                    <FieldHeader
                        field={field}
                        value={value}
                        readOnly={readOnly}
                        showFieldMenu={showFieldMenu}
                    />
                }

                {/* READ-ONLY TEXT */}
                {readOnly ? (
                    <div className={selectedModel.titleFieldKey === field.key ? 'text-title' : 'text-value'}
                        style={{ color: theme.foregroundColor }}>
                        {replaceLineBreaks(value)}
                    </div>
                ) : (
                    <>
                        {/* INPUT */}
                        <textarea
                            ref={inputRef}
                            className="text-input"
                            style={{
                                color: theme.foregroundColor,
                                fontFamily: selectedApp.fontFamily,
                                fontWeight: isTitle ? 'bold' : 'normal',
                                fontSize: isTitle ? '16pt' : '11pt',
                            }}
                            value={value}
                            onChange={(e) => {
                                handleChange(e);
                                adjustTextareaHeight(inputRef);
                            }}
                            onBlur={handleBlur}
                            maxLength={maxLength}
                            rows={lines}
                            readOnly={readOnly}
                        />
                    </>
                )}

            </FieldContainer>
        </>

    );
};

export default Text;
