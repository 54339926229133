import React from 'react';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import CalendarWeekHour from './CalendarWeekHour';

// Styles
import './CalendarWeekHourBlock.css';

/**
 * CalendarWeekHourBlock Component
 * 
 * This component renders a row in a weekly calendar, displaying the hour label on
 * the left-hand side and an hour block for each day in the week.
 * 
 * @param {integer} hour - The hour of the day to display.
 * @param {integer} hourIndex - The index of the hour in the day.
 * @param {integer} leftOffset - The width of the label.
 * @param {Array<Date>} daysToShow - Array of dates representing each day to display.
 * @param {function} onHourClick - Handles click events on the hour block.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const CalendarWeekHourBlock = ({ hour, hourIndex, leftOffset, daysToShow }) => {
    
    // Theme
    const { theme } = useTheme(); 

    return (
        <div className="calendar-week-hour-block-container" key={hourIndex}>
            {/* Hour label on the left side */}
            <div
                className="calendar-week-hour-label"
                style={{
                    color: theme.foregroundColor,
                    borderRightColor: theme.backgroundColorFaded,
                    width: leftOffset + "px"
                }}
            >
                {hour}
            </div>
            
            {daysToShow.map((date, dayIndex) => {
                // Set the date for the specific hour in this block
                const hourDate = new Date(date.getTime());
                hourDate.setHours(hourIndex, 0, 0, 0); // Set exact hour for CalendarWeekHour

                return (
                    <CalendarWeekHour
                        key={`${dayIndex}_${hourIndex}`}
                        date={hourDate} // Pass exact hour date
                    />
                );
            })}
        </div>
    );
};

export default CalendarWeekHourBlock;
